/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react'
import { connect } from 'react-redux'
import { withTranslation, useTranslation, WithTranslation } from 'react-i18next'

import { projectSelectors, history } from '../../reducers'
import Button from '../../shared/components/Button'
import { projectActions, tutorialActions, userActions } from '../../actions'
import { ETUTORIAL_TYPES, ROUTER_PATH } from '../../constants'
import { tutorialSelectors, TutorialState } from '../../reducers/tutorial'
import NewUserTutorial from './components/NewUserTutorial'
import ProjectCreationTutorial from './components/ProjectCreationTutorial'
import Modal from '../../shared/components/Modal'
import ProjectSettingsTutorial from './components/ProjectSettingsTutorial'
import TasksTutorial from './components/TasksTutorial'

type Props = {
	tutorial: TutorialState
	draftProject: any
	changeTutorialField: any
	createProject: any
	resetDraftProject: any
	getCurrentUser: any
} & PropsWithChildren<WithTranslation>

const Tutorial = ({
	tutorial,
	draftProject,
	changeTutorialField,
	createProject,
	resetDraftProject,
	getCurrentUser,
}: Props) => {
	const { t } = useTranslation()

	if (!tutorial.modalOpen) {
		return null
	}

	const handleChangeStep = (nextStep: boolean) => {
		changeTutorialField({
			currentStep: nextStep
				? tutorial.currentStep + 1
				: tutorial.currentStep - 1,
		})
	}
	const handleCloseModal = () => {
		changeTutorialField({ modalOpen: false, currentStep: 0 })
		resetDraftProject()
	}
	const handleDoneButtonClick = async (type: ETUTORIAL_TYPES) => {
		switch (type) {
			case ETUTORIAL_TYPES.CREATE_PROJECT:
				await createProject({
					name: draftProject.name,
					projectUrl: draftProject.url,
					isEcommerce: draftProject.isEcommerce,
					locationCode: draftProject.locationCode,
					languageName: draftProject.languageName,
					conversionRate: draftProject.conversionRate
						? parseFloat(draftProject.conversionRate)
						: null,
					conversionValue: draftProject.conversionValue
						? parseInt(draftProject.conversionValue)
						: null,
				})
				await getCurrentUser()
				history.push(ROUTER_PATH.PROJECT_SETTINGS)

				break

			default:
				break
		}
		handleCloseModal()
	}
	const handleStepClick = (index: number) => {
		changeTutorialField({ currentStep: index })
	}

	const handleMaxStepChange = (steps: number) => {
		changeTutorialField({ maxSteps: steps })
	}

	const TutorialContent = (tutorialType: ETUTORIAL_TYPES) => {
		switch (tutorialType) {
			case ETUTORIAL_TYPES.FIRST_TIME_USER:
				return (
					<>
						<NewUserTutorial closeModal={handleCloseModal} />
					</>
				)
			case ETUTORIAL_TYPES.CREATE_PROJECT:
				return (
					<>
						<ProjectCreationTutorial
							maxStepsChange={handleMaxStepChange}
							closeModal={handleCloseModal}
							handleDoneButtonClick={handleDoneButtonClick}
							handleChangeStep={handleChangeStep}
						/>
					</>
				)
			case ETUTORIAL_TYPES.PROJECT_SETTINGS:
				return (
					<>
						<ProjectSettingsTutorial
							maxStepsChange={handleMaxStepChange}
							closeModal={handleCloseModal}
							handleDoneButtonClick={handleDoneButtonClick}
						/>
					</>
				)
			case ETUTORIAL_TYPES.TASKS:
				return <TasksTutorial closeModal={handleCloseModal} />
			default:
				return null
		}
	}

	const renderSteps = (maxSteps: number) => {
		const steps = []
		for (let index = 0; index < maxSteps + 1; index++) {
			steps.push(
				<p
					onClick={() => {
						//handleStepClick(index)
					}}
					className={`step-marker ${
						index === tutorial.currentStep ? 'active' : ''
					}`}
				></p>,
			)
		}
		return steps
	}
	return (
		<div>
			<Modal
				alignment={tutorial.modalAlignment}
				modalContainerClassName='tutorial-modal'
				contentClassName='tutorial-modal-content'
				show={tutorial.modalOpen}
				header={t('common.error modal title')}
				disabledClickOutside={true}
				closeModal={handleCloseModal}
			>
				{TutorialContent(tutorial.tutorialType)}
				<div>
					<div className='tutorial-step-buttons-holder'>
						{tutorial.currentStep === 0 ? (
							<Button
								className='step-button'
								text={t('tutorial.next step')}
								onClick={() => handleChangeStep(true)}
							/>
						) : tutorial.maxSteps === tutorial.currentStep ? (
							<>
								<Button
									className='step-button'
									text={t('tutorial.previous step')}
									property={tutorial.tutorialType}
									onClick={() => handleChangeStep(false)}
								/>
								<Button
									className='step-button'
									text={t('tutorial.finish')}
									property={tutorial.tutorialType}
									onClick={() => handleDoneButtonClick(tutorial.tutorialType)}
								/>
							</>
						) : (
							<>
								<Button
									className='step-button'
									text={t('tutorial.previous step')}
									property={tutorial.tutorialType}
									onClick={() => handleChangeStep(false)}
								/>
								<Button
									className='step-button'
									text={t('tutorial.next step')}
									onClick={() => handleChangeStep(true)}
									disabled={!tutorial.nextStepValid}
								/>
							</>
						)}
					</div>
					<div className='steps-container'>
						{renderSteps(tutorial.maxSteps)}
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default connect(
	(state: any) => ({
		tutorial: tutorialSelectors.getTutorial(state.tutorial),
		currentProject: projectSelectors.getProject(state.project),
		draftProject: projectSelectors.getDraftProject(state.project),
	}),
	dispatch => ({
		getCurrentUser: () => dispatch(userActions.getCurrentUser()),
		changeTutorialField: (payload: any) =>
			dispatch(tutorialActions.changeTutorialField(payload)),
		createProject: (payload: any) =>
			dispatch(projectActions.createProject(payload)),
		resetDraftProject: () => dispatch(projectActions.resetDraftProject()),
	}),
)(withTranslation()(Tutorial))
