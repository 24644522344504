import { API, CountryObj, IProject } from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import {
	CLEAR_KEYWORDS,
	DELETE_KEYWORD,
	GET_KEYWORDS,
	GET_KEYWORD_RANKING,
	GET_TOP_KEYWORDS,
	POST_KEYWORD_DATA,
	POST_KEYWORD_RANKING,
	SAVE_NEW_KEYWORD,
	BULK_SAVE_NEW_KEYWORD,
	CLEAR_KEYWORD_RANKING,
	CLEAR_KEYWORDS_STATE,
	GET_RANKED_KEYWORDS,
	GET_KEYWORD_RANKING_SUCCESS,
	POST_KEYWORD_DATA_SUCCESS,
	GET_COUNTRIES_SUCCESS,
	GET_TOP_KEYWORDS_SUCCESS,
	GET_RANKED_KEYWORDS_SUCCESS,
	GET_PROJECT_BY_ID,
	SAVE_KEYWORD_LABEL,
	GET_KEYWORD_LABEL_BY_PROJECT_ID,
	SAVE_KEYWORD_LABEL_SUCCESS,
	GET_KEYWORD_LABEL_BY_PROJECT_ID_SUCCESS,
	UPDATE_KEYWORD_LABEL_BY_ID,
	UPDATE_KEYWORD_LABEL_BY_ID_SUCCESS,
	UPDATE_SELECTED_KEYWORDS_FOR_LABEL,
	GET_KEYWORD_RANKING_FOR_CHECKING,
	GET_KEYWORD_RANKING_FOR_CHECKING_SUCCESS,
} from './types'
import { Action, ActionWithoutPayload, SignOut } from './audit'
import {
	KeywordRanking,
	RankedKeyword,
	SuggestedKeywordObject,
	SuggestedKeywordPostData,
	TopKeyword,
} from '../reducers/keywords'
import { CreateProject } from './project'

type GetKeyWords = Action<typeof GET_KEYWORD_RANKING_SUCCESS, KeywordRanking[]>
type PostKeywordData = Action<
	typeof POST_KEYWORD_DATA_SUCCESS,
	SuggestedKeywordObject[]
>
type ClearKeywords = ActionWithoutPayload<typeof CLEAR_KEYWORDS>
type ClearKeywordRanking = ActionWithoutPayload<typeof CLEAR_KEYWORD_RANKING>
type ClearKeywordsState = ActionWithoutPayload<typeof CLEAR_KEYWORDS_STATE>
type GetCountries = Action<typeof GET_COUNTRIES_SUCCESS, CountryObj[]>
type GetTopKeywords = Action<typeof GET_TOP_KEYWORDS_SUCCESS, TopKeyword[]>
type GetRankedKeywords = Action<
	typeof GET_RANKED_KEYWORDS_SUCCESS,
	RankedKeyword[]
>
type SaveKeywordLabel = Action<typeof SAVE_KEYWORD_LABEL_SUCCESS, any>
type GetKeywordLabelByProjectId = Action<
	typeof GET_KEYWORD_LABEL_BY_PROJECT_ID_SUCCESS,
	any
>
type UpdateKeywordLabel = Action<typeof UPDATE_KEYWORD_LABEL_BY_ID_SUCCESS, any>
export type GetProjectById = Action<typeof GET_PROJECT_BY_ID, IProject>
export type GetKeywordRankingForChecking = Action<
	typeof GET_KEYWORD_RANKING_FOR_CHECKING_SUCCESS,
	string[]
>

export type KeywordActions =
	| GetKeyWords
	| PostKeywordData
	| ClearKeywords
	| ClearKeywordRanking
	| ClearKeywordsState
	| GetCountries
	| GetTopKeywords
	| GetRankedKeywords
	| GetProjectById
	| CreateProject
	| SaveKeywordLabel
	| GetKeywordLabelByProjectId
	| UpdateKeywordLabel
	| SignOut
	| GetKeywordRankingForChecking

export type NewKeyword = { keyword: string; searchVolume: number }
export type NewKeywordBulk = { keyword: string }[]
export type PostKeywordDataPayload = {
	keyword: string
	locationCode: number
	languageName: string
	depth: SuggestedKeywordPostData['depth']
}
export type RankedKeywordRequest = {
	domain: string
	locationCode: number
	languageName: string
}
export type KeywordLabelData = {
	label: string
	keywords: string[]
}
export const saveNewKeyword = (projectId: number, data: NewKeyword): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.SAVE_NEW_KEYWORD,
	)
	return {
		type: SAVE_NEW_KEYWORD,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}
export const saveBulkNewKeyword = (
	projectId: number,
	data: NewKeywordBulk,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.BULK_SAVE_NEW_KEYWORD,
	)
	return {
		type: BULK_SAVE_NEW_KEYWORD,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

// export const updateKeyword = (projectId: number, data) => {
// 	const apiUri = replaceParamsUri(
// 		['projectId'],
// 		[projectId],
// 		API.UPDATE_KEYWORD,
// 	)
// 	return {
// 		type: UPDATE_KEYWORD,
// 		payload: {
// 			request: {
// 				method: 'PATCH',
// 				url: apiUri,
// 				data,
// 			},
// 		},
// 	}
// }

export const getKeyWords = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_KEYWORD_RANKING,
	)
	return {
		type: GET_KEYWORDS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const deleteKeyword = (projectId: number, keywordId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'keywordId'],
		[projectId, keywordId],
		API.DELETE_KEYWORD,
	)
	return {
		type: DELETE_KEYWORD,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUri,
			},
		},
	}
}

export const postKeywordData = (
	projectId: number,
	data: PostKeywordDataPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.POST_KEYWORD_DATA,
	)
	return {
		type: POST_KEYWORD_DATA,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const getKeywordRanking = (projectId: number, filter = ''): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_KEYWORD_RANKING,
	)
	return {
		type: GET_KEYWORD_RANKING,
		payload: {
			request: {
				method: 'GET',
				url: `${apiUri}?filter=${filter}`,
			},
		},
	}
}

export const getKeywordRankingForChecking = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_KEYWORD_RANKING_FOR_CHECKING,
	)
	return {
		type: GET_KEYWORD_RANKING_FOR_CHECKING,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const postKeywordRanking = (
	projectId: number,
	data: NewKeyword,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.POST_KEYWORD_RANKING,
	)
	return {
		type: POST_KEYWORD_RANKING,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const clearKeywordRanking = (): any => {
	return {
		type: CLEAR_KEYWORD_RANKING,
	}
}

export const clearKeywordsState = (): any => {
	return {
		type: CLEAR_KEYWORDS_STATE,
	}
}

export const clearKeywords = (): any => {
	return {
		type: CLEAR_KEYWORDS,
	}
}

export const getTopKeywords = (
	projectId: number,
	queryObj: Record<string, any>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'query'],
		[projectId, new URLSearchParams(queryObj as Record<string, any>)],
		API.GET_GOOGLE_SEARCH_CONSOLE_DATA,
	)
	return {
		type: GET_TOP_KEYWORDS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getRankedKeywords = (
	projectId: number,
	keywordData: RankedKeywordRequest,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_RANKED_KEYWORDS,
	)
	return {
		type: GET_RANKED_KEYWORDS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: keywordData,
			},
		},
	}
}

export const saveKeywordLabel = (
	projectId: number,
	keywordLabelData: KeywordLabelData,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.SAVE_KEYWORD_LABEL,
	)
	return {
		type: SAVE_KEYWORD_LABEL,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: keywordLabelData,
			},
		},
	}
}

export const getKeywordLabelByProjectId = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_KEYWORD_LABEL_BY_PROJECT_ID,
	)
	return {
		type: GET_KEYWORD_LABEL_BY_PROJECT_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const updateKeywordLabel = (
	projectId: number,
	labelId: number,
	keywordLabelData: KeywordLabelData,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'labelId'],
		[projectId, labelId],
		API.UPDATE_KEYWORD_LABEL_BY_ID,
	)
	return {
		type: UPDATE_KEYWORD_LABEL_BY_ID,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data: keywordLabelData,
			},
		},
	}
}
