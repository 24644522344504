import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { languages } from '../constants'

export const formatSumsIntl = number => {
	if (isNaN(number)) {
		return number
	}
	return new Intl.NumberFormat('fi-FI', {
		style: 'currency',
		currency: 'EUR',
	}).format(number)
}

export const formatAmountsIntl = number => {
	if (isNaN(number)) {
		return number
	}
	return new Intl.NumberFormat('nb-NO').format(number)
}
i18n
	// load translation using xhr -> see /public/locales
	// learn more: https://github.com/i18next/i18next-xhr-backend
	.use(XHR)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: languages.en,
		lng: window.localStorage.getItem('i18nextLng'),
		debug: true,
		react: {
			transKeepBasicHtmlNodesFor: ['strong'],
			transSupportBasicHtmlNodes: true,
			wait: true,
			useSuspense: process.env.NODE_ENV !== 'test',
		},
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	})

export default i18n
