import _ from 'lodash'

import { ReportActions } from '../actions/report'
import {
	CHANGE_DRAFT_REPORT_TEMPLATE_FIELD,
	CHANGE_DRAFT_REPORT_PAGE_FIELD,
	CLEAR_DRAFT_PROJECT_TEMPLATE,
	GET_REPORT_DEFAULT_TEMPLATES_SUCCESS,
	GET_REPORT_TEMPLATES_SUCCESS,
	POST_REPORT_TEMPLATE_SUCCESS,
	POST_UPDATE_REPORT_TEMPLATE_SUCCESS,
	SELECT_REPORT_TEMPLATE,
	NEW_CUSTOM_REPORT,
	GET_REPORT_COMPONENT_DATA_SUCCESS,
	GET_CUSTOM_REPORT_DATA_SUCCESS,
	CLEAR_RAW_DATA_BY_ID,
	SET_SELECTED_REPORT_COMPONENT,
	COPY_SELECTED_REPORT_COMPONENT,
	DELETE_SELECTED_REPORT_COMPONENT,
	SIGN_OUT,
	GET_PROJECT_BY_ID_SUCCESS,
} from '../actions/types'

import {
	IReportComponent,
	IReportPage,
	ReportTemplatesType,
} from '../constants'
import { formatCustomReportChartData } from '../helpers/typeDataTransformer'
import { v4 } from 'uuid'

export type GetReportComponentDataResponse = {
	id: string
	data: {
		currentPeriod: any[]
		comparisonPeriod: any[]
	}
}
export type ReportState = {
	draft: ReportTemplatesType
	templates: ReportTemplatesType[]
	defaultTemplates: ReportTemplatesType[]
	selectedTemplate: ReportTemplatesType
	selectedComponent: (IReportComponent & { pageNumber: number }) | null
	rawData: Record<string, Record<string, any>[]>
}

const initialState: ReportState = {
	draft: {
		name: '',
		items: [],
		type: null,
		layout: {
			name: 'Default A4',
			width: '794px',
			height: '1107px',
		},
	},
	templates: [],
	defaultTemplates: [],
	selectedTemplate: {
		name: '',
		items: [],
		type: null,
		layout: {
			name: 'Default A4',
			width: '794px',
			height: '1107px',
		},
	},
	selectedComponent: null,
	rawData: {},
}

export const initialReportPage: IReportPage = {
	pageNumber: 1,
	items: [],
	logo: undefined,
	color: undefined,
	title: '',
	theme: {
		textStyles: {},
		componentStyles: {},
		pageStyles: {},
	},
}

export const reportReducer = (
	state = initialState,
	action: ReportActions,
): typeof initialState => {
	switch (action.type) {
		case CHANGE_DRAFT_REPORT_TEMPLATE_FIELD:
			return {
				...state,
				draft: { ...state.draft, [action.payload.field]: action.payload.value },
			}
		case CHANGE_DRAFT_REPORT_PAGE_FIELD:
			{
				if (state.draft.type === 'CUSTOM') {
					const clonedItems = _.cloneDeep(state.draft.items)
					clonedItems[action.payload.index] = {
						...clonedItems[action.payload.index],
						[action.payload.field]: action.payload.value,
					}
					return {
						...state,
						draft: { ...state.draft, items: clonedItems },
					}
				}
			}
			return state
		case NEW_CUSTOM_REPORT:
			return {
				...state,
				draft: {
					...initialState.draft,
					type: 'CUSTOM',
					theme: {
						textStyles: {},
						componentStyles: {},
						pageStyles: {},
					},
					layout: {
						name: 'Default A4',
						width: '794px',
						height: '1107px',
					},
					items: [{ ...initialReportPage }],
				},
			}
		case CLEAR_DRAFT_PROJECT_TEMPLATE:
			return { ...state, draft: { ...initialState.draft } }
		case GET_REPORT_TEMPLATES_SUCCESS:
			return { ...state, templates: action.payload.data }
		case GET_REPORT_DEFAULT_TEMPLATES_SUCCESS:
			return { ...state, defaultTemplates: action.payload.data }
		case SET_SELECTED_REPORT_COMPONENT:
			return { ...state, selectedComponent: action.payload }
		case COPY_SELECTED_REPORT_COMPONENT: {
			if (state.draft.type === 'CUSTOM' && state.selectedComponent) {
				const clonedItems = _.cloneDeep(state.draft.items)
				const { pageNumber, ...rest } = _.cloneDeep(state.selectedComponent)
				clonedItems[pageNumber].items.push(
					_.cloneDeep({
						...rest,
						id: v4(),
						title: rest.title ? `Copy of ${rest.title}` : '',
						placement: {
							...rest.placement,
							left: rest?.placement.left + 150,
							top: rest?.placement.top + 50,
						},
					}),
				)
				return {
					...state,
					draft: { ...state.draft, items: clonedItems },
					selectedComponent: null,
				}
			}
			return { ...state }
		}
		case DELETE_SELECTED_REPORT_COMPONENT: {
			if (state.draft.type === 'CUSTOM' && state.selectedComponent) {
				const clonedItems = _.cloneDeep(state.draft.items)
				const { pageNumber, ...rest } = _.cloneDeep(state.selectedComponent)
				const filteredItems = clonedItems[pageNumber].items.filter(
					item => item.id !== state.selectedComponent?.id,
				)
				clonedItems[pageNumber].items = filteredItems
				return {
					...state,
					draft: { ...state.draft, items: clonedItems },
					selectedComponent: null,
				}
			}
			return { ...state }
		}
		case GET_REPORT_COMPONENT_DATA_SUCCESS: {
			const { data } = action.payload.data
			if (!data || (data && !data.currentPeriod)) {
				return state
			}

			const clonedData = _.cloneDeep(state.rawData)
			if (clonedData[action.payload.data.id]) {
				delete clonedData[action.payload.data.id]
			}
			clonedData[action.payload.data.id] = formatCustomReportChartData(
				data.comparisonPeriod,
				data.currentPeriod,
			)
			return {
				...state,
				rawData: clonedData,
			}
		}
		case GET_CUSTOM_REPORT_DATA_SUCCESS: {
			const { data } = action.payload
			const combinedData = data.reduce((all: Record<string, any[]>, curr) => {
				all[curr.id] = formatCustomReportChartData(
					curr.data.comparisonPeriod,
					curr.data.currentPeriod,
				)
				return all
			}, {})
			return {
				...state,
				rawData: {
					...state.rawData,
					...combinedData,
				},
			}
		}

		case CLEAR_RAW_DATA_BY_ID:
			return {
				...state,
				rawData: {
					...state.rawData,
					[action.payload.id]: [],
				},
			}

		case POST_UPDATE_REPORT_TEMPLATE_SUCCESS: {
			const updateIndex = state.templates.findIndex(
				template => template.name === action.payload.data.name,
			)
			const clonedArray = [...state.templates]
			clonedArray[updateIndex] = action.payload.data
			return { ...state, templates: clonedArray }
		}
		case POST_REPORT_TEMPLATE_SUCCESS:
			return { ...state, templates: [...state.templates, action.payload.data] }
		case SELECT_REPORT_TEMPLATE:
			return {
				...state,
				selectedTemplate: { ...state.selectedTemplate, ...action.payload },
			}
		case GET_PROJECT_BY_ID_SUCCESS:
		case SIGN_OUT:
			return { ...initialState }
		default:
			return state
	}
}

export const reportSelectors = {
	getDraftTemplate: (state = initialState): ReportTemplatesType => state.draft,
	getTemplates: (state = initialState): ReportTemplatesType[] =>
		state.templates,
	getDefaultTemplates: (state = initialState): ReportTemplatesType[] =>
		state.defaultTemplates,
	getSelectedTemplate: (state = initialState): ReportTemplatesType =>
		state.selectedTemplate,
}
