/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation, useTranslation, WithTranslation } from 'react-i18next'
import { projectSelectors, userSelectors } from '../../../reducers'

import { projectActions, tutorialActions } from '../../../actions'
import { EHIGHLIGHT, ETUTORIAL_TYPES } from '../../../constants'
import Button from '../../../shared/components/Button'
import { tutorialSelectors } from '../../../reducers/tutorial'

import { TutorialState } from '../../../reducers/tutorial'

type Props = {
	currentProject: any
	tutorial: TutorialState
	editDraftProject: any
	changeTutorialField: any
	allCountries: any
	draftProject: any
	closeModal: any
	maxStepsChange: any
	handleDoneButtonClick: (type: ETUTORIAL_TYPES) => void
	user: any
} & PropsWithChildren<WithTranslation>

const ProjectSettingsTutorial = ({
	currentProject,
	tutorial,
	editDraftProject,
	changeTutorialField,
	allCountries,
	draftProject,
	closeModal,
	maxStepsChange,
	handleDoneButtonClick,
	user,
}: Props): JSX.Element | null => {
	const { t } = useTranslation()

	const tutorialOptions = {
		// projectSettings: {
		0: { highlighted: EHIGHLIGHT.NONE, modalAlignment: 'center' },
		1: { highlighted: EHIGHLIGHT.PROJECT_NAME, modalAlignment: 'left' },
		2: { highlighted: EHIGHLIGHT.PROJECT_URL, modalAlignment: 'left' },

		3: { highlighted: EHIGHLIGHT.COUNTRY_SELECTION, modalAlignment: 'left' },
		4: { highlighted: EHIGHLIGHT.LANGUAGE_SELECTION, modalAlignment: 'left' },
		5: { highlighted: EHIGHLIGHT.E_COMMERCE, modalAlignment: 'left' },
		6: { highlighted: EHIGHLIGHT.CONVERSION_VALUE, modalAlignment: 'left' },

		7: { highlighted: EHIGHLIGHT.CONVERSION_RATE, modalAlignment: 'left' },
		8: { highlighted: EHIGHLIGHT.NOTIFICATION, modalAlignment: 'left' },
		9: { highlighted: EHIGHLIGHT.DELETE_PROJECT, modalAlignment: 'left' },

		10: { highlighted: EHIGHLIGHT.DATASOURCES, modalAlignment: 'right' },
		11: { highlighted: EHIGHLIGHT.GENERATE_TASKS, modalAlignment: 'right' },
		// },
		// dashboard: {
		// 	0: { highlighted: 'tools', modalAlignment: 'right' },
		// },
	}

	type TutorialKeys = keyof typeof tutorialOptions

	// type TutorialOptions = {
	// 	[K in TutorialKeys]: {
	// 		[J in K]: Pick<TutorialState, 'highlighted' | 'modalAlignment'>
	// 	}
	// }

	// type StepsKeys<K extends TutorialKeys> = Pick<TutorialOptions, K>

	useEffect(() => {
		changeTutorialField(
			tutorialOptions[
				tutorial.currentStep as unknown as TutorialKeys
			] as unknown as typeof tutorialOptions,
		)
		return () => {
			changeTutorialField({ highlighted: EHIGHLIGHT.NONE })
		}
	}, [tutorial.currentStep])

	const summaryStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 1 headline')}
			</h6>
			<p>{t('tutorial.settings tutorial.step 1 text')}</p>
		</div>
	)
	const editNameStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 2 headline')}
			</h6>
			<p>{t('tutorial.settings tutorial.step 2 text')}</p>
		</div>
	)
	const projectUrlStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 3 headline')}
			</h6>
			<p>{t('tutorial.settings tutorial.step 3 text')}</p>
		</div>
	)
	const countrySelectionStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 4 headline')}
			</h6>
			<p>{t('tutorial.settings tutorial.step 4 text')}</p>
		</div>
	)
	const languageSelectionStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 5 headline')}
			</h6>
			<p>{t('tutorial.settings tutorial.step 5 text')}</p>
		</div>
	)
	const isECommerceStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 6 headline')}
			</h6>
			<p>{t('tutorial.settings tutorial.step 6 text')}</p>
		</div>
	)
	const conversionValueStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 7 headline')}
			</h6>
			{t('tutorial.settings tutorial.step 7 text')}
		</div>
	)
	const conversionRateStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 8 headline')}
			</h6>
			{t('tutorial.settings tutorial.step 8 text')}
		</div>
	)
	const analyticsGoalsStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>Project settings tutorial</h6>
			<p>Use this to see analytics goals</p>
		</div>
	)
	const notificationStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 9 headline')}
			</h6>
			<p>{t('tutorial.settings tutorial.step 9 text')}</p>
		</div>
	)
	const deleteProjectStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 10 headline')}
			</h6>
			<p>{t('tutorial.settings tutorial.step 10 text')}</p>
		</div>
	)

	const dataSourcesStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 11 headline')}
			</h6>
			<p>{t('tutorial.settings tutorial.step 11 text')}</p>
		</div>
	)
	const generateTasksStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 12 headline')}
			</h6>
			<p>{t('tutorial.settings tutorial.step 12 text')}</p>
		</div>
	)
	const finishStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.settings tutorial.step 13 headline')}
			</h6>
			<p>{t('tutorial.settings tutorial.step 13 text')}</p>
		</div>
	)

	switch (tutorial.currentStep) {
		case 0:
			return summaryStep()
		case 1:
			return editNameStep()
		case 2:
			return projectUrlStep()
		case 3:
			return countrySelectionStep()
		case 4:
			return languageSelectionStep()
		case 5:
			return isECommerceStep()
		case 6:
			return conversionValueStep()
		case 7:
			return conversionRateStep()
		case 8:
			return notificationStep()
		case 9:
			return deleteProjectStep()
		case 10:
			return dataSourcesStep()
		case 11:
			return generateTasksStep()
		case 12:
			return finishStep()
		default:
			return null
	}
}

export default connect(
	(state: any) => ({
		allCountries: state.keywords.allCountries,
		currentProject: projectSelectors.getProject(state.project),
		draftProject: projectSelectors.getDraftProject(state.project),
		tutorial: tutorialSelectors.getTutorial(state.tutorial),
		user: userSelectors.getUser(state.user),
	}),
	dispatch => ({
		editDraftProject: (payload: any) =>
			dispatch(projectActions.editDraftProject(payload)),
		changeTutorialField: (payload: any) =>
			dispatch(tutorialActions.changeTutorialField(payload)),
	}),
)(withTranslation()(ProjectSettingsTutorial))
