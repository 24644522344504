/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */

import { INotification, MessageActions } from '../actions/message'
import {
	SHOW_MESSAGE,
	CLEAR_MESSAGE,
	CREATE_PROJECT_SUCCESS,
	SAVE_SITE_URL_SUCCESS,
	POST_KEYWORD_RANKING_SUCCESS,
	SIGN_OUT,
	BULK_SAVE_NEW_KEYWORD_SUCCESS,
	CREATE_BULK_PROJECTS_SUCCESS,
	SCHEDULE_FACEBOOK_POST_SUCCESS,
	POST_GENERATE_SUGGESTIONS_BY_KEYWORDS_SUCCESS,
	SCHEDULE_INSTAGRAM_POST_SUCCESS,
	PUBLISH_INSTAGRAM_POST_SUCCESS,
	INVITE_USER_TO_COMPANY_SUCCESS,
	DELETE_FACEBOOK_POST_SUCCESS,
	DELETE_INSTAGRAM_SCHEDULED_POST_SUCCESS,
	SET_NOTIFICATIONS,
	INVITE_CONTRIBUTOR_TO_PROJECT_SUCCESS,
} from '../actions/types'
import i18n from '../services/i18n'

type MessageState = {
	message: string
	notifications: INotification[]
}
const initialState: MessageState = {
	message: '',
	notifications: [],
}

export const messageReducer = (
	state = initialState,
	action: MessageActions,
): typeof initialState => {
	switch (action.type) {
		case SCHEDULE_INSTAGRAM_POST_SUCCESS:
		case PUBLISH_INSTAGRAM_POST_SUCCESS:
		case SCHEDULE_FACEBOOK_POST_SUCCESS:
			return {
				...state,
				message: i18n.t('messages.post created succesfully'),
			}
		case DELETE_FACEBOOK_POST_SUCCESS:
			return {
				...state,
				message: i18n.t('messages.post deleted succesfully'),
			}
		case DELETE_INSTAGRAM_SCHEDULED_POST_SUCCESS:
			return {
				...state,
				message: i18n.t('messages.post deleted succesfully'),
			}
		case SHOW_MESSAGE:
			return {
				...state,
				message: action.payload.message,
			}
		case CREATE_PROJECT_SUCCESS:
			return {
				...state,
				message: i18n.t('messages.project created succesfully'),
			}
		case SAVE_SITE_URL_SUCCESS:
			return {
				...state,
				message: i18n.t('messages.technical audit started'),
			}
		case SIGN_OUT:
			return initialState
		case POST_KEYWORD_RANKING_SUCCESS:
			return {
				...state,
				message: i18n.t('messages.keywords saved'),
			}
		case POST_GENERATE_SUGGESTIONS_BY_KEYWORDS_SUCCESS:
			return {
				...state,
				message: i18n.t('messages.generating tasks'),
			}
		case BULK_SAVE_NEW_KEYWORD_SUCCESS:
			return {
				...state,
				message: i18n.t('messages.keywords saved'),
			}
		case CREATE_BULK_PROJECTS_SUCCESS: {
			const projectCount = action.payload.data && action.payload.data.length
			return {
				...state,
				message: `${projectCount} ${i18n.t('messages.new projects saved')}`,
			}
		}
		case INVITE_USER_TO_COMPANY_SUCCESS:
			return { ...state, message: i18n.t('messages.new user invited') }
		case INVITE_CONTRIBUTOR_TO_PROJECT_SUCCESS:
			return { ...state, message: i18n.t('messages.new user invited') }
		case SET_NOTIFICATIONS: {
			return { ...state, notifications: action.payload.notifications }
		}
		case CLEAR_MESSAGE:
			return initialState
		default:
			return state
	}
}

export const messageSelectors = {
	getMessage: (state = initialState): string => state.message,
}
