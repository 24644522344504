import {
	API,
	EAiArticleType,
	EAiBulkGenerationType,
	ECharacteristicOfThePost,
	EDayOfTheWeek,
	ETypeOfPost,
	IBulkGenerationExcelData,
} from './../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import {
	CHANGE_AI_FIELD,
	GET_POSTS_AI_CONTENT,
	GET_POSTS_AI_CONTENT_SUCCESS,
	CHANGE_AI_CREATED_CONTENT,
	CHANGE_AI_HEADLINE_KEYWORD,
	GET_AI_CONTENT,
	GET_AI_CONTENT_SUCCESS,
	GET_AI_HEADLINES,
	GET_AI_HEADLINES_SUCCESS,
	RESET_AI_CONTENT,
	CHANGE_AI_OPTION_KEYWORD,
	GET_CONTENT_IN_BULK,
	GET_ALL_READY_MODEL,
	GET_ALL_READY_MODEL_SUCCESS,
	CREATE_FINETUNE_MODEL,
	CREATE_FINETUNE_MODEL_SUCCESS,
	AI_IMAGE_GENERATOR,
	AI_IMAGE_GENERATOR_SUCCESS,
	LIST_ALL_FINETUNE_FILES,
	UPDATE_SAVED_FINETUNE_FILE,
	SEND_FINETUNE_FILE_TO_OPENAI,
	GET_SAVED_FINETUNE_FILE,
	POST_GOOGLE_ADS_HEADLINES,
	POST_GOOGLE_ADS_DESCRIPTION,
	GENERATE_SUBHEADINGS,
	GENERATE_BLOG_CONTENT,
	GET_ALL_AI_ARTICLE,
	GET_ALL_AI_ARTICLE_SUCCESS,
	GET_AI_PRODUCT_DESCRIPTION,
	GET_AI_PRODUCT_DESCRIPTION_SUCCESS,
	GET_ALL_AI_CATEGORY_DESCRIPTION,
	GET_ALL_AI_CATEGORY_DESCRIPTION_SUCCESS,
	TRANSLATE_CONTENT,
	TRANSLATE_CONTENT_SUCCESS,
	CREATE_AI_BULK_GENERATION,
	GET_ALL_AI_BULK_GENERATION,
	GET_ALL_AI_BULK_GENERATION_SUCCESS,
} from './types'
import { ActionWithoutPayload } from './audit'
import { ActionChangeField } from './ads'
import {
	AiArticle,
	AiBulkGeneration,
	AiProductDescriptionType,
	SuggestedContent,
} from '../reducers/ai-content'
import { GetProjectById } from './project'

interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P }
}

export type imgResponse = {
	payload: {
		data: { revised_prompt: string; url?: string; b64_json?: string }
	}
}

export type ChangeAIField = ActionChangeField<
	typeof CHANGE_AI_FIELD,
	{
		field: string
		value: string | boolean | string[] | number | unknown
	}
>
export type GetAllReadyModel = Action<typeof GET_ALL_READY_MODEL_SUCCESS, any>
export type CreateFinetuneModel = Action<
	typeof CREATE_FINETUNE_MODEL_SUCCESS,
	any
>
export type AiImageGenerator = Action<typeof AI_IMAGE_GENERATOR_SUCCESS, any>
type GetAiContent = Action<typeof GET_AI_CONTENT_SUCCESS, { content: string }>
type GetSuggestedPosts = Action<
	typeof GET_POSTS_AI_CONTENT_SUCCESS,
	SuggestedContent[]
>
type ChangeHeadlineKeyword = ActionChangeField<
	typeof CHANGE_AI_HEADLINE_KEYWORD,
	string
>
type ChangeCreatedBlogContent = ActionChangeField<
	typeof CHANGE_AI_CREATED_CONTENT,
	string
>
type GetAiHeadlines = Action<
	typeof GET_AI_HEADLINES_SUCCESS,
	{ content: string }
>
type ChangeAiOptionKeyword = ActionChangeField<
	typeof CHANGE_AI_OPTION_KEYWORD,
	string
>
type ResetAiContent = ActionWithoutPayload<typeof RESET_AI_CONTENT>
type GetALlAiArticle = Action<typeof GET_ALL_AI_ARTICLE_SUCCESS, AiArticle[]>
type GetAiProductDescription = Action<
	typeof GET_AI_PRODUCT_DESCRIPTION_SUCCESS,
	AiProductDescriptionType
>
type GetAllAiCategoryDescription = Action<
	typeof GET_ALL_AI_CATEGORY_DESCRIPTION_SUCCESS,
	any
>
type TranslateContent = Action<typeof TRANSLATE_CONTENT_SUCCESS, string>
type GetAllAiBulkGeneration = Action<
	typeof GET_ALL_AI_BULK_GENERATION_SUCCESS,
	AiBulkGeneration[]
>

export type AiContentActions =
	| GetAiContent
	| ResetAiContent
	| GetSuggestedPosts
	| ChangeAIField
	| GetAiHeadlines
	| ChangeHeadlineKeyword
	| ChangeCreatedBlogContent
	| ChangeAiOptionKeyword
	| GetProjectById
	| GetALlAiArticle
	| GetAiProductDescription
	| GetAllAiCategoryDescription
	| TranslateContent
	| GetAllAiBulkGeneration

export const resetAiContent = (): any => {
	return {
		type: RESET_AI_CONTENT,
	}
}

export const changeAIField = (
	field: string,
	value: string | boolean | string[] | number | unknown,
): ChangeAIField => {
	return {
		type: CHANGE_AI_FIELD,
		payload: {
			field,
			value,
		},
	}
}
export const getSuggestedPosts = (
	projectId: number,
	keyword: string,
	typeOfPost: ETypeOfPost,
	characteristicOfThePost: ECharacteristicOfThePost,
	dayOfTheWeek: EDayOfTheWeek,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_POST_AI_CONTENT,
	)
	return {
		type: GET_POSTS_AI_CONTENT,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					keyword,
					typeOfPost,
					characteristicOfThePost,
					dayOfTheWeek,
				},
			},
		},
	}
}
export const changeAiOptionKeyword = (keyword: string): any => {
	return {
		type: CHANGE_AI_OPTION_KEYWORD,
		payload: keyword,
	}
}
export const changeHeadlineKeyword = (keyword: string): any => {
	return {
		type: CHANGE_AI_HEADLINE_KEYWORD,
		payload: keyword,
	}
}
export const changeCreatedContent = (content: string): any => {
	return {
		type: CHANGE_AI_CREATED_CONTENT,
		payload: content,
	}
}

export const getAiContent = (
	projectId: number,
	companyId: number,
	keyword: string,
	typeOfPost: ETypeOfPost,
	characteristicOfThePost: ECharacteristicOfThePost,
	feature?: string,
	includeEmoji?: boolean,
	language = 'FI',
): any => {
	const apiUri = replaceParamsUri(
		['companyId', 'projectId'],
		[companyId, projectId],
		API.POST_AI_CONTENT,
	)
	return {
		type: GET_AI_CONTENT,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					keyword,
					language,
					typeOfPost,
					characteristicOfThePost,
					feature,
					includeEmoji,
				},
			},
		},
	}
}
export const getAiHeadlines = (
	companyId: number,
	projectId: number,
	keyword: string,
	typeOfPost: ETypeOfPost,
	characteristicOfThePost: ECharacteristicOfThePost,
	systemInput?: string,
	language = 'FI',
): any => {
	const apiUri = replaceParamsUri(
		['companyId', 'projectId'],
		[companyId, projectId],
		API.POST_AI_HEADLINES,
	)
	return {
		type: GET_AI_HEADLINES,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					keyword,
					language,
					typeOfPost,
					characteristicOfThePost,
					systemInput,
				},
			},
		},
	}
}
export const getGoogleAdsHeadlines = (url: string, projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.POST_GOOGLE_ADS_HEADLINES,
	)
	return {
		type: POST_GOOGLE_ADS_HEADLINES,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					url,
				},
			},
		},
	}
}
export const getGoogleAdsDescriptions = (
	url: string,
	headline: string,
	projectId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.POST_GOOGLE_ADS_DESCRIPTIONS,
	)
	return {
		type: POST_GOOGLE_ADS_DESCRIPTION,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					url,
					headline,
				},
			},
		},
	}
}
export const getContentInBulk = (
	companyId: number,
	projectId: number,
	keyword: string,
	option: unknown[],
	typeOfPost: ETypeOfPost | string,
	characteristicOfThePost: ECharacteristicOfThePost,
	feature?: string,
	systemInput?: string,
): any => {
	const apiUri = replaceParamsUri(
		['companyId', 'projectId'],
		[companyId, projectId],
		API.GET_CONTENT_IN_BULK,
	)
	return {
		type: GET_CONTENT_IN_BULK,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					keyword,
					option,
					typeOfPost,
					characteristicOfThePost,
					feature,
					systemInput,
				},
				responseType: 'blob',
			},
		},
	}
}
export const getAllReadyModelToSelect = (companyId: number): any => {
	const apiUri = replaceParamsUri(
		['companyId'],
		[companyId],
		API.GET_ALL_READY_MODEL,
	)
	return {
		type: GET_ALL_READY_MODEL,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export type ProductDetail = {
	name: string
	description: string
}
export const startFinetuneModel = (
	projectId: number,
	sourceType: string,
	url: string,
	fileName: string,
	urlList: string[],
	productList: ProductDetail[],
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'sourceType'],
		[projectId, sourceType],
		API.CREATE_FINETUNE_MODEL,
	)
	return {
		type: CREATE_FINETUNE_MODEL,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					url,
					fileName,
					urlList,
					productList,
				},
			},
		},
	}
}
export const aiImageGenerator = (
	projectId: number,
	postContent: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.AI_IMAGE_GENERATOR,
	)
	return {
		type: AI_IMAGE_GENERATOR,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					postContent,
				},
			},
		},
	}
}

export const generateSubHeadings = (
	projectId: number,
	selectedHeadline: string,
	wordAmount: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GENERATE_SUBHEADINGS,
	)
	return {
		type: GENERATE_SUBHEADINGS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					selectedHeadline,
					wordAmount,
				},
			},
		},
	}
}

export const generateBlogContent = (
	projectId: number,
	selectedHeadline: string,
	subHeadings: string[],
	wordAmount: number,
	continueGeneration?: boolean,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GENERATE_BLOG_CONTENT,
	)
	return {
		type: GENERATE_BLOG_CONTENT,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					selectedHeadline,
					subHeadings,
					wordAmount,
					continueGeneration,
				},
			},
		},
	}
}

export const listAllFinetunedFile = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.LIST_ALL_FINETUNE_FILES,
	)
	return {
		type: LIST_ALL_FINETUNE_FILES,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export type FinetuneFileUploadRequestBody = {
	originalFileName: string
	files: Blob[]
}

export const updateSavedFinetuneFile = (
	projectId: number,
	requestBody: FinetuneFileUploadRequestBody,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.UPDATE_SAVED_FINETUNE_FILE,
	)
	return {
		type: UPDATE_SAVED_FINETUNE_FILE,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}

export const sendFinetuneFileToOpenAI = (
	projectId: number,
	fileName: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.SEND_FINETUNE_FILE_TO_OPENAI,
	)
	return {
		type: SEND_FINETUNE_FILE_TO_OPENAI,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: { fileName },
			},
		},
	}
}

export const getGoogleCloudStorageFile = (
	projectId: number,
	fileName: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_SAVED_FINETUNE_FILE,
	)
	return {
		type: GET_SAVED_FINETUNE_FILE,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: { fileName },
			},
		},
	}
}

export const getAllAiArticle = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_ALL_AI_ARTICLE,
	)
	return {
		type: GET_ALL_AI_ARTICLE,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getAllAiCategoryDescription = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_ALL_AI_CATEGORY_DESCRIPTION,
	)
	return {
		type: GET_ALL_AI_CATEGORY_DESCRIPTION,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getAiProductDescriptionByName = (
	projectId: number,
	productName: string,
	articleType: EAiArticleType,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'articleType'],
		[projectId, articleType],
		API.GET_AI_PRODUCT_DESCRIPTION,
	)
	return {
		type: GET_AI_PRODUCT_DESCRIPTION,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: { name: productName },
			},
		},
	}
}

export const translateContent = (projectId: number, content: string): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.TRANSLATE_CONTENT,
	)
	return {
		type: TRANSLATE_CONTENT,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: { content: content },
			},
		},
	}
}

export const createAiBulkGeneration = (
	projectId: number,
	excelData: IBulkGenerationExcelData[],
	generationType: EAiBulkGenerationType,
	fileName: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'generationType'],
		[projectId, generationType],
		API.CREATE_AI_BULK_GENERATION,
	)
	return {
		type: CREATE_AI_BULK_GENERATION,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: { excelData, fileName },
			},
		},
	}
}

export const getAllBulkGenerationByProjectId = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_ALL_BULK_GENERATION_BY_PROJECT_ID,
	)
	return {
		type: GET_ALL_AI_BULK_GENERATION,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
