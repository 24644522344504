/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import { API, GENERATE_SUGGESTION_STATUS } from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import { ActionChangeField } from './ads'
import { Action, ActionWithoutPayload, SaveSiteUrlAction } from './audit'
import {
	GenerateSuggestionsByKeywords,
	PostCreateProject,
	PostCreateProjectBulk,
} from './project'
import {
	PostInstagramPost,
	ScheduleFacebookPost,
	ScheduleInstagramPost,
} from './some-post'
import {
	SHOW_MESSAGE,
	CLEAR_MESSAGE,
	ERROR_MESSAGE,
	POST_KEYWORD_RANKING_SUCCESS,
	BULK_SAVE_NEW_KEYWORD_SUCCESS,
	INVITE_USER_TO_COMPANY_SUCCESS,
	DELETE_FACEBOOK_POST_SUCCESS,
	DELETE_INSTAGRAM_SCHEDULED_POST_SUCCESS,
	SET_NOTIFICATIONS,
	INVITE_CONTRIBUTOR_TO_PROJECT_SUCCESS,
	UPDATE_CONTRIBUTOR_DATA_SUCCESS,
	DELETE_CONTRIBUTOR_DATA_SUCCESS,
} from './types'
import { SignOut } from './user'

export enum ENotificationTypes {
	'NEW_RELEASE' = 'NEW_RELEASE',
	'NEW_FEATURE' = 'NEW_FEATURE',
	'CRITICAL' = 'CRITICAL',
	'INFORMATION' = 'INFORMATION',
}

export interface INotification {
	title: string
	message: string
	createdAt: string
	seen: string | null
	type: ENotificationTypes
	relatedFirebaseId?: string | null
}

export interface IUserNotifications {
	uid: string
	companyId: number
	notifications: INotification
}

type ShowMessage = ActionChangeField<typeof SHOW_MESSAGE, { message: string }>
type SetNotifications = ActionChangeField<
	typeof SET_NOTIFICATIONS,
	{ notifications: INotification[] }
>
type ClearMessage = ActionWithoutPayload<typeof CLEAR_MESSAGE>
type ShowError = ActionChangeField<typeof ERROR_MESSAGE, { error: string }>
type PostKeywordRanking = Action<typeof POST_KEYWORD_RANKING_SUCCESS, any>
type BulkSaveNewKeyword = Action<typeof BULK_SAVE_NEW_KEYWORD_SUCCESS, any>
type InviteUserToCompany = Action<typeof INVITE_USER_TO_COMPANY_SUCCESS, any>
type InviteContributorToProject = Action<
	typeof INVITE_CONTRIBUTOR_TO_PROJECT_SUCCESS,
	any
>
type UpdateContributorData = Action<typeof UPDATE_CONTRIBUTOR_DATA_SUCCESS, any>
type DeleteContributorData = Action<typeof DELETE_CONTRIBUTOR_DATA_SUCCESS, any>
type DeleteFacebookPostSuccess = Action<
	typeof DELETE_FACEBOOK_POST_SUCCESS,
	any
>
type DeleteInstagramScheduledPostSuccess = Action<
	typeof DELETE_INSTAGRAM_SCHEDULED_POST_SUCCESS,
	any
>

export type MessageActions =
	| ShowMessage
	| ClearMessage
	| ShowError
	| ScheduleFacebookPost
	| ScheduleInstagramPost
	| PostInstagramPost
	| PostCreateProject
	| SaveSiteUrlAction
	| SignOut
	| PostKeywordRanking
	| GenerateSuggestionsByKeywords
	| BulkSaveNewKeyword
	| PostCreateProjectBulk
	| InviteUserToCompany
	| InviteContributorToProject
	| UpdateContributorData
	| DeleteContributorData
	| DeleteFacebookPostSuccess
	| DeleteInstagramScheduledPostSuccess
	| SetNotifications

export const showMessage = (message: string): any => {
	return {
		type: SHOW_MESSAGE,
		payload: {
			message,
		},
	}
}
export const setNotifications = (notifications: INotification[]): any => {
	return {
		type: SET_NOTIFICATIONS,
		payload: {
			notifications,
		},
	}
}

export const clearMessage = (): any => {
	return {
		type: CLEAR_MESSAGE,
	}
}

export const showError = (error: string): any => {
	return {
		type: ERROR_MESSAGE,
		payload: {
			error,
		},
	}
}
