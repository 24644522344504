import Stripe from 'stripe'
import { PaymentActions } from '../actions/payment'
import { GET_PAYMENT_PLANS_SUCCESS } from '../actions/types'
import { formatStripePaymentPlans } from '../helpers/dataTransformer'

export type PaymentPlanResponse = {
	id: string
	name: string
	description: string
	maximumNumberOfProject: number
	subscriptionLevel: string
	active: boolean
	prices: Stripe.Price[]
}
export type PaymentPlan = {
	productId: string
	description: string
	price: number
	priceDescription: string
	subscriptionFeatures: string
	price_id: string
}

type PaymentState = {
	plans: { Monthly: PaymentPlan[]; Yearly: PaymentPlan[] }
}
const initialState: PaymentState = {
	plans: { Monthly: [], Yearly: [] },
}

export const paymentReducer = (
	state = initialState,
	action: PaymentActions,
): typeof initialState => {
	switch (action.type) {
		case GET_PAYMENT_PLANS_SUCCESS:
			return {
				plans: formatStripePaymentPlans(action.payload.data),
			}
		default:
			return state
	}
}

export const paymentSelectors = {
	getPlans: (
		state = initialState,
	): { Monthly: PaymentPlan[]; Yearly: PaymentPlan[] } => state.plans,
}
