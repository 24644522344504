import { Chip } from '@mui/material'
import React, { LegacyRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
	DashboardTabValues,
	EHIGHLIGHT,
	highlighter,
	languages,
} from '../../constants'
import i18n from '../../services/i18n'
import Button from './Button'
import InformationBar from './InformationBar'
import ToolTip from './ToolTip'
import { useSelector } from 'react-redux'
import { INotification } from '../../actions/message'
import Notifications from './Notifications'
import { User } from '../../reducers/user'

export type Tab = {
	label: string
	value: string | DashboardTabValues
	amount?: number
	disabled?: boolean
	tooltip?: string
	hide?: boolean
}

type Props = {
	title: string
	selectedTab?: Tab
	buttonText?: string
	onButtonClick?: () => void
	selectTab?: (tab: Tab) => void
	tabs?: Tab[]
	buttons?: { text: string; onClick: () => void }[]
	customButtons?: JSX.Element[]
	customComponent?: JSX.Element
	isButtonDisabled?: boolean
	handleShowTutorial?: () => void
	highlighted?: EHIGHLIGHT
}

const TopNav = ({
	title,
	selectedTab,
	selectTab = () => {
		return
	},
	tabs,
	buttonText,
	onButtonClick,
	buttons,
	customButtons,
	customComponent,
	isButtonDisabled = false,
	handleShowTutorial,
	highlighted,
}: Props): JSX.Element => {
	const { t } = useTranslation()
	const { notifications, firebaseToken } = useSelector<any>(state => ({
		notifications: state.message.notifications,
		user: state.user.user,
		firebaseToken: state.user.firebaseToken,
	})) as {
		notifications: INotification[]
		firebaseToken: string | null
	}
	const handleClickTab = (tab: Tab) => {
		selectTab(tab)
	}
	const initialLanguage = window.localStorage.getItem('i18nextLng') as string
	const selectedLanguageEl = useRef(
		null as unknown as LegacyRef<HTMLButtonElement>,
	)
	// const selectLanguage = (language: string) => {
	// 	localStorage.setItem('i18nextLng', language)
	// 	i18n.changeLanguage(language)
	// 	window.location.reload()
	// }
	const renderTabs = (navTabs: Tab[]) => {
		if (!Array.isArray(navTabs)) {
			return null
		}

		return navTabs.map((tab: Tab, index) => {
			if (tab.hide) {
				return null
			} else {
				const customProps = { tabname: tab.value }
				return tab.tooltip ? (
					<ToolTip title={tab.tooltip} delay={0}>
						<div
							className={`tab ${
								selectedTab &&
								selectedTab.value.toLowerCase() === tab.value.toLowerCase()
									? 'selected'
									: ''
							} ${tab && tab.disabled ? 'disabled' : ''}`}
							key={index}
							onClick={
								tab && tab.disabled ? () => null : () => handleClickTab(tab)
							}
							{...customProps}
						>
							{tab.label}
						</div>
					</ToolTip>
				) : (
					<div
						className={`tab ${
							selectedTab &&
							selectedTab.value.toLowerCase() === tab.value.toLowerCase()
								? 'selected'
								: ''
						} ${tab && tab.disabled ? 'disabled' : ''}`}
						key={index}
						onClick={
							tab && tab.disabled ? () => null : () => handleClickTab(tab)
						}
						{...customProps}
					>
						{tab.label}
					</div>
				)
			}
		})
	}
	return (
		<>
			<div className='topnav'>
				<div
					className={`left ${highlighter(
						highlighted as number,
						EHIGHLIGHT.TASKS_TABS,
					)}`}
				>
					<div className='title'>
						<h3>{title}</h3>
					</div>
					{tabs && <div className='nav'>{renderTabs(tabs)} </div>}
				</div>
				<div className='right'>
					<div className='right__inner-container'>
						{customComponent ? <>{customComponent}</> : null}
						{buttons ? (
							<>
								<div className='topnav-buttons'>
									{buttons.map((button, i) => (
										<Button
											key={i}
											text={button.text}
											onClick={button.onClick}
										/>
									))}
								</div>

								{handleShowTutorial && (
									<Button
										className={'button-dark-white'}
										containerClassName={'topnav__help-button'}
										onClick={handleShowTutorial}
										text={t('topnav.help')}
									/>
								)}
							</>
						) : buttonText ? (
							<>
								<Button
									text={buttonText}
									disabled={isButtonDisabled}
									onClick={onButtonClick}
								/>
								{handleShowTutorial && (
									<Button
										className={'button-dark-white'}
										containerClassName={'topnav__help-button'}
										onClick={handleShowTutorial}
										text={t('topnav.help')}
									/>
								)}
							</>
						) : Array.isArray(customButtons) ? (
							<>{customButtons}</>
						) : null}
						{firebaseToken ? (
							<Notifications
								notifications={notifications}
								firebaseToken={firebaseToken}
							/>
						) : null}
						{/* <div className='right__language-selector'>
							<div className='c-f-o-dropdown'>
								<button
									ref={selectedLanguageEl as LegacyRef<HTMLButtonElement>}
									className='c-f-o-dropdown-btn'
								>
									<p>{(initialLanguage || languages.en).toUpperCase()}</p>
								</button>

								<div className='c-f-o-dropdown-content'>
									{Object.values(languages).map(i => (
										<p
											className='topnav--languages'
											key={i}
											onClick={() => selectLanguage(i)}
										>
											{i.toUpperCase()}
										</p>
									))}
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
			{selectedTab ? <InformationBar /> : null}
		</>
	)
}

export default TopNav
