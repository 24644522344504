/**
 *
 *
 * @author Jan Hellsten <hellsten@live.com>
 *
 * @copyright AI Marketing Oy 2021
 */

import React from 'react'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
// Components
import Button from '../components/Button'
import { ROUTER_PATH } from '../../constants'
import { userActions } from '../../actions'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			eventId: -1,
		}
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		Sentry.withScope(scope => {
			scope.setExtras(errorInfo)
			const eventId = Sentry.captureException(error)
			this.setState({
				eventId,
			})
		})
		// You can also log the error to an error reporting service
	}

	onRefreshPage = () => {
		this.setState(
			{
				hasError: false,
			},
			() => window.location.reload(),
		)
	}

	toHomePage = () => {
		this.setState(
			{
				hasError: false,
			},
			() => this.props.history.push(ROUTER_PATH.OVERVIEW),
		)
	}

	signout = () => {
		this.setState(
			{
				hasError: false,
			},
			() => this.props.dispatch(userActions.signOut()),
		)
		this.props.history.push(ROUTER_PATH.LOGIN)
	}

	render() {
		const { t } = this.props
		if (this.state.hasError) {
			return (
				<div className={'error-boundary'}>
					<div className={'error-boundary__box'}>
						<p>{t('error.somethingWentWrong')}</p>
						<p>{t('error.apology')}</p>
						<div className={'error-boundary__box-buttons'}>
							<Button
								className={'button-secondary'}
								onClick={this.toHomePage}
								text={t('common.home')}
								containerClassName={'button-left'}
							/>
							<Button
								className={'button-secondary'}
								onClick={this.onRefreshPage}
								text={t('common.refresh')}
							/>
						</div>
						<div>
							<Button
								className={'button-secondary'}
								onClick={this.signout}
								text={t('common.logout')}
							/>
						</div>
					</div>
				</div>
			)
		}
		return this.props.children
	}
}

export default connect()(withTranslation()(withRouter(ErrorBoundary)))
