/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddCircleOutline } from '@mui/icons-material'
import _ from 'lodash'
import moment from 'moment'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { adsActions, aiActions } from '../../../actions'
import {
	GoogleAdItem,
	GoogleAdKeywordItem,
	AdsGoogleCampaignItem,
	GoogleAdsGroupItem,
	GoogleAdsVisualisationPayload,
	EAdGroupAdType,
	EAdGroupStatus,
	EAdsTypeOptions,
	EBiddingStrategyTypes,
	EKeywordMatchType,
	enumToObject,
} from '../../../constants'
import {
	formatAiGoogleAdsHeadlines,
	fromMicrons,
	toMicrons,
} from '../../../helpers/dataTransformer'
import { adsSelectors, projectSelectors } from '../../../reducers'
import { GoogleAdsFilterOptions } from '../../../reducers/ads'
import { startLoading, stopLoading } from '../../../reducers/loading'
import Button from '../../../shared/components/Button'
import Input from '../../../shared/components/Input'
import SelectWithSearch from '../../../shared/components/SelectWithSearch'
import XCloser from '../../../shared/components/XCloser'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import CustomModal from '../../../shared/components/Modal'
interface Props<T extends GoogleAdItemType> {
	editableGoogleAdItem: T | null
	type: EAdsTypeOptions
	handleClose: () => void
	validateGoogleAdResource: any
	saveGoogleAdResource: any
	currentProject: any
	adGroupResourceName?: string
	adGroupAdResourceName?: string
	campaignBudgetResourceName?: string
	validateGoogleKeywordEditing: any
	saveGoogleKeyword: any
	validateEditGoogleAd: (projectId: number, data: Record<string, any>) => void
	saveEditGoogleAd: (projectId: number, data: Record<string, any>) => void
	filters: GoogleAdsFilterOptions
	ads: any
	adGroups: any
	getGoogleAds: any
	getAllAds: any
	getGoogleCampaigns: any
	getGoogleAdGroups: any
	getGoogleAllAdGroupsAndAssetGroups: any
	resetAds: any
	resetGoogleAdsFilters: any
	setStartLoading: any
	setStopLoading: any
	getGoogleAdsHeadlines: (url: string, projectId: number) => any
	getGoogleAdsDescriptions: (
		url: string,
		headline: string,
		projectId: number,
	) => any
}

export type GoogleAdItemType =
	| Partial<GoogleAdItem>
	| Partial<AdsGoogleCampaignItem>
	| Partial<GoogleAdsGroupItem>

export type SelectOptionType = {
	label: string
	value: string | number
}

type saveGoogleKeywordProperty = {
	keyword: string
	matchType: number
	keywordResourceName: string
	adGroupResourceName: string
}

const GoogleAdsEditModal = <T extends GoogleAdItemType>({
	editableGoogleAdItem,
	type,
	handleClose,
	validateGoogleAdResource,
	saveGoogleAdResource,
	currentProject,
	validateGoogleKeywordEditing,
	saveGoogleKeyword,
	validateEditGoogleAd,
	saveEditGoogleAd,
	ads,
	adGroups,
	filters,
	getAllAds,
	getGoogleCampaigns,
	getGoogleAllAdGroupsAndAssetGroups,
	resetAds,
	setStartLoading,
	setStopLoading,
	getGoogleAdsHeadlines,
	getGoogleAdsDescriptions,
}: Props<T>): JSX.Element | null => {
	const { t } = useTranslation()
	const [googleAdItem, setGoogleAdItem] = useState<GoogleAdItemType>({})
	const dispatch = useDispatch()
	const [headlineEditIndex, setHeadlineEditIndex] = useState<number>(-1)
	const [aiHeadlines, setAiHeadlines] = useState<string[]>([])
	const [descriptionEditIndex, setDescriptionEditIndex] = useState<number>(-1)
	const [aiDescriptions, setAiDescriptions] = useState<string[]>([])
	const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
	const handleGetGoogleAdsAiContent = async (
		webUrl: string,
		contentType: 'headline' | 'description',
	) => {
		setAiHeadlines([])
		try {
			setStartLoading()
			let requestCount = 0
			let reSent = true
			while (reSent && requestCount < 2) {
				const res = await getGoogleAdsHeadlines(webUrl, currentProject.id)
				requestCount += 1
				const parsedHeadlines = JSON.parse(res.payload.data.headlines)
				const parsedDescriptions = JSON.parse(res.payload.data.descriptions)
				if (
					Array.isArray(parsedHeadlines) &&
					Array.isArray(parsedDescriptions)
				) {
					if (contentType === 'headline') {
						setAiHeadlines(parsedHeadlines)
						reSent = false
					} else {
						setAiDescriptions(parsedDescriptions)
						reSent = false
					}
				} else {
					if (requestCount === 2) {
						setAiHeadlines(['Please try again later'])
					}
				}
			}
		} catch (error) {
			console.error(error)
		} finally {
			setStopLoading()
		}
	}

	const transformObjMicronsFields = (obj: any, normalize: boolean) => {
		if (Object.prototype.hasOwnProperty.call(obj, 'campaignBudget')) {
			obj.campaignBudget = normalize
				? fromMicrons(obj.campaignBudget)
				: toMicrons(obj.campaignBudget)
		}

		return obj
	}

	useEffect(() => {
		if (!editableGoogleAdItem) {
			setGoogleAdItem({
				type: type,
			})
		} else {
			const transformAdsItem = _.cloneDeep(editableGoogleAdItem)
			setGoogleAdItem(transformObjMicronsFields(transformAdsItem, true))
		}
	}, [editableGoogleAdItem])

	const handleChangeInput = (e: any) => {
		const { value, name } = e.target
		setGoogleAdItem({ ...googleAdItem, [name]: value })
	}

	const handleChangeExpandedTextAdInput = (
		e: ChangeEvent<HTMLInputElement>,
		index: number,
		property: keyof GoogleAdItem,
	) => {
		if (googleAdItem.type === EAdsTypeOptions.AD) {
			const clonedItems = _.cloneDeep(googleAdItem[property])
			clonedItems[index] = e.currentTarget.value
			setGoogleAdItem({ ...googleAdItem, [property]: clonedItems })
		}
	}
	const handleChangeArrayPropertyInput = (
		property: keyof GoogleAdItem,
		name: string,
		value: string,
		index: number,
	) => {
		if (googleAdItem.type === EAdsTypeOptions.AD) {
			const clonedItems = _.cloneDeep(googleAdItem[property])
			clonedItems[index][name] = value
			setGoogleAdItem({ ...googleAdItem, [property]: clonedItems })
		}
	}
	const handleChangeKeywordInput = (e: any, index: number) => {
		const { value, name } = e.target
		if (googleAdItem.type === EAdsTypeOptions.AD_GROUP && name === 'keyword') {
			if (!Array.isArray(googleAdItem.keyword)) {
				return
			}
			const updatedKeyword: GoogleAdKeywordItem =
				googleAdItem.keyword[index] &&
				Object.assign({}, googleAdItem.keyword[index])
			updatedKeyword.keyword = value
			updatedKeyword.changed = true
			const currentKeywords = [...googleAdItem.keyword]

			currentKeywords[index] = updatedKeyword
			setGoogleAdItem({ ...googleAdItem, keyword: [...currentKeywords] })
		}
	}
	const handleDropdownSelection = (value: SelectOptionType, name: string) => {
		setGoogleAdItem({ ...googleAdItem, [name]: value.value })
	}

	const handleKeywordDropdownSelection = (
		value: SelectOptionType,
		name: string,
	) => {
		if (googleAdItem.type === EAdsTypeOptions.AD_GROUP) {
			const updatedKeywords = googleAdItem.keyword?.map(
				(kw: GoogleAdKeywordItem) => {
					if (kw.keyword === name) {
						kw.matchType = Number(value.value)
					}
					return kw
				},
			)
			setGoogleAdItem({ ...googleAdItem, keyword: updatedKeywords })
		}
	}

	const getAdGroupResourceName = (
		adGroupName: string | undefined,
		googleAdGroups: any,
	) => {
		return googleAdGroups.filter((i: any) => i.adGroupName === adGroupName)[0]
			.adGroupResourceName
	}

	const mapEnumToObj = (e: any) => {
		const options = []
		const mappedEnum = enumToObject(e)
		for (const [key, value] of Object.entries(mappedEnum)) {
			options.push({ label: key, value })
		}
		return options
	}
	const handleCampaignBudgetEditing = async () => {
		if (googleAdItem.type === EAdsTypeOptions.CAMPAIGN) {
			try {
				const requestBody = {
					adsData: {
						resource_name: googleAdItem.campaignBudgetResourceName,
						amount_micros: Number(toMicrons(googleAdItem.campaignBudget)),
					},
				}
				dispatch(startLoading())
				await validateGoogleAdResource(
					currentProject.id,
					'campaignBudget',
					requestBody,
				)
				await saveGoogleAdResource(
					currentProject.id,
					'campaignBudget',
					requestBody,
				)
				resetAds()
				await getGoogleCampaigns(currentProject.id, {
					fromDate: filters.from,
					toDate: moment().toISOString(),
				})
			} catch (er) {
				console.error(er)
			} finally {
				dispatch(stopLoading())
				handleClose()
			}
		}
	}

	const handlesaveGoogleKeyword = async (
		property: saveGoogleKeywordProperty,
	) => {
		try {
			dispatch(startLoading())
			const requestBody = {
				adsData: {
					ad_group: property.adGroupResourceName,
					keyword: {
						match_type: property.matchType,
						text: property.keyword,
					},
				},
				resource_name: property.keywordResourceName,
			}
			await validateGoogleKeywordEditing(currentProject.id, requestBody)
			await saveGoogleKeyword(currentProject.id, requestBody)
			resetAds()
			await getGoogleCampaigns(currentProject.id, {
				fromDate: filters.from,
				toDate: moment().toISOString(),
			})
			await getGoogleAllAdGroupsAndAssetGroups(currentProject.id, {
				fromDate: filters.from,
				toDate: moment().toISOString(),
			})
			await getAllAds(currentProject.id, {
				fromDate: filters.from,
				toDate: moment().toISOString(),
			})
		} catch (er) {
			console.error(er)
		} finally {
			dispatch(stopLoading())
			handleClose()
		}
	}
	const handleAddInput = (propertyType: keyof GoogleAdItem) => {
		if (googleAdItem.type === EAdsTypeOptions.AD) {
			const newItem = {
				text: '',
				pinnedField: 0,
				notCreated: true,
			}
			setGoogleAdItem({
				...googleAdItem,
				[propertyType]: [...googleAdItem[propertyType], newItem],
			})
		}
	}
	const handleSave = async () => {
		switch (googleAdItem.type) {
			case EAdsTypeOptions.CAMPAIGN:
				try {
					const requestBody = {
						adsData: {
							resource_name: googleAdItem.campaignResourceName,
							name: googleAdItem.campaignName,
							campaignStatus: googleAdItem.campaignStatus,
						},
					}
					dispatch(startLoading())
					await validateGoogleAdResource(
						currentProject.id,
						googleAdItem.type,
						requestBody,
					)
					await saveGoogleAdResource(
						currentProject.id,
						googleAdItem.type,
						requestBody,
					)
					resetAds()
					await getGoogleCampaigns(currentProject.id, {
						fromDate: filters.from,
						toDate: moment().toISOString(),
					})
					await getGoogleAllAdGroupsAndAssetGroups(currentProject.id, {
						fromDate: filters.from,
						toDate: moment().toISOString(),
					})
					await getAllAds(currentProject.id, {
						fromDate: filters.from,
						toDate: moment().toISOString(),
					})
				} catch (er) {
					console.error(er)
				} finally {
					dispatch(stopLoading())
					handleClose()
				}
				break
			case EAdsTypeOptions.AD_GROUP:
				try {
					const requestBody = {
						adsData: {
							resource_name: googleAdItem.adGroupResourceName,
							name: googleAdItem.adGroupName,
							adGroupStatus: googleAdItem.adGroupStatus,
						},
					}
					dispatch(startLoading())
					await validateGoogleAdResource(
						currentProject.id,
						googleAdItem.type,
						requestBody,
					)
					await saveGoogleAdResource(
						currentProject.id,
						googleAdItem.type,
						requestBody,
					)
					resetAds()
					await getGoogleCampaigns(currentProject.id, {
						fromDate: filters.from,
						toDate: moment().toISOString(),
					})
					await getGoogleAllAdGroupsAndAssetGroups(currentProject.id, {
						fromDate: filters.from,
						toDate: moment().toISOString(),
					})
					await getAllAds(currentProject.id, {
						fromDate: filters.from,
						toDate: moment().toISOString(),
					})
				} catch (er) {
					console.error(er)
				} finally {
					dispatch(stopLoading())
					handleClose()
				}
				break
			case EAdsTypeOptions.AD: {
				const approvingAds = localStorage.getItem('approvingAds')
				const approvingAdsArray = approvingAds ? JSON.parse(approvingAds) : []
				const clonedGoogleAdItem = _.cloneDeep(googleAdItem)
				clonedGoogleAdItem.approving = true
				approvingAdsArray.push(clonedGoogleAdItem)
				localStorage.setItem('approvingAds', JSON.stringify(approvingAdsArray))
				try {
					dispatch(startLoading())
					let requestBody
					if (!googleAdItem.description) {
						googleAdItem.description = []
					}
					if (!googleAdItem.headlines) {
						googleAdItem.headlines = []
					}
					switch (googleAdItem.adGroupAdType) {
						case EAdGroupAdType[15]:
							requestBody = {
								adsData: {
									ad: {
										responsive_search_ad: {
											descriptions: googleAdItem.description,
											headlines: googleAdItem.headlines,
											path1: googleAdItem.path1,
											path2: googleAdItem.path2,
										},
										final_urls: [googleAdItem.finalUrl],
										type: EAdGroupAdType.RESPONSIVE_SEARCH_AD,
									},
									ad_group: googleAdItem.adGroupResourceName
										? googleAdItem.adGroupResourceName
										: getAdGroupResourceName(
												googleAdItem.adGroupName,
												adGroups,
										  ),
								},
								resource_name: googleAdItem.adGroupAdResourceName,
							}
							break
						case EAdGroupAdType[3]:
							requestBody = {
								adsData: {
									ad: {
										expanded_text_ad: {
											description: googleAdItem.description[0],
											description2: googleAdItem.description[1],
											headline_part1: googleAdItem.headlines[0],
											headline_part2: googleAdItem.headlines[1],
											headline_part3: googleAdItem.headlines[2],
											path1: googleAdItem.path1,
											path2: googleAdItem.path2,
										},
										final_urls: [googleAdItem.finalUrl],
										type: EAdGroupAdType.EXPANDED_TEXT_AD,
									},
									ad_group: googleAdItem.adGroupResourceName
										? googleAdItem.adGroupResourceName
										: getAdGroupResourceName(
												googleAdItem.adGroupName,
												adGroups,
										  ),
								},
								resource_name: googleAdItem.adGroupAdResourceName,
							}
							break
						case EAdGroupAdType[2]:
							requestBody = {
								adsData: {
									ad: {
										text_ad: {
											text_ad: {
												description1: googleAdItem.description[0],
												description2: googleAdItem.description[1],
												headline: googleAdItem.headlines[0],
											},
											final_urls: [googleAdItem.finalUrl],
											type: EAdGroupAdType.TEXT_AD,
										},
										ad_group: googleAdItem.adGroupResourceName
											? googleAdItem.adGroupResourceName
											: getAdGroupResourceName(
													googleAdItem.adGroupName,
													adGroups,
											  ),
									},
								},
								resource_name: googleAdItem.adGroupAdResourceName,
							}
							break
						default:
							requestBody = {}
							break
					}
					await validateEditGoogleAd(currentProject.id, requestBody)
					await saveEditGoogleAd(currentProject.id, requestBody)
					resetAds()
					await getGoogleCampaigns(currentProject.id, {
						fromDate: filters.from,
						toDate: moment().toISOString(),
					})
					await getGoogleAllAdGroupsAndAssetGroups(currentProject.id, {
						fromDate: filters.from,
						toDate: moment().toISOString(),
					})
					await getAllAds(currentProject.id, {
						fromDate: filters.from,
						toDate: moment().toISOString(),
					})
				} catch (er) {
					console.error(er)
				} finally {
					dispatch(stopLoading())
					handleClose()
				}
				break
			}
			default:
				break
		}
	}

	const renderDescriptions = (
		aItem: Partial<GoogleAdItem>,
		adGroupAdType: string | undefined,
	) => {
		switch (adGroupAdType) {
			case EAdGroupAdType[15]:
				return (
					<div className='ads-modal__content--container__campaigns--items__container--item value description'>
						{Array.isArray(aItem.description) &&
							aItem.description.map((desc: any, i: number) => {
								return descriptionEditIndex === i ? (
									<div className='ads-modal__content--container__campaigns--items__container--item__input-container edit-mode'>
										{aiDescriptions.length ? (
											<div className='ads-modal__content--container__campaigns--items__container--item__input-container__selector'>
												<SelectWithSearch
													options={formatAiGoogleAdsHeadlines(
														aiDescriptions,
														desc.text,
													)}
													value={{
														label: (desc.text as unknown as string) || '',
														value: (desc.text as unknown as string) || '',
													}}
													onChange={(option: any) => {
														handleChangeArrayPropertyInput(
															'description',
															'text',
															option.value,
															i,
														)
													}}
													fullWidth
												/>
											</div>
										) : (
											<Input
												key={i}
												name={'campaignBudget'}
												value={(desc.text as unknown as string) || ''}
												onChange={(option: any) => {
													handleChangeArrayPropertyInput(
														'description',
														'text',
														option.value,
														i,
													)
												}}
												maxLength={90}
												label={`${desc.text.length}/90`}
											/>
										)}

										<div
											className={`ads-modal__content--container__campaigns--items__container--item__input-container__icon ${
												aiDescriptions.length ? 'selector-icon' : ''
											}`}
											onClick={() =>
												handleGetGoogleAdsAiContent(
													(googleAdItem as GoogleAdItem).finalUrl !== null
														? ((googleAdItem as GoogleAdItem)
																.finalUrl as unknown as string)
														: '',
													'description',
												)
											}
										>
											<AutoFixHighIcon />
										</div>
										<div
											className={`ads-modal__content--container__campaigns--items__container--item__input-container__icon ${
												aiHeadlines.length ? 'selector-icon' : ''
											}`}
											onClick={() => setDescriptionEditIndex(-1)}
										>
											<DoneIcon />
										</div>
									</div>
								) : (
									<div className='ads-modal__content--container__campaigns--items__container--item__input-container'>
										<Input
											key={i}
											name={'campaignBudget'}
											value={(desc.text as unknown as string) || ''}
											onChange={(e: any) => {
												handleChangeArrayPropertyInput(
													'description',
													'text',
													e.currentTarget.value,
													i,
												)
											}}
											maxLength={30}
											label={`${desc.text.length}/30`}
											disabled={true}
										/>
										<div
											className={`ads-modal__content--container__campaigns--items__container--item__input-container__icon ${
												headlineEditIndex !== -1 ? 'disable' : ''
											}`}
											onClick={() => {
												setDescriptionEditIndex(i)
												//To keep the same ai content for other headline selection
												//setAiHeadlines([])
											}}
										>
											<EditIcon />
										</div>{' '}
									</div>
								)
							})}
						{Array.isArray(aItem.description) &&
							aItem.description.length < 4 && (
								<div
									className={
										'ads-visualisation-page__content--container__adGroups--item--keyword-item icon'
									}
									onClick={() => handleAddInput('description')}
								>
									{' '}
									<AddCircleOutline fontSize='large' />
								</div>
							)}
					</div>
				)
			case EAdGroupAdType[3]:
				return (
					<div className='ads-modal__content--container__campaigns--items__container--item value description'>
						{Array.isArray(aItem.description) &&
							aItem.description.map((desc: any, i: number) => {
								return (
									<Input
										key={i}
										name={'campaignBudget'}
										value={desc}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleChangeExpandedTextAdInput(e, i, 'description')
										}
										label={`${desc ? desc.length : 0}/90`}
										maxLength={90}
									/>
								)
							})}
					</div>
				)
			default:
				return null
		}
	}

	const renderHeadLines = (
		aItem: Partial<GoogleAdItem>,
		adGroupAdType: string | undefined,
	) => {
		switch (adGroupAdType) {
			case EAdGroupAdType[15]:
				return (
					<div className='ads-modal__content--container__campaigns--items__container--item value description'>
						{Array.isArray(aItem.headlines) &&
							aItem.headlines.map((headline: any, i: number) => {
								return headlineEditIndex === i ? (
									<div className='ads-modal__content--container__campaigns--items__container--item__input-container edit-mode'>
										{aiHeadlines.length ? (
											<div className='ads-modal__content--container__campaigns--items__container--item__input-container__selector'>
												<SelectWithSearch
													options={formatAiGoogleAdsHeadlines(
														aiHeadlines,
														headline.text,
													)}
													value={{
														label: (headline.text as unknown as string) || '',
														value: (headline.text as unknown as string) || '',
													}}
													onChange={(option: any) => {
														handleChangeArrayPropertyInput(
															'headlines',
															'text',
															option.value,
															i,
														)
													}}
													fullWidth
												/>
											</div>
										) : (
											<Input
												key={i}
												name={'campaignBudget'}
												value={(headline.text as unknown as string) || ''}
												onChange={(e: any) => {
													handleChangeArrayPropertyInput(
														'headlines',
														'text',
														e.currentTarget.value,
														i,
													)
												}}
												maxLength={30}
												label={`${headline.text.length}/30`}
											/>
										)}

										<div
											className={`ads-modal__content--container__campaigns--items__container--item__input-container__icon ${
												aiHeadlines.length ? 'selector-icon' : ''
											}`}
											onClick={() =>
												handleGetGoogleAdsAiContent(
													(googleAdItem as GoogleAdItem).finalUrl !== null
														? ((googleAdItem as GoogleAdItem)
																.finalUrl as unknown as string)
														: '',
													'headline',
												)
											}
										>
											<AutoFixHighIcon />
										</div>
										<div
											className={`ads-modal__content--container__campaigns--items__container--item__input-container__icon ${
												aiHeadlines.length ? 'selector-icon' : ''
											}`}
											onClick={() => setHeadlineEditIndex(-1)}
										>
											<DoneIcon />
										</div>
									</div>
								) : (
									<div className='ads-modal__content--container__campaigns--items__container--item__input-container'>
										<Input
											key={i}
											name={'campaignBudget'}
											value={(headline.text as unknown as string) || ''}
											onChange={(e: any) => {
												handleChangeArrayPropertyInput(
													'headlines',
													'text',
													e.currentTarget.value,
													i,
												)
											}}
											maxLength={30}
											label={`${headline.text.length}/30`}
											disabled={true}
										/>
										<div
											className={`ads-modal__content--container__campaigns--items__container--item__input-container__icon ${
												headlineEditIndex !== -1 ? 'disable' : ''
											}`}
											onClick={() => {
												setHeadlineEditIndex(i)
												//To keep the same ai content for other headline selection
												//setAiHeadlines([])
											}}
										>
											<EditIcon />
										</div>{' '}
									</div>
								)
							})}
						{Array.isArray(aItem.headlines) && aItem.headlines.length < 15 && (
							<div
								className={
									'ads-visualisation-page__content--container__adGroups--item--keyword-item icon'
								}
								onClick={() => handleAddInput('headlines')}
							>
								{' '}
								<AddCircleOutline fontSize='large' />
							</div>
						)}
					</div>
				)
			case EAdGroupAdType[3]:
				return (
					<div className='ads-modal__content--container__campaigns--items__container--item value description'>
						{Array.isArray(aItem.headlines) &&
							aItem.headlines.map((headline: any, i: number) => {
								return (
									<Input
										key={i}
										name={'campaignBudget'}
										value={headline || ''}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleChangeExpandedTextAdInput(e, i, 'headlines')
										}
										maxLength={30}
										label={`${headline ? headline.length : 0}/30`}
									/>
								)
							})}
					</div>
				)
			default:
				return null
		}
	}

	if (googleAdItem.type === EAdsTypeOptions.CAMPAIGN) {
		return (
			<div className={'ads-modal__content'}>
				<div className={'ads-modal__content--container'}>
					<div className='ads-modal__content--container-close'>
						<XCloser onClose={handleClose} />
					</div>
					<div className={'ads-modal__content--container__campaigns--items'}>
						<div
							className={
								'ads-modal__content--container__campaigns--items-header'
							}
						>
							<Input
								name={'campaignName'}
								value={googleAdItem.campaignName || ''}
								onChange={handleChangeInput}
								label={t('ads visualization.campaign name')}
							/>
						</div>
						<div
							className={
								'ads-modal__content--container__campaigns--items__container'
							}
						>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.campaign status')}
								</p>
								<div className='ads-modal__content--container__campaigns--items__container--item dropdown-holder'>
									<SelectWithSearch
										name='campaignStatus'
										options={mapEnumToObj(EAdGroupStatus)}
										value={{
											label:
												EAdGroupStatus[Number(googleAdItem.campaignStatus)],
											value:
												EAdGroupStatus[Number(googleAdItem.campaignStatus)],
										}}
										onChange={(v: SelectOptionType) =>
											handleDropdownSelection(v, 'campaignStatus')
										}
									/>
								</div>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{'BiddingStrategy '}
								</p>
								<div className='ads-modal__content--container__campaigns--items__container--item dropdown-holder'>
									<SelectWithSearch
										name='biddingStrategy'
										options={mapEnumToObj(EBiddingStrategyTypes)}
										isDisabled={true}
										value={{
											label:
												googleAdItem.biddingStrategyType &&
												EBiddingStrategyTypes[googleAdItem.biddingStrategyType],
											value: googleAdItem.biddingStrategyType,
										}}
										onChange={(v: SelectOptionType) =>
											handleDropdownSelection(v, 'biddingStrategy')
										}
									/>
								</div>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{'Campaign budget (EUR) '}
								</p>
								<div className='ads-modal__content--container__campaigns--items__container--item input-holder'>
									<Input
										name={'campaignBudget'}
										value={
											(googleAdItem.campaignBudget as unknown as string) || ''
										}
										onChange={handleChangeInput}
										step={0.1}
										type='number'
									/>
								</div>
								<div style={{ margin: 'auto' }}>
									<Button
										className='button-light-blue ads-modal__content--container__adgroups--items button'
										text={'Update campaign budget'}
										onClick={handleCampaignBudgetEditing}
									/>
								</div>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.conversions')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{googleAdItem.conversions}
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.roas')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{googleAdItem.ROAS}
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.price per conversion')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{`${googleAdItem.pricePerConversion} €`}
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.average cpc')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{`${googleAdItem.averageCPC}€`}
								</p>
							</div>
						</div>
						<div>
							<Button
								className='button-dark-blue ads-modal__content--container__campaigns--items__container--button'
								text={'Save'}
								onClick={handleSave}
							/>
							<Button
								className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
								text={t('common.close')}
								onClick={handleClose}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	} else if (googleAdItem.type === EAdsTypeOptions.AD_GROUP) {
		return (
			<div className={'ads-modal__content'}>
				<div className={'ads-modal__content--container'}>
					<div className='ads-modal__content--container-close'>
						<XCloser onClose={handleClose} />
					</div>
					<div className={'ads-modal__content--container__campaigns--items'}>
						<div className='ads-modal__content--container__campaigns--items-header'>
							<Input
								name={'adGroupName'}
								value={googleAdItem.adGroupName || ''}
								onChange={handleChangeInput}
								label={'Adgroup name'}
							/>
						</div>

						<div
							className={
								'ads-modal__content--container__campaigns--items__container'
							}
						>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.conversions')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{googleAdItem.conversions}
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.roas')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{googleAdItem.ROAS}
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.price per conversion')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{googleAdItem.pricePerConversion}
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.average cpc')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value  bold'>
									{`${googleAdItem.averageCPC}€`}
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{'Ad group status'}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value  bold'>
									<div className='ads-modal__content--container__campaigns--items__container--item dropdown-holder'>
										<SelectWithSearch
											name='adGroupStatus'
											options={mapEnumToObj(EAdGroupStatus)}
											value={{
												label:
													EAdGroupStatus[Number(googleAdItem.adGroupStatus)],
												value: googleAdItem.adGroupStatus,
											}}
											onChange={(v: SelectOptionType) =>
												handleDropdownSelection(v, 'adGroupStatus')
											}
										/>
									</div>
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item keywords'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('common.keywords')}
								</p>
								{googleAdItem.keyword &&
									googleAdItem.keyword.map(
										(item: GoogleAdKeywordItem, index: number) => (
											<div
												key={index}
												className='ads-modal__content--container__campaigns--items__container--item input-holder'
											>
												<Input
													name={'keyword'}
													value={item.keyword}
													onChange={(e: any) =>
														handleChangeKeywordInput(e, index)
													}
												/>
												<div className='ads-modal__content--container__campaigns--items__container--item dropdown-holder'>
													<SelectWithSearch
														name='matchType'
														options={mapEnumToObj(EKeywordMatchType)}
														value={{
															label: EKeywordMatchType[Number(item.matchType)],
															value: item.matchType,
														}}
														onChange={(v: SelectOptionType) =>
															handleKeywordDropdownSelection(v, item.keyword)
														}
													/>
												</div>
												<div>
													<Button
														className='button-light-blue ads-modal__content--container__adgroups--items button'
														text={t('common.update keyword')}
														onClick={handlesaveGoogleKeyword}
														property={{
															keyword: item.keyword,
															matchType: item.matchType,
															keywordResourceName: item.keywordResourceName,
															adGroupResourceName:
																googleAdItem.adGroupResourceName,
														}}
													/>
												</div>
											</div>
										),
									)}
							</div>
						</div>
						<div>
							<Button
								className='button-dark-blue ads-modal__content--container__campaigns--items__container--button'
								text={'Save'}
								onClick={handleSave}
							/>
							<Button
								className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
								text={t('common.close')}
								onClick={handleClose}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	} else if (googleAdItem.type === EAdsTypeOptions.AD) {
		return (
			<div className={'ads-modal__content'}>
				<CustomModal
					show={showWarningModal}
					closeModal={() => {
						setShowWarningModal(false)
					}}
					header={'common.error modal title'}
					disabledClickOutside={true}
					modalContainerClassName='draggable-task-view-create-custom-task-modal'
				>
					<div className='ads-modal__content__warning-modal'>
						<div className='ads-modal__content__warning-modal__text'>
							{'Warning: Editing the ad will move it into approving mode!'}
						</div>
						<div className='ads-modal__content__warning-modal__button'>
							<Button
								className='button-dark-blue ads-modal__content--container__campaigns--items__container--button'
								text={'Proceed'}
								onClick={handleSave}
							/>
							<Button
								className='button-dark-blue ads-modal__content--container__campaigns--items__container--button'
								text={'Cancel'}
								onClick={() => {
									setShowWarningModal(false)
								}}
							/>
						</div>
					</div>
				</CustomModal>
				<div className={'ads-modal__content--container'}>
					<div className='ads-modal__content--container-close'>
						<XCloser onClose={handleClose} />
					</div>
					<div className={'ads-modal__content--container__campaigns--items'}>
						{Array.isArray(googleAdItem.headlines) &&
						googleAdItem.headlines.length > 0 ? (
							<h6>
								{typeof googleAdItem.headlines[0] !== 'string' &&
								googleAdItem.headlines[0].text
									? googleAdItem.headlines[0].text
									: googleAdItem.headlines[0]}
							</h6>
						) : (
							<h6>{'Dynamic ad'}</h6>
						)}
						<div
							className={
								'ads-modal__content--container__campaigns--items__container ad'
							}
						>
							<div className='ads-modal__content--container__campaigns--items__container--item ad'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('common.clicks')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{googleAdItem.clicks}
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item ad'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.conversions')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{googleAdItem.conversions}
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item ad'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.roas')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{googleAdItem.ROAS}
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item ad'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.price per conversion')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{googleAdItem.pricePerConversion}
								</p>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item ad'>
								<p className='ads-modal__content--container__campaigns--items__container--item key'>
									{t('ads visualization.average cpc')}
								</p>
								<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
									{`${googleAdItem.averageCPC}€`}
								</p>
							</div>
						</div>
						<div
							className={
								'ads-modal__content--container__campaigns--items__container ad-details'
							}
						>
							{' '}
							<div className='ads-modal__content--container__campaigns--items__container--item ad-detail'>
								<p className='ads-modal__content--container__campaigns--items__container--item key bold'>
									{t('ads visualization.destination url')}
								</p>
								<Input
									name={'finalUrl'}
									value={(googleAdItem.finalUrl as unknown as string) || ''}
									onChange={handleChangeInput}
									label={'Final Url'}
								/>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item'>
								<p className='ads-modal__content--container__campaigns--items__container--item httphelper bold'>
									{googleAdItem.finalUrl}
								</p>
								<div className='ads-modal__content--container__campaigns--items__container--item input-holder'>
									<Input
										name={'path1'}
										value={(googleAdItem.path1 as unknown as string) || ''}
										onChange={handleChangeInput}
										maxLength={15}
										label={`Path1 ${
											googleAdItem.path1 ? googleAdItem.path1?.length : 0
										}/15`}
									/>
								</div>
								<p className='ads-modal__content--container__campaigns--items__container--item httphelper bold'>
									{'/'}
								</p>
								<div className='ads-modal__content--container__campaigns--items__container--item input-holder'>
									<Input
										name={'path2'}
										value={(googleAdItem.path2 as unknown as string) || ''}
										onChange={handleChangeInput}
										maxLength={15}
										label={`Path2 ${
											googleAdItem.path2 ? googleAdItem.path2?.length : 0
										}/15`}
									/>
								</div>
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item ad-detail'>
								<p className='ads-modal__content--container__campaigns--items__container--item key bold'>
									{`Headline(s) ${googleAdItem.headlines?.length}/${
										googleAdItem.adGroupAdType === EAdGroupAdType[15] ? 15 : 3
									}`}
								</p>
								{renderHeadLines(googleAdItem, googleAdItem.adGroupAdType)}
							</div>
							<div className='ads-modal__content--container__campaigns--items__container--item ad-detail'>
								<p className='ads-modal__content--container__campaigns--items__container--item key bold'>
									{`Description(s) ${
										googleAdItem.description
											? googleAdItem.description.length
											: 0
									}/${
										googleAdItem.adGroupAdType === EAdGroupAdType[15] ? 4 : 2
									}`}
								</p>
								{renderDescriptions(googleAdItem, googleAdItem.adGroupAdType)}
							</div>
						</div>
						<div>
							<Button
								className='button-dark-blue ads-modal__content--container__campaigns--items__container--button'
								text={'Save'}
								onClick={() => setShowWarningModal(true)}
							/>
							<Button
								className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
								text={t('common.close')}
								onClick={handleClose}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
	return null
}

export default connect(
	(state: any) => ({
		currentProject: projectSelectors.getProject(state.project),
		ads: adsSelectors.getGoogleAds(state.ads),
		adGroups: adsSelectors.getGoogleAdGroups(state.ads),
		filters: adsSelectors.getGoogleFilters(state.ads),
	}),
	dispatch => ({
		getGoogleAdsHeadlines: (url: string, projectId: number) =>
			dispatch(aiActions.getGoogleAdsHeadlines(url, projectId)),
		getGoogleAdsDescriptions: (
			url: string,
			headline: string,
			projectId: number,
		) => dispatch(aiActions.getGoogleAdsDescriptions(url, headline, projectId)),
		setStartLoading: () => dispatch(startLoading()),
		setStopLoading: () => dispatch(stopLoading()),
		saveEditGoogleAd: (projectId: number, data: Record<string, any>) =>
			dispatch(adsActions.saveEditGoogleAd(projectId, data)),
		validateEditGoogleAd: (projectId: number, data: Record<string, any>) =>
			dispatch(adsActions.validateEditGoogleAd(projectId, data)),
		saveGoogleKeyword: (projectId: number, data: Record<string, any>) =>
			dispatch(adsActions.saveGoogleKeyword(projectId, data)),
		validateGoogleKeywordEditing: (
			projectId: number,
			data: Record<string, any>,
		) => dispatch(adsActions.validateGoogleKeywordEditing(projectId, data)),
		validateGoogleAdResource: (
			projectId: number,
			type: EAdsTypeOptions,
			data: Record<string, any>,
		) => dispatch(adsActions.validateGoogleAdResource(projectId, type, data)),
		saveGoogleAdResource: (
			projectId: number,
			type: EAdsTypeOptions,
			data: Record<string, any>,
		) => dispatch(adsActions.saveGoogleAdResource(projectId, type, data)),

		getGoogleCampaigns: (
			projectId: number,
			{ fromDate, toDate }: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getGoogleAdsCampaigns(projectId, { fromDate, toDate }),
			),
		getGoogleAdGroups: (
			projectId: number,
			{ fromDate, toDate, campaignName }: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getGoogleAdGroupsByCampaignId(projectId, {
					fromDate,
					toDate,
					campaignName,
				}),
			),
		getGoogleAds: (
			projectId: number,
			{
				fromDate,
				toDate,
				adGroupResourceName: adGroupName,
			}: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getGoogleAdsGroupId(projectId, {
					fromDate,
					toDate,
					adGroupResourceName: adGroupName,
				}),
			),
		getAllAds: (
			projectId: number,
			{ fromDate, toDate }: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getGoogleAdsAllAds(projectId, {
					fromDate,
					toDate,
				}),
			),
		getGoogleAllAdGroupsAndAssetGroups: (
			projectId: number,
			{ fromDate, toDate }: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getGoogleAllAdGroupsAndAssetGroups(projectId, {
					fromDate,
					toDate,
				}),
			),
		resetAds: () => dispatch(adsActions.resetGoogleAdsState()),
		resetGoogleAdsFilters: () => dispatch(adsActions.resetGoogleAdsFilters()),
	}),
)(GoogleAdsEditModal)
