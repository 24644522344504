/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect } from 'react'
import { Alignment } from '../../reducers/tutorial'
import CloseIcon from '@mui/icons-material/Close'
import Button from './Button'

type Props = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any
	show?: boolean
	closeModal?: () => void
	modalContainerClassName?: string
	contentClassName?: string
	header?: string
	renderFooter?: () => JSX.Element
	disabledClickOutside?: boolean
	noCloseButton?: boolean
	alignment?: Alignment
}

const CustomModal: FC<Props> = ({
	children,
	show,
	closeModal,
	modalContainerClassName,
	contentClassName,
	renderFooter,
	disabledClickOutside,
	noCloseButton,
	alignment,
}: Props) => {
	const onModalClick = (e: any) => {
		if (disabledClickOutside) {
			return
		}
		if (e.target.type === 'checkbox') {
			return
		}
		if (e.defaultPrevented) {
			return
		}
		if (closeModal) {
			closeModal()
		}
	}

	useEffect(() => {
		const body = document.querySelector('body')
		if (body) {
			body.style.overflow = show ? 'hidden' : 'auto'
		}
	}, [show])

	const onContentClick = (e: any) => {
		if (e.target.type === 'checkbox') {
			return
		}
		return e.preventDefault()
	}
	const modalWrapperClass = ['modal__wrapper']
	if (show) {
		modalWrapperClass.push('show')
	} else {
		modalWrapperClass.push('hide')
	}
	const modalContainerClass = ['modal__container']
	if (modalContainerClassName) {
		modalContainerClass.push(modalContainerClassName)
	}
	const closeButtonClick = (e: any) => {
		if (e) {
			e.stopPropagation()
		}

		if (closeModal) {
			closeModal()
		}
	}
	return (
		<div className={modalWrapperClass.join(' ')} onClick={onModalClick}>
			<div
				className={`${modalContainerClass.join(' ')} align-${
					!alignment ? 'center' : alignment
				}`}
				onClick={onContentClick}
			>
				<div className='modal__header'>
					{noCloseButton ? null : (
						<Button
							text=''
							className='button-modal-close button-icon-with-text'
							containerClassName='modal__close-button-container'
							iconComponent={<CloseIcon />}
							iconPosition='start'
							onClick={closeButtonClick}
						></Button>
					)}
					{/* header && <h2>{header}</h2>*/}
				</div>

				<div className={`modal__content ${contentClassName || ''}`}>
					{show && children}
				</div>
				{renderFooter && <div className='modal__footer'>{renderFooter()}</div>}
			</div>
		</div>
	)
}

export default CustomModal
