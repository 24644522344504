/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
// import { Switch } from '@material-ui/core'

import { projectSelectors, userSelectors } from '../../reducers'
import { startLoading, stopLoading } from '../../reducers/loading'
import Button from '../../shared/components/Button'
import Input from '../../shared/components/Input'
import { adsActions } from '../../actions'
import {
	GoogleAdItem,
	AdsGoogleCampaignItem,
	GoogleAdsGroupItem,
	GoogleAdsVisualisationPayload,
	calculateAdsColorValue,
	EAdsTypeOptions,
	EOperators,
	EGoogleAdsAvailableValues,
	isCompanyOnFreeVersion,
} from '../../constants'
import { adsSelectors, GoogleAdsFilterOptions } from '../../reducers/ads'
import SelectWithSearch from '../../shared/components/SelectWithSearch'
import { fromMicrons } from '../../helpers/dataTransformer'
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { GoogleAdItemType } from './components/GoogleAdsModal'
import { isNotEmptyArray } from '../../helpers/fnHelpers'
import GoogleAdsModal from './components/GoogleAdsModal'
import GoogleAdsEditModal from './components/GoogleAdsEditModal'
import GoogleAdsCampaign from './components/GoogleAdsCampaign'
import ToolTipWithAnIcon from '../../shared/components/ToolTipWithAnIcon'
import CustomModal from '../../shared/components/Modal'

type Props = {
	currentProject: any
	getGoogleCampaigns: any
	getGoogleAdGroups: any
	getGoogleAds: any
	getGoogleAllAdGroupsAndAssetGroups: any
	getAllAds: any
	campaigns: AdsGoogleCampaignItem[]
	adGroups: GoogleAdsGroupItem[]
	ads: GoogleAdItem[]
	filters: GoogleAdsFilterOptions
	changeAdsField: any
	resetAds: any
	resetGoogleAdsFilters: any
	fullSize?: boolean
	company: any
} & PropsWithChildren<WithTranslation>

const AdsVisualisation = ({
	currentProject,
	getGoogleCampaigns,
	getGoogleAllAdGroupsAndAssetGroups,
	getAllAds,
	campaigns,
	adGroups,
	ads,
	filters,
	changeAdsField,
	resetAds,
	resetGoogleAdsFilters,
	fullSize,
	company,
}: Props) => {
	const { t, i18n } = useTranslation()
	const projectId = currentProject && currentProject.id
	const [showModal, setShowModal] = useState<boolean>(false)
	const [showCreateModal, setCreateShowModal] = useState<boolean>(false)
	const [showFilters, setShowFilters] = useState<boolean>(true)
	const [selectedItem, setSelectedItem] = useState<GoogleAdItemType>(null)
	const [showWarningModal, setShowWarningModal] = useState<boolean>(false)

	const dispatch = useDispatch()

	const handleItemClick = (handleGoogleAdItemClick: any, setModal: any) => {
		return function handleModalClick(item: GoogleAdItemType) {
			setModal((old: boolean) => !old)
			if (item === null) {
				setTimeout(() => handleGoogleAdItemClick(item), 100)
			} else handleGoogleAdItemClick(item)
		}
	}

	const setOnItemClick = isCompanyOnFreeVersion(company.subscriptionLevel)
		? null
		: handleItemClick(setSelectedItem, setShowModal)
	const setItemEditClick = isCompanyOnFreeVersion(company.subscriptionLevel)
		? null
		: handleItemClick(setSelectedItem, setCreateShowModal)

	function addColorsToValues<
		T extends AdsGoogleCampaignItem | GoogleAdsGroupItem | GoogleAdItem,
	>(item: T, type: EAdsTypeOptions): T {
		const value = Object.assign({}, item)
		value.type = type
		// Item size
		value.size = filters.size
		value.onClick = setOnItemClick
		value.onEditClick = setItemEditClick

		value.ROAS = value.ROAS ? Number(value.ROAS.toFixed(2)) : 0
		value.conversions = value.conversions
			? Number(value.conversions.toPrecision(2))
			: value.conversions
		value.pricePerConversion = value.pricePerConversion
			? Number(fromMicrons(value.pricePerConversion))
			: 0
		value.averageCPC = value.averageCPC
			? Number(fromMicrons(value.averageCPC))
			: 0
		if (
			filters.property !== null &&
			filters.value !== null &&
			value[filters.property] !== null
		) {
			value.color = calculateAdsColorValue(
				value[filters.property] || 0,
				filters.operator,
				filters.value,
			)
		}
		return value
	}

	const sizeOptions = ['large', 'medium', 'small']

	const mappedCampaigns = campaigns.map(campaign =>
		addColorsToValues(campaign, EAdsTypeOptions.CAMPAIGN),
	)
	const mappedAdGroups = adGroups.map(adGroup =>
		addColorsToValues(adGroup, EAdsTypeOptions.AD_GROUP),
	)
	const mappedAds = ads.map(ad => addColorsToValues(ad, EAdsTypeOptions.AD))

	const handleClick = async () => {
		const getGoogleAdsgleAdsData = async () => {
			try {
				dispatch(startLoading())
				resetAds()
				await getGoogleCampaigns(projectId, {
					fromDate: filters.from,
					toDate: filters.to,
				})
				await getGoogleAllAdGroupsAndAssetGroups(projectId, {
					fromDate: filters.from,
					toDate: filters.to,
				})
				await getAllAds(projectId, {
					fromDate: filters.from,
					toDate: filters.to,
				})
			} catch (error) {
				dispatch(stopLoading())
			} finally {
				dispatch(stopLoading())
			}
		}
		getGoogleAdsgleAdsData()
	}

	const getCampaignData = (
		campaignName: string,
		adGroupsToCheck: GoogleAdsGroupItem[],
		adsToCheck: GoogleAdItem[],
	) => {
		const filteredAdGroups = adGroupsToCheck.filter(
			adGroup => adGroup.campaignName === campaignName,
		)
		const filteredAds = adsToCheck.filter(item => {
			const found = filteredAdGroups.find(
				adg => adg.adGroupName === item.adGroupName,
			)
			return !!found
		})
		const approvingAds = localStorage.getItem('approvingAds')
		const approvingAdsArray = approvingAds ? JSON.parse(approvingAds) : []
		const combinedFilteredAds = filteredAds.concat(approvingAdsArray)

		return { filteredAdGroups, ads: combinedFilteredAds }
	}

	const calculateAverage = <T,>(campaignArray: T[], field: keyof T): string => {
		return (
			(campaignArray.reduce((total, current) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				total += !isNaN(current[field]) ? current[field] : 0
				return total
			}, 0) as unknown as number) / campaignArray.length || 0
		).toFixed(2)
	}
	const calculateTotal = <T,>(campaignArray: T[], field: keyof T): string => {
		return (
			campaignArray.reduce((total, current) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				total += !isNaN(current[field]) ? current[field] : 0
				return total
			}, 0) as unknown as number
		).toFixed(2)
	}

	const operators = [
		{ value: EOperators['<'], label: EOperators['<'] },
		{ value: EOperators['<='], label: EOperators['<='] },
		{ value: EOperators['='], label: EOperators['='] },
		{ value: EOperators['>'], label: EOperators['>'] },
		{ value: EOperators['>='], label: EOperators['>='] },
	]
	const availableValues = [
		{
			value: EGoogleAdsAvailableValues.ROAS,
			label: t('ads visualization.roas'),
		},
		{
			value: EGoogleAdsAvailableValues.PPC,
			label: t('ads visualization.price per conversion'),
		},
		{
			value: EGoogleAdsAvailableValues.CONVERSION,
			label: t('ads visualization.conversion'),
		},
		{
			value: EGoogleAdsAvailableValues.AVERAGE_CPC,
			label: t('ads visualization.average cpc'),
		},
	]

	const handleChangeField = (e: any, field: any) => {
		if (field === 'to' || field === 'from') {
			changeAdsField({ field: field, value: e })
		} else {
			const { value } = e
			changeAdsField({ field: field, value: value })
		}
	}
	const handleChangeInput = (e: any, field: any) => {
		const { value } = e.target

		e.preventDefault()
		changeAdsField({ field, value: value })
	}

	useEffect(() => {
		return () => {
			resetGoogleAdsFilters()
		}
	}, [resetGoogleAdsFilters])
	const selectedFilterValue = availableValues.find(
		value => value.value === filters.property,
	)
	return (
		<>
			{showModal && <GoogleAdsModal googleAdItem={selectedItem} />}
			{showCreateModal && (
				<GoogleAdsEditModal
					type={EAdsTypeOptions.CAMPAIGN}
					handleClose={() => setCreateShowModal(false)}
					editableGoogleAdItem={selectedItem}
				/>
			)}
			<div
				className={`ads-visualisation-page__content--ads ${fullSize && 'full'}`}
			>
				<CustomModal
					show={showWarningModal}
					closeModal={() => {
						setShowWarningModal(false)
					}}
					header={'common.error modal title'}
					disabledClickOutside={true}
					modalContainerClassName='draggable-task-view-create-custom-task-modal'
				>
					<div className='ads-modal__content__warning-modal'>
						<div className='ads-modal__content__warning-modal__text'>
							{
								'Warning: Getting new data will make approving ads disappear from Ads Visualization if they have not passed the process!'
							}
						</div>
						<div className='ads-modal__content__warning-modal__button'>
							<Button
								className='button-dark-blue ads-modal__content--container__campaigns--items__container--button'
								text={'Proceed'}
								onClick={() => {
									localStorage.removeItem('approvingAds')
									handleClick()
									setShowWarningModal(false)
								}}
							/>
							<Button
								className='button-dark-blue ads-modal__content--container__campaigns--items__container--button'
								text={'Cancel'}
								onClick={() => {
									setShowWarningModal(false)
								}}
							/>
						</div>
					</div>
				</CustomModal>
				<div className='ads-visualisation-page__content--container selectors'>
					<div className='ads-visualisation-page__content--container--selector--container'>
						<div className='ads-visualisation-page__content--container--selector--container--top-contantainer'>
							<h6 className='ads-visualisation-page__content--container--selector-title'>
								{t('topnav.google ads')}
							</h6>
							<p
								className='ads-visualisation-page__content--container--selector-title--hide-filters'
								onClick={() => setShowFilters(prev => !prev)}
							>
								{showFilters
									? t('ads visualization.hide filters')
									: t('ads visualization.show filters')}
							</p>
						</div>
						{showFilters && (
							<>
								<div className='ads-visualisation-page__content--container__selectors'>
									<div className='ads-visualisation-page__content--container__selectors--item property'>
										<p className='ads-visualisation-page__content--container__selectors--item title'>
											{t('ads visualization.property')}
											<ToolTipWithAnIcon
												title={t(
													'tooltips.ads visualization property filter explanation',
												)}
											/>
										</p>
										<SelectWithSearch
											name='value'
											options={availableValues}
											value={filters.property ? selectedFilterValue : null}
											defaultValue={null}
											onChange={(e: any) => handleChangeField(e, 'property')}
										/>
									</div>
									<div className='ads-visualisation-page__content--container__selectors--item operator'>
										<p className='ads-visualisation-page__content--container__selectors--item title'>
											{t('ads visualization.operator')}
											<ToolTipWithAnIcon
												title={t(
													'tooltips.ads visualization operator filter explanation',
												)}
											/>
										</p>
										<SelectWithSearch
											name='operator'
											options={operators}
											value={{
												value: filters.operator,
												label: filters.operator,
											}}
											onChange={(e: any) => handleChangeField(e, 'operator')}
										/>
									</div>

									<div className='ads-visualisation-page__content--container__selectors--item value'>
										<p className='ads-visualisation-page__content--container__selectors--item title'>
											{t('ads visualization.value')}
											<ToolTipWithAnIcon
												title={t(
													'tooltips.ads visualization value filter explanation',
												)}
											/>
										</p>
										<Input
											name={'Value'}
											value={filters.value ? filters.value.toString() : ''}
											onChange={(e: React.FormEvent<HTMLInputElement>) =>
												handleChangeInput(e, 'value')
											}
										/>
									</div>
									<div className='ads-visualisation-page__content--container__selectors--item options'>
										<div className='ads-visualisation-page__content--container__selectors--item--options'>
											{sizeOptions.map(size => {
												return (
													<p
														className={`ads-visualisation-page__content--container__selectors--item--options--item ${
															size === filters.size ? 'active' : ''
														}`}
														onClick={(e: any) =>
															handleChangeField({ value: size }, 'size')
														}
														key={size}
													>
														{t(`ads visualization.${size}`)}
													</p>
												)
											})}
										</div>
									</div>
									<div className='ads-visualisation-page__content--container__selectors--button'>
										<Button
											text={t('ads visualization.reset filters')}
											onClick={resetGoogleAdsFilters}
										/>
									</div>
								</div>
							</>
						)}
					</div>

					{showFilters && isNotEmptyArray(campaigns) && (
						<>
							<div className='ads-visualisation-page__content--container--divider' />
							<div className='ads-visualisation-page__content--container__account-details'>
								<h6>{t('ads visualization.account values')}</h6>
								<div className='ads-visualisation-page__content--container__account-details--items'>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<h5>{t('ads visualization.budget')}</h5>
										<p>{`${fromMicrons(
											calculateTotal<AdsGoogleCampaignItem>(
												campaigns,
												'campaignBudget',
											),
										)} € /D`}</p>
									</div>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<h5>{t('ads visualization.costs')}</h5>
										<p>{`${fromMicrons(
											calculateTotal<AdsGoogleCampaignItem>(campaigns, 'costs'),
										)} €`}</p>
									</div>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<h5>{t('ads visualization.conversions')}</h5>
										<p>
											{calculateTotal<AdsGoogleCampaignItem>(
												campaigns,
												'conversions',
											)}
										</p>
									</div>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<h5>{t('ads visualization.roas')}</h5>
										<p>
											{(
												parseFloat(
													calculateTotal<AdsGoogleCampaignItem>(
														campaigns,
														'conversionValue',
													),
												) /
												parseFloat(
													fromMicrons(
														calculateTotal<AdsGoogleCampaignItem>(
															campaigns,
															'costs',
														),
													),
												)
											).toFixed(2)}
										</p>
									</div>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<h5>{t('ads visualization.price per conversion')}</h5>
										<p>
											{`${(
												parseFloat(
													fromMicrons(
														calculateTotal<AdsGoogleCampaignItem>(
															campaigns,
															'costs',
														),
													),
												) /
												parseFloat(
													calculateTotal<AdsGoogleCampaignItem>(
														campaigns,
														'conversions',
													),
												)
											).toFixed(2)} €`}
										</p>
									</div>
									<div className='ads-visualisation-page__content--container__account-details--items--container'>
										<h5>{t('ads visualization.average cpc')}</h5>
										<p>
											{`${(
												parseFloat(
													fromMicrons(
														calculateTotal<AdsGoogleCampaignItem>(
															campaigns,
															'costs',
														),
													),
												) /
												parseFloat(
													calculateTotal<GoogleAdsGroupItem>(
														adGroups,
														'clicks',
													),
												)
											).toFixed(2)} €`}
										</p>
									</div>
								</div>
							</div>
						</>
					)}

					{showFilters && (
						<>
							<div className='ads-visualisation-page__content--container--divider' />
							<div className='ads-visualisation-page__content--container__selectors dates'>
								<h6>{t('ads visualization.date selection')}</h6>
								<div className='ads-visualisation-page__content--container__selectors'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<div className='ads-visualisation-page__content--container__selectors--date'>
											<p className='ads-visualisation-page__content--container__selectors--item title'>
												{t('ads visualization.start date')}
											</p>
											<KeyboardDatePicker
												value={filters.from}
												onChange={(e: any) => handleChangeField(e, 'from')}
												maxDate={filters.to}
												lang={i18n.language}
											/>
										</div>
										<div className='ads-visualisation-page__content--container__selectors--date'>
											<p className='ads-visualisation-page__content--container__selectors--item title'>
												{t('ads visualization.end date')}
											</p>
											<KeyboardDatePicker
												value={filters.to}
												onChange={(e: any) => handleChangeField(e, 'to')}
												disableFuture
												lang={i18n.language}
											/>
										</div>
									</MuiPickersUtilsProvider>
									<div className='ads-visualisation-page__content--container__selectors--date'>
										<Button
											text={t('ads visualization.get data')}
											onClick={() => setShowWarningModal(true)}
											toolTipText={t('tooltips.get data explanation')}
											disabled={
												!currentProject.id ||
												isCompanyOnFreeVersion(company.subscriptionLevel)
											}
										/>
									</div>
								</div>
								{/* <div className='ads-visualisation-page__content--container__selectors--date'>
							<Button
								disabled={true}
								text='Create Ad'
								onClick={() => setCreateShowModal(true)}
							/>
						</div> */}
							</div>
						</>
					)}
				</div>

				<div className='ads-visualisation-page__content--container'>
					<div className='ads-visualisation-page__content--container__campaigns scrollable'>
						{mappedCampaigns.map(campaign => {
							return (
								<GoogleAdsCampaign
									key={campaign.campaignName}
									campaign={campaign}
									ads={
										getCampaignData(
											campaign.campaignName,
											mappedAdGroups,
											mappedAds,
										).ads
									}
									adGroups={
										getCampaignData(
											campaign.campaignName,
											mappedAdGroups,
											mappedAds,
										).filteredAdGroups
									}
								/>
							)
						})}
					</div>
				</div>
			</div>
		</>
	)
}

export default connect(
	(state: any) => ({
		currentProject: projectSelectors.getProject(state.project),
		campaigns: adsSelectors.getGoogleCampaigns(state.ads),
		adGroups: adsSelectors.getGoogleAdGroups(state.ads),
		ads: adsSelectors.getGoogleAds(state.ads),
		filters: adsSelectors.getGoogleFilters(state.ads),
		company: userSelectors.getCompany(state.user),
	}),
	dispatch => ({
		getGoogleCampaigns: (
			projectId: number,
			{ fromDate, toDate }: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getGoogleAdsCampaigns(projectId, { fromDate, toDate }),
			),
		getGoogleAdGroups: (
			projectId: number,
			{ fromDate, toDate, campaignName }: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getGoogleAdGroupsByCampaignId(projectId, {
					fromDate,
					toDate,
					campaignName,
				}),
			),
		getGoogleAds: (
			projectId: number,
			{
				fromDate,
				toDate,
				adGroupResourceName: adGroupName,
			}: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getGoogleAdsGroupId(projectId, {
					fromDate,
					toDate,
					adGroupResourceName: adGroupName,
				}),
			),
		getAllAds: (
			projectId: number,
			{ fromDate, toDate }: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getGoogleAdsAllAds(projectId, {
					fromDate,
					toDate,
				}),
			),
		getGoogleAllAdGroupsAndAssetGroups: (
			projectId: number,
			{ fromDate, toDate }: GoogleAdsVisualisationPayload,
		) =>
			dispatch(
				adsActions.getGoogleAllAdGroupsAndAssetGroups(projectId, {
					fromDate,
					toDate,
				}),
			),
		changeAdsField: ({ value, field }: any) =>
			dispatch(adsActions.changeGoogleAdsField({ field, value })),
		resetAds: () => dispatch(adsActions.resetGoogleAdsState()),
		resetGoogleAdsFilters: () => dispatch(adsActions.resetGoogleAdsFilters()),
	}),
)(withTranslation()(AdsVisualisation))
