import _ from 'lodash'
import React, { memo } from 'react'
import { connect } from 'react-redux'
import LoadingBars from '../../components/UI/LoadingBars'
import { primaryColor } from '../../constants'
import {
	dashboardSelectors,
	DashboaReducerState,
	loadingSelectors,
} from '../../reducers'

type Props = {
	authLoading: boolean
	appLoading: boolean
	dashboardLoading: boolean
	children: JSX.Element
}

const LoadingScreen = ({
	authLoading,
	appLoading,
	dashboardLoading,
	children,
}: Props) => {
	const loading = authLoading || appLoading || dashboardLoading
	const body = document.querySelector('body')
	if (body) {
		body.style.overflow = loading ? 'hidden' : 'auto'
	}
	return (
		<>
			{loading ? (
				<div className='loading-screen'>
					<LoadingBars color={primaryColor} />
				</div>
			) : null}
			{children}
		</>
	)
}
export default memo(
	connect((state: DashboaReducerState) => ({
		authLoading: loadingSelectors.getAuthLoading(state.loading),
		appLoading: loadingSelectors.getAppLoading(state.loading),
		dashboardLoading: dashboardSelectors.getLoading(state.dashboard),
	}))(LoadingScreen),
	(prevProps, nextProps) => _.isEqual(nextProps, prevProps),
)
