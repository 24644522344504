import { KeywordActions } from '../actions/keywords'
import {
	GET_COUNTRIES_SUCCESS,
	GET_KEYWORD_RANKING_SUCCESS,
	GET_TOP_KEYWORDS_SUCCESS,
	POST_KEYWORD_DATA_SUCCESS,
	SIGN_OUT,
	CLEAR_KEYWORDS,
	GET_PROJECT_BY_ID,
	CLEAR_KEYWORD_RANKING,
	CLEAR_KEYWORDS_STATE,
	GET_RANKED_KEYWORDS_SUCCESS,
	CREATE_PROJECT,
	GET_KEYWORD_LABEL_BY_PROJECT_ID_SUCCESS,
	SAVE_KEYWORD_LABEL_SUCCESS,
	UPDATE_KEYWORD_LABEL_BY_ID_SUCCESS,
	UPDATE_SELECTED_KEYWORDS_FOR_LABEL,
	GET_KEYWORD_RANKING_FOR_CHECKING_SUCCESS,
} from '../actions/types'
import * as _ from 'lodash'

import {
	CountryObjSelectValue,
	EKeywordRole,
	EKeywordSource,
	ETableOptions,
	transformCountries,
} from '../constants'

export type KeywordRanking = {
	keyword: string
	ranking: [
		{
			id: number
			position: number
			date: Date
			searchVolume: number
			keywordPageUrl: string
			origin: string
			clicks: number
			ctr: number
		},
	]
}

export type SuggestedKeywordPostData = {
	keyword: string
	languageName: string
	locationCode: number
	filters?: Array<Array<string | number>>
	depth: 1 | 2 | 3 | 4
}

export type ISuggestedKeywordData = {
	keyword_data: SuggestedKeywordObject
	depth: SuggestedKeywordPostData['depth']
	related_keywords: string[]
}

export type SuggestedKeywordObject = {
	keyword: string
	location_code: number
	language_code: string
	bingMonthlySearchVolume?: number | null
	monthlySearchVolume?: number | null
	depth?: SuggestedKeywordPostData['depth']
	cpc?: number | null
	keyword_info: {
		last_updated_time: string
		competition: number
		cpc: number
		search_volume: number
		categories: number[]
		monthly_searches: {
			year: number
			month: number
			search_volume: number
		}[]
	}
	impressions_info: {
		last_updated_time: string
		bid: number
		match_type: string
		ad_position_min: number
		ad_position_max: number
		ad_position_average: number
		cpc_min: number
		cpc_max: number
		cpc_average: number
		daily_impressions_min: number
		daily_impressions_max: number
		daily_impressions_average: number
		daily_clicks_min: number
		daily_clicks_max: number
		daily_clicks_average: number
		daily_cost_min: number
		daily_cost_max: number
		daily_cost_average: number
	}
	bing_keyword_info: {
		last_updated_time: string
		search_volume: number
		monthly_searches: {
			year: number
			month: number
			search_volume: number
		}[]
	}
	serp_info: string | null
	relatedKeywordData: ISuggestedKeywordData[]
	type?: ETableOptions
}

export type TopKeyword = {
	clicks: number
	impressions: number
	ctr: number
	position: number
	query: string
}

export type RankedKeyword = {
	keyword: string
	CTR: number
	clicks: number
	conversionRate: number
	impression?: number
	role?: EKeywordRole
	source?: EKeywordSource
	pageUrl?: string
	searchVolume?: number
	type?: ETableOptions
}

export type KeywordLabel = {
	id: number
	label: string
	keywords: string[]
	inputValue?: string
}

export type KeywordState = {
	keywordRanking: KeywordRanking[]
	keywords: SuggestedKeywordObject[]
	selectedKeyword: SuggestedKeywordObject | null
	allCountries: CountryObjSelectValue[]
	topKeywords: TopKeyword[]
	rankedKeywords: RankedKeyword[]
	keywordLabels: KeywordLabel[]
	selectedForKeywordLabels: string[]
	keywordRankingForChecking: string[]
}

const initialState: KeywordState = {
	keywordRanking: [],
	keywords: [],
	selectedKeyword: null,
	allCountries: [],
	topKeywords: [],
	rankedKeywords: [],
	keywordLabels: [],
	selectedForKeywordLabels: [],
	keywordRankingForChecking: [],
}

export const keywordsReducer = (
	state = initialState,
	action: KeywordActions,
): typeof initialState => {
	switch (action.type) {
		case GET_KEYWORD_RANKING_SUCCESS: {
			return {
				...state,
				keywordRanking: [...action.payload.data],
			}
		}
		case POST_KEYWORD_DATA_SUCCESS:
			return {
				...state,
				selectedKeyword: action.payload.data[0],
				keywords: action.payload.data.map((item: SuggestedKeywordObject) => {
					item.type = ETableOptions.PLANNER
					item.cpc = item.cpc ? item.cpc : 0
					return item
				}),
			}
		case CLEAR_KEYWORDS:
			return { ...state, keywords: [] }
		case CLEAR_KEYWORD_RANKING:
			return { ...state, keywordRanking: [] }
		case CLEAR_KEYWORDS_STATE:
			return { ...initialState }
		case GET_COUNTRIES_SUCCESS: {
			const transformedCountries = transformCountries(action.payload.data)
			return {
				...state,
				allCountries: transformedCountries,
			}
		}
		case GET_TOP_KEYWORDS_SUCCESS: {
			const keywords = action.payload.data.slice(0, 3)
			return {
				...state,
				topKeywords: keywords,
			}
		}
		case GET_RANKED_KEYWORDS_SUCCESS:
			return {
				...state,
				rankedKeywords: action.payload.data.map((item: RankedKeyword) => {
					item.type = ETableOptions.PAGE_EXPLORER
					return item
				}),
			}
		case GET_KEYWORD_LABEL_BY_PROJECT_ID_SUCCESS:
		case SAVE_KEYWORD_LABEL_SUCCESS:
		case UPDATE_KEYWORD_LABEL_BY_ID_SUCCESS:
			return {
				...state,
				keywordLabels: action.payload.data,
			}
		case GET_KEYWORD_RANKING_FOR_CHECKING_SUCCESS:
			return {
				...state,
				keywordRankingForChecking: action.payload.data,
			}
		case CREATE_PROJECT:
		case GET_PROJECT_BY_ID:
		case SIGN_OUT:
			return { ...initialState, allCountries: state.allCountries }
		default:
			return state
	}
}

export const keywordsSelectors = {
	getKeywords: (state = initialState): SuggestedKeywordObject[] =>
		state.keywords,
	getKeywordsRanking: (state = initialState): KeywordRanking[] =>
		state.keywordRanking,
	getSelectedForKeywordLabels: (state = initialState): string[] =>
		state.selectedForKeywordLabels,
	getKeywordLabels: (state = initialState): KeywordLabel[] =>
		state.keywordLabels,
	getKeywordRankingForChecking: (state = initialState): string[] =>
		state.keywordRankingForChecking,
}
