import { API } from './../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import {
	CHECK_RANK_PREDICTION_BY_ID,
	CHECK_RANK_PREDICTION_BY_ID_SUCCESS,
	CREATE_NEW_RANK_PREDICTION,
	CREATE_NEW_RANK_PREDICTION_SUCCESS,
	GET_RANK_PREDICTIONS_BY_COMPANY_ID,
	GET_RANK_PREDICTIONS_BY_COMPANY_ID_SUCCESS,
	GET_RANK_PREDICTION_BY_ID,
	GET_RANK_PREDICTION_BY_ID_SUCCESS,
} from './types'

interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P }
}

export enum ERankPredictionDomainItemStatus {
	'NOT_STARTED' = 'NOT_STARTED',
	'IN_PROGRESS' = 'IN_PROGRESS',
	'COMPLETED' = 'COMPLETED',
	'FAILED' = 'FAILED',
	'ERROR' = 'ERROR',
}

export type DomainLinkDetailsType = {
	domain: string
	backlinks: number
}

export type RankPredictionDomain = {
	domain: string
	name: string
	url: string
	position: number
	referringDomainsStatus: ERankPredictionDomainItemStatus
	crawlingStatus: ERankPredictionDomainItemStatus
	onPageStatus: ERankPredictionDomainItemStatus
	onPageId: string
	metadata: {
		h1Keyword: boolean
		h2Keyword: boolean
		headingCount: number
		headingKeywordCount: number
		imageAltCount: number
		imageTitleCount: number
		metaDescriptionLength: number
		metadataDescriptionKeywordCount: number
		titleKeywordCount: number
		titleLength: number
		totalKeywordCount: number
		wordsCount: number
	}
	backlinks: {
		referringDomains: number
		backlinks: number
		domainsRankTop100: number
		domainsRankTop200: number
		domainsRankTop300: number
		domainsRankTop400: number
		domainsRankTop500: number
		domainsRankTop100Details: DomainLinkDetailsType[]
		domainsRankTop200Details: DomainLinkDetailsType[]
		domainsRankTop300Details: DomainLinkDetailsType[]
		domainsRankTop400Details: DomainLinkDetailsType[]
		domainsRankTop500Details: DomainLinkDetailsType[]
		linksRankTop100: number
		linksRankTop200: number
		linksRankTop300: number
		linksRankTop400: number
		linksRankTop500: number
		anchorReferringLinks: number
		imageReferringLinks: number
		linkReferringLinks: number
		linksRankTop100Allocation: number
		linksRankTop200Allocation: number
		linksRankTop300Allocation: number
		linksRankTop400Allocation: number
		linksRankTop500Allocation: number
		domainsRankTop100Allocation: number
		domainsRankTop200Allocation: number
		domainsRankTop300Allocation: number
		domainsRankTop400Allocation: number
		domainsRankTop500Allocation: number
	}
	pageDetails: any[] | null
	searchVolume: number
	languageName: string
	locationCode: number
}

export type RankPredictionType = {
	keyword: string
	url: string
	domains: RankPredictionDomain[]
	createdAt: string
	accountId: number
	projectId: number
	rankPrediction: any
	firebaseId: string
	status: ERankPredictionDomainItemStatus
}

type CreateRankPrediction = Action<
	typeof CREATE_NEW_RANK_PREDICTION_SUCCESS,
	{ content: string }
>
type GetRankPrediction = Action<
	typeof GET_RANK_PREDICTION_BY_ID_SUCCESS,
	RankPredictionType
>
type CheckRankPrediction = Action<
	typeof CHECK_RANK_PREDICTION_BY_ID_SUCCESS,
	RankPredictionType | string
>
type GetRankPredictions = Action<
	typeof GET_RANK_PREDICTIONS_BY_COMPANY_ID_SUCCESS,
	RankPredictionType[]
>

export type RankPredictionActions =
	| CreateRankPrediction
	| GetRankPrediction
	| GetRankPredictions
	| CheckRankPrediction

export const createRankPrediction = (
	companyId: number,
	projectId: number,
	keyword: string,
	url: string,
): any => {
	const apiUri = replaceParamsUri(
		['companyId', 'projectId'],
		[companyId, projectId],
		API.CREATE_RANK_PREDICTION,
	)
	return {
		type: CREATE_NEW_RANK_PREDICTION,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					keyword,
					url,
				},
			},
		},
	}
}

export const getRankPredictionById = (
	companyId: number,
	projectId: number,
	firebaseId: string,
): any => {
	const apiUri = replaceParamsUri(
		['companyId', 'projectId', 'firebaseId'],
		[companyId, projectId, firebaseId],
		API.GET_RANK_PREDICTION_BY_ID,
	)
	return {
		type: GET_RANK_PREDICTION_BY_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const checkRankPredictionById = (
	companyId: number,
	projectId: number,
	firebaseId: string,
): any => {
	const apiUri = replaceParamsUri(
		['companyId', 'projectId', 'firebaseId'],
		[companyId, projectId, firebaseId],
		API.CHECK_RANK_PREDICTION_BY_ID,
	)
	return {
		type: CHECK_RANK_PREDICTION_BY_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getRankPredictionByCompanyId = (
	companyId: number,
	projectId: number,
): any => {
	const apiUri = replaceParamsUri(
		['companyId', 'projectId'],
		[companyId, projectId],
		API.GET_RANK_PREDICTION_BY_COMPANY_ID,
	)
	return {
		type: GET_RANK_PREDICTIONS_BY_COMPANY_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
