import { Comment, CommentActions } from '../actions/comment'
import {
	CREATE_COMMENT_BY_SUGGESTION_ID_SUCCESS,
	CREATE_COMMENT_BY_TASK_ID_SUCCESS,
	GET_ALL_COMMENTS_BY_TYPE_AND_ID_SUCCESS,
	RESET_COMMENT_STATE,
} from '../actions/types'

export type CommentState = {
	allComments: Comment[]
}

const initialState: CommentState = {
	allComments: [],
}

export const commentReducer = (
	state = initialState,
	action: CommentActions,
): CommentState => {
	switch (action.type) {
		case GET_ALL_COMMENTS_BY_TYPE_AND_ID_SUCCESS:
			return { ...state, allComments: action.payload.data }
		case CREATE_COMMENT_BY_SUGGESTION_ID_SUCCESS:
		case CREATE_COMMENT_BY_TASK_ID_SUCCESS:
			return {
				...state,
				allComments: [...state.allComments, action.payload.data],
			}
		case RESET_COMMENT_STATE:
			return { ...initialState }
		default:
			return state
	}
}

export const commentSelectors = {
	allComments: (state = initialState): Comment[] => state.allComments,
}
