import { TutorialState } from '../reducers/tutorial'
import { ActionChangeField } from './ads'
import { TUTORIAL_CHANGE_FIELD } from './types'

const tutorialChangeField = TUTORIAL_CHANGE_FIELD

export type TutorialChangeValues = TutorialState

type TutorialChangesFieldAction = ActionChangeField<
	typeof tutorialChangeField,
	Partial<TutorialChangeValues>
>
export type TutorialActionTypes = TutorialChangesFieldAction

export const changeTutorialField = (
	payload: Partial<TutorialChangeValues>,
): any => {
	return {
		type: tutorialChangeField,
		payload,
	}
}
