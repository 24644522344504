/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withTranslation, useTranslation, WithTranslation } from 'react-i18next'
// import { Switch } from '@material-ui/core'

import Input from '../../../shared/components/Input'
import {
	canAccessWithSubscriptionLevel,
	ESubscriptionLevel,
	ETUTORIAL_TYPES,
	findLanguageName,
	findlocationCode,
	transformLanguages,
} from '../../../constants'
import Button from '../../../shared/components/Button'
import { tutorialSelectors, TutorialState } from '../../../reducers/tutorial'
import YesNoButtons from '../../../shared/components/YesNoButtons'
import { projectSelectors, userSelectors } from '../../../reducers'
import { projectActions, tutorialActions } from '../../../actions'
import SelectWithSearch from '../../../shared/components/SelectWithSearch'

type Props = {
	currentProject: any
	tutorial: TutorialState
	editDraftProject: any
	changeTutorialField: any
	allCountries: any
	draftProject: any
	closeModal: any
	maxStepsChange: any
	handleDoneButtonClick: (type: ETUTORIAL_TYPES) => void
	handleChangeStep: (nextStep: boolean) => void
	company: any
} & PropsWithChildren<WithTranslation>

const ProjectCreationTutorial = ({
	tutorial,
	editDraftProject,
	changeTutorialField,
	allCountries,
	draftProject,
	maxStepsChange,
	handleDoneButtonClick,
	handleChangeStep,
	company,
}: Props) => {
	const { t } = useTranslation()

	const findCountryName = findlocationCode(allCountries)

	useEffect(() => {
		if (tutorial.currentStep === 3) {
			changeTutorialField({ nextStepValid: true })
		}

		if (tutorial.currentStep === 1 && draftProject.name.length < 2) {
			changeTutorialField({ nextStepValid: false })
		}
		if (tutorial.currentStep === 2 && draftProject.url.length < 9) {
			changeTutorialField({ nextStepValid: false })
		}
		if (
			((draftProject.accessToDataSources === false &&
				tutorial.currentStep === 4) ||
				(draftProject.accessToDataSources === true &&
					draftProject.isEcommerce === false &&
					tutorial.currentStep === 5)) &&
			(parseFloat(draftProject.conversionRate || 0) === 0 ||
				parseFloat(draftProject.conversionValue || 0) === 0)
		) {
			changeTutorialField({ nextStepValid: false })
		}
	}, [tutorial.currentStep])

	const [selectedLanguage, setSelectedLanguage] = useState(
		findLanguageName(
			transformLanguages(
				findCountryName(draftProject.locationCode) &&
					findCountryName(draftProject.locationCode).availableLanguages,
			),
		)(draftProject.languageName),
	)

	const handleNextStepValidation = (field: string, val: any) => {
		if (tutorial.currentStep === 1) {
			if (!val || val.length < 3) {
				changeTutorialField({ nextStepValid: false })
			} else {
				changeTutorialField({ nextStepValid: true })
			}
		} else if (tutorial.currentStep === 2) {
			if (val.length < 5) {
				changeTutorialField({ nextStepValid: false })
			} else {
				changeTutorialField({ nextStepValid: true })
			}
		} else if (tutorial.currentStep === 4 || tutorial.currentStep === 5) {
			const parsedValue = val ? parseFloat(val) : 0
			if (
				(draftProject.conversionRate &&
					field === 'conversionRate' &&
					parsedValue == 0) ||
				(draftProject.conversionValue &&
					field === 'conversionValue' &&
					parsedValue == 0) ||
				(draftProject.conversionValue &&
					draftProject.conversionRate &&
					field === 'conversionRate' &&
					parsedValue == 0) ||
				(draftProject.conversionRate &&
					draftProject.conversionValue &&
					field === 'conversionValue' &&
					parsedValue == 0) ||
				(!parseFloat(draftProject.conversionValue || 0) &&
					draftProject.conversionRate &&
					field === 'conversionRate' &&
					parsedValue != 0) ||
				(!parseFloat(draftProject.conversionRate || 0) &&
					draftProject.conversionValue &&
					field === 'conversionValue' &&
					parsedValue != 0) ||
				(!draftProject.conversionRate && !draftProject.conversionValue)
			) {
				changeTutorialField({ nextStepValid: false })
			} else {
				changeTutorialField({ nextStepValid: true })
			}
		}
	}

	const handleUserInput = (e: any) => {
		const { value, name } = e.target
		editDraftProject({ [name]: value })
		handleNextStepValidation(name, value)
	}
	const handleAccessToDataSourcesChange = (hasAccess: boolean) => {
		if (hasAccess) {
			editDraftProject({ conversionRate: '', conversionValue: '' })
		} else {
			editDraftProject({ conversionRate: '2', conversionValue: '200' })
		}
		if (draftProject.accessToDataSources === hasAccess) {
			handleChangeStep(true)
		} else {
			editDraftProject({ accessToDataSources: hasAccess })
			handleChangeStep(true)
		}
	}
	const [availableLanguages, setAvailableLanguages] = useState(
		draftProject.languageName &&
			transformLanguages(
				findCountryName(draftProject.locationCode) &&
					findCountryName(draftProject.locationCode).availableLanguages,
			),
	)

	const handleSelectCountry = (value: any) => {
		setAvailableLanguages(transformLanguages(value.availableLanguages))
		setSelectedLanguage(transformLanguages(value.availableLanguages)[0])
		editDraftProject({
			locationCode: value.value,
			languageName: transformLanguages(value.availableLanguages)[0].label,
		})
	}
	const handleSelectLanguage = (value: any) => {
		editDraftProject({ languageName: value.label })
		setSelectedLanguage(value)
	}

	const nameProjectStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.project creation tutorial.step 2 headline')}
			</h6>
			<Input
				value={draftProject.name}
				onChange={handleUserInput}
				label={t('common.project name')}
				name='name'
			/>
		</div>
	)

	const summaryStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.project creation tutorial.step 1 headline')}
			</h6>
			<p>{t('tutorial.project creation tutorial.step 1 text')}</p>
		</div>
	)

	const localizationAndUrlStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.project creation tutorial.step 3 headline')}
			</h6>
			<div className='project-tutorial-holder--project-url-holder'>
				<p className='project-tutorial-holder--project-url-holder--paragraph'>
					https://
				</p>
				<Input
					onChange={handleUserInput}
					value={draftProject.url}
					label={t('common.project url')}
					name='url'
				/>
			</div>
			<div className='tutorial-modal__country-selection'>
				<div className='tutorial-modal__country-selection--item'>
					<p>{t('setting page.country selection')}</p>
					<SelectWithSearch
						name='country'
						options={allCountries}
						value={findCountryName(draftProject.locationCode)}
						defaultValue={findCountryName(draftProject.locationCode)}
						onChange={handleSelectCountry}
					/>
				</div>
				{availableLanguages.length > 1 ? (
					<div className='tutorial-modal__country-selection--item'>
						<p>{t('setting page.language selection')}</p>
						<SelectWithSearch
							name='language'
							options={availableLanguages}
							value={selectedLanguage}
							defaultValue={selectedLanguage}
							onChange={handleSelectLanguage}
						/>
					</div>
				) : null}
			</div>
		</div>
	)

	// const isEcommerceStep = () => (
	// 	<div className='project-tutorial-holder'>
	// 		<h6 className='project-tutorial-header'>
	// 			{t('tutorial.project creation tutorial.step 5 headline')}
	// 		</h6>
	// 		<div className='project-tutorial-switch-holder'>
	// 			<YesNoButtons
	// 				handleChange={handleSwitch}
	// 				selectedValue={draftProject.isEcommerce}
	// 				property={'isEcommerce'}
	// 				isYesDisabled={!draftProject.accessToDataSources}
	// 			/>
	// 		</div>
	// 	</div>
	// )

	const accessToDataSourcesStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.project creation tutorial.step 4 headline')}
			</h6>
			<div className='project-tutorial-switch-holder'>
				<YesNoButtons
					handleChange={handleAccessToDataSourcesChange}
					selectedValue={draftProject.accessToDataSources}
					//property={'accessToDataSources'}
					isNoDisabled={canAccessWithSubscriptionLevel(
						company.subscriptionLevel,
						[ESubscriptionLevel.BASIC, ESubscriptionLevel.TRIAL],
					)}
				/>
			</div>
		</div>
	)

	const conversionStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('common.conversion value')}
			</h6>
			<p className='project-tutorial___body-table-overview'>
				{t('tutorial.project creation tutorial.step 6 text 1')}
			</p>
			<div>
				<Input
					value={draftProject.conversionValue}
					onChange={handleUserInput}
					label={t('common.conversion value')}
					name='conversionValue'
					type='number'
					min={0}
				/>
				<p className='project-tutorial___body-table-overview'>
					{t('tutorial.project creation tutorial.step 6 text 2')}
				</p>
				<Input
					value={draftProject.conversionRate}
					onChange={handleUserInput}
					label={t('common.conversion rate')}
					name='conversionRate'
					type='number'
					step={0.1}
					min={0}
				/>
			</div>
		</div>
	)
	const finishStep = () => (
		<div className='project-tutorial-holder'>
			<p>{t('tutorial.project creation tutorial.step 9 text')}</p>
			<Button
				className='finish-button'
				text={t('common.finish')}
				onClick={() => handleDoneButtonClick(tutorial.tutorialType)}
			/>
		</div>
	)

	type VariationsType = {
		[key: string]: Record<number, () => JSX.Element>
	}

	const variations: VariationsType = {
		defaultPath: {
			0: summaryStep,
			1: nameProjectStep,
			2: localizationAndUrlStep,
			3: accessToDataSourcesStep,
		},
		projectWithDatasources: {
			4: finishStep,
		},
		projectWithNonDatasources: {
			4: conversionStep,
			5: finishStep,
		},
	}

	const renderSelection = (): JSX.Element => {
		if (tutorial.currentStep < 4) {
			return variations.defaultPath[tutorial.currentStep]()
		}

		if (draftProject.accessToDataSources === true) {
			if (tutorial.maxSteps !== 4) {
				maxStepsChange(4)
			}

			return variations.projectWithDatasources[tutorial.currentStep]()
		}

		if (draftProject.accessToDataSources === false) {
			if (tutorial.maxSteps !== 4) {
				maxStepsChange(4)
			}
			return variations.projectWithNonDatasources[tutorial.currentStep]()
		} else {
			return <div>{'empty'}</div>
		}
	}

	return <div className='project-tutorial'>{renderSelection()}</div>
}

export default connect(
	(state: any) => ({
		allCountries: state.keywords.allCountries,
		currentProject: projectSelectors.getProject(state.project),
		draftProject: projectSelectors.getDraftProject(state.project),
		tutorial: tutorialSelectors.getTutorial(state.tutorial),
		company: userSelectors.getCompany(state.user),
	}),
	dispatch => ({
		editDraftProject: (payload: any) =>
			dispatch(projectActions.editDraftProject(payload)),
		changeTutorialField: (payload: any) =>
			dispatch(tutorialActions.changeTutorialField(payload)),
	}),
)(withTranslation()(ProjectCreationTutorial))
