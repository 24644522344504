import { API, ETypeOfItem, ISearchConsoleKeyword } from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import { SignOut } from './audit'
import {
	FETCH_DETAILS_DATA,
	GET_PRODUCT_CATEGORY_TREE_MAP,
	GET_PRODUCT_CATEGORY_TREE_MAP_SUCCESS,
} from './types'

interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P }
}

type GetProductCategoryTreeMap = Action<
	typeof GET_PRODUCT_CATEGORY_TREE_MAP_SUCCESS,
	any
>

export type CategoryCreatorActions = GetProductCategoryTreeMap | SignOut

export const getProductCategoryTreeMap = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_PRODUCT_CATEGORY_TREE_MAP,
	)
	return {
		type: GET_PRODUCT_CATEGORY_TREE_MAP,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const fetchDetailsData = (
	projectId: number,
	type: ETypeOfItem,
	url: string,
	searchData: ISearchConsoleKeyword[],
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'type'],
		[projectId, type],
		API.FETCH_DETAILS_DATA,
	)
	return {
		type: FETCH_DETAILS_DATA,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					url: url,
					searchConsoleKeywordData: searchData,
				},
			},
		},
	}
}
