import { API } from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import { ActionChangeField } from './ads'
import { ActionWithoutPayload } from './audit'
import {
	CHANGE_WP_SELECTED_ITEM_TITLE,
	CHANGE_WP_CURRENT_PAGE_BY_TYPE,
	CREATE_WP_ITEM_BY_TYPE,
	CREATE_WP_ITEM_BY_TYPE_SUCCESS,
	GET_WP_ITEM_BY_ID,
	GET_WP_ITEM_BY_ID_SUCCESS,
	GET_WP_PAGES,
	GET_WP_PAGES_SUCCESS,
	GET_WP_POSTS,
	GET_WP_POSTS_SUCCESS,
	RESET_WP_SELECTED_ITEM,
	RESET_WP_STATE,
	UPDATE_WP_ITEM_BY_ID,
	UPDATE_WP_ITEM_BY_ID_SUCCESS,
	SET_WP_SELECTED_ITEM,
} from './types'

interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P }
}

type GetWordPressPagesOrPostsResponse = {
	items: WordPressItemType[]
	totalItems: number
	totalPages: number
}

type GetWordPressPosts = Action<
	typeof GET_WP_POSTS_SUCCESS,
	GetWordPressPagesOrPostsResponse
>
type GetWordPressPages = Action<
	typeof GET_WP_PAGES_SUCCESS,
	GetWordPressPagesOrPostsResponse
>
type GetWordPressItemById = Action<
	typeof GET_WP_ITEM_BY_ID_SUCCESS,
	WordPressItemType
>
type UpdateWordPressItemById = Action<
	typeof UPDATE_WP_ITEM_BY_ID_SUCCESS,
	WordPressItemType
>
type CreateWordPressItemById = Action<
	typeof CREATE_WP_ITEM_BY_TYPE_SUCCESS,
	WordPressItemType
>
type ResetWordPressItem = ActionWithoutPayload<typeof RESET_WP_SELECTED_ITEM>
type ResetWordPressState = ActionWithoutPayload<typeof RESET_WP_STATE>
type ChangeSelectedItemTitle = ActionChangeField<
	typeof CHANGE_WP_SELECTED_ITEM_TITLE,
	{ value: string }
>

type ChangeWordPressCurrentPageByType = ActionChangeField<
	typeof CHANGE_WP_CURRENT_PAGE_BY_TYPE,
	{ type: 'posts' | 'pages'; value: number }
>
type SetSelectedItem = ActionChangeField<
	typeof SET_WP_SELECTED_ITEM,
	CreateWordPressItemType
>

export type WordPressActions =
	| GetWordPressPosts
	| GetWordPressPages
	| GetWordPressItemById
	| UpdateWordPressItemById
	| CreateWordPressItemById
	| ResetWordPressItem
	| ResetWordPressState
	| ChangeWordPressCurrentPageByType
	| ChangeSelectedItemTitle
	| SetSelectedItem

export enum EWordPressContentType {
	post = 'post',
	page = 'page',
}
export enum EPublishStatus {
	publish = 'publish',
	future = 'future',
	draft = 'draft',
	pending = 'pending',
	private = 'private',
}
export enum EFilterOption {
	id = 'id',
	title = 'title',
	date = 'date',
}
export enum EFilterAction {
	asc = 'asc',
	desc = 'desc',
}
export type WordPressItemType = {
	id: number
	type: EWordPressContentType
	link: string
	title: string
	content: string
	excerpt: string
	date: string
	modified: string
}
export type CreateWordPressItemType = {
	title: string
	content: string
	status?: EPublishStatus
	type?: EWordPressContentType
}

export const changeCurrentWordPressPageByType = (
	type: 'pages' | 'posts',
	value: number,
): any => {
	return {
		type: CHANGE_WP_CURRENT_PAGE_BY_TYPE,
		payload: {
			type,
			value,
		},
	}
}
export const changeSelectedItemTitle = (value: string): any => {
	return {
		type: CHANGE_WP_SELECTED_ITEM_TITLE,
		payload: {
			value,
		},
	}
}

export const createWordPressItem = (
	projectId: number,
	wordPressContentType: EWordPressContentType,
	requestBody: CreateWordPressItemType,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'wordPressContentType'],
		[projectId, wordPressContentType],
		API.CREATE_WORDPRESS_ITEM_BY_TYPE,
	)
	return {
		type: CREATE_WP_ITEM_BY_TYPE,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}
export const updateWordPressItem = (
	projectId: number,
	contentId: number,
	wordPressContentType: EWordPressContentType,
	requestBody: CreateWordPressItemType,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'wordPressContentType', 'contentId'],
		[projectId, wordPressContentType, contentId],
		API.UPDATE_WORDPRESS_ITEM_BY_ID,
	)
	return {
		type: UPDATE_WP_ITEM_BY_ID,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}

export const getWordPressItem = (
	projectId: number,
	contentId: number,
	wordPressContentType: EWordPressContentType,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'wordPressContentType', 'contentId'],
		[projectId, wordPressContentType, contentId],
		API.GET_WORDPRESS_ITEM_BY_ID,
	)
	return {
		type: GET_WP_ITEM_BY_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const getWordPressPages = (
	projectId: number,
	page = 1,
	itemsPerPage = 20,
	filterOption: EFilterOption = EFilterOption.id,
	filterAction: EFilterAction = EFilterAction.asc,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'wordPressContentType'],
		[projectId, EWordPressContentType.page],
		API.GET_WORDPRESS_ITEMS_BY_TYPE,
	)
	return {
		type: GET_WP_PAGES,
		payload: {
			request: {
				method: 'GET',
				url: `${apiUri}?pageNumber=${page}&itemsPerPage=${itemsPerPage}&filteringOption=${filterOption}&filteringAction=${filterAction}`,
			},
		},
	}
}
export const getWordPressPosts = (
	projectId: number,
	page = 1,
	itemsPerPage = 20,
	filterOption: EFilterOption = EFilterOption.id,
	filterAction: EFilterAction = EFilterAction.asc,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'wordPressContentType'],
		[projectId, EWordPressContentType.post],
		API.GET_WORDPRESS_ITEMS_BY_TYPE,
	)
	return {
		type: GET_WP_POSTS,
		payload: {
			request: {
				method: 'GET',
				url: `${apiUri}?pageNumber=${page}&itemsPerPage=${itemsPerPage}&filteringOption=${filterOption}&filteringAction=${filterAction}`,
			},
		},
	}
}

export const resetWordPressSelectedItem = (): any => {
	return {
		type: RESET_WP_SELECTED_ITEM,
	}
}
export const setSelectedItem = (selectedItem: CreateWordPressItemType): any => {
	return {
		type: SET_WP_SELECTED_ITEM,
		payload: selectedItem,
	}
}
export const resetWordPressState = (): any => {
	return {
		type: RESET_WP_STATE,
	}
}
