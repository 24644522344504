import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FacebookAdsItem, EFacebookAdsOptions } from '../../../constants'
import Button from '../../../shared/components/Button'
import XCloser from '../../../shared/components/XCloser'

interface Props<T extends FacebookAdsItem> {
	facebookAdItem: T | null
}

const FacebookAdsModal = <T extends FacebookAdsItem>({
	facebookAdItem,
}: Props<T>): JSX.Element | null => {
	const { t } = useTranslation()

	if (facebookAdItem === null) {
		return null
	}
	const renderContent = () => (
		<div
			className={'ads-modal__content--container__campaigns--items__container'}
		>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.budget')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{`${facebookAdItem.dailyBudget} €/D`}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.spend')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{facebookAdItem.spend}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.frequency')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{facebookAdItem.frequency}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.cpm')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{facebookAdItem.CPM}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.price per conversion')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{`${facebookAdItem.costPerConversion} €`}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.impressions')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{facebookAdItem.impressions}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.reach')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{facebookAdItem.reach}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.roas')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{facebookAdItem.ROAS}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.clicks')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{facebookAdItem.clicks}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.conversions')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{facebookAdItem.conversions}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.conversion value')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{`${facebookAdItem.conversionValue} €`}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.conversion rate')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{`${facebookAdItem.conversionRate} %`}
				</p>
			</div>
			<div className='ads-modal__content--container__campaigns--items__container--item facebook'>
				<p className='ads-modal__content--container__campaigns--items__container--item key'>
					{t('ads visualization.average cpc')}
				</p>
				<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
					{`${facebookAdItem.CPC} €`}
				</p>
			</div>
		</div>
	)
	try {
		if (facebookAdItem.type === EFacebookAdsOptions.CAMPAIGN) {
			return (
				<div className={'ads-modal__content'}>
					<div className={'ads-modal__content--container'}>
						<div className='ads-modal__content--container-close'>
							<XCloser onClose={() => facebookAdItem.onClick(null)} />
						</div>
						<div
							key={facebookAdItem.name}
							className={'ads-modal__content--container__campaigns--items'}
						>
							<h6>{facebookAdItem.name}</h6> {renderContent()}
						</div>
						<Button
							className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
							text={t('common.close')}
							onClick={() => facebookAdItem.onClick(null)}
						/>
					</div>
				</div>
			)
		} else if (facebookAdItem.type === EFacebookAdsOptions.AD_SETS) {
			return (
				<div className={'ads-modal__content'}>
					<div className={'ads-modal__content--container'}>
						<div className='ads-modal__content--container-close'>
							<XCloser onClose={() => facebookAdItem.onClick(null)} />
						</div>
						<div
							key={facebookAdItem.name}
							className={'ads-modal__content--container__campaigns--items'}
						>
							<h6>{facebookAdItem.name}</h6> {renderContent()}
						</div>
						<Button
							className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
							text={t('common.close')}
							onClick={() => facebookAdItem.onClick(null)}
						/>
					</div>
				</div>
			)
		} else if (facebookAdItem.type === EFacebookAdsOptions.AD) {
			return (
				<div className={'ads-modal__content'}>
					<div className={'ads-modal__content--container'}>
						<div className='ads-modal__content--container-close'>
							<XCloser onClose={() => facebookAdItem.onClick(null)} />
						</div>
						<div className={'ads-modal__content--container__campaigns--items'}>
							{renderContent()}
						</div>
						<Button
							className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
							text={t('common.close')}
							onClick={() => facebookAdItem.onClick(null)}
						/>
					</div>
				</div>
			)
		}
		return null
	} catch (error) {
		return null
	}
}

export default FacebookAdsModal
