import _ from 'lodash'
import {
	RankPredictionActions,
	RankPredictionType,
} from '../actions/rank-prediction'
import {
	CHECK_RANK_PREDICTION_BY_ID_SUCCESS,
	GET_RANK_PREDICTIONS_BY_COMPANY_ID_SUCCESS,
	GET_RANK_PREDICTION_BY_ID_SUCCESS,
} from '../actions/types'

export type RankPredictionState = {
	rankPredictions: RankPredictionType[]
	rankPrediction: RankPredictionType | null
}

export const initialState: RankPredictionState = {
	rankPredictions: [],
	rankPrediction: null,
}

export const rankPredictionReducer = (
	state = initialState,
	action: RankPredictionActions,
): typeof initialState => {
	switch (action.type) {
		case GET_RANK_PREDICTIONS_BY_COMPANY_ID_SUCCESS:
			return {
				...state,
				rankPredictions: action.payload.data,
			}
		case GET_RANK_PREDICTION_BY_ID_SUCCESS:
			return {
				...state,
				rankPrediction: action.payload.data,
			}
		case CHECK_RANK_PREDICTION_BY_ID_SUCCESS: {
			if (
				action.payload.data instanceof Object &&
				action.payload.data.firebaseId
			) {
				const firebaseId = action.payload.data.firebaseId
				const predictions = _.cloneDeep(state.rankPredictions)
				const foundIndex = predictions.findIndex(
					prediction => prediction.firebaseId === firebaseId,
				)
				if (foundIndex !== -1) {
					predictions[foundIndex] = action.payload.data
				}
				return {
					...state,
					rankPredictions: predictions,
				}
			} else {
				return state
			}
		}
		default:
			return state
	}
}
