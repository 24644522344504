import * as React from 'react'
import Button from './Button'
import { useTranslation } from 'react-i18next'

type Props = {
	handleChange: (hasAccess: boolean) => void
	selectedValue: boolean
	//property: string
	isYesDisabled?: boolean
	isNoDisabled?: boolean
}

const YesNoButtons = ({
	handleChange,
	selectedValue,
	//property,
	isYesDisabled,
	isNoDisabled,
}: Props): JSX.Element => {
	const { t } = useTranslation()
	return (
		<div className={'yes-no-buttons'}>
			<Button
				text={t('common.no')}
				onClick={() => {
					handleChange(false)
				}}
				disabled={isNoDisabled}
				containerClassName={!selectedValue ? 'yes-no-buttons--selected' : ''}
			/>
			<Button
				text={t('common.yes')}
				onClick={() => {
					handleChange(true)
				}}
				disabled={isYesDisabled}
				containerClassName={selectedValue ? 'yes-no-buttons--selected' : ''}
			/>
		</div>
	)
}

export default YesNoButtons
