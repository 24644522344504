/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation, useTranslation, WithTranslation } from 'react-i18next'

import { Settings } from '@material-ui/icons'
import {
	projectSelectors,
	userSelectors,
	dashboardSelectors,
} from '../../../reducers'
import { projectActions, tutorialActions } from '../../../actions'
import { EHIGHLIGHT } from '../../../constants'
import Button from '../../../shared/components/Button'
import { tutorialSelectors } from '../../../reducers/tutorial'

import { TutorialState } from '../../../reducers/tutorial'

type Props = {
	activeSuggestions: any[]
	currentProject: any
	tutorial: TutorialState
	editDraftProject: any
	changeTutorialField: any
	allCountries: any
	draftProject: any
	closeModal: any
	user: any
} & PropsWithChildren<WithTranslation>

const ProjectSettingsTutorial = ({
	activeSuggestions,
	tutorial,
	changeTutorialField,
	closeModal,
}: Props): JSX.Element | null => {
	const { t } = useTranslation()

	const tutorialOptions = {
		// projectSettings: {
		0: { highlighted: EHIGHLIGHT.NONE, modalAlignment: 'center' },
		1: { highlighted: EHIGHLIGHT.TASKS_TABS, modalAlignment: 'bottom' },
		2: { highlighted: EHIGHLIGHT.TASKS_FILTERS, modalAlignment: 'bottom' },
		3: { highlighted: EHIGHLIGHT.TASK_DESCRIPTION, modalAlignment: 'bottom' },
		4: { highlighted: EHIGHLIGHT.IGNORE_BUTTON, modalAlignment: 'bottom' },
		5: { highlighted: EHIGHLIGHT.DONE_BUTTON, modalAlignment: 'bottom' },
		6: { highlighted: EHIGHLIGHT.ADD_IN_PROGRESS, modalAlignment: 'bottom' },
		7: { highlighted: EHIGHLIGHT.SHOW_DETAILS, modalAlignment: 'bottom' },
		// },
		// dashboard: {
		// 	0: { highlighted: 'tools', modalAlignment: 'right' },
		// },
	}

	type TutorialKeys = keyof typeof tutorialOptions

	// type TutorialOptions = {
	// 	[K in TutorialKeys]: {
	// 		[J in K]: Pick<TutorialState, 'highlighted' | 'modalAlignment'>
	// 	}
	// }

	// type StepsKeys<K extends TutorialKeys> = Pick<TutorialOptions, K>

	useEffect(() => {
		changeTutorialField(
			tutorialOptions[
				tutorial.currentStep as unknown as TutorialKeys
			] as unknown as typeof tutorialOptions,
		)
		return () => {
			changeTutorialField({ highlighted: EHIGHLIGHT.NONE })
		}
	}, [tutorial.currentStep])

	const summaryStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.tasks tutorial.step 1 headline')}
			</h6>
			<p>{t('tutorial.tasks tutorial.step 1 text')}</p>
		</div>
	)

	const tabNavigationStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.tasks tutorial.step 2 headline')}
			</h6>
			<p>{t('tutorial.tasks tutorial.step 2 text')}</p>
		</div>
	)
	const filtersSelectionStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.tasks tutorial.step 3 headline')}
			</h6>
			<p>{t('tutorial.tasks tutorial.step 3 text')}</p>
		</div>
	)
	const noTasksStep = (): JSX.Element => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.tasks tutorial.step 4-2 headline')}
			</h6>
			<p>{t('tutorial.tasks tutorial.step 4-2 text')}</p>
			<br />
			<div className='project-settings-navigator'>
				<Settings fontSize='large' />
				<p>{t('sidenav.project settings')}</p>
			</div>
		</div>
	)
	const taskDescriptionStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.tasks tutorial.step 4-1 headline')}
			</h6>
			{t('tutorial.tasks tutorial.step 4-1 text')}
		</div>
	)
	const hideTaskButtonStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.tasks tutorial.step 5 headline')}
			</h6>
			<i className='material-icons'>visibility_off</i>
			<br />
			<p>{t('tutorial.tasks tutorial.step 5 text')}</p>
		</div>
	)
	const doneTaskButtonStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.tasks tutorial.step 6 headline')}
			</h6>
			<i className='material-icons'>check_circle</i>
			<br />
			<p> {t('tutorial.tasks tutorial.step 6 text')}</p>
		</div>
	)
	const addToDoButtonStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.tasks tutorial.step 7 headline')}
			</h6>
			<p className='show-and-tell-btn'>{t('common.add inprogress')}</p>
			<br />
			<p> {t('tutorial.tasks tutorial.step 7 text')}</p>
		</div>
	)
	const showDetailsButtonStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.tasks tutorial.step 8 headline')}
			</h6>
			<p className='show-and-tell-btn'>{t('common.show details')}</p>
			<br />
			<p> {t('tutorial.tasks tutorial.step 8 text')}</p>
		</div>
	)
	const finishStep = () => (
		<div className='project-tutorial-holder'>
			<p> {t('tutorial.tasks tutorial.step 9 text')}</p>
		</div>
	)

	if (activeSuggestions.length > 0) {
		switch (tutorial.currentStep) {
			case 0:
				return summaryStep()
			case 1:
				return tabNavigationStep()
			case 2:
				return filtersSelectionStep()
			case 3:
				return taskDescriptionStep()
			case 4:
				return hideTaskButtonStep()
			case 5:
				return doneTaskButtonStep()
			case 6:
				return addToDoButtonStep()
			case 7:
				return showDetailsButtonStep()
			case 8:
				return finishStep()
			default:
				return null
		}
	} else {
		switch (tutorial.currentStep) {
			case 0:
				return summaryStep()
			case 1:
				return tabNavigationStep()
			case 2:
				return filtersSelectionStep()
			case 3:
				return noTasksStep()
			case 4:
				return finishStep()
			default:
				return null
		}
	}
}

export default connect(
	(state: any) => ({
		allCountries: state.keywords.allCountries,
		currentProject: projectSelectors.getProject(state.project),
		draftProject: projectSelectors.getDraftProject(state.project),
		tutorial: tutorialSelectors.getTutorial(state.tutorial),
		user: userSelectors.getUser(state.user),
		activeSuggestions: dashboardSelectors.getActiveSuggestions(state.dashboard),
	}),
	dispatch => ({
		editDraftProject: (payload: any) =>
			dispatch(projectActions.editDraftProject(payload)),
		changeTutorialField: (payload: any) =>
			dispatch(tutorialActions.changeTutorialField(payload)),
	}),
)(withTranslation()(ProjectSettingsTutorial))
