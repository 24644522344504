/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import {
	GET_CURRENT_USER,
	SIGN_IN,
	SIGN_UP,
	SIGN_OUT,
	RESET_PASSWORD,
	FORGET_PASSWORD,
	UPDATE_USER,
	RESET_LOADERS,
	CHANGE_USER_FIELD,
	CHANGE_COMPANY_FIELD,
	CHANGE_SIDENAV_STATUS,
	SET_SIDENAV_STATUS,
	UPDATE_COMPANY,
	GET_COMPANY_USERS,
	INVITE_USER_TO_COMPANY,
	UPDATE_COMPANY_USER,
	GET_CURRENT_USER_SUCCESS,
	GET_COMPANY_USERS_SUCCESS,
	SIGN_IN_SUCCESS,
	SIGN_UP_SUCCESS,
	SIGN_IN_FAIL,
	UPDATE_COMPANY_USER_SUCCESS,
	UPDATE_USER_SUCCESS,
	UNSUBSCRIBE_PLAN_SUCCESS,
	GET_CURRENT_USER_FIREBASE_TOKEN_SUCCESS,
	GET_CURRENT_USER_FIREBASE_TOKEN,
	CREATE_COMPANY,
	CREATE_COMPANY_SUCCESS,
	UPDATE_COMPANY_SUCCESS,
	GET_PROJECT_USER,
	GET_PROJECT_USER_SUCCESS,
} from './types'
import { API, HTTP_CODE } from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import { Company, CompanyUser, User } from '../reducers/user'
import { Action, ActionWithoutPayload } from './audit'
import { ActionChangeField } from './ads'
import { NewUserInvite, UserRoleChange } from '../components/Company/Company'
import { GetProjectById, PostCreateProject } from './project'

type GetCurrentUser = Action<typeof GET_CURRENT_USER_SUCCESS, User>

type GetCompanyUsers = Action<typeof GET_COMPANY_USERS_SUCCESS, CompanyUser[]>

export type SignOut = ActionWithoutPayload<typeof SIGN_OUT>

export type SignIn = Action<
	typeof SIGN_IN_SUCCESS,
	{
		data: Record<string, any>
		status: HTTP_CODE
	}
>

type SignInFail = Action<
	typeof SIGN_IN_FAIL,
	{
		data: Record<string, any>
		status: HTTP_CODE
	}
>

type SignUp = Action<
	typeof SIGN_UP_SUCCESS,
	{
		data: Record<string, any>
		status: HTTP_CODE
	}
>

type UpdateCompanyUser = Action<typeof UPDATE_COMPANY_USER_SUCCESS, CompanyUser>

type GetUserFirebaseCustomToken = Action<
	typeof GET_CURRENT_USER_FIREBASE_TOKEN_SUCCESS,
	{ token: string }
>

type UpdateUser = Action<typeof UPDATE_USER_SUCCESS, User>

type UnsubscribePlan = Action<typeof UNSUBSCRIBE_PLAN_SUCCESS, User>

type ChangeUserField = ActionChangeField<typeof CHANGE_USER_FIELD, User>

type ChangeCompanyField = ActionChangeField<
	typeof CHANGE_COMPANY_FIELD,
	Company
>

type ChangeSidenavStatus = ActionWithoutPayload<typeof CHANGE_SIDENAV_STATUS>

type SetSidenavStatus = ActionChangeField<typeof SET_SIDENAV_STATUS, boolean>

type CreateCompany = Action<typeof CREATE_COMPANY_SUCCESS, Company>

type UpdateCompany = Action<typeof UPDATE_COMPANY_SUCCESS, Company>

type GetProjectUser = Action<typeof GET_PROJECT_USER_SUCCESS, any>

export type UserActions =
	| GetCurrentUser
	| GetCompanyUsers
	| SignOut
	| SignIn
	| SignUp
	| SignInFail
	| UpdateCompanyUser
	| UpdateUser
	| UnsubscribePlan
	| ChangeUserField
	| ChangeCompanyField
	| ChangeSidenavStatus
	| SetSidenavStatus
	| GetUserFirebaseCustomToken
	| GetProjectById
	| PostCreateProject
	| CreateCompany
	| UpdateCompany
	| GetProjectUser

// Update data type

export type LoginData = {
	email: string
	password: string
}

export type SignUpData = {
	email: string
	firstName: string
	lastName: string
	phoneNumber: string
	password: string
}

export type ResetPasswordData = {
	password: string
}

export type ForgetPasswordData = { email: string }

export const getUserFirebaseToken = (): any => {
	return {
		type: GET_CURRENT_USER_FIREBASE_TOKEN,
		payload: {
			request: {
				method: 'GET',
				url: API.GET_FIREBASE_CUSTOM_TOKEN,
			},
		},
	}
}
export const getCurrentUser = (): any => {
	return {
		type: GET_CURRENT_USER,
		payload: {
			request: {
				method: 'GET',
				url: API.GET_CURRENT_USER,
			},
		},
	}
}

export const getCompanyUsers = (companyId: number): any => {
	const apiUri = replaceParamsUri(
		['companyId'],
		[companyId],
		API.GET_COMPANY_USERS,
	)
	return {
		type: GET_COMPANY_USERS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const signOut = (): any => {
	return {
		type: SIGN_OUT,
	}
}

export const signIn = (data: LoginData): any => {
	return {
		type: SIGN_IN,
		payload: {
			request: {
				method: 'POST',
				url: API.SIGN_IN,
				data,
			},
		},
	}
}

export const signUp = (data: SignUpData): any => {
	return {
		type: SIGN_UP,
		payload: {
			request: {
				method: 'POST',
				url: API.SIGN_UP,
				data,
			},
		},
	}
}

export const forgetPassword = (data: ForgetPasswordData): any => {
	return {
		type: FORGET_PASSWORD,
		payload: {
			request: {
				method: 'POST',
				url: API.FORGET_PASSWORD,
				data,
			},
		},
	}
}

export const resetPassword = (data: ResetPasswordData): any => {
	return {
		type: RESET_PASSWORD,
		payload: {
			request: {
				method: 'POST',
				url: API.RESET_PASSWORD,
				data,
			},
		},
	}
}

export const updateUser = (userId: number, data: Partial<User>): any => {
	const apiUri = replaceParamsUri(['id'], [userId], API.UPDATE_USER)
	return {
		type: UPDATE_USER,
		payload: {
			request: {
				method: 'PUT',
				url: apiUri,
				data,
			},
		},
	}
}

export const resetLoaders = (): any => {
	return {
		type: RESET_LOADERS,
	}
}

export const changeUserField = (payload: Partial<User>): any => {
	return {
		type: CHANGE_USER_FIELD,
		payload,
	}
}
export const changeCompanyField = (payload: Partial<Company>): any => {
	return {
		type: CHANGE_COMPANY_FIELD,
		payload,
	}
}
export const changeSidenavStatus = (): any => {
	return {
		type: CHANGE_SIDENAV_STATUS,
	}
}
export const setSidenavStatus = (isExpanded: boolean): any => {
	return {
		type: SET_SIDENAV_STATUS,
		payload: isExpanded,
	}
}

export const updateCompany = (
	companyId: number,
	data: Partial<Company>,
): any => {
	const apiUri = replaceParamsUri(
		['companyId'],
		[companyId],
		API.UPDATE_COMPANY,
	)
	return {
		type: UPDATE_COMPANY,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}
export const updateCompanyUser = (
	companyId: number,
	userId: number,
	data: UserRoleChange,
): any => {
	const apiUri = replaceParamsUri(
		['companyId', 'userId'],
		[companyId, userId],
		API.UPDATE_COMPANY_USER,
	)
	return {
		type: UPDATE_COMPANY_USER,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}

export const inviteUserToCompany = (
	companyId: number,
	data: NewUserInvite,
): any => {
	const apiUri = replaceParamsUri(
		['companyId'],
		[companyId],
		API.INVITE_USER_TO_COMPANY,
	)
	return {
		type: INVITE_USER_TO_COMPANY,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const createCompany = (data: Partial<Company>): any => {
	return {
		type: CREATE_COMPANY,
		payload: {
			request: {
				method: 'POST',
				url: API.CREATE_COMPANY,
				data,
			},
		},
	}
}

export const getProjectUser = (projectId: number, companyId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'companyId'],
		[projectId, companyId],
		API.GET_PROJECT_USER,
	)
	return {
		type: GET_PROJECT_USER,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
