import React, { ReactElement } from 'react'
import { GoogleAdKeywordItem } from '../../../constants'
import { fromMicrons } from '../../../helpers/dataTransformer'
import { GoogleAdsFilterOptions } from '../../../reducers/ads'

interface Props {
	keyword: GoogleAdKeywordItem
	size: GoogleAdsFilterOptions['size']
}

export default function GoogleAdsKeywordItem({
	keyword,
	size,
}: Props): ReactElement {
	return size === 'medium' ? (
		<div
			key={keyword.keyword}
			className='ads-visualisation-page__content--container__adGroups--item--keyword-item'
		>
			<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key blue'>
				{keyword.keyword}
			</p>
			<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value blue'>{`${
				keyword.averageCPC ? fromMicrons(keyword.averageCPC) : 0
			}`}</p>
		</div>
	) : (
		<div
			key={keyword.keyword}
			className='ads-visualisation-page__content--container__adGroups--item--keyword-item'
		>
			<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key blue'>
				{keyword.keyword}
			</p>
		</div>
	)
}
