import React, { ReactElement, useState } from 'react'
import {
	GoogleAdItem,
	AdsGoogleCampaignItem,
	GoogleAdsGroupItem,
} from '../../../constants'
import { fromMicrons } from '../../../helpers/dataTransformer'
import { isNotEmptyArray } from '../../../helpers/fnHelpers'
import GoogleAdsItem from './GoogleAdsItem'
import GoogleAdsGroup from './GoogleAdsGroup'
import { PlusOne } from '@material-ui/icons'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'

interface Props {
	campaign: AdsGoogleCampaignItem
	adGroups: GoogleAdsGroupItem[]
	ads: GoogleAdItem[]
}

export default function GoogleAdsCampaign({
	campaign,
	adGroups,
	ads,
}: Props): ReactElement {
	const { t } = useTranslation()
	const [showDetails, setShowDetails] = useState<boolean>(false)
	const valuesToRender = [
		{
			text: t('ads visualization.budget'),
			value: `${fromMicrons(campaign.campaignBudget)}€/D`,
		},
		{
			text: t('ads visualization.conversions'),
			value: campaign.conversions,
		},
		{
			text: t('ads visualization.roas'),
			value: campaign.ROAS,
		},
		{
			text: t('ads visualization.price per conversion'),
			value: `${campaign.pricePerConversion}€`,
		},
		{
			text: t('ads visualization.average cpc'),
			value: `${campaign.averageCPC}€`,
		},
	]

	const renderValues = ({
		text,
		value,
	}: {
		text: string
		value: string | number
	}) => {
		return (
			<div
				key={text}
				className='ads-visualisation-page__content--container__adGroups--item--keyword-item border'
			>
				<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
					{text}
				</p>
				<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
					{value}
				</p>
			</div>
		)
	}

	if (campaign.size === 'large') {
		return (
			<div
				key={campaign.campaignName}
				className={`ads-visualisation-page__content--container__campaigns--container ${campaign.color}  ${campaign.size}`}
			>
				<div
					className={`ads-visualisation-page__content--container__campaigns--item ${campaign.color} ${campaign.size}`}
				>
					<div
						className={
							'ads-visualisation-page__content--container__campaigns--item-header'
						}
					>
						<h6 onClick={() => campaign.onClick(campaign)}>
							{campaign.campaignName}
						</h6>
						<>
							<span
								className={
									'ads-visualisation-page__content--container__campaigns--item-header edit'
								}
								onClick={() => campaign.onEditClick(campaign)}
							>
								<EditIcon />
							</span>
						</>
					</div>
					<div>{valuesToRender.map(renderValues)}</div>
				</div>
				{isNotEmptyArray(adGroups) && (
					<div
						className={`ads-visualisation-page__content--container__adGroups--items ${campaign.size}`}
					>
						{adGroups.map((adGroup, index) => (
							<GoogleAdsGroup
								key={index}
								size={campaign.size ? campaign.size : 'medium'}
								adGroup={adGroup}
								ads={ads.filter(
									(ad: GoogleAdItem) => ad.adGroupName === adGroup.adGroupName,
								)}
							/>
						))}
					</div>
				)}
			</div>
		)
	} else if (campaign.size === 'medium') {
		return (
			<div
				key={campaign.campaignName}
				className={`ads-visualisation-page__content--container__campaigns--container  ${campaign.size}`}
			>
				<div
					className={`ads-visualisation-page__content--container__campaigns--item ${
						campaign.color
					} ${campaign.size}  ${showDetails ? 'open' : ''}`}
				>
					<div
						className={
							'ads-visualisation-page__content--container__campaigns--item-header'
						}
					>
						<span />
						<h6 onClick={() => campaign.onClick(campaign)}>
							{campaign.campaignName}
						</h6>
						<>
							<span
								onClick={() => campaign.onEditClick(campaign)}
								className={
									'ads-visualisation-page__content--container__campaigns--item-header edit'
								}
							>
								<EditIcon />
							</span>
						</>
					</div>
					<div
						className={`ads-visualisation-page__content--container__campaigns--item--hidden medium ${
							showDetails ? 'open' : ''
						}`}
					>
						{valuesToRender.map(renderValues)}
					</div>

					<>
						<p
							className='ads-visualisation-page__content--container__adGroups--item--keyword--view-more'
							onClick={() => setShowDetails((old: boolean) => !old)}
						>
							{showDetails
								? `${t('common.view less')} -`
								: `${t('common.view more')} +`}
						</p>
					</>
				</div>
				{isNotEmptyArray(adGroups) && (
					<div
						className={`ads-visualisation-page__content--container__adGroups--items ${campaign.size}`}
					>
						{adGroups.map((adGroup, index) => (
							<GoogleAdsGroup
								key={index}
								size={campaign.size ? campaign.size : 'medium'}
								adGroup={adGroup}
								ads={ads.filter(
									(ad: GoogleAdItem) => ad.adGroupName === adGroup.adGroupName,
								)}
							/>
						))}
					</div>
				)}
			</div>
		)
	} else {
		return (
			<div
				key={campaign.campaignName}
				className={`ads-visualisation-page__content--container__campaigns--container  ${campaign.size}`}
			>
				<div
					className={`ads-visualisation-page__content--container__campaigns--item ${
						campaign.color
					} ${campaign.size}  ${showDetails ? 'open' : ''}`}
				>
					<div
						className={
							'ads-visualisation-page__content--container__campaigns--item-header'
						}
					>
						<span />
						<h6 onClick={() => campaign.onClick(campaign)}>
							{campaign.campaignName}
						</h6>
						<>
							<span
								className={
									'ads-visualisation-page__content--container__campaigns--item-header edit'
								}
								onClick={() => campaign.onEditClick(campaign)}
							>
								<EditIcon />
							</span>
						</>
					</div>
					<div
						className={`ads-visualisation-page__content--container__campaigns--item--hidden  ${
							showDetails ? 'open' : ''
						}`}
					>
						{valuesToRender.map(renderValues)}
					</div>
					{!showDetails ? (
						<div
							className={
								'ads-visualisation-page__content--container__adGroups--item--keyword-item icon'
							}
							onClick={() => setShowDetails((old: boolean) => !old)}
						>
							<AddCircleOutline fontSize='large' />
						</div>
					) : (
						<div
							className={
								'ads-visualisation-page__content--container__adGroups--item--keyword-item icon'
							}
							onClick={() => setShowDetails((old: boolean) => !old)}
						>
							<RemoveCircleOutline fontSize='large' />
						</div>
					)}
				</div>
				{isNotEmptyArray(adGroups) && (
					<div
						className={`ads-visualisation-page__content--container__adGroups--items ${campaign.size}`}
					>
						{adGroups.map((adGroup, index) => (
							<GoogleAdsGroup
								key={index}
								size={campaign.size ? campaign.size : 'medium'}
								adGroup={adGroup}
								ads={ads.filter(
									(ad: GoogleAdItem) => ad.adGroupName === adGroup.adGroupName,
								)}
							/>
						))}
					</div>
				)}
			</div>
		)
	}
}
