import { API } from './../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import {
	CREATE_MERCHANT_PRODUCT_REQUEST,
	CREATE_MERCHANT_PRODUCT_REQUEST_SUCCESS,
	DELETE_PRODUCT_TRACKING,
	DELETE_PRODUCT_TRACKING_SUCCESS,
	GET_ALL_PRODUCT_PRICE,
	GET_ALL_PRODUCT_PRICE_SUCCESS,
	UPDATE_TRACKING_DATE,
	UPDATE_TRACKING_DATE_SUCCESS,
} from './types'
import { SignOut } from './audit'

interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P }
}

type CreateMerchantProductRequest = Action<
	typeof CREATE_MERCHANT_PRODUCT_REQUEST_SUCCESS,
	any
>

type GetAllProductPrice = Action<typeof GET_ALL_PRODUCT_PRICE_SUCCESS, any>

type DeleteOneProductTracking = Action<
	typeof DELETE_PRODUCT_TRACKING_SUCCESS,
	any
>

export type PriceScalpingActions =
	| CreateMerchantProductRequest
	| GetAllProductPrice
	| DeleteOneProductTracking
	| SignOut

export const createMerchantProductRequest = (
	companyId: number,
	projectId: number,
	domain: string,
	productNameList: string[],
): any => {
	const apiUri = replaceParamsUri(
		['companyId', 'projectId'],
		[companyId, projectId],
		API.CREATE_MERCHANT_PRODUCT_REQUEST,
	)
	return {
		type: CREATE_MERCHANT_PRODUCT_REQUEST,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: { domain, productNameList },
			},
		},
	}
}

export const getAllProductPrice = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_ALL_PRODUCT_PRICE,
	)
	return {
		type: GET_ALL_PRODUCT_PRICE,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const deleteOneProductTracking = (
	projectId: number,
	id: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'id'],
		[projectId, id],
		API.DELETE_PRODUCT_TRACKING,
	)
	return {
		type: DELETE_PRODUCT_TRACKING,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUri,
			},
		},
	}
}

export const updateTrackingDate = (
	projectId: number,
	id: number,
	timeInterval: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'id'],
		[projectId, id],
		API.UPDATE_TRACKING_DATE,
	)
	return {
		type: UPDATE_TRACKING_DATE,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data: { timeInterval },
			},
		},
	}
}
