import moment from 'moment'
import { SomePostActions } from '../actions/some-post'
import {
	AI_IMAGE_GENERATOR_SUCCESS,
	CHANGE_FIELD,
	GET_FACEBOOK_POST_ATTACHMENTS,
	GET_FACEBOOK_POST_ATTACHMENTS_SUCCESS,
	GET_INSTAGRAM_ACCOUNT_SUCCESS,
	GET_LINKEDIN_POST_BY_ID_SUCCESS,
	GET_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS,
	GET_PROJECT_BY_ID_SUCCESS,
	RESET_DRAFT_SOME_POST,
	UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS,
} from '../actions/types'
import { ESomePostType } from '../constants'

export type LinkedInImageType = {
	downloadUrl: string
	owner: string
	id: string
	downloadUrlExpiresAt: number
	status: 'AVAILABLE' | 'PROCESSING' | 'PROCESSING_FAILED' | 'WAITING_UPLOAD'
}

export type PostImage = {
	url: string
	facebookId?: string
	googleStorageId?: string
	linkedInId?: string
}
export type DraftSomePost = {
	facebookSelected: boolean
	instagramSelected: boolean
	linkedInSelected: boolean
	somePostType: 'text' | 'imagepost'
	someInput: string
	toBePublished: boolean
	facebookPostTime: string
	instagramPostTime: string
	linkedInPostTime: string
	link: string
	postImages: PostImage[]
	instagramId: string
	accountId?: number
	statistic?: any
}

export type SomePostState = {
	draftSomePost: DraftSomePost
}

export const initialState: SomePostState = {
	draftSomePost: {
		facebookSelected: false,
		instagramSelected: false,
		linkedInSelected: false,
		somePostType: 'text',
		someInput: '',
		toBePublished: true,
		facebookPostTime: moment().format(),
		instagramPostTime: moment().format(),
		linkedInPostTime: moment().format(),
		link: '',
		postImages: [],
		instagramId: '',
		statistic: {},
	},
}

export const somePostReducer = (
	state = initialState,
	action: SomePostActions,
): typeof initialState => {
	switch (action.type) {
		case CHANGE_FIELD:
			return {
				...state,
				[action.payload.field]: action.payload.value,
			}
		case GET_INSTAGRAM_ACCOUNT_SUCCESS:
			return {
				...state,
				draftSomePost: {
					...state.draftSomePost,
					instagramId: action.payload.data.id,
				},
			}
		case GET_PROJECT_BY_ID_SUCCESS:
			return { ...initialState }
		case GET_FACEBOOK_POST_ATTACHMENTS:
		case RESET_DRAFT_SOME_POST:
			return {
				...state,
				draftSomePost: {
					...initialState.draftSomePost,
				},
			}
		case GET_FACEBOOK_POST_ATTACHMENTS_SUCCESS: {
			const { type } = action.payload.data
			if (type === ESomePostType.FACEBOOK) {
				const { message, scheduled_publish_time, permalink_url } =
					action.payload.data
				const attachments = action.payload.data?.attachments?.data[0]
				if (
					attachments &&
					attachments.subattachments &&
					Array.isArray(attachments.subattachments.data)
				) {
					const imageList = attachments.subattachments.data.map(
						(attachment: any) => {
							return {
								url: attachment.media.image.src,
								facebookId: attachment.target.id,
							}
						},
					)
					return {
						...state,
						draftSomePost: {
							...initialState.draftSomePost,
							postImages: [...imageList],
							someInput: message,
							toBePublished: scheduled_publish_time ? false : true,
							facebookPostTime: scheduled_publish_time
								? moment.unix(scheduled_publish_time).format()
								: initialState.draftSomePost.facebookPostTime,
							link: permalink_url
								? permalink_url
								: initialState.draftSomePost.link,
						},
					}
				} else {
					const image =
						attachments && attachments.media && attachments.media.image
							? [
									{
										url: attachments.media.image.src,
										facebookId: attachments.target.id,
									},
							  ]
							: []
					return {
						...state,
						draftSomePost: {
							...initialState.draftSomePost,
							postImages: image,
							someInput: message,
							toBePublished: scheduled_publish_time ? false : true,
							facebookPostTime: scheduled_publish_time
								? moment.unix(scheduled_publish_time).format()
								: initialState.draftSomePost.facebookPostTime,
							link: permalink_url
								? permalink_url
								: initialState.draftSomePost.link,
						},
					}
				}
			} else if (type === ESomePostType.INSTAGRAM) {
				const { caption, media_url, media_type, permalink } =
					action.payload.data
				return {
					...state,
					draftSomePost: {
						...initialState.draftSomePost,
						someInput: caption,
						postImages: [{ url: media_url }],
						link: permalink ? permalink : initialState.draftSomePost.link,
					},
				}
			} else if (type === ESomePostType.INSTAGRAM_SCHEDULED) {
				const { imageURLs, text, accountId, toBePublishedTime } =
					action.payload.data
				const imageList = imageURLs.map((image: any) => {
					return { url: image.url, googleStorageId: image.id }
				})
				return {
					...state,
					draftSomePost: {
						...initialState.draftSomePost,
						postImages: [...imageList],
						someInput: text,
						accountId,
						toBePublished: toBePublishedTime ? false : true,
						instagramPostTime: toBePublishedTime
							? moment.unix(toBePublishedTime).format()
							: initialState.draftSomePost.facebookPostTime,
					},
				}
			} else {
				return {
					...state,
					draftSomePost: {
						...initialState.draftSomePost,
					},
				}
			}
		}
		case GET_LINKEDIN_POST_BY_ID_SUCCESS:
			return {
				...state,
				draftSomePost: {
					...initialState.draftSomePost,
					someInput: action.payload.data.commentary,
					linkedInSelected: true,
					linkedInPostTime: action.payload.data.createdAt,
					postImages:
						Array.isArray(action.payload.data.images) &&
						action.payload.data.images.length
							? action.payload.data.images.map(
									({
										downloadUrl,
										id,
									}: {
										downloadUrl: string
										id: string
									}) => ({
										id,
										url: downloadUrl,
									}),
							  )
							: [],
					statistic: action.payload.data.statistic,
					link: `https://www.linkedin.com/feed/update/${action.payload.data.id}`,
				},
			}
		case UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS:
		case GET_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS: {
			const { imageURLs, text, accountId, toBePublishedTime } =
				action.payload.data
			const imageList = imageURLs.map((image: any) => {
				return { url: image.url, googleStorageId: image.id }
			})
			return {
				...state,
				draftSomePost: {
					...initialState.draftSomePost,
					postImages: [...imageList],
					someInput: text,
					accountId,
					toBePublished: toBePublishedTime ? false : true,
					linkedInPostTime: toBePublishedTime
						? moment.unix(toBePublishedTime).format()
						: initialState.draftSomePost.facebookPostTime,
				},
			}
		}
		case AI_IMAGE_GENERATOR_SUCCESS:
			return {
				...state,
				draftSomePost: {
					...state.draftSomePost,
					postImages: [
						{
							url: `data:image/png;base64,${action.payload.data.b64_json}`,
						},
						...state.draftSomePost.postImages,
					],
				},
			}
		default:
			return state
	}
}

export const somePostSelectors = {
	getSomePostReducer: (state = initialState): SomePostState => state,
}
