/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import { replaceParamsUri } from '../helpers/apiHelpers'
import {
	GET_GOOGLE_ANALYTIC_VIEWS,
	POST_GOOGLE_ANALYTIC_VIEWS,
	GET_GOOGLE_SEARCH_CONSOLE_SITES,
	POST_GOOGLE_SEARCH_CONSOLE_SITES,
	GET_GOOGLE_ANALYTIC_DATA_SESSIONS,
	GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL,
	GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS,
	GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE,
	GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE,
	GET_FACEBOOK_PAGE_SITES,
	POST_FACEBOOK_PAGE_SITES,
	GET_GOOGLE_ADS_DATA,
	GET_GOOGLE_ADS_CHART_DATA,
	POST_GOOGLE_ADS_VIEWS,
	CREATE_PROJECT,
	CREATE_BULK_PROJECTS,
	GET_GOOGLE_ADS_VIEWS,
	UNSUBSCRIBE_DATA_SOURCE,
	UPDATE_SUBSCRIBER_PROJECT,
	GET_GOOGLE_ANALYTIC_PAGE,
	GENERATE_SUGGESTION_BY_TYPE,
	GET_SUGGESTION_BY_TYPE,
	GET_SUGGESTION_DATA_BY_ID,
	GET_GOOGLE_ADS_AD_GROUP_DATA,
	GET_GOOGLE_ADS_CAMPAIGN_DATA,
	SELECT_PROJECT,
	GET_FACEBOOK_AD_MANAGERS,
	POST_FACEBOOK_AD_MANAGERS,
	UPDATE_SUGGESTION_STATUS,
	MODIFY_PROJECT,
	GET_REPORT_DATA,
	CHECK_GENERATE_STATUS,
	GET_COUNTRIES,
	POST_GOOGLE_ANALYTICS_GOALS,
	GET_GOOGLE_ANALYTICS_GOALS,
	RESET_DRAFT_PROJECT,
	CHECK_PROJECT_AMOUNT,
	UPDATE_PROJECT_TYPE,
	EDIT_PROJECT_FIELD,
	GET_ALL_PROJECTS_SUCCESS,
	EDIT_DRAFT_PROJECT,
	GET_GOOGLE_ANALYTIC_PAGE_SUCCESS,
	GET_GOOGLE_ANALYTIC_DATA_SUCCESS,
	GET_GOOGLE_ANALYTIC_VIEWS_SUCCESS,
	POST_GOOGLE_ANALYTIC_VIEWS_SUCCESS,
	GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE_SUCCESS,
	GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE_SUCCESS,
	GET_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS,
	POST_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS,
	GET_GOOGLE_ADS_AD_GROUP_DATA_SUCCESS,
	GET_GOOGLE_ADS_CAMPAIGN_DATA_SUCCESS,
	GET_GOOGLE_ADS_CHART_DATA_SUCCESS,
	GET_GOOGLE_ADS_VIEWS_SUCCESS,
	POST_GOOGLE_ADS_VIEWS_SUCCESS,
	GET_GOOGLE_ANALYTICS_GOALS_SUCCESS,
	POST_GOOGLE_ANALYTICS_GOALS_SUCCESS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_SUCCESS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS_SUCCESS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS_SUCCESS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE_SUCCESS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE_SUCCESS,
	GET_FACEBOOK_PAGE_SITES_SUCCESS,
	POST_FACEBOOK_PAGE_SITES_SUCCESS,
	POST_FACEBOOK_AD_MANAGERS_SUCCESS,
	GET_FACEBOOK_AD_MANAGERS_SUCCESS,
	CREATE_PROJECT_SUCCESS,
	CREATE_BULK_PROJECTS_SUCCESS,
	UNSUBSCRIBE_DATA_SOURCE_SUCCESS,
	UPDATE_SUBSCRIBER_PROJECT_SUCCESS,
	GENERATE_SUGGESTION_BY_TYPE_SUCCESS,
	GET_SUGGESTION_BY_TYPE_SUCCESS,
	UPDATE_SUGGESTION_STATUS_SUCCESS,
	GET_SUGGESTION_DATA_BY_ID_SUCCESS,
	GET_REPORT_DATA_SUCCESS,
	MODIFY_PROJECT_SUCCESS,
	UPDATE_PROJECT_TYPE_SUCCESS,
	CHECK_GENERATE_STATUS_SUCCESS,
	CHECK_PROJECT_AMOUNT_SUCCESS,
	GET_COUNTRIES_SUCCESS,
	SIGN_UP_SUCCESS,
	GET_ALL_PROJECTS,
	GET_PROJECT_BY_ID_SUCCESS,
	GET_PROJECT_BY_ID,
	GET_SUGGESTION_COUNT,
	RESET_DASHBOARD_REDUCER,
	RESET_PROJECT_STATE,
	POST_GENERATE_SINGLE_SUGGESTION_SUCCESS,
	POST_GENERATE_SINGLE_SUGGESTION,
	POST_GENERATE_SUGGESTIONS_BY_KEYWORDS,
	POST_GENERATE_SUGGESTIONS_BY_KEYWORDS_SUCCESS,
	POST_GOOGLE_ANALYTIC_GA4_PROPERTY,
	GET_GOOGLE_ANALYTIC_GA4_PROPERTIES,
	GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_SUCCESS,
	POST_GOOGLE_ANALYTIC_GA4_PROPERTY_SUCCESS,
	UPDATE_DATA_SOURCE,
	UPDATE_DATA_SOURCE_SUCCESS,
	POST_EXTERNAL_LINKS_FOR_SUGGESTION,
	POST_EXTERNAL_LINKS_FOR_SUGGESTION_SUCCESS,
	CLEAR_EXTERNAL_LINKS,
	GET_GOOGLE_ANALYTICS_EVENTS,
	UPDATE_SUGGESTION_ASSIGNEES,
	GENERATE_TRACKING_DATA,
	MODIFY_EDIT_PROJECT_FIELD,
	GET_COMPETITOR_DATA,
	INVITE_CONTRIBUTOR_TO_PROJECT,
	GET_CONTRIBUTOR_LIST,
	GET_CONTRIBUTOR_LIST_SUCCESS,
	UPDATE_CONTRIBUTOR_DATA,
	DELETE_CONTRIBUTOR_DATA,
	UPDATE_CONTRIBUTOR_DATA_SUCCESS,
	DELETE_CONTRIBUTOR_DATA_SUCCESS,
	INVITE_CONTRIBUTOR_TO_PROJECT_SUCCESS,
	GET_LINKEDIN_PAGES,
	GET_LINKEDIN_PAGES_SUCCESS,
	POST_LINKEDIN_PAGES_SUCCESS,
	POST_LINKEDIN_PAGES,
	GET_GOOGLE_ANALYTICS_COMPARISON_DATA,
	GET_GOOGLE_ANALYTICS_COMPARISON_DATA_SUCCESS,
	GET_PRODUCT_REFERENCE,
	GET_PRODUCT_REFERENCE_SUCCESS,
	SAVE_PRODUCT_REFERENCE,
	SAVE_PRODUCT_REFERENCE_SUCCESS,
} from './types'
import {
	API,
	FACEBOOK_METRICS,
	METRICS,
	GOOGLE_SEARCH_DIMENSIONS,
	GoogleAdsReportType,
	IProject,
	IReportTemplateItem,
	SingleSuggestionResponse,
	IExternalLinksForSuggestion,
} from '../constants'
import { query30daysFromToday } from '../helpers/fnHelpers'
import { ActionWithoutPayload, SignOut } from './audit'
import { Suggestion } from '../reducers/dashboard'
import { ProjectContributor } from '../reducers/project'

interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P }
}

interface ActionChangeField<T, P> {
	readonly type: T
	readonly payload: P
}

type ProjectChangesFieldAction = ActionChangeField<
	typeof EDIT_DRAFT_PROJECT,
	{ field: keyof IProject; value: string | IProject[] }
>
type EditProjectFieldAction = ActionChangeField<
	typeof MODIFY_EDIT_PROJECT_FIELD,
	{ field: keyof IProject; value: string }
>
type GetGoogleAnalyticsPageData = Action<
	typeof GET_GOOGLE_ANALYTIC_PAGE_SUCCESS,
	any
>
type GetGoogleAnalyticsData = Action<
	typeof GET_GOOGLE_ANALYTIC_DATA_SUCCESS,
	any
>
type GetGoogleAnalyticsAnalyticsViews = Action<
	typeof GET_GOOGLE_ANALYTIC_VIEWS_SUCCESS,
	any
>
type PostGoogleAnalyticsAnalyticsViews = Action<
	typeof POST_GOOGLE_ANALYTIC_VIEWS_SUCCESS,
	any
>
type GetGoogleAnalyticsAnalyticsGA4Properties = Action<
	typeof GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_SUCCESS,
	any
>
type PostGoogleAnalyticsAnalyticsGA4Property = Action<
	typeof POST_GOOGLE_ANALYTIC_GA4_PROPERTY_SUCCESS,
	any
>
type GetGoogleSearchData = Action<
	| typeof GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE_SUCCESS
	| typeof GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE_SUCCESS,
	any
>
type GetGoogleSearchSites = Action<
	typeof GET_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS,
	any
>
type PostGoogleSearchSites = Action<
	typeof POST_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS,
	any
>
type GetGoogleAdsData = Action<
	| typeof GET_GOOGLE_ADS_AD_GROUP_DATA_SUCCESS
	| typeof GET_GOOGLE_ADS_CAMPAIGN_DATA_SUCCESS,
	any
>
type GetGoogleAdsChartData = Action<
	typeof GET_GOOGLE_ADS_CHART_DATA_SUCCESS,
	any
>
type GetGoogleAdsViews = Action<typeof GET_GOOGLE_ADS_VIEWS_SUCCESS, any>
type PostGoogleAdsViews = Action<typeof POST_GOOGLE_ADS_VIEWS_SUCCESS, any>
type GetGoogleAnalyticGoals = Action<
	typeof GET_GOOGLE_ANALYTICS_GOALS_SUCCESS,
	any
>
type PostGoogleAnalyticGoals = Action<
	typeof POST_GOOGLE_ANALYTICS_GOALS_SUCCESS,
	any
>
type GetFacebookInsights = Action<
	| typeof GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_SUCCESS
	| typeof GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS_SUCCESS
	| typeof GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS_SUCCESS
	| typeof GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE_SUCCESS
	| typeof GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE_SUCCESS,
	any
>
type GetLinkedInPages = Action<typeof GET_LINKEDIN_PAGES_SUCCESS, any>
type PostLinkedInPages = Action<typeof POST_LINKEDIN_PAGES_SUCCESS, any>
type GetFacebookPages = Action<typeof GET_FACEBOOK_PAGE_SITES_SUCCESS, any>
type PostFacebookPages = Action<typeof POST_FACEBOOK_PAGE_SITES_SUCCESS, any>
type GetFacebookAdManager = Action<typeof GET_FACEBOOK_AD_MANAGERS_SUCCESS, any>
type PostFacebookAdManager = Action<
	typeof POST_FACEBOOK_AD_MANAGERS_SUCCESS,
	any
>
export type CreateProject = ActionWithoutPayload<typeof CREATE_PROJECT>
export type PostCreateProject = Action<typeof CREATE_PROJECT_SUCCESS, IProject>
export type PostCreateProjectBulk = Action<
	typeof CREATE_BULK_PROJECTS_SUCCESS,
	any
>
type DeleteUnsubscribeDatasource = Action<
	typeof UNSUBSCRIBE_DATA_SOURCE_SUCCESS,
	any
>
type UpdateProjectDataSource = Action<typeof UPDATE_DATA_SOURCE_SUCCESS, any>
type PatchUpdateProjectSubscribers = Action<
	typeof UPDATE_SUBSCRIBER_PROJECT_SUCCESS,
	any
>
type GetGenerateSuggestionByType = Action<
	typeof GENERATE_SUGGESTION_BY_TYPE_SUCCESS,
	any
>
type GetSuggestionByType = Action<
	typeof GET_SUGGESTION_BY_TYPE_SUCCESS,
	Suggestion[]
>
type GenerateSingleSuggestion = Action<
	typeof POST_GENERATE_SINGLE_SUGGESTION_SUCCESS,
	SingleSuggestionResponse
>
export type GenerateSuggestionsByKeywords = Action<
	typeof POST_GENERATE_SUGGESTIONS_BY_KEYWORDS_SUCCESS,
	void
>
type InviteContributorToProject = Action<
	typeof INVITE_CONTRIBUTOR_TO_PROJECT_SUCCESS,
	ProjectContributor[]
>
type GetContributorList = Action<
	typeof GET_CONTRIBUTOR_LIST_SUCCESS,
	ProjectContributor[]
>
type UpdateContributorData = Action<typeof UPDATE_CONTRIBUTOR_DATA_SUCCESS, any>
type DeleteContributorData = Action<typeof DELETE_CONTRIBUTOR_DATA_SUCCESS, any>
type GetSuggestionById = Action<typeof GET_SUGGESTION_DATA_BY_ID_SUCCESS, any>
type PatchSuggestion = Action<typeof UPDATE_SUGGESTION_STATUS_SUCCESS, any>
type GetReportData = Action<typeof GET_REPORT_DATA_SUCCESS, any>
type SelectProject = Action<typeof SELECT_PROJECT, IProject>
export type PatchModifyProject = Action<typeof MODIFY_PROJECT_SUCCESS, IProject>
type PatchProjectType = Action<typeof UPDATE_PROJECT_TYPE_SUCCESS, IProject>
type GetExternalLinksForSuggestion = Action<
	typeof POST_EXTERNAL_LINKS_FOR_SUGGESTION_SUCCESS,
	IExternalLinksForSuggestion[]
>
type GetGenerateSuggestionStatus = Action<
	typeof CHECK_GENERATE_STATUS_SUCCESS,
	any
>
type GetCheckProjectAmount = Action<typeof CHECK_PROJECT_AMOUNT_SUCCESS, any>
type GetCountries = Action<typeof GET_COUNTRIES_SUCCESS, any>
type EditProjectFields = Action<
	typeof EDIT_PROJECT_FIELD,
	{
		field: keyof IProject
		value: string | IProject[]
	}
>

type ResetDraftProject = ActionWithoutPayload<typeof RESET_DRAFT_PROJECT>
type ResetProjectState = ActionWithoutPayload<typeof RESET_PROJECT_STATE>
type ResetExternalLinks = ActionWithoutPayload<typeof CLEAR_EXTERNAL_LINKS>
type GetAllProjects = Action<
	typeof GET_ALL_PROJECTS_SUCCESS,
	Pick<IProject, 'id' | 'name'>[]
>
export type GetProjectById = Action<typeof GET_PROJECT_BY_ID_SUCCESS, IProject>
type SignUpSuccess = Action<typeof SIGN_UP_SUCCESS, any>
export type GetGoogleAnalyticsComparisonData = Action<
	typeof GET_GOOGLE_ANALYTICS_COMPARISON_DATA_SUCCESS,
	any
>
export type GetProductReference = Action<
	typeof GET_PRODUCT_REFERENCE_SUCCESS,
	any
>

export type SaveProductReference = Action<
	typeof SAVE_PRODUCT_REFERENCE_SUCCESS,
	any
>

export type ProjectsActions =
	| ProjectChangesFieldAction
	| ResetDraftProject
	| GetAllProjects
	| EditProjectFields
	| GetCountries
	| GetCheckProjectAmount
	| GetGenerateSuggestionStatus
	| PatchProjectType
	| PatchModifyProject
	| SelectProject
	| GetReportData
	| PatchSuggestion
	| GetSuggestionById
	| GetSuggestionByType
	| GetGenerateSuggestionByType
	| PatchUpdateProjectSubscribers
	| UpdateProjectDataSource
	| DeleteUnsubscribeDatasource
	| PostCreateProjectBulk
	| PostCreateProject
	| PostFacebookAdManager
	| GetFacebookAdManager
	| PostFacebookPages
	| GetFacebookPages
	| GetFacebookInsights
	| PostGoogleAnalyticGoals
	| GetGoogleAnalyticGoals
	| PostGoogleAdsViews
	| GetGoogleAdsViews
	| GetGoogleAdsChartData
	| GetGoogleAdsData
	| PostGoogleSearchSites
	| GetGoogleSearchSites
	| GetGoogleSearchData
	| PostGoogleAnalyticsAnalyticsViews
	| GetGoogleAnalyticsAnalyticsViews
	| GetGoogleAnalyticsData
	| GetGoogleAnalyticsPageData
	| SignOut
	| SignUpSuccess
	| GetProjectById
	| ResetProjectState
	| GenerateSingleSuggestion
	| GenerateSuggestionsByKeywords
	| GetGoogleAnalyticsAnalyticsGA4Properties
	| PostGoogleAnalyticsAnalyticsGA4Property
	| GetExternalLinksForSuggestion
	| ResetExternalLinks
	| EditProjectFieldAction
	| InviteContributorToProject
	| GetContributorList
	| UpdateContributorData
	| DeleteContributorData
	| GetLinkedInPages
	| PostLinkedInPages
	| GetGoogleAnalyticsComparisonData
	| GetProductReference
	| SaveProductReference

export const getGoogleAnalyticPage = (
	projectId: number,
	queryObj = query30daysFromToday,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'query'],
		[projectId, new URLSearchParams(queryObj as Record<string, any>)],
		API.GET_GOOGLE_ANALYTIC_PAGE,
	)
	return {
		type: GET_GOOGLE_ANALYTIC_PAGE,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const editDraftProject = (payload: {
	field: keyof IProject
	value: string | IProject[]
}): any => {
	return {
		type: 'EDIT_DRAFT_PROJECT',
		payload,
	}
}
export const editEditProject = (
	field: keyof IProject,
	value: string | Partial<IProject>,
): any => {
	return {
		type: MODIFY_EDIT_PROJECT_FIELD,
		payload: { field, value },
	}
}
export const resetProjectState = (): any => {
	return {
		type: 'RESET_PROJECT_STATE',
	}
}
export const resetExternalLinks = (): any => {
	return {
		type: CLEAR_EXTERNAL_LINKS,
	}
}

export const getGoogleAnalyticData = (
	projectId: number,
	queryObj: any,
	type: keyof typeof METRICS = queryObj['metrics[]'],
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'query'],
		[projectId, new URLSearchParams(queryObj as Record<string, any>)],
		API.GET_GOOGLE_ANALYTIC_DATA,
	)
	let actionType = 'GET_GOOGLE_ANALYTIC_DATA'
	switch (type) {
		case METRICS.SESSIONS:
			actionType = GET_GOOGLE_ANALYTIC_DATA_SESSIONS
			break
		case METRICS.GOAL_CONVERSION_RATE_ALL:
			actionType = GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL
			break
		case METRICS.PAGE_VIEWS:
			actionType = GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS
			break
		default:
			break
	}
	return {
		type: actionType,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getGoogleAnalyticViews = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_GOOGLE_ANALYTIC_VIEWS,
	)
	return {
		type: GET_GOOGLE_ANALYTIC_VIEWS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export type AddAnalyticsViewDto = {
	viewId: string
	name?: string
	siteUrl: string
	accountId: string
	webPropertyId: string
}

export const postGoogleAnalyticViews = (
	projectId: number,
	data: AddAnalyticsViewDto,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_GOOGLE_ANALYTIC_VIEWS,
	)
	return {
		type: POST_GOOGLE_ANALYTIC_VIEWS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}
export const postExternalKeywordForSuggestion = (
	projectId: number,
	keyword: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_EXTERNAL_LINKS_FOR_SUGGESTION,
	)
	return {
		type: POST_EXTERNAL_LINKS_FOR_SUGGESTION,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: { keyword },
			},
		},
	}
}

export const getGoogleAnalyticGA4Properties = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_GOOGLE_ANALYTIC_GA4_PROPERTIES,
	)
	return {
		type: GET_GOOGLE_ANALYTIC_GA4_PROPERTIES,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const postGoogleAnalyticGA4Property = (
	projectId: number,
	data: { propertyId: string },
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_GOOGLE_ANALYTIC_GA4_PROPERTY,
	)
	return {
		type: POST_GOOGLE_ANALYTIC_GA4_PROPERTY,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const getGoogleAnalyticsComparisonDataForHomePage = (
	projectId: number,
	data: { date: string },
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GOOGLE_ANALYTICS_COMPARISON_DATA,
	)
	return {
		type: GET_GOOGLE_ANALYTICS_COMPARISON_DATA,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
				data,
			},
		},
	}
}

export const getGoogleSearchData = (projectId: number, queryObj: any): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'query'],
		[projectId, new URLSearchParams(queryObj as Record<string, any>)],
		API.GET_GOOGLE_SEARCH_CONSOLE_DATA,
	)
	let actionType = 'GET_GOOGLE_SEARCH_CONSOLE_DATA'
	switch (queryObj['dimensions[]']) {
		case GOOGLE_SEARCH_DIMENSIONS.PAGE:
			actionType = GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE
			break
		case GOOGLE_SEARCH_DIMENSIONS.DATE:
			actionType = GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE
			break
		default:
			break
	}
	return {
		type: actionType,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getGoogleSearchSites = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_GOOGLE_SEARCH_SITES,
	)
	return {
		type: GET_GOOGLE_SEARCH_CONSOLE_SITES,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const postGoogleSearchSites = (
	projectId: number,
	data: { url: string },
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_GOOGLE_SEARCH_SITES,
	)
	return {
		type: POST_GOOGLE_SEARCH_CONSOLE_SITES,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const getGoogleAdsData = (projectId: number, type: string): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'type', 'query'],
		[projectId, type, new URLSearchParams({})],
		API.GET_GOOGLE_ADS_DATA,
	)
	let actionType = GET_GOOGLE_ADS_DATA
	switch (type) {
		case GoogleAdsReportType.adGroupAd:
			actionType = GET_GOOGLE_ADS_AD_GROUP_DATA
			break
		case GoogleAdsReportType.campaign:
			actionType = GET_GOOGLE_ADS_CAMPAIGN_DATA
			break
		default:
			break
	}
	return {
		type: actionType,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getGoogleAdsChartData = (
	projectId: number,
	type: string,
	queryObj: any,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'type', 'query'],
		[projectId, type, new URLSearchParams(queryObj as Record<string, any>)],
		API.GET_GOOGLE_ADS_DATA,
	)
	return {
		type: GET_GOOGLE_ADS_CHART_DATA,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getGoogleAdsViews = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_GOOGLE_ADS_VIEWS,
	)
	return {
		type: GET_GOOGLE_ADS_VIEWS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export type GoogleAdsAccountDto = {
	managerId: string | null
	customerId: string | null
}

export const postGoogleAdsViews = (
	projectId: number,
	data: GoogleAdsAccountDto,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_GOOGLE_ADS_VIEWS,
	)
	return {
		type: POST_GOOGLE_ADS_VIEWS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const getLinkedInPages = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_LINKEDIN_PAGES,
	)
	return {
		type: GET_LINKEDIN_PAGES,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const saveLinkedInPage = (
	projectId: number,
	linkedInPageId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_LINKEDIN_PAGES,
	)
	return {
		type: POST_LINKEDIN_PAGES,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: { linkedInPageId },
			},
		},
	}
}
export const getGoogleAnalyticsGoals = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_GOOGLE_ANALYTICS_GOALS,
	)
	return {
		type: GET_GOOGLE_ANALYTICS_GOALS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const getGoogleGA4Events = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_GOOGLE_ANALYTICS_EVENTS,
	)
	return {
		type: GET_GOOGLE_ANALYTICS_EVENTS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export type GoogleAnalyticsGoalDto = {
	goalId: string
	goalName: string
	goalValue: number
}

export interface IGoogleAnalyticsEvents {
	eventId: string
	eventName: string
	eventValue: number
	eventRate: number
	eventCount: string
}

export const postGoogleAnalyticsGoals = (
	projectId: number,
	data: GoogleAnalyticsGoalDto[] | IGoogleAnalyticsEvents[],
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_GOOGLE_ANALYTICS_GOALS,
	)
	return {
		type: POST_GOOGLE_ANALYTICS_GOALS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const getFacebookInsights = (
	projectId: number,
	metric: string,
	queryObj: any,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'facebookMetric', 'query'],
		[projectId, metric, new URLSearchParams(queryObj as Record<string, any>)],
		API.GET_FACEBOOK_PAGES_INSIGHT,
	)
	let actionType = 'GET_FACEBOOK_PAGE_INSIGHT'
	switch (metric) {
		case FACEBOOK_METRICS.PAGE_FANS:
			actionType = GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS
			break
		case FACEBOOK_METRICS.PAGE_ACTIONS:
			actionType = GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS
			break
		case FACEBOOK_METRICS.PAGE_REACTIONS:
			actionType = GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS
			break
		case FACEBOOK_METRICS.PAGE_FANS_ONLINE:
			actionType = GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE
			break
		case FACEBOOK_METRICS.PAGE_FANS_GENDER_AGE:
			actionType = GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE
			break
		default:
			break
	}
	return {
		type: actionType,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getFacebookPages = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_FACEBOOK_PAGES,
	)
	return {
		type: GET_FACEBOOK_PAGE_SITES,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const postFacebookPages = (
	projectId: number,
	data: { id: string },
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_FACEBOOK_PAGES,
	)
	return {
		type: POST_FACEBOOK_PAGE_SITES,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const getFacebookAdManager = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_FACEBOOK_AD_MANAGERS,
	)
	return {
		type: GET_FACEBOOK_AD_MANAGERS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const postFacebookAdManager = (
	projectId: number,
	data: { id: string },
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.API_FACEBOOK_AD_MANAGERS,
	)
	return {
		type: POST_FACEBOOK_AD_MANAGERS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const createProject = (data: Partial<IProject>): any => {
	if (data.projectUrl) {
		data.projectUrl = data.projectUrl.trim()
	}
	return {
		type: CREATE_PROJECT,
		payload: {
			request: {
				method: 'POST',
				url: API.CREATE_PROJECT,
				data,
			},
		},
	}
}
export const saveBulkProspectProjects = (data: IProject[]): any => {
	return {
		type: CREATE_BULK_PROJECTS,
		payload: {
			request: {
				method: 'POST',
				url: API.CREATE_BULK_PROJECTS,
				data,
			},
		},
	}
}

export const unsubscribeDataSource = (
	projectId: number,
	datasourceType: string,
): any => {
	const apiUri = replaceParamsUri(
		['id', 'datasourceType'],
		[projectId, datasourceType],
		API.UNSUBSCRIBE_DATA_SOURCE,
	)
	return {
		type: UNSUBSCRIBE_DATA_SOURCE,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUri,
			},
		},
	}
}

export const updateProjectDataSource = (
	projectId: number,
	ga4StartDate: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.UPDATE_DATA_SOURCE,
	)
	return {
		type: UPDATE_DATA_SOURCE,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data: { ga4StartDate },
			},
		},
	}
}

export const updateSubscribersProject = (
	projectId: number,
	data: Partial<IProject>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.UPDATE_SUBSCRIBER_PROJECT,
	)
	return {
		type: UPDATE_SUBSCRIBER_PROJECT,
		payload: {
			request: {
				method: 'PUT',
				url: apiUri,
				data,
			},
		},
	}
}

export const generateSuggestions = (
	projectId: number,
	suggestionType: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'suggestionType'],
		[projectId, suggestionType],
		API.GENERATE_SUGGESTION_BY_TYPE,
	)
	return {
		type: GENERATE_SUGGESTION_BY_TYPE,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const getCompetitorData = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_COMPETITOR_DATA,
	)
	return {
		type: GET_COMPETITOR_DATA,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const generateSingleSuggestion = (
	keyword: string,
	url: string,
	locationCode: number,
	languageName: string,
): any => {
	const data = { keyword, url, locationCode, languageName }
	return {
		type: POST_GENERATE_SINGLE_SUGGESTION,
		payload: {
			request: {
				method: 'POST',
				url: API.GENERATE_SINGLE_SUGGESTION,
				data,
			},
		},
	}
}
export const generateSingleSuggestionsByKeywords = (
	projectId: number,
	keywords: { keyword: string; url: string }[],
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GENERATE_SUGGESTIONS_BY_KEYWORDS,
	)
	return {
		type: POST_GENERATE_SUGGESTIONS_BY_KEYWORDS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: { keywords },
			},
		},
	}
}

export const getSuggestions = (
	projectId: number,
	suggestionType = 'all',
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'suggestionType'],
		[projectId, suggestionType],
		API.GET_SUGGESTION_BY_TYPE,
	)
	return {
		type: GET_SUGGESTION_BY_TYPE,
		payload: {
			request: {
				method: 'GET',
				url: `${apiUri}`,
			},
		},
	}
}
export const getSuggestionCount = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'suggestionType'],
		[projectId, 'all'],
		API.GET_SUGGESTION_COUNT,
	)
	return {
		type: GET_SUGGESTION_COUNT,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const updateSuggestionStatus = (
	data: any,
	projectId: number,
	suggestionId: number,
	suggestionType: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'suggestionId', 'suggestionType'],
		[projectId, suggestionId, suggestionType],
		API.UPDATE_SUGGESTION_STATUS,
	)
	return {
		type: UPDATE_SUGGESTION_STATUS,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}

export const getSuggestionById = (
	projectId: number,
	suggestionType: string,
	suggestionId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'suggestionType', 'suggestionId'],
		[projectId, suggestionType, suggestionId],
		API.GET_SUGGESTION_DATA_BY_ID,
	)
	return {
		type: GET_SUGGESTION_DATA_BY_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const generateTrackingData = (
	projectId: number,
	suggestionType: string,
	suggestionId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'suggestionType', 'suggestionId'],
		[projectId, suggestionType, suggestionId],
		API.GENERATE_TRACKING_DATA,
	)
	return {
		type: GENERATE_TRACKING_DATA,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getReportData = (
	projectId: number,
	date: string,
	data: IReportTemplateItem[],
): any => {
	const apiUrl = replaceParamsUri(
		['projectId', 'date'],
		[projectId, date],
		API.GET_REPORT_DATA,
	)
	return {
		type: GET_REPORT_DATA,
		payload: {
			request: {
				method: 'POST',
				url: apiUrl,
				data: { components: data },
			},
		},
	}
}
export const selectProject = (data: IProject): any => {
	return {
		type: SELECT_PROJECT,
		payload: {
			data,
		},
	}
}

export const resetDashboard = (): any => {
	return {
		type: RESET_DASHBOARD_REDUCER,
	}
}

export const modifyProject = (data: IProject, projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.MODIFY_PROJECT,
	)
	if (data.projectUrl) {
		data.projectUrl = data.projectUrl.trim()
	}
	return {
		type: MODIFY_PROJECT,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}
export const updateProjectType = (
	projectId: number,
	data: Partial<IProject>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.UPDATE_PROJECT_TYPE,
	)
	return {
		type: UPDATE_PROJECT_TYPE,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}

export const checkGenerationStatus = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.CHECK_GENERATION_STATUS,
	)
	return {
		type: CHECK_GENERATE_STATUS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const checkProjectAmount = (): any => {
	return {
		type: CHECK_PROJECT_AMOUNT,
		payload: {
			request: {
				method: 'GET',
				url: API.CHECK_PROJECTS_AMOUNT,
			},
		},
	}
}

export const getCountries = (): any => {
	return {
		type: GET_COUNTRIES,
		payload: {
			request: {
				method: 'GET',
				url: API.GET_COUNTRIES,
			},
		},
	}
}

export const resetDraftProject = (): any => {
	return {
		type: RESET_DRAFT_PROJECT,
	}
}

export const editProjectFields = (values: {
	field: keyof IProject
	value: string | IProject[]
}): any => {
	return {
		type: EDIT_PROJECT_FIELD,
		payload: values,
	}
}

export const getAllProjects = () => {
	return {
		type: GET_ALL_PROJECTS,
		payload: {
			request: {
				method: 'GET',
				url: API.GET_ALL_PROJECTS,
			},
		},
	}
}

export const getProjectById = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_PROJECT_BY_ID,
	)
	return {
		type: GET_PROJECT_BY_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const updateSuggestionAssignees = (suggestionId: number, data: any) => {
	const apiUri = replaceParamsUri(
		['suggestionId'],
		[suggestionId],
		API.UPDATE_SUGGESTION_ASSIGNEES,
	)
	return {
		type: UPDATE_SUGGESTION_ASSIGNEES,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}

export type NewContributorInvite = {
	firstName: string
	lastName: string
	email: string
	role: string
}

export const inviteContributorToProject = (
	projectId: number,
	data: NewContributorInvite,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.INVITE_CONTRIBUTOR_TO_PROJECT,
	)
	return {
		type: INVITE_CONTRIBUTOR_TO_PROJECT,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export type contributorData = {
	accessLevel: string
}

export const getContributorList = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_CONTRIBUTOR_LIST,
	)
	return {
		type: GET_CONTRIBUTOR_LIST,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const updateContributorData = (
	projectId: number,
	contributorDataId: number,
	data: contributorData,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'contributorDataId'],
		[projectId, contributorDataId],
		API.UPDATE_CONTRIBUTOR_DATA,
	)
	return {
		type: UPDATE_CONTRIBUTOR_DATA,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const deleteContributorData = (
	projectId: number,
	contributorDataId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'contributorDataId'],
		[projectId, contributorDataId],
		API.DELETE_CONTRIBUTOR_DATA,
	)
	return {
		type: DELETE_CONTRIBUTOR_DATA,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUri,
			},
		},
	}
}

export const getProductReference = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_PRODUCT_REFERENCE,
	)
	return {
		type: GET_PRODUCT_REFERENCE,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const saveProductReference = (
	projectId: number,
	urls: string[],
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.SAVE_PRODUCT_REFERENCE,
	)
	return {
		type: SAVE_PRODUCT_REFERENCE,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: { urls: urls },
			},
		},
	}
}
