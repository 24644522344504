import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import HelpIcon from '@mui/icons-material/Help'
import { styled } from '@material-ui/core/styles'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'
import { DASHBOA_PRIMARY_COLOR } from '../../constants'

const StyledToolTip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: DASHBOA_PRIMARY_COLOR,
		boxShadow: '1px 1px 1px 1px #ffffff25',
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: DASHBOA_PRIMARY_COLOR,
	},
}))

const ToolTipWithAnIcon = (props: {
	icon?: JSX.Element
	title: string
	delay?: number
	component?: JSX.Element
}): JSX.Element => {
	const { title, delay = 0, icon = <HelpIcon />, component } = props
	return (
		<>
			<StyledToolTip
				className='tool-tip'
				title={
					component ? component : <p className='tool-tip__title'>{title}</p>
				}
				enterDelay={delay}
				arrow
				TransitionComponent={Zoom}
			>
				{icon}
			</StyledToolTip>
		</>
	)
}

export default ToolTipWithAnIcon
