import React from 'react'

type Props = {
	containerClassName: string
	onClick: () => void
	text: string
	align?: 'start' | 'center' | 'end'
}
const ButtonText = ({
	containerClassName,
	onClick,
	text,
	align,
}: Props): JSX.Element => {
	return (
		<div
			className={`text-button ${
				containerClassName ? containerClassName : ''
			}  ${align ? `align-${align}` : ''}`}
		>
			<span onClick={onClick}>{text}</span>
		</div>
	)
}
export default ButtonText
