import moment from 'moment'
import React from 'react'
import { formatDecimals } from './fnHelpers'
import { formatGoogleAnalyticsData } from './typeDataTransformer'
import i18n from '../services/i18n'
import { formatSumsIntl, formatAmountsIntl } from '../services/i18n'
import {
	ESuggestionStatus,
	ETaskPriority,
	SuggestionStatus,
} from '../constants'
import {
	MoreHoriz,
	SignalCellular1BarRounded,
	SignalCellular3BarRounded,
	SignalCellular4BarRounded,
	SignalCellular0BarRounded,
} from '@mui/icons-material'
import { IProject } from '../constants'

const week = 7
export const formatGoogleAnalyticsSessions = data =>
	data.map(e => {
		return {
			date: moment(e.date).format('DD.MM'),
			sessions: Number(e.sessions),
		}
	})

/**
 *
 * @param {Array} data Array of objects that needs to be reformatted
 * @param {boolean} isNewMonthUsers If returning value should be named differently for comparison
 * @returns Array of objects with date and users
 */
export const formatGoogleAnalyticsUsers = (data, isNewMonthUsers = false) => {
	if (isNewMonthUsers) {
		return data.map(e => {
			return {
				date: moment(e.date).format('DD.MM'),
				newUsers: Number(e.users),
			}
		})
	}
	return data.map(e => {
		return {
			date: moment(e.date).format('DD.MM'),
			users: Number(e.users),
		}
	})
}

/**
 *
 * @param {Array} data Array of objects that needs to be reformatted
 * @param {string} valueToCalculate Value that should be used when combining data and will return data with that value
 * @param {string} dividerValue Value that should be used when combining data and will return data divided with that value
 * @param {string} finalValue Value that should be used when naming data
 * @returns Array of objects with date and defined value
 */
export const formatGoogleAnalyticsDataWithDividing = (
	data,
	valueToCalculate,
	dividerValue,
	finalValue,
) => {
	const mapped = data.map(e => {
		return {
			date: e.date,
			[valueToCalculate]: Number(e[valueToCalculate]),
			[dividerValue]: Number(e[dividerValue]),
		}
	})
	const combined = mapped.reduce((accumulator, cur) => {
		const date = cur.date
		const found = accumulator.find(elem => elem.date === date)
		if (found) {
			found[valueToCalculate] += cur[valueToCalculate]
			found[dividerValue] += cur[dividerValue]
		} else {
			accumulator.push(cur)
		}
		return accumulator
	}, [])
	const combinedValues = combined.map(combItem => {
		combItem[finalValue] = combItem[valueToCalculate] / combItem[dividerValue]
		return combItem
	})
	return combinedValues
}

export const formatGoogleAnalyticsDataWithoutDividing = (
	data,
	valueToCalculate,
	newKey,
) => {
	if (!newKey) {
		newKey = valueToCalculate
	}
	const mapped = data.map(e => {
		return {
			date: moment(e.date).format('DD.MM'),
			[newKey]: Number(e[valueToCalculate]),
			dividerCount: 0,
		}
	})
	const combined = mapped.reduce((accumulator, cur) => {
		const date = cur.date
		const found = accumulator.find(elem => elem.date === date)
		if (found) {
			found[newKey] += cur[newKey]
			found.dividerCount++
		} else {
			accumulator.push(cur)
		}
		return accumulator
	}, [])
	return combined.map(val => {
		if (val[newKey]) {
			val[newKey] = formatDecimals(val[newKey] / val.dividerCount / 60)
		}

		return val
	})
}
export const formatGoogleAnalyticsDataForSessionDuration = (
	data,
	valueToCalculate,
	newKey,
) => {
	if (!newKey) {
		newKey = valueToCalculate
	}
	const mapped = data.map(e => {
		return {
			date: moment(e.date).format('DD.MM'),
			[newKey]: Number(e[valueToCalculate]),
			dividerCount: Number(e.users),
			dividerValue: Number(e[valueToCalculate]) * Number(e.users),
		}
	})
	const combined = mapped.reduce((accumulator, cur) => {
		const date = cur.date
		const found = accumulator.find(elem => elem.date === date)
		if (found) {
			found.dividerCount += cur.dividerCount
			found.dividerValue += cur.dividerValue
		} else {
			accumulator.push(cur)
		}
		return accumulator
	}, [])
	return combined.map(val => {
		if (val[newKey]) {
			val[newKey] = formatDecimals(val.dividerValue / val.dividerCount / 60)
		}

		return val
	})
}

export const calculateGrowth = (originalValue, comparisonValue) => {
	originalValue = Number(originalValue)
	comparisonValue = Number(comparisonValue)

	const difference = originalValue - comparisonValue
	if (isNaN(difference) || difference === 0) {
		return 0
	}
	const formattedValue = formatDecimals((difference / comparisonValue) * 100)
	if (isNaN(difference) || difference === 0 || !isFinite(formattedValue)) {
		return 0
	}

	return formattedValue
}
export const calculateDifference = (originalValue, comparisonValue) => {
	originalValue = Number(originalValue)
	comparisonValue = Number(comparisonValue)

	const difference = originalValue - comparisonValue
	if (isNaN(difference)) {
		return 0
	}

	return difference
}

export const calculateByValues = (
	currentMonthData,
	lastMonthData,
	valueToCalculate,
	operator = '+',
	shouldCalculateDifference = false,
) => {
	const combinedCurrentMonthData = currentMonthData.reduce(
		(accumulator, cur) => {
			return (accumulator += Number(cur[valueToCalculate]))
		},
		0,
	)
	const combinedlastMonthData = lastMonthData.reduce((accumulator, cur) => {
		return (accumulator += Number(cur[valueToCalculate]))
	}, 0)

	if (shouldCalculateDifference) {
		return calculateGrowth(combinedCurrentMonthData, combinedlastMonthData)
	}

	switch (operator) {
		case '+':
			return combinedCurrentMonthData + combinedlastMonthData
		case '*':
			return combinedCurrentMonthData * combinedlastMonthData
		case '/':
			return combinedCurrentMonthData / combinedlastMonthData
		case '-':
			return combinedCurrentMonthData - combinedlastMonthData
		default:
			return 0
	}
}

export const formatGoogleAnalyticsGoalConversionRateAll = data =>
	data.map(e => {
		return {
			date: moment(e.date).format('DD.MM'),
			conversionRate: Number(e.goalConversionRateAll),
		}
	})
export const formatGoogleAnalyticsPageViews = data =>
	data.map(e => {
		return {
			device: e.deviceCategory,
			views: Number(e.pageviews),
		}
	})
export const formatGoogleAnalyticsDeviceUsers = data =>
	data
		.map(e => {
			return {
				device: e.deviceCategory,
				users: Number(e.users),
			}
		})
		.reduce((acc, curr) => {
			const foundIndex = acc.findIndex(obj => obj.device === curr.device)
			if (foundIndex !== -1) {
				acc[foundIndex].users += curr.users
			} else {
				acc.push(curr)
			}
			return acc
		}, [])
const _formatFacebookData = (data, getAll, period) => {
	if (getAll) {
		return data.reduce((prev, item) => {
			return { ...prev, [item.end_time]: item.value }
		}, {})
	}
	if (period !== '2weeks') {
		const formattedData = data.map(item => {
			return {
				date: moment(item.end_time).format('DD.MM'),
				value: item.value,
			}
		})
		return formattedData
	} else {
		const dataLength = data.length
		if (dataLength < week * 3 - 1) {
			return {}
		}
		const thisWeekEl = data[dataLength - 1]
		const lastWeekEl = data[dataLength - 1 - week]
		const last2WeekEl = data[dataLength - 1 - week * 2]
		const last3WeekEl = data[dataLength - 1 - week * 3]
		return {
			[moment(thisWeekEl.end_time).format('DD.MM')]:
				thisWeekEl.value + lastWeekEl.value,
			[moment(last2WeekEl.end_time).format('DD.MM')]:
				last2WeekEl.value + last3WeekEl.value,
		}
	}
}
const _formatFacebookDataReactions = (data, type, period) => {
	const typeData = arr => {
		if (type) {
			return { [type]: arr.reduce((prev, curr) => prev + curr[type] || 0, 0) }
		} else {
			return {
				reactions: arr.reduce((prev, curr) => {
					const sumReactions = Object.values(curr).reduce(
						(prevReactions, currReactions) => prevReactions + currReactions,
						0,
					)
					return prev + sumReactions
				}, 0),
			}
		}
	}
	if (period !== '2weeks') {
		const firstEl = data[0]
		const lastEl = data[data.length - 1]
		return [
			{
				...typeData([firstEl.value]),
				date: moment(firstEl.end_time).format('DD.MM'),
			},
			{
				...typeData([lastEl.value]),
				date: moment(lastEl.end_time).format('DD.MM'),
			},
		]
	} else {
		const dataLength = data.length
		if (dataLength < week * 3 - 1) {
			return []
		}
		const thisWeekEl = data[dataLength - 1]
		const lastWeekEl = data[dataLength - 1 - week]
		const last2WeekEl = data[dataLength - 1 - week * 2]
		const last3WeekEl = data[dataLength - 1 - week * 3]

		return [
			{
				...typeData([thisWeekEl.value, lastWeekEl.value]),
				date: moment(thisWeekEl.end_time).format('DD.MM'),
			},
			{
				...typeData([last2WeekEl.value, last3WeekEl.value]),
				date: moment(last2WeekEl.end_time).format('DD.MM'),
			},
		]
	}
}
export const formatFacebookPageData = (data, period) =>
	_formatFacebookData(data, false, period)

export const formatFacebookPageDataAll = (data, period) =>
	_formatFacebookData(data, true, period)

export const formatFacebookPageReactions = (data, type, period) =>
	_formatFacebookDataReactions(data, type, period)

export const formatGoogleSearchDataDate = data =>
	data.map(e => {
		return { ...e, date: moment(e.date).format('DD.MM') }
	})

export const formatGoogleSearchDataTable = data =>
	data.map(({ page, ...rest }) => {
		return { page, ...rest }
	})

export const formatFacebookTable = (data, period) => {
	const fbData = _formatFacebookData(data, true, period)
	return Object.keys(fbData).reduce((dateData, currDate) => {
		const currDateData = fbData[currDate]
		const orderedCurrDateData = Object.keys(currDateData)
			.sort()
			.reduce((dayData, key) => {
				return { ...dayData, [key]: currDateData[key] }
			}, {})
		return [{ date: currDate, ...orderedCurrDateData }, ...dateData]
	}, [])
}

export const formatFacebookAgeGender = (data, period) => {
	const _data = _formatFacebookData(data, true, period)
	const _dataValues = Object.values(_data)
	const _sumData = _dataValues.reduce((prev, dayData) => {
		const dayDataKeys = Object.keys(dayData)
		dayDataKeys.forEach(category => {
			if (prev[category]) {
				prev[category] = prev[category] + dayData[category]
			} else {
				prev[category] = dayData[category]
			}
		})
		return prev
	}, {})
	const _sumDataKeys = Object.keys(_sumData)
	const _avgGroupData = _sumDataKeys.reduce((prev, category) => {
		const [gender, ageGroup] = category.split('.')
		const avgValue = formatDecimals(_sumData[category] / _dataValues.length)
		if (prev[ageGroup]) {
			prev[ageGroup][gender] = avgValue
		} else {
			prev[ageGroup] = { [gender]: avgValue }
		}
		return prev
	}, {})
	const formattedData = Object.keys(_avgGroupData)
		.sort()
		.reduce((prev, group) => {
			return [...prev, { name: group, ..._avgGroupData[group] }]
		}, [])
	return formattedData
}

export const formatFacebookOnlineTime = (data, period) => {
	const _data = _formatFacebookData(data, true, period)
	const _dataKeys = Object.keys(_data)
	const _sumDay = moment.weekdays().reduce((prev, day) => {
		return { ...prev, [day]: null }
	}, {})

	const _sumData = _dataKeys.reduce((prev, day) => {
		const dayData = _data[day]
		// Get data for online days
		const dayOfWeek = moment(day).format('dddd')
		const sumOfDay = Object.values(dayData).reduce(
			(dayValues, value) => dayValues + value,
			0,
		)
		_sumDay[dayOfWeek] = _sumDay[dayOfWeek] || 0 + sumOfDay
		// Get data for online time
		const dayKeys = Object.keys(dayData)
		if (dayKeys.length === 0) {
			return prev
		}
		dayKeys.forEach(hour => {
			prev[hour] = (prev[hour] || 0) + dayData[hour]
		})
		return prev
	}, {})

	const formattedData = {
		byTime: Object.keys(_sumData)
			.reduce((prev, key) => {
				// This hour value and check here exist because the Facebook return data in PDT/PST timezone and we have to convert it.
				const hourValue = Number(key) + 10
				const hour = hourValue > 23 ? hourValue - 24 : hourValue
				return [
					...prev,
					{
						name: hour,
						value: formatDecimals(_sumData[key] / _dataKeys.length),
					},
				]
			}, [])
			.sort((a, b) => a.name - b.name),
		byDay: Object.keys(_sumDay).reduce((prev, key) => {
			return [
				...prev,
				{ name: key, value: formatDecimals(_sumDay[key] / _dataKeys.length) },
			]
		}, []),
	}
	return formattedData
}

export const fromMicrons = (number, precision = 2) => {
	if (typeof parseFloat(number) !== 'number') {
		return number
	}

	return (parseFloat(number) / 1000000).toFixed(precision)
}
export const toMicrons = (number, precision = 2) => {
	if (typeof parseFloat(number) !== 'number') {
		return number
	}

	return (parseFloat(number) * 1000000).toFixed(precision)
}

export const formatGoogleAdsChartData = data => {
	const mapped = data.map(item => {
		let date = null
		let segmentWeek = null
		if (item.segments && item.segments.date) {
			date = item.segments.date
		} else if (item.segments && item.segments.week) {
			segmentWeek = item.segments.week
		}
		return {
			...item.metrics,
			date,
			week: segmentWeek,
			cost: fromMicrons(item.metrics.cost_micros || 0),
			averageCost: fromMicrons(item.metrics.average_cost || 0),
		}
	})
	return mapped
		.reduce((grouped, curr) => {
			if (curr.date) {
				const foundDate = grouped.findIndex(item => item.date === curr.date)
				if (foundDate !== -1) {
					const groupedItem = grouped[foundDate]
					groupedItem.cost =
						parseFloat(groupedItem.cost) + parseFloat(curr.cost)
					groupedItem.clicks =
						parseInt(groupedItem.clicks) + parseInt(curr.clicks)
					groupedItem.averageCost =
						parseFloat(groupedItem.averageCost) + parseFloat(curr.averageCost)
					groupedItem.impressions =
						parseInt(groupedItem.impressions) + parseInt(curr.impressions)
				} else {
					grouped.push(curr)
				}
			} else {
				grouped.push(curr)
			}
			return grouped
		}, [])
		.sort((a, b) => moment(a.date).unix() - moment(b.date).unix())
}
export const formatMicrons = adsData => {
	const data = Object.assign({}, adsData)
	if (data.cost_micros) {
		data.cost = data.cost_micros / 10000000
		// data.cost_micros = data.cost_micros / 10000000
		data.average_cost = data.average_cost / 1000000
		data.cost_per_conversion = data.cost_per_conversion / 1000000
		delete data.cost_micros
		return data
	}
	return data
}

const fieldShorthand = {
	conversions_from_interactions_rate: 'conv_rate',
	interaction_rate: 'CTR',
}

const formattedMetrics = item => {
	const itemKeys = Object.keys(item)
	return itemKeys.reduce(
		(prev, key) =>
			fieldShorthand[key]
				? { ...prev, [fieldShorthand[key]]: item[key] }
				: { ...prev, [key]: item[key] },
		{},
	)
}

export const formatgoogleAdsAdGroupTable = data =>
	data
		.map(item => {
			return {
				description: item.ad_group_ad.ad,
				ad_group: item.ad_group.name,
				...formattedMetrics(formatMicrons(item.metrics)),
			}
		})
		.sort((a, b) => (b.description ? 1 : -1))

export const formatgoogleAdsCampaignTable = data =>
	data.map(item => {
		return {
			campaign_name: item.campaign.name,
			...formattedMetrics(formatMicrons(item.metrics)),
		}
	})

export const formatStripePaymentPlans = allPlans => {
	const plans = allPlans.filter(p => {
		return p.active === true
	})

	const transformedPlans = {
		Monthly: [],
		Yearly: [],
	}

	plans.forEach(plan => {
		plan.prices.forEach(price => {
			if (price.active) {
				if (price.recurring.interval === 'year') {
					transformedPlans.Yearly.push({
						productId: plan.id,
						description: plan.name,
						price: price.unit_amount / 100,
						priceDescription: price.recurring.interval,
						subscriptionFeatures: plan.description,
						price_id: price.id,
					})
				}
				if (price.recurring.interval === 'month') {
					transformedPlans.Monthly.push({
						productId: plan.id,
						description: plan.name,
						price: price.unit_amount / 100,
						priceDescription: price.recurring.interval,
						subscriptionFeatures: plan.description,
						price_id: price.id,
					})
				}
			}
		})
	})
	return transformedPlans
}

/**
 *
 * @param {Moment} date
 * @param {boolean} onlyMonth
 *
 * Takes a moment object and returns month and year as locale string
 * With only month option it will return only month as string
 *
 */
export const localeStringFromDate = (
	date,
	onlyMonth = false,
	language = 'en',
) => {
	if (!date || !moment().isValid(date)) {
		return
	} else {
		const newDate = new Date(date)
		if (onlyMonth) {
			return newDate.toLocaleString(language, { month: 'long' })
		}
		return newDate.toLocaleString(language, { month: 'long' })
	}
}

/**
 *
 * @param {String | int} month is value between 0 - 11
 *
 * 0 returns 'Tammikuussa'
 */
export const toLocalMonthString = month => {
	month = parseInt(month)
	switch (month) {
		case 0:
			return 'Tammikuussa'
		case 1:
			return 'Helmikuussa'
		case 2:
			return 'Maaliskuussa'
		case 3:
			return 'Huhtikuussa'
		case 4:
			return 'Toukokuussa'
		case 5:
			return 'Kesäkuussa'
		case 6:
			return 'Heinäkuussa'
		case 7:
			return 'Elokuussa'
		case 8:
			return 'Syyskuussa'
		case 9:
			return 'Lokakuussa'
		case 10:
			return 'Marraskuussa'
		case 11:
			return 'Joulukuussa'
		default:
			return 'none'
	}
}
export const transformMetaData = metadata => {
	if (typeof metadata !== 'string') {
		return metadata
	}
	switch (metadata.toLowerCase()) {
		case 'metadatadescriptionkeywordcount':
			return i18n.t(`tasks.metadata.metadatadescriptionkeywordcount`)
		case 'headingkeywordcount':
			return i18n.t(`tasks.metadata.headingkeywordcount`)
		case 'metadescriptionlength':
			return i18n.t(`tasks.metadata.metadescriptionlength`)
		case 'headingcount':
			return i18n.t(`tasks.metadata.headingcount`)
		case 'imagealtcount':
			return i18n.t(`tasks.metadata.imagealtcount`)
		case 'imagetitlecount':
			return i18n.t(`tasks.metadata.imagetitlecount`)
		case 'titlekeywordcount':
			return i18n.t(`tasks.metadata.titlekeywordcount`)
		case 'titlelength':
			return i18n.t(`tasks.metadata.titlelength`)
		case 'totalkeywordcount':
			return i18n.t(`tasks.metadata.totalkeywordcount`)
		case 'h2keyword':
			return i18n.t(`tasks.metadata.h2keyword`)
		case 'h1keyword':
			return i18n.t(`tasks.metadata.h1keyword`)
		case 'totalwordcount':
			return i18n.t(`tasks.metadata.totalwordcount`)
		case 'wordscount':
			return i18n.t(`tasks.metadata.wordscount`)
		case 'keyword':
			return i18n.t(`tasks.metadata.keyword`)
		case 'adsgroupname':
			return i18n.t(`tasks.metadata.adsgroupname`)
		case 'averagecpc':
			return i18n.t(`tasks.metadata.averagecpc`)
		case 'clicks':
			return i18n.t(`tasks.metadata.clicks`)
		case 'averagecost':
			return i18n.t(`tasks.metadata.averagecost`)
		case 'qualityscore':
			return i18n.t(`tasks.metadata.qualityscore`)
		case 'aimcpc':
			return i18n.t(`tasks.metadata.aimcpc`)
		case 'costs':
			return i18n.t(`tasks.metadata.costs`)
		case 'aimqualityscore':
			return i18n.t(`tasks.metadata.aimqualityscore`)
		case 'keywordmatchtype':
			return i18n.t(`tasks.metadata.keywordmatchtype`)
		default:
			return metadata
	}
}

/**
 *
 * @param {Array} arr Array of objects with value that needs to summed up
 * @param {string} value What property should be used for summing
 * @returns Total sum of all selected values in the array
 */

export const getTotalSum = (arr, value) => {
	let total = 0
	arr.forEach(day => {
		if (!isNaN(day[value])) {
			total += Number(day[value])
		}
	})
	return total
}

export const getValue = description => description.replace(/[^0-9-]/g, '')

export const extractDeviceType = text => {
	if (!text) {
		return ''
	}
	switch (text) {
		case text.includes('mobile'):
			return 'mobiilissa'
		case text.includes('tablet'):
			return 'tabletilla'
		case text.includes('desktop'):
			return 'pöytäkoneella'
		case text.includes('laptop'):
			return 'kannettavalla'
		default:
			return ''
	}
}

/**
 * Types for the suggestions
 */

export const SuggestionType = {
	seo: 'seo',
	ads: 'ads',
	cro: 'cro',
	some: 'some',
}
/**
 * Types for the descriptions
 *
 * Shitty way to do this, but let's improve this in the future
 */

export const DescriptionType = {
	mainLandingPage: 'This is the primary keyword',
	totalWords: 'Total words in the landing page',
	totalWordsNewLandingPage: 'Total words in new landing page',
	totalKeywordRepetition:
		'Total keyword repetition in whole page should be increased by',
	keywordRepetitionInHeading:
		'Total keyword repetition in headings should be increased by',
	keywordImageTitles: 'Should repeat the keyword in image titles',
	keywordImageAttributeTexts:
		'Should repeat the keyword in image attribute texts',
	totalMetaTitleLength:
		'Total amount of characters in title should be increased by',
	totalMetaDescriptionLength:
		'Total amount of characters in description should be increased by',
	titleLength: 'Title should should have',
	abTesting: 'We recommend to make A/B testing for landing page',
	improveAdsConversionRate: 'Aiming to improve e-commerce conversion rate',
	metaTitle: 'The keyword should be added to the meta description.',
	titleTag: 'The keyword should be added to the site title tag.',
	lessInternalLinks: 'There are less than average internal links on this site',
	keywordFilename: 'The keyword should be mentioned in the image file names.',
	imageAltTag: 'Total keyword repetition in image attribute texts',
	imageKeywordText: 'The keyword should be added to the image',
	keywordMetaDescription: 'Meta description should should have',
	increaseKeywordsInHeadings:
		'Increase the amount of keywords in the headings and subheadings',
}

/**
 * Sub types of the suggestions
 */

export const SuggestionSubType = {
	seoLandingPage: 'seo_landing_page',
	seoKeywordOptimization: 'seo_keyword_optimization',
	adsQualityScore: 'ads_quality_score',
	adsNegativeKeyword: 'ads_negative_keyword',
	adsInaccurateKeyword: 'ads_inaccurate_keyword',
	adsKeywordBid: 'ads_keyword_bid',
	negativeAdGroupSpending: 'negative_ad_group_spending',
	negativeAdGroupSpendingHourly: 'negative_ad_group_spending_hourly',
	croHeatmap: 'cro_heatmap',
	croABTesting: 'cro_ab_testing',
	croCombinationDeviceSource: 'cro_combination_device_source',
	someReduceFrequency: 'some_reduce_frequency',
	someCheckAdRelevance: 'some_check_ad_relevance',
}

/**
 *
 * @param {Object} conversionData Object containing conversion property
 * @param {Object} conversionRateData Object containing conversion rate property
 * @returns String for conversions
 */

export const conversionValueStringFromData = (
	conversionRateData,
	conversionThisMonth,
) => {
	const differencePercent = 100 - conversionRateData.rate
	if (conversionRateData.conversion > 0) {
		return {
			amountOfConversions: formatAmountsIntl(conversionThisMonth.conversion),
			differenceAmount: `+${formatAmountsIntl(conversionRateData.conversion)}`,
			percent: `+${formatAmountsIntl(differencePercent)}%`,
			barPercent:
				differencePercent && differencePercent > 100 ? 100 : differencePercent,
		}
	} else if (conversionRateData.conversion === 0) {
		return {
			amountOfConversions: `${formatAmountsIntl(
				conversionThisMonth.conversion,
			)}`,
			differenceAmount: '0',
			percent: '0%',
			barPercent: 0,
		}
	} else {
		return {
			amountOfConversions: `${formatAmountsIntl(
				conversionThisMonth.conversion,
			)}`,
			differenceAmount: `${formatAmountsIntl(conversionRateData.conversion)}`,
			percent: `-${formatAmountsIntl(differencePercent)}%`,
			barPercent: -differencePercent,
		}
	}
}

export const conversionStringFromData = (
	conversionRateData,
	conversionData,
) => {
	if (conversionRateData.conversion > 0) {
		return `${i18n.t('report.basic analysis.conversions increased', {
			amountOfConversions: conversionData.conversion,
			increaseAmount: Number(conversionRateData.rate).toFixed(1),
		})}`
	} else if (conversionRateData.conversion === 0) {
		return `${i18n.t('report.basic analysis.conversions stayed the same', {
			amountOfConversions: conversionData.conversion,
		})}`
	} else {
		return `${i18n.t('report.basic analysis.conversions decreased', {
			amountOfConversions: conversionData.conversion,
			decreaseAmount: Number(conversionRateData.rate).toFixed(1),
		})}`
	}
}

/**
 *
 * @param {Object} suggestionData Object of suggestion data
 * @param {integer} i index for div key
 * @returns div for the report with suggestion data on it
 */

export const suggestionStringBuilder = (suggestionData, i) => {
	if (
		!suggestionData ||
		suggestionData.potentialValue === null ||
		suggestionData.potentialValue <= 0
	) {
		return ''
	} else if (
		suggestionData &&
		!Array.isArray(suggestionData.description) &&
		!suggestionData.description.length
	) {
		return ''
	}
	return (
		<div className='report__page-body-done-actions' key={i}>
			<p>{``}</p>
			<br />
			<p>{i18n.t('report.why it was done')}</p>
			<p>{suggestionData.description}</p>
			<p>
				{i18n.t('report.potential value for change')}:{' '}
				{suggestionData.potentialValue} EUR
			</p>
		</div>
	)
}

export const constructSuggestionString = suggestionDescription => {
	switch (suggestionDescription) {
		case suggestionDescription.includes('Total words in new landing page is'):
			return ''

		default:
			break
	}
}

/**
 *
 * @param {Array} data Google search data
 * @param {number} take How many top row want to get, default is 20
 * @returns Array of sorted data to add to the table element
 */

export const formatGoogleSearchDataTableForReport = (
	currentMonthData,
	lastMonthData,
	take = 20,
) => {
	const mappedCurrentMonth = currentMonthData.map(
		({ page, clicks, impressions, position, ...rest }) => {
			return {
				page,
				clicks,
				impressions,
				position,
				...rest,
			}
		},
	)
	const mappedLastMonthData = lastMonthData.map(
		({ page, clicks, impressions, position, ...rest }) => {
			return {
				page,
				clicks,
				impressions,
				position,
				...rest,
			}
		},
	)

	mappedCurrentMonth.map(item => {
		mappedLastMonthData.map(lastMonthItem => {
			if (lastMonthItem.page === item.page) {
				item['clicks change'] = calculateDifference(
					item.clicks,
					lastMonthItem.clicks,
				)
				item['impressions change'] = calculateDifference(
					item.impressions,
					lastMonthItem.impressions,
				)
				item['ctr change'] = `${calculateGrowth(item.ctr, lastMonthItem.ctr)}%`
			}
		})
		return item
	})
	return mappedCurrentMonth
		.sort(
			(a, b) =>
				b.impressions - a.impressions || b.clicks - a.clicks || b.ctr - a.ctr,
		)
		.slice(0, take)
}

/**
 *
 * @param {Array} data Google search data
 * @param {number} take How many top row want to get, default is 20
 * @returns Array of sorted data to add to the table element
 */

export const formatGoogleSearchTermDataTableForReport = (
	currentMonthData,
	lastMonthData,
	take = 20,
) => {
	const mappedCurrentMonth = currentMonthData.map(
		({ query, clicks, impressions, position, ...rest }) => {
			return {
				query,
				clicks,
				impressions,
				position,
				...rest,
			}
		},
	)
	if (lastMonthData && lastMonthData.length) {
		const mappedLastMonthData = lastMonthData.map(
			({ query, clicks, impressions, position, ...rest }) => {
				return {
					query,
					clicks,
					impressions,
					position,
					...rest,
				}
			},
		)
		mappedCurrentMonth.map(item => {
			mappedLastMonthData.map(lastMonthItem => {
				if (lastMonthItem.query === item.query) {
					item['clicks change'] = calculateDifference(
						item.clicks,
						lastMonthItem.clicks,
					)
					item['impressions change'] = calculateDifference(
						item.impressions,
						lastMonthItem.impressions,
					)
					item['ctr change'] = `${calculateGrowth(
						item.ctr,
						lastMonthItem.ctr,
					)}%`
				}
			})
			return item
		})
		return mappedCurrentMonth
			.sort(
				(a, b) =>
					b.impressions - a.impressions || b.clicks - a.clicks || b.ctr - a.ctr,
			)
			.slice(0, take)
	}
	return mappedCurrentMonth
		.sort(
			(a, b) =>
				b.impressions - a.impressions || b.clicks - a.clicks || b.ctr - a.ctr,
		)
		.slice(0, take)
}
/**
 *
 * @param {Array} data Google search data
 * @param {number} take How many top row want to get, default is 20
 * @returns Array of sorted data to add to the table element
 */

export const formatGoogleAnalyticsDataTableForReport = (
	currentMonthData,
	comparisonData,
	take = 20,
) => {
	const getMappedFields = ({
		source,
		users,
		sessions,
		avgSessionDuration,
		uniquePurchases,
	}) => ({
		source,
		users,
		sessions,
		avgSessionDuration: avgSessionDuration / sessions,
		conversions: uniquePurchases,
	})
	const mappedComparisonData = comparisonData.map(getMappedFields)
	const mappedCurrentMonthData = currentMonthData.map(getMappedFields)
	if (mappedComparisonData && mappedComparisonData.length) {
		mappedCurrentMonthData.map(item => {
			mappedComparisonData.map(lastMonthItem => {
				if (lastMonthItem.source === item.source) {
					item['sessions change'] = `${calculateGrowth(
						item.sessions,
						lastMonthItem.sessions,
					)}%`
					item['users change'] = `${calculateGrowth(
						item.users,
						lastMonthItem.users,
					)}%`
					item['avgSessionDuration change'] = `${calculateGrowth(
						item.avgSessionDuration,
						lastMonthItem.avgSessionDuration,
					)}%`
					item['conversions change'] = `${calculateGrowth(
						item.conversions,
						lastMonthItem.conversions,
					)}%`
				}
			})
			return item
		})
		return mappedCurrentMonthData
			.sort(
				(a, b) =>
					b.users - a.users ||
					b.avgSessionDuration - a.avgSessionDuration ||
					b.conversions - a.conversions,
			)
			.slice(0, take)
	}
	return mappedCurrentMonthData
		.sort(
			(a, b) =>
				b.users - a.users ||
				b.avgSessionDuration - a.avgSessionDuration ||
				b.conversions - a.conversions,
		)
		.slice(0, take)
}

export const calculateMarketingSpending = ({
	facebookMarketingThisMonth,
	facebookMarketingLastMonth,
	googlePPCThisMonth,
	googlePPCLastMonth,
}) => {
	let totalThisMonth = 0
	if (!isNaN(facebookMarketingThisMonth) && facebookMarketingThisMonth > 0) {
		totalThisMonth += facebookMarketingThisMonth
	}
	if (!isNaN(googlePPCThisMonth) && googlePPCThisMonth > 0) {
		totalThisMonth += googlePPCThisMonth
	}
	const googlePPCPercent = (googlePPCThisMonth / totalThisMonth) * 100
	const facebookMarketingPercent =
		(facebookMarketingThisMonth / totalThisMonth) * 100
	const googleBarPercent =
		googlePPCPercent > 0 ? googlePPCPercent : -googlePPCPercent
	const facebookBarPercent =
		facebookMarketingPercent > 0
			? facebookMarketingPercent
			: -facebookMarketingPercent
	return {
		googlePPCPercent: `${Number(googlePPCPercent).toFixed(1)}%`,
		googleBarPercent,
		facebookMarketingPercent: `${Number(facebookMarketingPercent).toFixed(1)}%`,
		facebookBarPercent,
	}
}

export const extractKeywordPerformance = keywordData => {
	if (
		keywordData &&
		keywordData.keywordPerformances.length &&
		keywordData.keywordPerformances.length > 3
	) {
		return keywordData.keywordPerformances.find(
			performance => performance.source === 0,
		)
	} else {
		return { url: '', position: '' }
	}
}
export const translateDescription = description => {
	if (!description) {
		return description
	}
	if (
		description.includes(
			'Total words in the landing page should be increased by',
		)
	) {
		return description.replace(
			'Total words in the landing page should be increased by',
			i18n.t(
				'tasks.ads task descriptions.total words in the landing page should be increased by',
			),
		)
	} else if (description.indexOf(DescriptionType.mainLandingPage) !== -1) {
		return `${i18n.t('report.description data.primary keyword')}`
	} else if (description.indexOf(DescriptionType.lessInternalLinks) !== -1) {
		return `${i18n.t('report.description data.lessInternalLinks')}`
	} else if (
		description.indexOf(DescriptionType.totalKeywordRepetition) !== -1
	) {
		return `${i18n.t(
			'report.description data.increasing keyword density',
		)} ${getValue(description)} ${i18n.t('report.description data.words')}`
	} else if (
		description.includes(
			'Increase the amount of keywords in the headings and subheadings of the page by',
		)
	) {
		return description
			.replace(
				'Increase the amount of keywords in the headings and subheadings of the page by',
				i18n.t(
					'tasks.ads task descriptions.increase the amount of keywords in the headings and subheadings of the page by',
				),
			)
			.replace('characters', i18n.t('common.characters'))
	} else if (description.includes('Title should should have')) {
		return description
			.replace(
				'Title should should have',
				i18n.t('tasks.ads task descriptions.title should should have'),
			)
			.replace('characters', i18n.t('common.characters'))
	} else if (
		description.includes(
			'Total keyword repetition in image titles should be increased by',
		)
	) {
		return description.replace(
			'Total keyword repetition in image titles should be increased by',
			i18n.t(
				'tasks.ads task descriptions.total keyword repetition in image titles should be increased by',
			),
		)
	} else if (description.includes('Total keyword repetition in headings is')) {
		return description.replace(
			'Total keyword repetition in headings is',
			i18n.t(
				'tasks.ads task descriptions.total keyword repetition in headings is',
			),
		)
	} else if (
		description.includes(
			'Total keyword repetition in image attribute texts should be increased by',
		)
	) {
		return description.replace(
			'Total keyword repetition in image attribute texts should be increased by',
			i18n.t(
				'tasks.ads task descriptions.total keyword repetition in image attribute texts should be increased by',
			),
		)
	} else if (
		description.includes(
			'Total amount of characters in description should be increased by',
		)
	) {
		return description.replace(
			'Total amount of characters in description should be increased by',
			i18n.t(
				'tasks.ads task descriptions.total amount of characters in description should be increased by',
			),
		)
	} else if (description.includes('Total words in new landing page is')) {
		return description.replace(
			'Total words in new landing page is',
			i18n.t('tasks.ads task descriptions.total words in new landing page is'),
		)
	} else if (description.includes('Total keyword repetition in titles is ')) {
		return description.replace(
			'Total keyword repetition in titles is ',
			i18n.t(
				'tasks.ads task descriptions.total keyword repetition in titles is ',
			),
		)
	} else if (
		description.includes('Total keyword repetition in descriptions is')
	) {
		return description.replace(
			'Total keyword repetition in descriptions is',
			i18n.t(
				'tasks.ads task descriptions.total keyword repetition in descriptions is',
			),
		)
	} else if (
		description.includes(
			'Total amount of characters in title should be increased by',
		)
	) {
		return description.replace(
			'Total amount of characters in title should be increased by',
			i18n.t(
				'tasks.ads task descriptions.total amount of characters in title should be increased by',
			),
		)
	} else if (
		description.includes(
			'The keyword should be added to the image ALT (alternative) text',
		)
	) {
		return description.replace(
			'The keyword should be added to the image ALT (alternative) text',
			i18n.t(
				'tasks.ads task descriptions.the keyword should be added to the image alternative text',
			),
		)
	} else if (
		description.includes('Total keyword repetition in whole page is')
	) {
		return description.replace(
			'Total keyword repetition in whole page is',
			i18n.t(
				'tasks.ads task descriptions.total keyword repetition in whole page is',
			),
		)
	} else if (
		description.includes('The keyword should be added to the site title tag')
	) {
		return description.replace(
			'The keyword should be added to the site title tag',
			i18n.t(
				'tasks.ads task descriptions.the keyword should be added to the site title tag',
			),
		)
	} else if (
		description.includes('The keyword should be added to the meta description')
	) {
		return description.replace(
			'The keyword should be added to the meta description',
			i18n.t(
				'tasks.ads task descriptions.the keyword should be added to the meta description',
			),
		)
	} else if (description.includes('Should repeat the keyword in headings')) {
		return description.replace(
			'Should repeat the keyword in headings',
			i18n.t(
				'tasks.ads task descriptions.should repeat the keyword in headings',
			),
		)
	} else if (
		description.includes(
			'The keyword should be mentioned in the image file names',
		)
	) {
		return description.replace(
			'The keyword should be mentioned in the image file names',
			i18n.t(
				'tasks.ads task descriptions.the keyword should be mentioned in the image file names',
			),
		)
	} else if (
		description.includes('Total keyword repetition in image attribute texts is')
	) {
		return description.replace(
			'Total keyword repetition in image attribute texts is',
			i18n.t(
				'tasks.ads task descriptions.total keyword repetition in image attribute texts is',
			),
		)
	} else if (
		description.includes('Meta description should should have') &&
		description.includes('characters')
	) {
		return description
			.replace(
				'Meta description should should have',
				i18n.t(
					'tasks.ads task descriptions.meta description should should have',
				),
			)
			.replace('characters', i18n.t('common.characters'))
	} else if (
		description.includes('Title should have') &&
		description.includes('characters')
	) {
		return description
			.replace(
				'Title should should have',
				i18n.t('tasks.ads task descriptions.title should should have'),
			)
			.replace('characters', i18n.t('common.characters'))
	} else if (
		description.includes(
			'Total keyword repetition in titles should be increased by',
		)
	) {
		return description.replace(
			'Total keyword repetition in titles should be increased by',
			i18n.t(
				'tasks.ads task descriptions.total keyword repetition in titles should be increased by',
			),
		)
	} else if (
		description.includes(
			'Total keyword repetition in descriptions should be increased by',
		)
	) {
		return description.replace(
			'Total keyword repetition in descriptions should be increased by',
			i18n.t(
				'tasks.ads task descriptions.total keyword repetition in descriptions should be increased by',
			),
		)
	} else {
		return description
	}
}

export const translateTitle = description => {
	if (description) {
		if (description.includes('Improve quality score for keyword')) {
			return description.replace(
				'Improve quality score for keyword',
				i18n.t('tasks.task titles.improve quality score for keyword'),
			)
		} else if (
			description.includes(
				'We recommend building more accurate search terms and own ad groups for keyword',
			)
		) {
			return description.replace(
				'We recommend building more accurate search terms and own ad groups for keyword',
				i18n.t(
					'tasks.task titles.we recommend building more accurate search terms and own ad groups for keyword',
				),
			)
		} else if (
			description.includes('has been click but not produced conversions')
		) {
			return description.replace(
				'has been click but not produced conversions',
				i18n.t('tasks.task titles.has been click but not produced conversions'),
			)
		} else if (description.includes('Create a new landing page for keyword')) {
			return description.replace(
				'Create a new landing page for keyword',
				i18n.t('tasks.task titles.create a new landing page for keyword'),
			)
		} else if (
			description.includes(
				'is less effective than the average keyword of this ADS account',
			)
		) {
			return description.replace(
				'is less effective than the average keyword of this ADS account',
				i18n.t(
					'tasks.task titles.is less effective than the average keyword of this ADS account',
				),
			)
		} else if (description.includes('Improve performance for keyword')) {
			return description
				.replace(
					'Improve performance for keyword',
					i18n.t('tasks.task titles.improve performance for keyword'),
				)
				.replace(
					'for landing page',
					i18n.t('tasks.task titles.for landing page'),
				)
		} else if (
			description.includes('Decrease bids or add to negatives keyword list')
		) {
			return description.replace(
				'Decrease bids or add to negatives keyword list',
				i18n.t(
					'tasks.task titles.decrease bids or add to negatives keyword list',
				),
			)
		} else if (
			description.includes('Site') &&
			description.includes('is underperforming')
		) {
			return description
				.replace('Site', i18n.t('tasks.task titles.site'))
				.replace(
					'is underperforming',
					i18n.t('tasks.task titles.is underperforming'),
				)
		} else if (description.includes('We recommend to check landing page')) {
			return description
				.replace(
					'We recommend to check landing page',
					i18n.t('tasks.task titles.we recommend to check landing page'),
				)
				.replace('for device', i18n.t('tasks.task titles.for device'))
				.replace('by source', i18n.t('tasks.task titles.by source'))
		} else {
			return description
		}
	} else {
		return description
	}
}

/**
 *
 * @param {string} descriptionData One of the values values in suggestion.description
 * @returns The translated string
 * Takes a string as an input and returns a translated version of it
 **/
export const extractDescriptionData = descriptionData => {
	if (!descriptionData) {
		return ''
	} else if (descriptionData.indexOf(DescriptionType.totalWords) !== -1) {
		return `${i18n.t(
			'report.description data.increasing text lenght',
		)} ${getValue(descriptionData)} ${i18n.t('report.description data.words')}`
	} else if (
		descriptionData.indexOf(DescriptionType.totalKeywordRepetition) !== -1
	) {
		return `${i18n.t(
			'report.description data.increasing keyword density',
		)} ${getValue(descriptionData)} ${i18n.t('report.description data.words')}`
	} else if (descriptionData.indexOf(DescriptionType.mainLandingPage) !== -1) {
		return `${i18n.t('report.description data.primary keyword')}`
	} else if (
		descriptionData.indexOf(DescriptionType.totalMetaDescriptionLength) !== -1
	) {
		return `${i18n.t(
			'report.description data.optimizing meta description',
		)} ${getValue(descriptionData)} ${i18n.t('report.description data.words')}`
	} else if (
		descriptionData.indexOf(DescriptionType.totalMetaTitleLength) !== -1
	) {
		return `${i18n.t(
			'report.description data.optimizing meta title',
		)} ${getValue(descriptionData)} ${i18n.t('report.description data.words')}`
	} else if (
		descriptionData.indexOf(DescriptionType.keywordRepetitionInHeading) !== -1
	) {
		return `${i18n.t(
			'report.description data.adding keyword to titles',
		)}${getValue(descriptionData)} ${i18n.t('report.description data.words')}`
	} else if (descriptionData.indexOf(DescriptionType.titleLength) !== -1) {
		return `${i18n.t('report.description data.increasing headline length')}`
	} else if (
		descriptionData.indexOf(DescriptionType.keywordImageTitles) !== -1
	) {
		return `${i18n.t(
			'report.description data.increasing keywords to image headlines',
		)}`
	} else if (
		descriptionData.indexOf(DescriptionType.keywordImageAttributeTexts) !== -1
	) {
		return `${i18n.t(
			'report.description data.increasing keywords to image descriptions',
		)}`
	} else if (descriptionData.indexOf(DescriptionType.imageKeywordText) !== -1) {
		return ``
	} else if (descriptionData.indexOf(DescriptionType.keywordFilename) !== -1) {
		return `${i18n.t('report.description data.image filename')}`
	} else if (descriptionData.indexOf(DescriptionType.imageAltTag) !== -1) {
		return `${i18n.t('report.description data.image alt tag')}`
	} else if (
		descriptionData.indexOf(DescriptionType.keywordMetaDescription) !== -1
	) {
		return `${i18n.t('report.description data.meta description')}`
	} else if (
		descriptionData.indexOf(DescriptionType.lessInternalLinks) !== -1
	) {
		return `${i18n.t('report.description data.lessInternalLinks')}`
	} else if (descriptionData.indexOf(DescriptionType.titleTag) !== -1) {
		return `${i18n.t('report.description data.keyword to page title')}`
	} else if (descriptionData.indexOf(DescriptionType.metaTitle) !== -1) {
		return `${i18n.t('report.description data.meta title')}`
	} else if (
		descriptionData.indexOf(DescriptionType.increaseKeywordsInHeadings) !== -1
	) {
		return `${i18n.t('report.description data.keyword to title')}`
	} else {
		return ''
	}
}
export const extractCreateLandingPageDescriptionData = descriptionData => {
	if (!descriptionData) {
		return ''
	} else if (
		descriptionData.indexOf(DescriptionType.totalWordsNewLandingPage) !== -1
	) {
		return `${i18n.t(
			'report.description data.word length should be about',
		)} ${getValue(descriptionData)} ${i18n.t('report.description data.words')}`
	} else if (
		descriptionData.indexOf(DescriptionType.totalKeywordRepetition) !== -1
	) {
		return `${i18n.t(
			'report.description data.keyword density should be',
		)} ${getValue(descriptionData)}`
	} else if (
		descriptionData.indexOf(DescriptionType.totalMetaDescriptionLength) !== -1
	) {
		return `${i18n.t(
			'report.description data.adding words to metadescription',
			{ wordAmount: getValue(descriptionData) },
		)}`
	} else if (
		descriptionData.indexOf(DescriptionType.totalMetaTitleLength) !== -1
	) {
		return `${i18n.t('report.description data.adding words to metaheadline', {
			wordAmount: getValue(descriptionData),
		})}`
	} else if (
		descriptionData.indexOf(DescriptionType.keywordRepetitionInHeading) !== -1
	) {
		return `${i18n.t('report.description data.adding keywords to headlines', {
			wordAmount: getValue(descriptionData),
		})}`
	} else if (descriptionData.indexOf(DescriptionType.titleLength) !== -1) {
		return `${i18n.t('report.description data.headline lenght')} ${getValue(
			descriptionData,
		)}`
	} else if (
		descriptionData.indexOf(DescriptionType.keywordImageTitles) !== -1
	) {
		return `${i18n.t(
			'report.description data.adding keywords to image headlines',
		)}`
	} else if (
		descriptionData.indexOf(DescriptionType.keywordImageAttributeTexts) !== -1
	) {
		return `${i18n.t(
			'report.description data.adding keywords to image descriptions',
		)}`
	} else if (
		descriptionData.indexOf(DescriptionType.totalMetaTitleLength) !== -1
	) {
		return `${i18n.t(
			'report.description data.optimizing meta title',
		)} ${getValue(descriptionData)} ${i18n.t('report.description data.words')}`
	} else if (
		descriptionData.indexOf(DescriptionType.keywordRepetitionInHeading) !== -1
	) {
		return `${i18n.t(
			'report.description data.adding keyword to titles',
		)}${getValue(descriptionData)} ${i18n.t('report.description data.words')}`
	} else if (descriptionData.indexOf(DescriptionType.titleLength) !== -1) {
		return `${i18n.t('report.description data.increasing headline length')}`
	} else if (
		descriptionData.indexOf(DescriptionType.keywordImageTitles) !== -1
	) {
		return `${i18n.t(
			'report.description data.increasing keywords to image headlines',
		)}`
	} else if (
		descriptionData.indexOf(DescriptionType.keywordImageAttributeTexts) !== -1
	) {
		return `${i18n.t(
			'report.description data.increasing keywords to image descriptions',
		)}`
	} else if (descriptionData.indexOf(DescriptionType.imageKeywordText) !== -1) {
		return ``
	} else if (descriptionData.indexOf(DescriptionType.keywordFilename) !== -1) {
		return `${i18n.t('report.description data.image filename')}`
	} else if (descriptionData.indexOf(DescriptionType.imageAltTag) !== -1) {
		return `${i18n.t('report.description data.image alt tag')}`
	} else if (
		descriptionData.indexOf(DescriptionType.keywordMetaDescription) !== -1
	) {
		return `${i18n.t('report.description data.meta description')}`
	} else if (descriptionData.indexOf(DescriptionType.titleTag) !== -1) {
		return `${i18n.t('report.description data.keyword to page title')}`
	} else if (descriptionData.indexOf(DescriptionType.metaTitle) !== -1) {
		return `${i18n.t('report.description data.meta title')}`
	} else if (
		descriptionData.indexOf(DescriptionType.increaseKeywordsInHeadings) !== -1
	) {
		return `${i18n.t('report.description data.keyword to title')}`
	} else {
		return ''
	}
}

/**
 *
 * @param {Object} data Suggestion object for extracting data
 * @param {String} type One of suggestion types
 * @param {String} subtype One of suggestion sub types
 * @returns String for rendering on report
 */

export const suggestionInfoString = (
	data,
	type,
	subtype,
	conversionRate,
	avgConversionValue,
	index,
) => {
	const { potentialValue, source } = data
	switch (type) {
		case SuggestionType.seo:
			if (subtype === SuggestionSubType.seoKeywordOptimization) {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>
							{`${i18n.t('report.optimized page', {
								page: extractKeywordPerformance(source).url,
							})}`}
							'{source.keyword}'{' '}
							{data.description.reduce((acc, curr, i) => {
								const val = extractDescriptionData(curr)
								if (val) {
									acc += data.description.length - 1 === i ? val : val + ', '
									return acc
								} else {
									return acc
								}
							}, '')}{' '}
						</p>
						<br />
						<p style={{ fontWeight: 'bold' }}>
							{i18n.t('report.why it was done')}
						</p>
						<p>
							Sivun sijoitus on nyt {extractKeywordPerformance(source).position}
							, hakuja termille '{source.keyword}' on kuukausittain
							keskimääräisesti {source.keywordValue.monthlySearchVolume} ja
							korkeampi sijoitus toisi klikkauksia lisää ja sitä kautta enemmän
							myyntiä.
							{/* Sivun sijoitus on nyt {extractKeywordPerformance(source).position}
							, hakuja termille '{source.keyword}' on kuukausittain
							keskimääräisesti {source.keywordValue.monthlySearchVolume} ja
							korkeampi sijoitus toisi{' '}
							{extractKeywordPerformance(source).clicks + 2} klikkausta lisää
							kuukaudessa. Konversioasteen ollessa {conversionRate}%, tämä
							tarkoittaa 2 kauppaa tai {formatDecimals(2 * avgConversionValue)}€
							enemmän myyntiä kuukaudessa. */}
						</p>
						<div>
							<p>
								{i18n.t('report.potential value for change')}:{' '}
								<strong> {potentialValue} </strong> EUR
							</p>
						</div>
					</div>
				)
			} else {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>
							Teimme uuden laskeutumissivun termille '{source.keyword}' tämän
							muutoksen potentiaalinen arvo on {potentialValue} € kolmelle
							kuukaudelle jaksotettuna{' '}
						</p>
					</div>
				)
			}
		case SuggestionType.ads:
			if (subtype === SuggestionSubType.adsNegativeKeyword) {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>{`Termi ${source.keyword} ei ole tuottanut konversioita yhtä tehokkaasti kuin ADS tilin mainokset keskimäärin, joten kyseisen termin budjettia muokattiin/termi poistettiin.`}</p>{' '}
					</div>
				)
			}
			if (subtype === SuggestionSubType.adsQualityScore) {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>{`Termille ${source.keyword} laatupisteet olivat ${source.adsQualityScore.qualityScore}. Teimme muutoksia joilla pyritään parantamaan laatupisteitä ja tätäkautta madaltamaan klikkaushintoja (joka nyt on ollut ${source.adsQualityScore.averageCpc}).`}</p>{' '}
					</div>
				)
			}
			if (subtype === SuggestionSubType.adsInaccurateKeyword) {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>{`Teimme tarkemman mainosyhmän termille ${source.keyword} jotta mainoksista saadaan osuvampia ja tätä kautta mainonnan tehoa parannettua`}</p>{' '}
					</div>
				)
			}
			if (subtype === SuggestionSubType.negativeAdGroupSpending) {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>
							Mainosryhmän {source.negativeAdGroupSpending.adGroup} näyttöaikoja
							optimoitiin.
						</p>
						<br />
						<p style={{ fontWeight: 'bold' }}>
							{i18n.t('report.why it was done')}
						</p>
						<p>
							Mainosryhmä mainoksien näyttöaikoja optiomoitiin, jotta saadaan
							mainoksille enemmän näyttöjä tuottavaan aikaan.
						</p>
						<div>
							<p>
								{i18n.t('report.potential value for change')}:{' '}
								<strong> {potentialValue} </strong> EUR
							</p>
						</div>
					</div>
				)
			}
			if (subtype === SuggestionSubType.negativeAdGroupSpendingHourly) {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>
							Mainosryhmän {source.negativeAdGroupSpending.adGroup} näyttöaikoja
							optimoitiin.
						</p>
						<br />
						<p style={{ fontWeight: 'bold' }}>
							{i18n.t('report.why it was done')}
						</p>
						<p>
							Mainosryhmä mainoksien näyttöaikoja optiomoitiin, jotta saadaan
							mainoksille enemmän näyttöjä tuottavaan aikaan.
						</p>
						<div>
							<p>
								{i18n.t('report.potential value for change')}:{' '}
								<strong>{potentialValue} </strong>EUR
							</p>
						</div>
					</div>
				)
			}
			return ''
		case SuggestionType.cro:
			if (subtype === SuggestionSubType.croCombinationDeviceSource) {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>
							Teimme sivulle {source.keyword} optimointia{' '}
							{extractDeviceType(
								source.croCombinationDeviceSource.deviceCategory,
							)}
							.
						</p>
						<br />
						<p style={{ fontWeight: 'bold' }}>
							{i18n.t('report.why it was done')}
						</p>
						<p>
							Sivun konversioaste joka on{' '}
							{source.croCombinationDeviceSource.calculatedConversionRate} % on
							huomattavasti pienempi{' '}
							{extractDeviceType(
								source.croCombinationDeviceSource.deviceCategory,
							)}{' '}
							kuin muissa laitteissa.
						</p>
						<div>
							<p>
								{i18n.t('report.potential value for change')}:
								<strong> {potentialValue} </strong> EUR
							</p>
						</div>
					</div>
				)
			}
			if (subtype === SuggestionSubType.croHeatmap) {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>Teimme sivulle {source.keyword} optimointia.</p>
						<br />
						<p style={{ fontWeight: 'bold' }}>
							{i18n.t('report.why it was done')}
						</p>
						<p>
							Sivun konversioaste joka on{' '}
							{source.croHeatmap.calculatedConversionRate} % on huomattavasti
							pienempi kuin muissa sivuston sivuissa.
						</p>
						<div>
							<p>
								{i18n.t('report.potential value for change')}:{' '}
								<strong> {potentialValue} </strong>EUR
							</p>
						</div>
					</div>
				)
			}
			if (subtype === SuggestionSubType.croABTesting) {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>Teimme sivulle {source.keyword} A/B testausta.</p>
						<br />
						<p style={{ fontWeight: 'bold' }}>
							{i18n.t('report.why it was done')}
						</p>
						<p>
							Sivun konversioaste joka on{' '}
							{source.croABTesting.calculatedConversionRate} % on huomattavasti
							pienempi kuin muissa sivuston sivuissa. Tekemällä A/B testausta
							voimme seurata millä asioilla saamme sivun konversioasteen
							nousemaan.
						</p>
						<div>
							<p>
								{i18n.t('report.potential value for change')}:{' '}
								<strong> {potentialValue} </strong>EUR
							</p>
						</div>
					</div>
				)
			}
			return ''
		case SuggestionType.some:
			if (subtype === SuggestionSubType.someCheckAdRelevance) {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>{`Pyrimme optimoimaan sosiaalisen median puolella (mainoksen nimi) mainosta. Optimoinnilla pyritään parantamaan konversiontuottokykyä, joka oli (alle keskiarvon)`}</p>{' '}
					</div>
				)
			}
			if (subtype === SuggestionSubType.someReduceFrequency) {
				return (
					<div key={index} className={'report__page-body-done-actions'}>
						<p>{`Vähensimme mainoksen näyttökertoja per käyttäjä`}</p>{' '}
					</div>
				)
			}
			return ''
		default:
			break
	}
}

// Makes sure that project contains a valid datasource obj
// Fixes the problem caused by datasources not included in newly created projects
export const validateCreatedProject = project => {
	if (!project.dataSources) {
		project.dataSources = []
		return project
	} else {
		return project
	}
}

export const filterInactiveProjects = projects => {
	return projects
		.filter(p => p.isActivated)
		.sort((a, b) => {
			return a.name.localeCompare(b.name)
		})
}

export const patchSuggestion = (allSuggestions, updatedSuggestion) => {
	const newSuggestions = {
		[SuggestionStatus.pending]: allSuggestions[SuggestionStatus.pending].filter(
			suggestion => suggestion.id !== updatedSuggestion.id,
		),
		[SuggestionStatus.active]: allSuggestions[SuggestionStatus.active].filter(
			suggestion => suggestion.id !== updatedSuggestion.id,
		),
		[SuggestionStatus.archived]: allSuggestions[
			SuggestionStatus.archived
		].filter(suggestion => suggestion.id !== updatedSuggestion.id),
		[SuggestionStatus.done]: allSuggestions[SuggestionStatus.done].filter(
			suggestion => suggestion.id !== updatedSuggestion.id,
		),
		[SuggestionStatus.not_useful]: allSuggestions[
			SuggestionStatus.not_useful
		].filter(suggestion => suggestion.id !== updatedSuggestion.id),
	}
	newSuggestions[updatedSuggestion.status].unshift(updatedSuggestion)
	return newSuggestions
}
export const mapSuggestions = allSuggestions => {
	const newSuggestions = {
		[SuggestionStatus.pending]: allSuggestions.filter(
			suggestion => suggestion.status === SuggestionStatus.pending,
		),
		[SuggestionStatus.active]: allSuggestions.filter(
			suggestion => suggestion.status === SuggestionStatus.active,
		),
		[SuggestionStatus.archived]: allSuggestions.filter(
			suggestion => suggestion.status === SuggestionStatus.archived,
		),
		[SuggestionStatus.done]: allSuggestions.filter(
			suggestion => suggestion.status === SuggestionStatus.done,
		),
		[SuggestionStatus.not_useful]: allSuggestions.filter(
			suggestion => suggestion.status === SuggestionStatus.not_useful,
		),
	}
	return newSuggestions
}
export const patchTasks = (allTasks, updatedTask) => {
	const newTasks = {
		[ESuggestionStatus.PENDING]: allTasks[ESuggestionStatus.PENDING].filter(
			suggestion => suggestion.id !== updatedTask.id,
		),
		[ESuggestionStatus.ACTIVE]: allTasks[ESuggestionStatus.ACTIVE].filter(
			suggestion => suggestion.id !== updatedTask.id,
		),
		[ESuggestionStatus.ARCHIVED]: allTasks[ESuggestionStatus.ARCHIVED].filter(
			suggestion => suggestion.id !== updatedTask.id,
		),
		[ESuggestionStatus.DONE]: allTasks[ESuggestionStatus.DONE].filter(
			suggestion => suggestion.id !== updatedTask.id,
		),
		[ESuggestionStatus.NOT_USEFUL]: allTasks[
			ESuggestionStatus.NOT_USEFUL
		].filter(suggestion => suggestion.id !== updatedTask.id),
	}
	newTasks[updatedTask.status].unshift(updatedTask)
	return newTasks
}

export const patchAssignees = (allItem, updatedItem) => {
	const updatedAssignees = updatedItem.assignees
	const foundItemIndex = allItem[updatedItem.status].findIndex(
		item => item.id === updatedItem.id,
	)
	const newItems = { ...allItem }
	newItems[updatedItem.status][foundItemIndex].assignees = updatedAssignees.map(
		obj => obj.id,
	)
	return newItems
}
export const patchCompanyUsers = (companyUsers, updatedUser) => {
	const clonedUsers = [...companyUsers]
	const foundItemIndex = clonedUsers.findIndex(
		item => item.id === updatedUser.id,
	)
	clonedUsers[foundItemIndex] = updatedUser
	return clonedUsers
}

export const patchContributors = (contributorList, updatedList) => {
	const clonedContributorList = [...contributorList]
	const foundItemIndex = clonedContributorList.findIndex(
		item => item.id === updatedList.id,
	)
	clonedContributorList[foundItemIndex] = updatedList
	return clonedContributorList
}

/* SuggestionStatus codes
	pending: 0,
	active: 1,
	archived: 2,
	done: 3,
	not_useful: 4
*/
export const translateSuggestionStatus = statusCode => {
	switch (statusCode) {
		case 0:
			return 'Pending'
		case 1:
			return 'Active'
		case 2:
			return 'Archived'
		case 3:
			return 'Done'
		case 4:
			return 'Not useful'
		default:
			return ''
	}
}

export const translateGenerationStatus = (statusCode, type) => {
	switch (statusCode) {
		case 0:
			return `${type.toUpperCase()} suggestions not generated`
		case 1:
			return `Generating ${type.toUpperCase()} suggestions...`
		case 2:
			return `${type.toUpperCase()} suggestions generated`
		case 3:
			return `Failed generating ${type.toUpperCase()} suggestions`
		default:
			return ``
	}
}

export const sortArrayByValue = (arrayOfObjects, valueToSort) => {
	return arrayOfObjects.sort(function (a, b) {
		return a[valueToSort] - b[valueToSort]
	})
}

export const transformProjectToOptions = project => ({
	label: project.company.name,
	options: [{ value: project.id, label: project.name }],
})
export const transformProjectsToOptions = arr => {
	let result = []

	arr.forEach(item => {
		let companyName
		if (!item.company || !item.company.name) {
			companyName = ''
		} else {
			companyName = item.company.name
		}
		const id = item.id
		const name = item.name

		const companyIndex = result.findIndex(
			company => company.label === companyName,
		)

		if (companyIndex !== -1) {
			result[companyIndex].options.push({ value: id, label: name })
		} else {
			result.push({
				label: companyName,
				options: [{ value: id, label: name }],
			})
		}
	})

	return result
}
export const filterObjectProperties = (filters, array) => {
	const filtered = array.map(ob => {
		const handled = filters.map(f => {
			delete ob[f]
		})
	})
	return array
}

// Format date from date
// Arrange the dates into order from the most current to the last
export const formatToMonths = date => {
	return date.substring(0, date.indexOf('T'))
}

export const getStartDateOfMonth = (date, onlyMonth = false) => {
	if (!date || !moment().isValid(date)) {
		return
	} else {
		if (onlyMonth) {
			return date.startOf('month').format('MMMM').toLocaleString(i18n.language)
		}
		return date.startOf('month').format('DD.MM').toLocaleString(i18n.language)
	}
}

export const getEndDateOfMonth = (date, onlyMonth = false, noEndYear) => {
	if (!date || !moment().isValid(date)) {
		return
	} else {
		if (onlyMonth) {
			return date.endOf('month').format('MMMM').toLocaleString(i18n.language)
		}
		return date
			.endOf('month')
			.format(noEndYear ? 'DD.MM' : 'DD.MM.YYYY')
			.toLocaleString(i18n.language)
	}
}

export const legendFormatter = (legend, dateRange = '') => {
	switch (legend) {
		case 'usersThisMonth':
			return `${i18n.t('values.this month users')} ${dateRange}`
		case 'usersLastMonth':
			return `${i18n.t('values.last month users')} ${dateRange}`
		case 'usersLastYear':
			return `${i18n.t('values.last year users')} ${dateRange}`
		case 'sessions':
			return `${i18n.t('values.sessions')} ${dateRange}`
		case 'conversionRate':
			return `${i18n.t('values.conversion rate')} ${dateRange}`
		case 'desktop':
			return `${i18n.t('values.desktop')} ${dateRange}`
		case 'mobile':
			return `${i18n.t('values.mobile')} ${dateRange}`
		case 'tablet':
			return `${i18n.t('values.tablet')} ${dateRange}`
		case 'uniquePurchases':
			return `${i18n.t('values.conversions')} ${dateRange}`
		case 'pageviewsThisMonth':
			return `${i18n.t('values.page views')} ${dateRange}`
		case 'pagesPerVisitor':
			return `${i18n.t('values.pages per visitor')} ${dateRange}`
		case 'avgSessionDuration':
			return `${i18n.t('values.average session duration')} ${dateRange}`
		case 'avgSessionDurationLastYear':
			return `${i18n.t(
				'values.average session duration last year',
			)} ${dateRange}`
		case 'avgSessionDurationLastMonth':
			return `${i18n.t(
				'values.average session duration last month',
			)} ${dateRange}`
		case 'uniquePurchasesLastYear':
			return `${i18n.t('values.unique purchases last year')} ${dateRange}`
		case 'uniquePurchasesLastMonth':
			return `${i18n.t('values.unique purchases last month')} ${dateRange}`
		case 'pageviewsLastYear':
			return `${i18n.t('values.pageviews last year')} ${dateRange}`
		case 'pageviewsLastMonth':
			return `${i18n.t('values.pageviews last month')} ${dateRange}`
		case 'users':
			return `${i18n.t('values.users')} ${dateRange}`
		case 'average_cpc':
			return `${i18n.t('values.average_cpc')} ${dateRange}`
		case 'quality_score':
			return `${i18n.t('values.quality_score')} ${dateRange}`
		case 'cost_micros':
			return `${i18n.t('values.cost_micros')} ${dateRange}`
		case 'cost_per_conversion':
			return `${i18n.t('values.cost_per_conversion')} ${dateRange}`
		case 'average_cost':
			return `${i18n.t('values.average_cost')} ${dateRange}`
		case 'bounceRate':
			return `${i18n.t('values.bounceRate')} ${dateRange}`
		case 'transactionsPerSession':
			return `${i18n.t('values.transactionsPerSession')} ${dateRange}`
		case 'totalQualityScore':
			return `${i18n.t('values.totalQualityScore')} ${dateRange}`
		case 'post_click_quality_score':
			return `${i18n.t('values.post_click_quality_score')} ${dateRange}`
		case 'creative_quality_score':
			return `${i18n.t('values.creative_quality_score')} ${dateRange}`
		case 'impressions':
			return `${i18n.t('values.impressions')} ${dateRange}`
		case 'clicks':
			return `${i18n.t('values.clicks')} ${dateRange}`
		case 'position':
			return `${i18n.t('values.position')} ${dateRange}`
		case 'ctr':
			return `${i18n.t('values.ctr')} ${dateRange}`
		case 'date':
			return `${i18n.t('common.date')} ${dateRange}`
		case 'deviceCategory':
			return `${i18n.t('values.deviceCategory')} ${dateRange}`
		default:
			return legend
	}
}

export const effectivenessGraphAdsDataFormatter = data => {
	return {
		...data,
		average_cpc: fromMicrons(data.average_cpc),
		all_conversions_value_per_cost: fromMicrons(
			data.all_conversions_value_per_cost,
		),
		cost_micros: fromMicrons(data.cost_micros),
		average_cost: fromMicrons(data.average_cost),
	}
}
export const effectivenessGraphAdsDataFormatterWithNumbers = data => {
	return {
		...data,
		average_cpc: parseFloat(fromMicrons(data.cost_micros)) / data.clicks,
		all_conversions_value_per_cost: parseFloat(
			fromMicrons(data.all_conversions_value_per_cost),
		),
		cost_per_conversion: formatDecimals(
			parseFloat(fromMicrons(data.cost_micros)) / data.conversions,
			2,
		),
		cost_micros: parseFloat(fromMicrons(data.cost_micros)),
		average_cost: parseFloat(fromMicrons(data.average_cost)),
		roas: formatDecimals(
			data.conversions_value / parseFloat(fromMicrons(data.cost_micros)),
			4,
		),
	}
}

export const effectivenessGraphSomeDataFormatter = data => {
	return {
		...data,
		date: data.date_start,
	}
}

export const effectivenessGraphCroDataFormatter = data => {
	return {
		...data,
		avgSessionDuration: (data.avgSessionDuration / 60).toFixed(2),
	}
}

export const calculatePercentageAndBarPercentage = value => {
	if (value > 0) {
		return {
			percent: `${formatDecimals(value).toFixed(1)} %`,
			barPercent: value && value > 50 ? 50 : value,
		}
	} else if (value == 0) {
		return {
			percent: '0 %',
			barPercent: 0,
		}
	} else {
		return {
			percent: `${formatDecimals(value).toFixed(1)} %`,
			barPercent: value,
		}
	}
}

export const userTotalStringFromData = (
	googleAnalyticsForThisMonth,
	googleAnalyticsForLastMonth,
) => {
	const userPercent = calculateByValues(
		formatGoogleAnalyticsData(googleAnalyticsForThisMonth, 'users'),
		formatGoogleAnalyticsData(googleAnalyticsForLastMonth, 'users'),
		'users',
		'/',
		true,
	)
	return calculatePercentageAndBarPercentage(userPercent)
}

export const pageviewsTotalStringFromData = (
	pageviewsThisMonth,
	pageviewsLastMonth,
) => {
	const pageviewsPercent =
		((pageviewsThisMonth - pageviewsLastMonth) / pageviewsLastMonth) * 100
	return calculatePercentageAndBarPercentage(pageviewsPercent)
}

export const sessionsTotalStringFromData = (
	avgSessionsThisMonth,
	avgSessionsLastMonth,
) => {
	const sessionsPercent = calculateByValues(
		avgSessionsThisMonth,
		avgSessionsLastMonth,
		'avgSessionDuration',
		'/',
		true,
	)
	return calculatePercentageAndBarPercentage(sessionsPercent)
}

/**
 *
 * @param {Object} param Object with facebookMarketingThisMonth and googlePPCThisMonth properties
 * @returns Calculated total amount of marketing with 2 decimals
 */

export const calculateMarketing = ({
	facebookMarketingThisMonth,
	facebookMarketingLastMonth,
	googlePPCThisMonth,
	googlePPCLastMonth,
}) => {
	let totalThisMonth = 0
	let totalLastMonth = 0
	if (!isNaN(facebookMarketingThisMonth) && facebookMarketingThisMonth > 0) {
		totalThisMonth += facebookMarketingThisMonth
	}
	if (!isNaN(googlePPCThisMonth) && googlePPCThisMonth > 0) {
		totalThisMonth += googlePPCThisMonth
	}
	if (!isNaN(facebookMarketingLastMonth) && facebookMarketingLastMonth > 0) {
		totalLastMonth += facebookMarketingLastMonth
	}
	if (!isNaN(googlePPCLastMonth) && googlePPCLastMonth > 0) {
		totalLastMonth += googlePPCLastMonth
	}

	const difference = formatDecimals(totalThisMonth - totalLastMonth)
	// Prevent not being NaN if both values are 0
	const differencePercent = difference ? (difference / totalLastMonth) * 100 : 0
	const { percent, barPercent } =
		calculatePercentageAndBarPercentage(differencePercent)
	// const barPercent =
	// 	differencePercent > 0 ? differencePercent : -differencePercent
	return {
		total: formatSumsIntl(Number(totalThisMonth).toFixed(2)),
		percent,
		barPercent,
	}
}

export const multipleExist = (array, value) => {
	const result = array.every(a => {
		return a === value
	})
	return result
}

export const transformAdCreativeData = (
	primaryTexts,
	headLines,
	descriptionsText,
) => {
	const mapAdTexts = array => {
		const mapped = array.reduce((texts, textValue) => {
			if (textValue) {
				texts.push({ text: textValue })
			}
			return texts
		}, [])
		return mapped.length ? mapped : [{ text: '' }]
	}
	const bodies = mapAdTexts(primaryTexts)
	const titles = mapAdTexts(headLines)
	const descriptions = mapAdTexts(descriptionsText)
	return {
		bodies,
		titles,
		descriptions,
	}
}

export const calculateDevice = (googleAnalyticsData, device) => {
	let total = 0
	googleAnalyticsData.map(item => {
		if (item.deviceCategory === device) {
			total += Number(item.users)
		}
	})
	return total
}

export const calculateDevicePercentage = (googleAnalyticsData, device) => {
	const totalDevice = calculateDevice(googleAnalyticsData, device)
	return (totalDevice / getTotalSum(googleAnalyticsData, 'users')) * 100
}

export const renderTag = (categoryName, attributeName) => {
	return (
		<div className={`category-container ${categoryName}`}>
			<span className={`category-container--dot ${categoryName}`} />
			<p className='category-container--name'>
				{`${i18n.t(`tasks.${categoryName}`)} ${
					attributeName ? `- ${attributeName}` : ''
				}`}
			</p>
		</div>
	)
}
export const renderPriorityIcon = prio => {
	switch (prio) {
		case ETaskPriority.LOW:
			return <SignalCellular1BarRounded htmlColor='#333132' fontSize='large' />
		case ETaskPriority.MEDIUM:
			return <SignalCellular3BarRounded htmlColor='#333132' fontSize='large' />
		case ETaskPriority.HIGH:
			return <SignalCellular4BarRounded htmlColor='#333132' fontSize='large' />
		default:
			return <SignalCellular0BarRounded htmlColor='#333132' fontSize='large' />
	}
}

export const priorityValueToText = prio => {
	switch (prio) {
		case ETaskPriority.LOW:
			return i18n.t('tasks.filters.low')
		case ETaskPriority.MEDIUM:
			return i18n.t('tasks.filters.medium')
		case ETaskPriority.HIGH:
			return i18n.t('tasks.filters.high')
		default:
			return i18n.t('tasks.priority')
	}
}

export const formatSelectorData = (
	enumValues,
	translationKey,
	replaceKey = '',
) => {
	const values = Object.values(enumValues).map(item => ({
		label: i18n.t(
			translationKey
				? `${translationKey}.${item}`.replace(replaceKey, '')
				: `common.${item}`,
		),
		value: item,
	}))
	return values
}

export const formatAiGoogleAdsHeadlines = (aiHeadlines, existingHeadline) => {
	const newArr = aiHeadlines.map(headline => {
		return {
			label: headline,
			value: headline,
		}
	})
	newArr.unshift({
		label: existingHeadline,
		value: existingHeadline,
	})
	return newArr
}
