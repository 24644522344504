/**
 *  message component
 * Display message message as a banner
 *
 * @author trung <trung@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import React from 'react'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import { intervalTime } from '../../constants'
import { messageActions } from '../../actions'

class Message extends React.Component {
	state = {
		show: false,
		vertical: 'top',
		horizontal: 'center',
	}

	handleClose = () => {
		this.setState({ show: false })
	}

	componentDidMount() {
		if (this.props.message) {
			this.setState({ show: true })
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.message.message !== this.props.message.message &&
			this.props.message.message
		) {
			this.setState({ show: true })
			window.setTimeout(
				() => this.setState({ show: false }, () => this.props.setMessage('')),
				intervalTime,
			)
		}
	}

	render() {
		const { message } = this.props
		const { show } = this.state

		if (!message || (message && !message.message)) {
			return null
		}
		return (
			<div className='message-container'>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					key={`${this.state.vertical},${this.state.horizontal}`}
					open={show}
					style={{ zIndex: 20000 }}
					onClose={this.handleClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id='message-id'>{message.message}</span>}
				/>
			</div>
		)
	}
}

export default connect(
	state => ({
		message: state.message,
	}),
	dispatch => ({
		setMessage: message => dispatch(messageActions.showMessage(message)),
	}),
)(Message)
