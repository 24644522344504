import { TaskActions, TaskItem } from '../actions/task'
import {
	CREATE_CUSTOM_TASK_SUCCESS,
	GET_ALL_TASKS_SUCCESS,
	GET_PROJECT_BY_ID_SUCCESS,
	MODIFY_PROJECT_SUCCESS,
	RESET_TASK_STATE,
	UPDATE_TASK_ASSIGNEES_SUCCESS,
	UPDATE_TASK_SUCCESS,
} from '../actions/types'
import { ESuggestionStatus } from '../constants'

import { patchAssignees, patchTasks } from '../helpers/dataTransformer'

type TaskState = {
	tasks: {
		[key in ESuggestionStatus]: TaskItem[]
	}
}

export const initialState: TaskState = {
	tasks: {
		[ESuggestionStatus.PENDING]: [],
		[ESuggestionStatus.ACTIVE]: [],
		[ESuggestionStatus.ARCHIVED]: [],
		[ESuggestionStatus.DONE]: [],
		[ESuggestionStatus.NOT_USEFUL]: [],
	},
}

const combineTask = (tasks: TaskItem[]) => {
	return tasks.reduce(
		(acc: TaskState['tasks'], cur) => {
			acc[cur.status].push(cur)
			return acc
		},
		{
			[ESuggestionStatus.PENDING]: [],
			[ESuggestionStatus.ACTIVE]: [],
			[ESuggestionStatus.ARCHIVED]: [],
			[ESuggestionStatus.DONE]: [],
			[ESuggestionStatus.NOT_USEFUL]: [],
		},
	)
}
export const taskReducer = (
	state = initialState,
	action: TaskActions,
): typeof initialState => {
	switch (action.type) {
		case GET_ALL_TASKS_SUCCESS:
			return { ...state, tasks: combineTask(action.payload.data) }
		case MODIFY_PROJECT_SUCCESS:
			return action.payload.data.isActivated === false
				? { ...initialState }
				: state
		case CREATE_CUSTOM_TASK_SUCCESS:
			return { ...state, tasks: patchTasks(state.tasks, action.payload.data) }
		case UPDATE_TASK_SUCCESS:
			return {
				...state,
				tasks: patchTasks(state.tasks, action.payload.data),
			}
		case UPDATE_TASK_ASSIGNEES_SUCCESS:
			return {
				...state,
				tasks: patchAssignees(state.tasks, action.payload.data),
			}
		case RESET_TASK_STATE:
		case GET_PROJECT_BY_ID_SUCCESS:
			return { ...initialState }
		default:
			return state
	}
}

export const taskSelectors = {
	getTaskState: (state = initialState): TaskState['tasks'] => state.tasks,
	getAllTasks: (state = initialState): TaskItem[] => [
		...state.tasks[ESuggestionStatus.ACTIVE],
		...state.tasks[ESuggestionStatus.ARCHIVED],
		...state.tasks[ESuggestionStatus.DONE],
		...state.tasks[ESuggestionStatus.NOT_USEFUL],
		...state.tasks[ESuggestionStatus.PENDING],
	],
	taskCount: (state = initialState): any => {
		return {
			[ESuggestionStatus.ACTIVE]: state.tasks[ESuggestionStatus.ACTIVE].length,
			[ESuggestionStatus.ARCHIVED]:
				state.tasks[ESuggestionStatus.ARCHIVED].length,
			[ESuggestionStatus.DONE]: state.tasks[ESuggestionStatus.DONE].length,
			[ESuggestionStatus.NOT_USEFUL]:
				state.tasks[ESuggestionStatus.NOT_USEFUL].length,
			[ESuggestionStatus.PENDING]:
				state.tasks[ESuggestionStatus.PENDING].length,
		}
	},
	getActiveTasks: (state = initialState): TaskItem[] =>
		state.tasks[ESuggestionStatus.ACTIVE],
	getArchivedTasks: (state = initialState): TaskItem[] =>
		state.tasks[ESuggestionStatus.ARCHIVED],
	getDoneTasks: (state = initialState): TaskItem[] =>
		state.tasks[ESuggestionStatus.DONE],
	getNotUsefulTasks: (state = initialState): TaskItem[] =>
		state.tasks[ESuggestionStatus.NOT_USEFUL],
	getPendingTasks: (state = initialState): TaskItem[] =>
		state.tasks[ESuggestionStatus.PENDING],
	getTasksForTaskView: (
		state = initialState,
	): {
		todoTasks: TaskItem[]
		resolvedTasks: TaskItem[]
		ignoredTasks: TaskItem[]
		pendingTasks: TaskItem[]
	} => ({
		todoTasks: state.tasks[ESuggestionStatus.ACTIVE],
		resolvedTasks: state.tasks[ESuggestionStatus.DONE],
		ignoredTasks: state.tasks[ESuggestionStatus.NOT_USEFUL],
		pendingTasks: state.tasks[ESuggestionStatus.PENDING],
	}),
}
