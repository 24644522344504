/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import {
	SIGN_IN_SUCCESS,
	SIGN_OUT,
	GET_CURRENT_USER_SUCCESS,
	UPDATE_USER_SUCCESS,
	SIGN_IN_FAIL,
	UNSUBSCRIBE_PLAN_SUCCESS,
	CHANGE_USER_FIELD,
	CHANGE_SIDENAV_STATUS,
	SET_SIDENAV_STATUS,
	CHANGE_COMPANY_FIELD,
	GET_COMPANY_USERS_SUCCESS,
	UPDATE_COMPANY_USER_SUCCESS,
	GET_CURRENT_USER_FIREBASE_TOKEN_SUCCESS,
	GET_PROJECT_BY_ID_SUCCESS,
	CREATE_COMPANY_SUCCESS,
	UPDATE_COMPANY_SUCCESS,
	CREATE_PROJECT_SUCCESS,
	GET_PROJECT_USER_SUCCESS,
} from '../actions/types'
import { UserActions } from '../actions/user'
import { ESubscriptionLevel, IProject } from '../constants'
import { patchCompanyUsers } from '../helpers/dataTransformer'

export type Company = {
	id: number
	name: string
	bussinessId: number | null
	email: string
	address: string | null
	companyType: string
	planId: string | null
	billingDate: Date
	maximumNumberOfProject: number
	subscriptionLevel: ESubscriptionLevel
	trialEndDate: Date
	projectUser?: CompanyUser[]
	openAIEngine: {
		magicWrite: string
		contentCreator: string
		postScheduler: string
		productDescription: string
	}
}

export enum EUserRoles {
	OWNER = 'OWNER',
	ADMIN = 'ADMIN',
	USER = 'USER',
	CUSTOMER = 'CUSTOMER',
}

export type CompanyUser = {
	id: number
	firstName: string
	lastName: string
	email: string
	userRole: EUserRoles
}

export type User = {
	id: number | null
	firstName: string
	lastName: string
	email: string
	phoneNumber: string
	billingDate: Date | null
	projects: IProject[]
	company?: Company
	firebaseId: string
	userRole: EUserRoles | ''
}

export type UserState = {
	user: User
	company: Company
	projectCompany: Pick<
		Company,
		'id' | 'name' | 'subscriptionLevel' | 'projectUser'
	>
	error: boolean
	isSidenavOpen: boolean
	firebaseToken: string | null
	companyUsers: CompanyUser[]
}
const initialState: UserState = {
	user: {
		id: null,
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		billingDate: null,
		projects: [],
		firebaseId: '',
		userRole: '',
	},
	company: {
		id: -1,
		name: '',
		bussinessId: null,
		email: '',
		address: null,
		companyType: '',
		planId: null,
		billingDate: new Date(),
		maximumNumberOfProject: -1,
		subscriptionLevel: ESubscriptionLevel.TRIAL,
		trialEndDate: new Date(),
		openAIEngine: {
			magicWrite: 'gpt-4o',
			contentCreator: 'gpt-4o',
			postScheduler: 'gpt-4o',
			productDescription: 'gpt-4o',
		},
	},
	projectCompany: {
		id: -1,
		name: '',
		subscriptionLevel: ESubscriptionLevel.NOT_SUBSCRIBED,
		projectUser: [],
	},
	companyUsers: [],
	error: false,
	isSidenavOpen: true,
	firebaseToken: null,
}

export const userReducer = (
	state = initialState,
	action: UserActions,
): typeof initialState => {
	switch (action.type) {
		case SIGN_IN_SUCCESS:
			return {
				...state,
				user: {
					...action.payload.data.data.user,
				},
				error: false,
			}
		case SIGN_IN_FAIL:
			return {
				...state,
				error: true,
			}
		case GET_CURRENT_USER_SUCCESS:
			return {
				...state,
				user: {
					...action.payload.data,
				},
				company: action.payload.data.company
					? action.payload.data.company
					: initialState.company,
			}
		case GET_COMPANY_USERS_SUCCESS:
			return {
				...state,
				companyUsers: action.payload.data,
			}
		case UPDATE_COMPANY_USER_SUCCESS: {
			const patchedUsers = patchCompanyUsers(
				state.companyUsers,
				action.payload.data,
			)
			return {
				...state,
				companyUsers: patchedUsers,
			}
		}
		case UPDATE_USER_SUCCESS: {
			return {
				...state,
				user: { ...state.user, ...action.payload.data },
			}
		}
		case UPDATE_COMPANY_SUCCESS: {
			return {
				...state,
				company: action.payload.data,
			}
		}
		case UNSUBSCRIBE_PLAN_SUCCESS: {
			const existingCompany = state.company ? state.company : null
			if (!existingCompany) {
				return { ...state }
			}
			return {
				...state,
				company: {
					...existingCompany,
					planId: null,
				},
			}
		}
		case GET_CURRENT_USER_FIREBASE_TOKEN_SUCCESS: {
			if (action.payload.data && !action.payload.data.token) {
				return { ...state }
			}
			return {
				...state,
				firebaseToken: action.payload.data.token,
			}
		}
		case GET_PROJECT_BY_ID_SUCCESS:
		case CREATE_PROJECT_SUCCESS:
			if (!action.payload.data.company) {
				return { ...state }
			}
			return {
				...state,
				projectCompany: {
					...action.payload.data.company,
				},
			}
		case GET_PROJECT_USER_SUCCESS:
			return {
				...state,
				projectCompany: {
					...state.projectCompany,
					projectUser: [...action.payload.data],
				},
			}
		case CREATE_COMPANY_SUCCESS:
			return {
				...state,
				company: {
					...action.payload.data,
				},
			}

		case CHANGE_USER_FIELD:
			return { ...state, user: { ...state.user, ...action.payload } }
		case CHANGE_COMPANY_FIELD:
			return {
				...state,
				company: {
					...state.company,
					...action.payload,
				},
			}
		case CHANGE_SIDENAV_STATUS:
			return { ...state, isSidenavOpen: !state.isSidenavOpen }
		case SET_SIDENAV_STATUS:
			return { ...state, isSidenavOpen: action.payload }
		case SIGN_OUT:
			return initialState
		default:
			return state
	}
}

export const userSelectors = {
	getUser: (state = initialState): User => state.user,
	getCompanyUsers: (state = initialState): CompanyUser[] => state.companyUsers,
	getProjectUsers: (state = initialState): CompanyUser[] =>
		state.projectCompany.projectUser ? state.projectCompany.projectUser : [],
	getCompany: (
		state = initialState,
	): Pick<Company, 'id' | 'name' | 'subscriptionLevel'> => state.projectCompany,
	getUserCompany: (state = initialState): Company => state.company,
	getProjects: (state = initialState): IProject[] => state.user.projects,
	getError: (state = initialState): boolean => state.error,
	isSidenavOpen: (state = initialState): boolean => state.isSidenavOpen,
	isAuthenticated: (state = initialState): boolean =>
		Boolean(userSelectors.getUser(state).id) &&
		Boolean(localStorage.getItem('token')),
}
