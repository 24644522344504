export const regex = {
	emptyField: /(.|\s)*\S(.|\s)*/,
	email:
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	// any 8 characters
	min8Characters: /^.{8,}$/,
	// any 8 characters
	min2Characters: /^.{2,}$/,
	// contain at least 1 lowercase character, 1 uppercase character, 1 number, 1 special character and min 8 characters in total
	password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
	finnishPhoneNo:
		/^((([\+][\s]?)|([0]{2}[\s-]?))([358]{3})([\s-]?)|([0]))(([1-9][0-9]?)([\s-]?)([0-9]{2,4})([\s-]?)([0-9]{2,4})([\s-]?))([0-9]{0,3})$/,
	phoneNumber:
		/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
	url: /[(http(s)):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
	urlWithHttp:
		/^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
}

/**
 * Validator input, true when haveError
 * @param  {String} input
 * @param  {('emptyField'|'email'|'min8Characters'|'min2Characters'|'password'|'finnishPhoneNo'|'phoneNumber'|'url'|'urlWithHttp')} validatorType
 * @returns Boolean
 */
export const validator = (input, validatorType) => {
	if (regex[validatorType] === undefined) {
		throw new Error('validatorType is not exists')
	}

	return !regex[validatorType].test(input)
}

export const defaultValidatorError = {
	emptyField: 'Field cannot be empty',
	email: 'Incorrect email',
	min8Characters: 'Min 8 characters',
	min2Characters: 'Min 2 characters',
	general: 'Error',
	password: 'Incorrect password',
	finnishPhoneNo: 'Incorrect Phone number',
	url: 'URL incorrect.',
	urlWithHttp: 'URL incorrect. Missing https://',
}
export const responseValidator = (response, successfulStatus = 200) => {
	return response.payload && response.payload.status === successfulStatus
}
const defaultError = {
	status: null,
	msg: 'Whoops! Something went wrong',
	detail: 'Whoops! Something went wrong',
}
/**
 * Format and humanize error message
 * to show specific error message in UI
 *
 * @param {Object} error object from server
 * @return {Object} status code + title + detail
 */
export const normalizeError = error => {
	const errorData = error.response ? error.response.data : null

	if (!errorData) {
		return defaultError
	}

	// const errorObject = errorData.errors[0]

	return errorData
}
