import {
	CHANGE_WP_SELECTED_ITEM_TITLE,
	CHANGE_WP_CURRENT_PAGE_BY_TYPE,
	CREATE_WP_ITEM_BY_TYPE_SUCCESS,
	GET_WP_ITEM_BY_ID_SUCCESS,
	GET_WP_PAGES_SUCCESS,
	GET_WP_POSTS_SUCCESS,
	RESET_WP_STATE,
	UPDATE_WP_ITEM_BY_ID_SUCCESS,
	SET_WP_SELECTED_ITEM,
} from '../actions/types'
import {
	CreateWordPressItemType,
	WordPressActions,
	WordPressItemType,
} from '../actions/wp-tools'

export type WordPressState = {
	posts: {
		items: WordPressItemType[]
		currentPage: number
		maxPage: number
		totalItems: number
	}
	pages: {
		items: WordPressItemType[]
		currentPage: number
		maxPage: number
		totalItems: number
	}
	selectedItem: WordPressItemType | CreateWordPressItemType | null
}

export const initialState: WordPressState = {
	posts: {
		items: [],
		currentPage: 1,
		maxPage: 1,
		totalItems: 0,
	},
	pages: {
		items: [],
		currentPage: 1,
		maxPage: 1,
		totalItems: 0,
	},
	selectedItem: null,
}

export const wpReducer = (
	state = initialState,
	action: WordPressActions,
): typeof initialState => {
	switch (action.type) {
		case CREATE_WP_ITEM_BY_TYPE_SUCCESS:
		case UPDATE_WP_ITEM_BY_ID_SUCCESS:
		case GET_WP_ITEM_BY_ID_SUCCESS:
			return { ...state, selectedItem: action.payload.data }
		case GET_WP_PAGES_SUCCESS:
			return {
				...state,
				pages: {
					...state.pages,
					items: action.payload.data.items,
					maxPage: action.payload.data.totalPages,
					totalItems: action.payload.data.totalItems,
				},
			}
		case GET_WP_POSTS_SUCCESS:
			return {
				...state,
				posts: {
					...state.posts,
					items: action.payload.data.items,

					maxPage: action.payload.data.totalPages,
					totalItems: action.payload.data.totalItems,
				},
			}
		case CHANGE_WP_CURRENT_PAGE_BY_TYPE:
			return {
				...state,
				[action.payload.type]: {
					...state[action.payload.type],
					currentPage: action.payload.value,
				},
			}
		case CHANGE_WP_SELECTED_ITEM_TITLE: {
			return state.selectedItem
				? {
						...state,
						selectedItem: {
							...state.selectedItem,
							title: action.payload.value,
						},
				  }
				: state
		}
		case SET_WP_SELECTED_ITEM:
			return {
				...state,
				selectedItem: action.payload,
			}

		case RESET_WP_STATE: {
			return { ...initialState }
		}
		default:
			return state
	}
}

export const wpSelectors = {
	getWordPressPosts: (state = initialState): WordPressItemType[] =>
		state.posts.items,
	getWordPressPages: (state = initialState): WordPressItemType[] =>
		state.pages.items,
	getWordPressPagesMaxPage: (state = initialState): number =>
		state.pages.maxPage,
	getWordPressPagesTotalItems: (state = initialState): number =>
		state.pages.totalItems,
	getWordPressPagesCurrentPage: (state = initialState): number =>
		state.pages.currentPage,
	getWordPressPostsMaxPage: (state = initialState): number =>
		state.posts.maxPage,
	getWordPressPostsTotalItems: (state = initialState): number =>
		state.posts.totalItems,
	getWordPressPostsCurrentPage: (state = initialState): number =>
		state.posts.currentPage,
	getSelectedWordPressItem: (
		state = initialState,
	): WordPressItemType | CreateWordPressItemType | null => state.selectedItem,
}
