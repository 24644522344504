import { ButtonProps } from '@material-ui/core'
import React, { forwardRef, LegacyRef } from 'react'
import ToolTip from './ToolTip'
import HelpIcon from '@mui/icons-material/Help'

type Props = {
	text: string
	icon?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick?: (...args: any[]) => (void | Promise<void>) | (() => void)
	className?: string
	textClassName?: string
	buttonProps?: ButtonProps
	containerClassName?: string
	iconClassName?: string
	iconComponent?: JSX.Element
	iconPosition?: string
	type?: 'button' | 'submit' | 'reset'
	disabled?: boolean
	property?: string | number | Record<string, unknown>
	toolTipText?: string
}

// eslint-disable-next-line react/display-name
const Button = forwardRef(
	(
		{
			text,
			icon,
			onClick,
			className,
			textClassName,
			buttonProps,
			containerClassName,
			iconClassName,
			iconComponent,
			iconPosition,
			type = 'button',
			disabled,
			property,
			toolTipText,
			...props
		}: Props,
		ref: LegacyRef<HTMLDivElement> | undefined,
	) => {
		const buttonTextClassName = textClassName
			? [textClassName].concat(['button-text']).join(' ')
			: 'button-text'
		const buttonContainerClassName = containerClassName
			? ['button-container'].concat([containerClassName]).join(' ')
			: 'button-container'
		const _onClick = () =>
			typeof onClick === 'function' ? onClick(property) : null
		const renderIcon = () =>
			iconComponent ? iconComponent : <i className={`${iconClassName} icon`} />

		return (
			<div className={buttonContainerClassName} ref={ref} {...props}>
				<button
					onClick={_onClick}
					className={className}
					type={type}
					{...buttonProps}
					disabled={disabled}
				>
					<div
						className={`${buttonTextClassName} ${
							iconPosition ? `icon-${iconPosition}` : ''
						} ${iconClassName && !text ? 'button-icon' : ''}`}
					>
						{(iconClassName || iconComponent) && iconPosition === 'start'
							? renderIcon()
							: null}
						{text}
						{(iconClassName || iconComponent) && iconPosition === 'end'
							? renderIcon()
							: null}
					</div>
					{toolTipText ? (
						<ToolTip title={toolTipText} delay={0}>
							<HelpIcon />
						</ToolTip>
					) : null}
				</button>
			</div>
		)
	},
)

export default Button
