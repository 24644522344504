/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React from 'react'

const UnauthorizedLayout = ({ children }) => <div>{children}</div>

export default UnauthorizedLayout
