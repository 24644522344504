import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
	active: boolean
	keyValue?: string
	onClick: () => void
	href?: string
	to?: any
	className?: string
	icon?: JSX.Element | null
	description: string
	extraOption?: JSX.Element | null
	avatar?: JSX.Element | null
	sidenavOpen: boolean
}

export const MenuItem = ({
	active,
	keyValue,
	onClick,
	href,
	className,
	icon,
	description,
	extraOption,
	avatar,
	sidenavOpen,
	to,
}: Props): JSX.Element => {
	let styles = className
		? 'custom-menu-container__item'.concat(' ', className)
		: 'custom-menu-container__item'

	if (active) {
		styles = styles.concat(' ', 'custom-menu-container__item--active')
	}
	if (icon && extraOption) {
		return (
			<Link
				key={keyValue}
				onClick={onClick}
				to={to}
				href={href}
				className={styles}
			>
				{icon}
				{sidenavOpen && (
					<>
						<p className={'custom-menu-container__item--text'}>{description}</p>
						<div className={'custom-menu-container__item--empty third'}>
							{extraOption}
						</div>
					</>
				)}
			</Link>
		)
	} else if (icon && avatar) {
		return (
			<Link
				key={keyValue}
				onClick={onClick}
				to={to}
				href={href}
				className={styles}
			>
				{icon}
				{sidenavOpen && (
					<>
						<p className={'custom-menu-container__item--text'}>{description}</p>
						<div className={'custom-menu-container__item--empty third'}>
							{avatar}
						</div>
					</>
				)}
			</Link>
		)
	} else if (icon) {
		return (
			<Link
				key={keyValue}
				onClick={onClick}
				to={to}
				href={href}
				className={styles}
			>
				{icon}
				{sidenavOpen && (
					<p className={'custom-menu-container__item--text'}>{description}</p>
				)}
			</Link>
		)
	} else if (avatar) {
		return (
			<Link
				key={keyValue}
				onClick={onClick}
				to={to}
				href={href}
				className={styles}
			>
				<p className={'custom-menu-container__item--empty'}></p>
				<p className={'custom-menu-container__item--text'}>{description}</p>
				<div className={'custom-menu-container__item--empty'}>{avatar}</div>
			</Link>
		)
	} else {
		return (
			<Link
				key={keyValue}
				onClick={onClick}
				to={to}
				href={href}
				className={styles}
			>
				{sidenavOpen ? (
					<>
						<p className={'custom-menu-container__item--empty small'}></p>
						<p className={'custom-menu-container__item--text  no-margin'}>
							{description}
						</p>
					</>
				) : (
					<p className={'custom-menu-container__item--empty'}>
						{description.substring(0, 1)}
					</p>
				)}
			</Link>
		)
	}
}
