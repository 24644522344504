/**
 *  message component
 * Display message message as a banner
 *
 * @author juho <juho@dashboa.com>
 *
 * @copyright Dashboa 2022
 */
import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { errorActions } from '../../actions'
import CustomModal from '../../shared/components/Modal'
import { useTranslation } from 'react-i18next'
import Button from '../../shared/components/Button'

interface InformationModalProps {
	statusCodes: number[]
	clearErrorMessage: () => void
}

const InformationModal: FunctionComponent<InformationModalProps> | null = ({
	statusCodes,
	clearErrorMessage,
}) => {
	const { t } = useTranslation()
	const errors = statusCodes.filter((i: number) => String(i)[0] === '3')
	if (!errors.length) {
		return null
	}
	const handleClose = () => {
		clearErrorMessage()
	}
	return (
		<>
			<CustomModal
				modalContainerClassName='no-overflow'
				show={true}
				closeModal={handleClose}
			>
				<div className='error-message__information-container'>
					{errors.map(i => {
						return <p key={i}>{t(`message codes.${i}`)}</p>
					})}
					<Button text={t('common.close')} onClick={handleClose} />
				</div>
			</CustomModal>
		</>
	)
}

export default connect(
	(state: any) => ({
		statusCodes: state.error.statusCodes,
	}),
	dispatch => ({
		clearErrorMessage: () => dispatch(errorActions.clearErrorMessage()),
	}),
)(InformationModal)
