import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { FacebookAdsItem } from '../../../constants'
import { FacebookAdsFilterOptions } from '../../../reducers/ads'
import EditIcon from '@mui/icons-material/Edit'

interface Props {
	ad: FacebookAdsItem
	size: FacebookAdsFilterOptions['size']
}

export default function FacebookAdItem({ ad, size }: Props): ReactElement {
	const { t } = useTranslation()
	return (
		<div
			key={ad.adId}
			className={`ads-visualisation-page__content--container__adGroups--item small blue-border ${ad.color} ${size}`}
		>
			<div
				className={
					'ads-visualisation-page__content--container__campaigns--item-header'
				}
			>
				<h6 onClick={() => ad.onClick(ad)}>{ad.name}</h6>
				<>
					{ad.adCreative !== undefined ? (
						<span
							className={
								'ads-visualisation-page__content--container__campaigns--item-header edit'
							}
							onClick={() => ad.onEditClick(ad)}
						>
							<EditIcon />
						</span>
					) : null}
				</>
			</div>
			<div className='ads-visualisation-page__content--container__adGroups--item--keyword'>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.spend')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{`${ad.spend}€`}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.clicks')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{ad.clicks}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.impressions')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{ad.impressions}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.reach')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{ad.reach}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.roas')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{ad.ROAS}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.conversions')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{ad.conversions}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.conversion value')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{ad.conversionValue}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.price per conversion')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{`${ad.costPerConversion}€`}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.average cpc')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{`${ad.CPC}€`}
					</p>
				</div>
			</div>
		</div>
	)
}
