// error handling reducer

import { ErrorActions } from '../actions/error'
import { CLEAR_ERROR_MESSAGE } from '../actions/types'

type ErrorState = {
	statusCodes: number[]
}

const initialState: ErrorState = {
	statusCodes: [],
}

// return error message if there is error returned from action
export const errorReducer = (
	state = initialState,
	action: ErrorActions,
): any => {
	if (action.type === CLEAR_ERROR_MESSAGE) {
		return initialState
	} else if (
		action.type.endsWith('FAIL') &&
		action.error &&
		action.error.response &&
		action.error.response.data.errorCode &&
		!state.statusCodes.includes(action.error.response.data.errorCode)
	) {
		switch (action.type) {
			default:
				return {
					...state,
					statusCodes: [
						...state.statusCodes,
						action.error.response.data.errorCode,
					],
				}
		}
	} else {
		return state
	}
}
