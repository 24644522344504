import { Action as A } from 'redux'
import { ActionWithoutPayload } from './audit'
import { CLEAR_ERROR_MESSAGE } from './types'

interface ActionWithFail<T, P> {
	readonly type: T
	readonly error: P
}

type ActionFail = ActionWithFail<
	string,
	{ config: any; request: any; response: { data: any } }
>

type ClearErrorMessage = ActionWithoutPayload<typeof CLEAR_ERROR_MESSAGE>

export type ErrorActions = ActionFail

export const clearErrorMessage = (): ClearErrorMessage => {
	return {
		type: CLEAR_ERROR_MESSAGE,
	}
}
