import React from 'react'
import CloseIcon from '@mui/icons-material/Close'

interface XCloserProps {
	onClose: () => void
}

const XCloser: React.FunctionComponent<XCloserProps> = ({ onClose }) => {
	return (
		<div onClick={onClose} className='xcloser'>
			<CloseIcon />
		</div>
	)
}
export default XCloser
