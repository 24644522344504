/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	API,
	AuditRowObject,
	AuditSiteDetail,
	AuditPageDetails,
	AuditSummaryResponse,
	AuditPageDetailObject,
} from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import {
	SAVE_SITE_URL,
	SAVE_SITE_URL_SUCCESS,
	GET_REDIRECT_CHAIN_BY_AUDIT_ID,
	GET_REDIRECT_CHAIN_BY_AUDIT_ID_SUCCESS,
	VIEW_RESOURCES_SUCCESS,
	GET_AUDIT_SUMMARY_BY_ID,
	GET_AUDIT_SUMMARY_BY_ID_SUCCESS,
	GET_AUDITS_BY_PROJECT_ID,
	GET_AUDITS_BY_PROJECT_ID_SUCCESS,
	GET_PAGE_DETAILS_BY_AUDIT_ID,
	GET_PAGE_DETAILS_BY_AUDIT_ID_SUCCESS,
	RESET_AUDIT_STATE,
	SIGN_OUT,
	SET_SELECTED_AUDIT,
} from './types'

export interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P }
}
export interface ActionWithoutPayload<T> {
	readonly type: T
}

interface SiteDetailsAction<T, P> {
	readonly type: T
	readonly payload: { data: any }
}

const saveSiteUrlActionType = SAVE_SITE_URL_SUCCESS
const getAuditSummaryActionType = GET_AUDIT_SUMMARY_BY_ID_SUCCESS
const getAuditSummariesActionType = GET_AUDITS_BY_PROJECT_ID_SUCCESS
const viewPageDetailsActionType = GET_PAGE_DETAILS_BY_AUDIT_ID_SUCCESS
const viewBrokenLinksActionType = GET_REDIRECT_CHAIN_BY_AUDIT_ID_SUCCESS
const viewResourcesActionType = VIEW_RESOURCES_SUCCESS
const resetAuditStateActionType = RESET_AUDIT_STATE
const signOutActionType = SIGN_OUT
const setSelectedAuditType = SET_SELECTED_AUDIT

export type SaveSiteUrlAction = Action<
	typeof saveSiteUrlActionType,
	AuditRowObject[]
>
type GetAuditSummaryAction = SiteDetailsAction<
	typeof getAuditSummaryActionType,
	AuditSiteDetail
>
type ViewPageDetailsAction = Action<
	typeof viewPageDetailsActionType,
	AuditPageDetails & {
		mainPageTags?: {
			h1: string[]
			h2: string[]
			h3: string[]
		}
	}
>
type ViewBrokenLinksAction = Action<
	typeof viewBrokenLinksActionType,
	AuditPageDetailObject[]
>
type ViewResourcesAction = Action<
	typeof viewResourcesActionType,
	AuditRowObject[]
>
type GetAuditSummariesAction = Action<
	typeof getAuditSummariesActionType,
	AuditSummaryResponse[]
>
type SetSelectedAudit = Action<
	typeof setSelectedAuditType,
	AuditSummaryResponse
>
type ResetAuditState = ActionWithoutPayload<typeof resetAuditStateActionType>
export type SignOut = ActionWithoutPayload<typeof signOutActionType>

export type AuditActions =
	| SaveSiteUrlAction
	| GetAuditSummaryAction
	| ViewPageDetailsAction
	| ViewBrokenLinksAction
	| ViewResourcesAction
	| GetAuditSummariesAction
	| ResetAuditState
	| SignOut
	| SetSelectedAudit

export const saveSiteUrl = (
	projectId: number,
	data: Record<string, number>,
): any => {
	const apiUri = replaceParamsUri(['projectId'], [projectId], API.SAVE_SITE_URL)
	return {
		type: SAVE_SITE_URL,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const getAuditPageDetails = (
	projectId: number,
	auditId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'auditSummaryId'],
		[projectId, auditId],
		API.GET_PAGE_DETAILS_BY_AUDIT_ID,
	)
	return {
		type: GET_PAGE_DETAILS_BY_AUDIT_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const getRedirectChains = (projectId: number, auditId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'auditSummaryId'],
		[projectId, auditId],
		API.GET_REDIRECT_CHAIN_BY_AUDIT_ID,
	)
	return {
		type: GET_REDIRECT_CHAIN_BY_AUDIT_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
// export const viewResources = (projectId: number): any => {
// 	const apiUri = replaceParamsUri(
// 		['projectId'],
// 		[projectId],
// 		API.VIEW_RESOURCES,
// 	)
// 	return {
// 		type: VIEW_RESOURCES,
// 		payload: {
// 			request: {
// 				method: 'GET',
// 				url: apiUri,
// 			},
// 		},
// 	}
// }

export const getAuditSummaries = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_AUDITS_BY_PROJECT_ID,
	)
	return {
		type: GET_AUDITS_BY_PROJECT_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const getAuditSummary = (projectId: number, auditId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'auditSummaryId'],
		[projectId, auditId],
		API.GET_AUDIT_SUMMARY_BY_ID,
	)
	return {
		type: GET_AUDIT_SUMMARY_BY_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const resetAuditState = (): any => {
	return {
		type: RESET_AUDIT_STATE,
	}
}

export const setSelectedAudit = (audit: AuditSummaryResponse): any => {
	return {
		type: SET_SELECTED_AUDIT,
		payload: { data: audit },
	}
}
