/**
 * Configure redux store
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import {
	AnyAction,
	configureStore,
	Dispatch,
	Middleware,
	MiddlewareArray,
} from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { routerMiddleware } from 'connected-react-router'
import axios, { AxiosRequestConfig } from 'axios'
import { multiClientMiddleware } from 'redux-axios-middleware'
import { createLogger } from 'redux-logger'

import { rootReducer, history } from './reducers'
import { PERSIST_KEY, backendUri, autoMergeLevel3 } from './constants'
import { showMessage } from './actions/message'
import { userActions } from './actions'
import i18n from './services/i18n'

// Create client alias
// Used in action creators
const baseURL = backendUri
const client = {
	default: {
		client: axios.create({
			baseURL: baseURL,
			responseType: 'json',
		}),
	},
}

// Config redux-persist
const persistConfig = {
	key: PERSIST_KEY,
	storage,
	stateReconciler: autoMergeLevel3,
	blacklist: [
		'form',
		'router',
		'error',
		'login',
		'audit',
		'message',
		'loading',
	],
	// timeout: 1000,
	debug: true,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
const axiosOptions = {
	returnRejectedPromiseOnError: true,
	interceptors: {
		request: [
			// eslint-disable-next-line no-empty-pattern
			({}, config: AxiosRequestConfig) => {
				const currentLocalToken = localStorage.getItem('token')
				const resetPasswordToken = localStorage.getItem('resetPasswordToken')
				const token = currentLocalToken || resetPasswordToken
				if (token) {
					// Add authentication tokens to request header
					config.headers = { authorization: 'Bearer ' + token }
				}
				return config
			},
		],
		response: [
			{
				// eslint-disable-next-line no-empty-pattern
				success: ({}, response: any) => {
					// handle success response
					return response
				},
				// eslint-disable-next-line no-empty-pattern
				error: ({ dispatch }: any, error: any) => {
					if (
						(error && error.response && error.response.data,
						error.response &&
							(error.response.data.message || error.response.data.errorCode))
					) {
						if (
							error.response.data.message === 'JWT validation error' ||
							error.response.data.message === 'Error on authentication!' ||
							error.response.data.errorCode === 5030
						) {
							localStorage.removeItem('token')
							setTimeout(
								() => dispatch(showMessage(i18n.t('messages.login expired'))),
								500,
							)
							dispatch(userActions.signOut())
						}
					}
					// handle error response
					return Promise.reject(error)
				},
			},
		],
	},
}

const loggerMiddleware = []
const isProduction = process.env.NODE_ENV === 'production'
if (!isProduction) {
	const logger = createLogger()
	loggerMiddleware.push(logger)
}

// Create redux store
const middlewares: Middleware<any, any, Dispatch<AnyAction>>[] = [
	routerMiddleware(history),
	multiClientMiddleware(client, axiosOptions),
	...loggerMiddleware,
]

export const store = configureStore({
	reducer: persistedReducer,
	// middleware: new MiddlewareArray().concat(
	// 	routerMiddleware(history),
	// 	multiClientMiddleware(client, axiosOptions),
	// 	...loggerMiddleware,
	// ),
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(middlewares),
	devTools: true,
})
export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
