import { TutorialActionTypes } from '../actions/tutorial'
import { TUTORIAL_CHANGE_FIELD } from '../actions/types'
import { EHIGHLIGHT, ETUTORIAL_TYPES } from '../constants'

export type Alignment = 'left' | 'right' | 'center' | 'top' | 'bottom'

export type TutorialState = {
	firstTime: boolean
	currentStep: number
	maxSteps: number
	tutorialType: ETUTORIAL_TYPES
	modalOpen: boolean
	showHints: boolean
	nextStepValid: boolean
	modalAlignment: Alignment
	highlighted: number
}

const initialState: TutorialState = {
	firstTime: true,
	currentStep: 0,
	maxSteps: 5,
	tutorialType: ETUTORIAL_TYPES.CREATE_PROJECT,
	modalOpen: false,
	showHints: false,
	nextStepValid: false,
	modalAlignment: 'left',
	highlighted: EHIGHLIGHT.NONE,
}

export const tutorialReducer = (
	state = initialState,
	action: TutorialActionTypes,
): TutorialState => {
	switch (action.type) {
		case TUTORIAL_CHANGE_FIELD:
			return { ...state, ...action.payload }
		default:
			return state
	}
}

export const tutorialSelectors = {
	getTutorial: (state = initialState): TutorialState => state,
}
