import React, { PropsWithChildren, useState } from 'react'
import Button from './Button'
import Input from './Input'
import { validator } from '../../helpers/validator'
import { connect } from 'react-redux'
import { projectActions, userActions } from '../../actions'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import { IProject } from '../../constants'

type Props = {
	createProject: (data: Partial<IProject>) => void
	onProjectCreate: () => void
} & PropsWithChildren<WithTranslation>

const Createproject = ({
	createProject,
	onProjectCreate,
}: Props): JSX.Element => {
	const [form, setForm] = useState({
		projectName: '',
	})
	const { t } = useTranslation()
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target
		setForm({ ...form, [name]: value })
	}
	const handleCreateProject = async (body: Partial<IProject>) => {
		try {
			await createProject(body)
			onProjectCreate()
		} catch (er) {
			console.error(er)
		}
	}

	return (
		<div className='create-project-modal'>
			<h4>{t('create project modal.title')}</h4>
			<p>{t('create project modal.subtitle')}</p>
			<Input
				name='projectName'
				label={t('create project modal.project name')}
				value={form.projectName}
				onChange={handleChange}
			/>
			<Button
				disabled={validator(form.projectName, 'min2Characters')}
				text={t('create project modal.create project')}
				property={{ name: form.projectName }}
				onClick={handleCreateProject}
			/>
		</div>
	)
}

export default connect(null, dispatch => ({
	createProject: (data: Partial<IProject>) =>
		dispatch(projectActions.createProject(data)),
	getCurrentUser: () => dispatch(userActions.getCurrentUser()),
}))(withTranslation()(Createproject))
