import { API } from '../constants'
import { UPLOAD_KEYWORDS_FILE } from './types'

export const uploadExcelFile = (file: File): any => {
	const data = new FormData()
	data.append('file', file)

	return {
		type: UPLOAD_KEYWORDS_FILE,
		payload: {
			request: {
				method: 'POST',
				url: API.POST_KEYWORD_EXCEL,
				data,
			},
		},
	}
}
