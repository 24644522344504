import { size } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	GoogleAdItem,
	AdsGoogleCampaignItem,
	GoogleAdsGroupItem,
	EAdsTypeOptions,
} from '../../../constants'
import { fromMicrons } from '../../../helpers/dataTransformer'
import Button from '../../../shared/components/Button'
import XCloser from '../../../shared/components/XCloser'
import GoogleAdsKeywordItem from './GoogleAdsKeywordItem'

interface Props<T extends GoogleAdItemType> {
	googleAdItem: T
}

const translateCompaignStatus = (status: number) => {
	switch (status) {
		case 0:
			return 'UNSPECIFIED'
		case 1:
			return 'UNKNOWN'
		case 2:
			return 'ENABLED'
		case 3:
			return 'PAUSED'
		case 4:
			return 'REMOVED'
	}
}

export type GoogleAdItemType =
	| GoogleAdItem
	| AdsGoogleCampaignItem
	| GoogleAdsGroupItem
	| null

const GoogleAdsModal = <T extends GoogleAdItemType>({
	googleAdItem,
}: Props<T>): JSX.Element | null => {
	const [isKeywordsOpen, setIsKeywordOpen] = useState<boolean>(false)
	const [isAdsOpen, setIsAdsOpen] = useState<boolean>(false)
	const { t } = useTranslation()
	if (googleAdItem === null) {
		return null
	}
	try {
		if (googleAdItem.type === EAdsTypeOptions.CAMPAIGN) {
			return (
				<div className={'ads-modal__content'}>
					<div className={'ads-modal__content--container'}>
						<div className='ads-modal__content--container-close'>
							<XCloser onClose={() => googleAdItem.onClick(null)} />
						</div>
						<div
							key={googleAdItem.campaignName}
							className={'ads-modal__content--container__campaigns--items'}
						>
							<h6>{googleAdItem.campaignName}</h6>{' '}
							<div
								className={
									'ads-modal__content--container__campaigns--items__container'
								}
							>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{'BiddingStrategy '}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{googleAdItem.biddingStrategy}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{'Campaing status '}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{translateCompaignStatus(googleAdItem.campaignStatus)}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.budget')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{`${fromMicrons(googleAdItem.campaignBudget)}€/D`}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.conversions')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{googleAdItem.conversions}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.roas')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{googleAdItem.ROAS}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.price per conversion')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{`${googleAdItem.pricePerConversion} €`}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.average cpc')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{`${googleAdItem.averageCPC}€`}
									</p>
								</div>
							</div>
							<Button
								className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
								text={t('common.close')}
								onClick={() => googleAdItem.onClick(null)}
							/>
						</div>
					</div>
				</div>
			)
		} else if (googleAdItem.type === EAdsTypeOptions.AD_GROUP) {
			return (
				<div className={'ads-modal__content'}>
					<div className={'ads-modal__content--container'}>
						<div className='ads-modal__content--container-close'>
							<div className='ads-modal__content--container-close'>
								<XCloser onClose={() => googleAdItem.onClick(null)} />
							</div>
						</div>
						<div
							key={googleAdItem.campaignName}
							className={'ads-modal__content--container__campaigns--items'}
						>
							<h6>{googleAdItem.campaignName}</h6>{' '}
							<div
								className={
									'ads-modal__content--container__campaigns--items__container'
								}
							>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.conversions')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{googleAdItem.conversions}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.roas')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{googleAdItem.ROAS}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.price per conversion')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{googleAdItem.pricePerConversion}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.average cpc')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value  bold'>
										{`${googleAdItem.averageCPC}€`}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.adgroup status')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value  bold'>
										{`${googleAdItem.adGroupStatus}`}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item keywords'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('common.keywords')}
									</p>
									{Array.isArray(googleAdItem.keyword) &&
										googleAdItem.keyword.map(item => (
											<p
												key={item.keyword}
												className='ads-modal__content--container__campaigns--items__container--item keyword value bold'
											>
												{item.keyword}
											</p>
										))}
								</div>
							</div>
							<Button
								className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
								text={t('common.close')}
								onClick={() => googleAdItem.onClick(null)}
							/>
						</div>
					</div>
				</div>
			)
		} else if (googleAdItem.type === EAdsTypeOptions.AD) {
			return (
				<div className={'ads-modal__content'}>
					<div className={'ads-modal__content--container'}>
						<div className='ads-modal__content--container-close'>
							<XCloser onClose={() => googleAdItem.onClick(null)} />
						</div>
						<div className={'ads-modal__content--container__campaigns--items'}>
							{googleAdItem.headlines && googleAdItem.headlines.length > 0 ? (
								<h6>
									{typeof googleAdItem.headlines[0] !== 'string' &&
									googleAdItem.headlines[0].text
										? googleAdItem.headlines[0].text
										: googleAdItem.headlines[0]}
								</h6>
							) : (
								<h6>{'Dynamic ad'}</h6>
							)}
							<div
								className={
									'ads-modal__content--container__campaigns--items__container ad'
								}
							>
								<div className='ads-modal__content--container__campaigns--items__container--item ad'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('common.clicks')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{googleAdItem.clicks}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item ad'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.conversions')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{googleAdItem.conversions}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item ad'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.roas')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{googleAdItem.ROAS}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item ad'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.price per conversion')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{googleAdItem.pricePerConversion}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item ad'>
									<p className='ads-modal__content--container__campaigns--items__container--item key'>
										{t('ads visualization.average cpc')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value bold'>
										{`${googleAdItem.averageCPC}€`}
									</p>
								</div>
							</div>
							<div
								className={
									'ads-modal__content--container__campaigns--items__container ad-details'
								}
							>
								<div className='ads-modal__content--container__campaigns--items__container--item ad-detail'>
									<p className='ads-modal__content--container__campaigns--items__container--item key bold'>
										{t('ads visualization.headlines')}
									</p>
									<div className='ads-modal__content--container__campaigns--items__container--item value description'>
										{googleAdItem.headlines &&
											googleAdItem.headlines.length > 0 &&
											googleAdItem.headlines.map((headline: any, i: number) => {
												if (typeof headline !== 'string' && headline.text) {
													return <p key={i}>{headline.text}</p>
												} else {
													return <p key={i}>{headline}</p>
												}
											})}
									</div>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item ad-detail'>
									<p className='ads-modal__content--container__campaigns--items__container--item key bold'>
										{t('ads visualization.destination url')}
									</p>
									<p className='ads-modal__content--container__campaigns--items__container--item value'>
										{googleAdItem.finalUrl}
									</p>
								</div>
								<div className='ads-modal__content--container__campaigns--items__container--item ad-detail'>
									<p className='ads-modal__content--container__campaigns--items__container--item key bold'>
										{t('ads visualization.description')}
									</p>
									<div className='ads-modal__content--container__campaigns--items__container--item value description'>
										{googleAdItem.description &&
											googleAdItem.description.length > 0 &&
											googleAdItem.description.map((desc: any, i: number) => {
												if (typeof desc !== 'string' && desc.text) {
													return <p key={i}>{desc.text}</p>
												} else {
													return <p key={i}>{desc}</p>
												}
											})}
									</div>
								</div>
							</div>
							<Button
								className='button-light-blue ads-modal__content--container__campaigns--items__container--button'
								text={t('common.close')}
								onClick={() => googleAdItem.onClick(null)}
							/>
						</div>
					</div>
				</div>
			)
		}
		return null
	} catch (error) {
		return null
	}
}

export default GoogleAdsModal
