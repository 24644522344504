import {
	SUBSCRIBE_PLAN,
	UNSUBSCRIBE_PLAN,
	GET_PAYMENT_PLANS,
	GET_PAYMENT_PORTAL,
	GET_COUPON_CODE,
	FINALISE_PLAN,
	GET_PAYMENT_PLANS_SUCCESS,
} from './types'
import { API } from '../constants'
import { Action } from './audit'
import { PaymentPlanResponse } from '../reducers/payment'

type GetPaymentPlans = Action<
	typeof GET_PAYMENT_PLANS_SUCCESS,
	PaymentPlanResponse[]
>
export type PaymentActions = GetPaymentPlans

export type SubscribeData = {
	productId: string
	token: string
	billingDate: Date
	planId: string
	couponCode: string
}

export const getPaymentPlans = (): any => {
	return {
		type: GET_PAYMENT_PLANS,
		payload: {
			request: {
				method: 'GET',
				url: API.GET_PAYMENT_PLANS,
			},
		},
	}
}

export const subscribe = (data: SubscribeData): any => {
	return {
		type: SUBSCRIBE_PLAN,
		payload: {
			request: {
				method: 'POST',
				url: API.SUBSCRIBE_PLAN,
				data,
			},
		},
	}
}

export const finalise = (
	data: Pick<
		SubscribeData,
		'productId' | 'billingDate' | 'planId' | 'couponCode'
	>,
): any => {
	return {
		type: FINALISE_PLAN,
		payload: {
			request: {
				method: 'POST',
				url: API.FINALISE_PLAN,
				data,
			},
		},
	}
}

export const unsubscribe = (): any => {
	return {
		type: UNSUBSCRIBE_PLAN,
		payload: {
			request: {
				method: 'DELETE',
				url: API.UNSUBSCRIBE_PLAN,
			},
		},
	}
}

export const getPaymentPortal = (): any => {
	return {
		type: GET_PAYMENT_PORTAL,
		payload: {
			request: {
				method: 'GET',
				url: API.GET_PAYMENT_PORTAL,
			},
		},
	}
}

export const getCoupon = (data: Pick<SubscribeData, 'couponCode'>): any => {
	return {
		type: GET_COUPON_CODE,
		payload: {
			request: {
				method: 'POST',
				url: API.GET_COUPON_CODE,
				data,
			},
		},
	}
}
