import { Moment } from 'moment'
import {
	API,
	ESuggestionStatus,
	ETaskAttribute,
	ETaskCategory,
	ETaskType,
} from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import { Comment } from './comment'
import { GetProjectById, PatchModifyProject } from './project'
import {
	CREATE_CUSTOM_TASK,
	CREATE_CUSTOM_TASK_SUCCESS,
	CREATE_TASK,
	GENERATE_TASK_TRACKING_DATA,
	GET_ALL_TASKS,
	GET_ALL_TASKS_SUCCESS,
	RESET_TASK_STATE,
	SIGN_OUT_SUCCESS,
	UPDATE_TASK,
	UPDATE_TASK_ASSIGNEES,
	UPDATE_TASK_ASSIGNEES_SUCCESS,
	UPDATE_TASK_SUCCESS,
} from './types'

export interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P }
}
export interface ActionWithoutPayload<T> {
	readonly type: T
}

type GetAllTasks = Action<typeof GET_ALL_TASKS_SUCCESS, TaskItem[]>
type UpdateTask = Action<typeof UPDATE_TASK_SUCCESS, TaskItem>
type UpdateTaskAssignee = Action<typeof UPDATE_TASK_ASSIGNEES_SUCCESS, TaskItem>
type CreateCustomTask = Action<typeof CREATE_CUSTOM_TASK_SUCCESS, TaskItem>

type ResetTaskState = ActionWithoutPayload<typeof RESET_TASK_STATE>
export type SignOut = ActionWithoutPayload<typeof SIGN_OUT_SUCCESS>

export type TaskActions =
	| GetAllTasks
	| UpdateTask
	| ResetTaskState
	| SignOut
	| GetProjectById
	| PatchModifyProject
	| UpdateTaskAssignee
	| CreateCustomTask

export type TaskItem = {
	id: number
	status: ESuggestionStatus
	type: ETaskType
	taskCode: number
	comments: number | Comment[]
	startedAt: Moment | string | null
	completedAt: Moment | string | null
	createdAt: Date
	updatedAt: Date
	assignees: number[]
	title?: string
	description?: string
	category?: ETaskCategory
	dueDate?: Moment | string | null
	priority: number
	attributeType?: ETaskAttribute | null
	attributeId?: string | null
	trackingAttribute?: string | null
}

export const createTask = (projectId: number, data: Partial<TaskItem>): any => {
	const apiUri = replaceParamsUri(['projectId'], [projectId], API.CREATE_TASK)
	return {
		type: CREATE_TASK,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const createCustomTask = (
	projectId: number,
	data: Partial<TaskItem>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.CREATE_CUSTOM_TASK,
	)
	return {
		type: CREATE_CUSTOM_TASK,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const getAllTasks = (projectId: number): any => {
	const apiUri = replaceParamsUri(['projectId'], [projectId], API.GET_ALL_TASKS)
	return {
		type: GET_ALL_TASKS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const updateTaskById = (
	projectId: number,
	taskId: number,
	data: Partial<TaskItem>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'taskId'],
		[projectId, taskId],
		API.UPDATE_TASK_BY_ID,
	)
	return {
		type: UPDATE_TASK,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}

export const updateTaskAssignees = (
	taskId: number,
	data: Pick<TaskItem, 'assignees'>,
): any => {
	const apiUri = replaceParamsUri(
		['taskId'],
		[taskId],
		API.UPDATE_TASK_ASSIGNEES,
	)
	return {
		type: UPDATE_TASK_ASSIGNEES,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}

export const generateTaskTrackingData = (
	projectId: number,
	taskId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'taskId'],
		[projectId, taskId],
		API.GENERATE_TASK_TRACKING_DATA,
	)
	return {
		type: GENERATE_TASK_TRACKING_DATA,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const generateTrackingDataForPredictor = (
	projectId: number,
	keyword: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GENERATE_TRACKING_DATA_FOR_PREDICTOR,
	)
	return {
		type: GENERATE_TASK_TRACKING_DATA,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: { keyword },
			},
		},
	}
}

export const resetTaskState = (): any => {
	return {
		type: RESET_TASK_STATE,
	}
}
