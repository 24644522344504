import { AuditActions } from '../actions/audit'
import {
	GET_AUDITS_BY_PROJECT_ID_SUCCESS,
	GET_AUDIT_SUMMARY_BY_ID_SUCCESS,
	VIEW_RESOURCES_SUCCESS,
	GET_REDIRECT_CHAIN_BY_AUDIT_ID_SUCCESS,
	GET_PAGE_DETAILS_BY_AUDIT_ID_SUCCESS,
	RESET_AUDIT_STATE,
	SIGN_OUT,
	SET_SELECTED_AUDIT,
} from '../actions/types'

import {
	AuditCollection,
	AuditMainTags,
	AuditPageDetails,
	AuditRowObject,
	AuditSiteDetail,
	AuditSummaryResponse,
	getOptions,
	PageMetrics,
} from '../constants'

export type AuditState = {
	audit: AuditSummaryResponse
	auditRows: AuditCollection[]
	audits: AuditSummaryResponse[]
	pageDetails: AuditPageDetails
	resources: AuditRowObject[]
	siteDetails: AuditSiteDetail
	pageMetrics: PageMetrics | null
	checks: Record<string, number | string | boolean | never>
	mainPageTags: AuditMainTags
}

const initialState: AuditState = {
	audit: { auditScore: null, createdAt: null, id: null, requestId: null },
	auditRows: [],
	audits: [],
	pageDetails: {
		customJsResponse: [],
		duplicateTitle: [],
		duplicateMetaTags: [],
		brokenLinks: [],
		noTitle: [],
		noDescription: [],
		titleTooLong: [],
		noh1Tag: [],
		multipleh1Tag: [],
		noImageAlt: [],
		highLoadingTime: [],
		hasLinksToRedirects: [],
		orphanPage: [],
		httpsToHttpLinks: [],
		redirectChains: [],
	},
	resources: [],
	siteDetails: {
		crawl_progress: '',
		crawl_status: {
			max_crawl_pages: null,
			pages_in_queue: null,
			pages_crawled: null,
		},
		crawl_stop_reason: null,
		domain_info: {
			name: null,
			cms: null,
			ip: null,
			server: null,
			crawl_start: null,
			crawl_end: null,
			extended_crawl_status: null,
			ssl_info: {
				valid_certificate: null,
				certificate_issuer: null,
				certificate_subject: null,
				certificate_version: null,
				certificate_hash: null,
				certificate_expiration_date: null,
			},
			checks: {
				sitemap: null,
				robots_txt: null,
				start_page_deny_flag: null,
				ssl: null,
				test_canonicalization: null,
				test_hidden_server_signature: null,
				test_page_not_found: null,
				test_directory_browsing: null,
				test_https_redirect: null,
				http2: null,
			},
			total_pages: null,
			page_not_found_status_code: null,
			canonicalization_status_code: null,
			directory_browsing_status_code: null,
			main_domain: null,
		},
		page_metrics: {
			links_external: null,
			links_internal: null,
			duplicate_title: null,
			duplicate_description: null,
			duplicate_content: null,
			broken_links: null,
			broken_resources: null,
			links_relation_conflict: null,
			redirect_loop: null,
			onpage_score: null,
			checks: {
				canonical: null,
				duplicate_meta_tags: null,
				no_description: null,
				frame: null,
				large_page_size: null,
				irrelevant_description: null,
				irrelevant_meta_keywords: null,
				is_https: null,
				is_http: null,
				title_too_long: null,
				low_content_rate: null,
				small_page_size: null,
				no_h1_tag: null,
				recursive_canonical: null,
				no_favicon: null,
				no_image_alt: null,
				no_image_title: null,
				seo_friendly_url: null,
				seo_friendly_url_characters_check: null,
				seo_friendly_url_dynamic_check: null,
				seo_friendly_url_keywords_check: null,
				seo_friendly_url_relative_length_check: null,
				title_too_short: null,
				no_content_encoding: null,
				high_waiting_time: null,
				high_loading_time: null,
				is_redirect: null,
				is_broken: null,
				is_4xx_code: null,
				is_5xx_code: null,
				is_www: null,
				no_doctype: null,
				no_encoding_meta_tag: null,
				high_content_rate: null,
				low_character_count: null,
				high_character_count: null,
				low_readability_rate: null,
				irrelevant_title: null,
				deprecated_html_tags: null,
				duplicate_title_tag: null,
				no_title: null,
				flash: null,
				lorem_ipsum: null,
				has_misspelling: null,
				canonical_to_broken: null,
				canonical_to_redirect: null,
				has_links_to_redirects: null,
				is_orphan_page: null,
				has_meta_refresh_redirect: null,
				meta_charset_consistency: null,
				size_greater_than_3mb: null,
				has_html_doctype: null,
				https_to_http_links: null,
				has_render_blocking_resources: null,
				redirect_chain: null,
				canonical_chain: null,
				is_link_relation_conflict: null,
			},
		},
	},
	pageMetrics: null,
	checks: {},
	mainPageTags: {
		h1: [],
		h2: [],
		h3: [],
	},
}

export const auditReducer = (
	state = initialState,
	action: AuditActions,
): typeof initialState => {
	switch (action.type) {
		case GET_REDIRECT_CHAIN_BY_AUDIT_ID_SUCCESS: {
			const rows = [
				...state.auditRows,
				...getOptions<AuditPageDetails>({
					redirectChains: action.payload.data,
				} as AuditPageDetails),
			]
			return {
				...state,
				auditRows: rows,
			}
		}
		case GET_PAGE_DETAILS_BY_AUDIT_ID_SUCCESS: {
			const customJs = action.payload.data
				? action.payload.data.customJsResponse
				: null
			const mainPageTags = action.payload.data.mainPageTags
			const checksToUpdate = customJs
				? { ...state.checks, ...customJs[0] }
				: state.checks
			if (customJs) {
				delete action.payload.data.customJsResponse
			}
			if (mainPageTags) {
				delete action.payload.data.mainPageTags
			}
			return {
				...state,
				pageDetails: action.payload.data,
				auditRows: getOptions<Omit<AuditPageDetails, 'customJsResponse'>>(
					action.payload.data,
				),
				checks: checksToUpdate,
				mainPageTags: mainPageTags || state.mainPageTags,
			}
		}
		case SET_SELECTED_AUDIT:
			return { ...state, audit: action.payload.data }
		case GET_AUDITS_BY_PROJECT_ID_SUCCESS:
			return { ...state, audits: action.payload.data }
		case VIEW_RESOURCES_SUCCESS:
			return { ...state, resources: action.payload.data }
		case GET_AUDIT_SUMMARY_BY_ID_SUCCESS: {
			let checks = {
				...action.payload.data.page_metrics,
				...action.payload.data.domain_info,
			}
			if (action.payload.data.page_metrics) {
				checks = { ...checks, ...action.payload.data.page_metrics.checks }
			}
			if (action.payload.data.domain_info) {
				checks = {
					...checks,
					...action.payload.data.domain_info.checks,
					...action.payload.data.domain_info.ssl_info,
				}
			}
			return {
				...state,
				siteDetails: action.payload.data,
				pageMetrics: action.payload.data.page_metrics,
				checks: {
					...state.checks,
					...checks,
				},
			}
		}
		case SIGN_OUT:
		case RESET_AUDIT_STATE:
			return { ...initialState }
		default:
			return state
	}
}

export const auditSelectors = {
	getPageDetails: (state = initialState): AuditPageDetails => state.pageDetails,
	getResources: (state = initialState): AuditRowObject[] => state.resources,
	getSiteDetails: (state = initialState): AuditSiteDetail => state.siteDetails,
	getSiteMetrics: (state = initialState): PageMetrics | null =>
		state.pageMetrics,
	getChecks: (
		state = initialState,
	): Record<string, number | string | boolean | never> => state.checks,
	getAudits: (state = initialState): AuditSummaryResponse[] => state.audits,
	getAuditRows: (state = initialState): AuditCollection[] => state.auditRows,
	getAudit: (state = initialState): AuditSummaryResponse => state.audit,
	getMainTags: (state = initialState): AuditMainTags => state.mainPageTags,
}
