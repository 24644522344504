/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	API,
	AuditRowObject,
	AuditSiteDetail,
	AuditPageDetails,
	AuditSummaryResponse,
	AuditPageDetailObject,
} from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import { Action, ActionWithoutPayload } from './audit'
import {
	CREATE_COMMENT_BY_SUGGESTION_ID,
	CREATE_COMMENT_BY_SUGGESTION_ID_SUCCESS,
	CREATE_COMMENT_BY_TASK_ID,
	CREATE_COMMENT_BY_TASK_ID_SUCCESS,
	DELETE_COMMENT_BY_ID,
	GET_ALL_COMMENTS_BY_TYPE_AND_ID,
	GET_ALL_COMMENTS_BY_TYPE_AND_ID_SUCCESS,
	RESET_COMMENT_STATE,
	UPDATE_COMMENT_BY_ID,
	UPDATE_COMMENT_BY_ID_SUCCESS,
} from './types'

export type AuthorInfo = {
	id: number
	firstName: string
	lastName: string
	email: string
}

export type Comment = {
	id: number
	comment: string
	fileKey: string
	author: AuthorInfo
	createdAt: Date
	updatedAt: Date
}

export enum ECommentType {
	SUGGESTION = 'suggestion',
	TASK = 'task',
}

type GetAllCommentsById = Action<
	typeof GET_ALL_COMMENTS_BY_TYPE_AND_ID_SUCCESS,
	Comment[]
>
type CreateCommentByTaskId = Action<
	typeof CREATE_COMMENT_BY_TASK_ID_SUCCESS,
	Comment
>
type CreateCommentBySuggestionId = Action<
	typeof CREATE_COMMENT_BY_SUGGESTION_ID_SUCCESS,
	Comment
>
type UpdateCommentByCommentId = Action<
	typeof UPDATE_COMMENT_BY_ID_SUCCESS,
	Comment
>
type DeleteCommentByCommentId = Action<
	typeof UPDATE_COMMENT_BY_ID_SUCCESS,
	Comment
>

export type ResetComments = ActionWithoutPayload<typeof RESET_COMMENT_STATE>

export type CommentActions =
	| ResetComments
	| GetAllCommentsById
	| CreateCommentByTaskId
	| CreateCommentBySuggestionId
	| UpdateCommentByCommentId
	| DeleteCommentByCommentId

export const getAllCommentsByTypeAndId = (
	type: ECommentType,
	id: number,
	projectId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'type', 'itemId'],
		[projectId, type, id],
		API.GET_ALL_COMMENTS,
	)
	return {
		type: GET_ALL_COMMENTS_BY_TYPE_AND_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const createTaskComment = (
	comment: Partial<Comment>,
	id: number,
	projectId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'itemId'],
		[projectId, id],
		API.CREATE_TASK_COMMENT,
	)
	return {
		type: CREATE_COMMENT_BY_TASK_ID,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: comment,
			},
		},
	}
}
export const createSuggestionComment = (
	comment: Partial<Comment>,
	id: number,
	projectId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'itemId'],
		[projectId, id],
		API.CREATE_SUGGESTION_COMMENT,
	)
	return {
		type: CREATE_COMMENT_BY_SUGGESTION_ID,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: comment,
			},
		},
	}
}
export const updateCommentById = (
	comment: Partial<Comment>,
	commentId: number,
	projectId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'commentId'],
		[projectId, commentId],
		API.UPDATE_COMMENT_BY_ID,
	)
	return {
		type: UPDATE_COMMENT_BY_ID,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data: comment,
			},
		},
	}
}
export const deleteCommentById = (
	commentId: number,
	projectId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'commentId'],
		[projectId, commentId],
		API.DELETE_COMMENT_BY_ID,
	)
	return {
		type: DELETE_COMMENT_BY_ID,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUri,
			},
		},
	}
}

export const resetCommentState = (): any => {
	return {
		type: RESET_COMMENT_STATE,
	}
}
