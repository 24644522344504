import React from 'react'

type Props = {
	items: (JSX.Element | null)[]
	className?: string
}
export const MenuItemContainer = ({
	items = [],
	className,
}: Props): JSX.Element => {
	const styles = className
		? 'custom-menu-container'.concat(' ', className)
		: 'custom-menu-container'
	return <div className={styles}>{items}</div>
}
