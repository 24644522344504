/**
 * Error message component
 * Display error message as a banner
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import React, {
	FunctionComponent,
	PropsWithChildren,
	useEffect,
	useState,
} from 'react'
import { connect } from 'react-redux'
import ButtonText from '../../shared/components/ButtonText'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import InformationModal from './InformationModal'
import { intervalTime } from '../../constants'
import { errorActions } from '../../actions'

type ErrorMessageProps = {
	error: any
	clearErrorMessage: () => void
} & PropsWithChildren<WithTranslation>

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
	error,
	clearErrorMessage,
}) => {
	const { t } = useTranslation()

	useEffect(() => {
		window.setTimeout(() => {
			if (error.statusCodes.length) {
				clearErrorMessage()
			}
		}, intervalTime)
	}, [clearErrorMessage, error.statusCodes.length])
	const showErrorMessages = error.statusCodes.filter(
		(i: number) => String(i)[0] !== '3',
	)
	return (
		<>
			<InformationModal />
			{showErrorMessages.length ? (
				<div className='error-message-container'>
					<div className='icon'>
						<i className='material-icons'>info</i>
					</div>
					<div className='message'>
						{showErrorMessages.map((i: number) => (
							<p key={i}>{t(`message codes.${i}`)}</p>
						))}
					</div>
					<div className='button'>
						<ButtonText
							containerClassName={''}
							text={t('common.close')}
							onClick={() => {
								clearErrorMessage()
							}}
						/>
					</div>
				</div>
			) : null}
		</>
	)
}

export default connect(
	(state: any) => ({
		error: state.error,
	}),
	dispatch => ({
		clearErrorMessage: () => dispatch(errorActions.clearErrorMessage()),
	}),
)(withTranslation()(ErrorMessage))
