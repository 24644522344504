import { SignOut } from '../actions/audit'
import { PriceScalpingActions } from '../actions/price-scalping'
import {
	DELETE_PRODUCT_TRACKING_SUCCESS,
	GET_ALL_PRODUCT_PRICE_SUCCESS,
	SIGN_OUT,
	UPDATE_TRACKING_DATE_SUCCESS,
} from '../actions/types'
import { ETableOptions, PriceScalpingObj } from '../constants'

export type PriceScalpingState = {
	priceScalping: PriceScalpingObj[]
}

const initialState: PriceScalpingState = {
	priceScalping: [
		{
			id: -1,
			domain: '',
			productName: '',
			requestIdOfGetProductId: '',
			requestIdOfGetPriceList: '',
			status: 0,
			priceList: [],
			createdAt: new Date(),
			timeInterval: 0,
			dateToRetrack: null,
		},
	],
}

export const priceScalpingReducer = (
	state = initialState,
	action: PriceScalpingActions | SignOut,
): typeof initialState => {
	switch (action.type) {
		case GET_ALL_PRODUCT_PRICE_SUCCESS:
			return {
				...state,
				priceScalping: [...action.payload.data],
			}
		case DELETE_PRODUCT_TRACKING_SUCCESS:
			return {
				...state,
				priceScalping: [...action.payload.data],
			}
		case SIGN_OUT:
			return initialState
		default:
			return state
	}
}

export const priceScalpingSelector = {
	getPriceScalping: (
		state = initialState,
	): PriceScalpingState['priceScalping'] => state.priceScalping,
}
