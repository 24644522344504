/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import {
	formatGoogleAnalyticsSessions,
	formatGoogleAnalyticsGoalConversionRateAll,
	formatFacebookPageData,
	formatFacebookPageReactions,
	formatFacebookOnlineTime,
	formatGoogleSearchDataDate,
	formatGoogleSearchDataTable,
	formatFacebookAgeGender,
	formatGoogleAdsChartData,
	formatgoogleAdsAdGroupTable,
	formatgoogleAdsCampaignTable,
	patchSuggestion,
	// formatGoogleAnalyticsDeviceSessions,
	formatGoogleAnalyticsDeviceUsers,
	patchAssignees,
	mapSuggestions,
} from '../helpers/dataTransformer'
import {
	GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE,
	GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE_SUCCESS,
	GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE_FAIL,
	GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE,
	GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE_SUCCESS,
	GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE_FAIL,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_SUCCESS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_FAIL,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE_SUCCESS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE_FAIL,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE_SUCCESS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE_FAIL,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS_SUCCESS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS_FAIL,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS_SUCCESS,
	GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS_FAIL,
	GET_GOOGLE_ANALYTIC_DATA_SESSIONS,
	GET_GOOGLE_ANALYTIC_DATA_SESSIONS_SUCCESS,
	GET_GOOGLE_ANALYTIC_DATA_SESSIONS_FAIL,
	GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL,
	GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL_SUCCESS,
	GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL_FAIL,
	GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS,
	GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS_SUCCESS,
	GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS_FAIL,
	GET_GOOGLE_ANALYTIC_PAGE,
	GET_GOOGLE_ANALYTIC_PAGE_SUCCESS,
	GET_GOOGLE_ANALYTIC_PAGE_FAIL,
	GET_GOOGLE_ADS_CHART_DATA,
	GET_GOOGLE_ADS_CHART_DATA_SUCCESS,
	GET_GOOGLE_ADS_CHART_DATA_FAIL,
	GET_GOOGLE_ADS_AD_GROUP_DATA,
	GET_GOOGLE_ADS_AD_GROUP_DATA_SUCCESS,
	GET_GOOGLE_ADS_AD_GROUP_DATA_FAIL,
	GET_GOOGLE_ADS_CAMPAIGN_DATA,
	GET_GOOGLE_ADS_CAMPAIGN_DATA_SUCCESS,
	GET_GOOGLE_ADS_CAMPAIGN_DATA_FAIL,
	GENERATE_SUGGESTION_BY_TYPE,
	GET_SUGGESTION_BY_TYPE,
	GENERATE_SUGGESTION_BY_TYPE_FAIL,
	GET_SUGGESTION_BY_TYPE_FAIL,
	GENERATE_SUGGESTION_BY_TYPE_SUCCESS,
	GET_SUGGESTION_BY_TYPE_SUCCESS,
	SIGN_OUT,
	SIGN_IN_SUCCESS,
	SIGN_UP_SUCCESS,
	RESET_LOADERS,
	UPDATE_SUGGESTION_STATUS,
	UPDATE_SUGGESTION_STATUS_SUCCESS,
	UPDATE_SUGGESTION_STATUS_FAIL,
	GET_SUGGESTION_COUNT_SUCCESS,
	RESET_DASHBOARD_REDUCER,
	RESET_PROJECT_STATE,
	GET_PROJECT_BY_ID,
	CREATE_PROJECT,
	UPDATE_SUGGESTION_ASSIGNEES_SUCCESS,
	GET_GOOGLE_ANALYTICS_COMPARISON_DATA_SUCCESS,
	UPDATE_CHAT_STREAM,
	RESET_CHAT_STREAM,
} from '../actions/types'
import {
	ESuggestionStatus,
	ESuggestionType,
	ETaskPriority,
	IProject,
	SuggestionStatus,
} from '../constants'
import { ProjectsActions } from '../actions/project'
import { DashboardActions } from '../actions/dashboard'

export type SuggestionCount = {
	seo: number
	ads: number
	some: number
	cro: number
}

export type SuggestionSource = {
	id: number
	keyword: string
	keywordValue?: Record<string, any>
	description: string[]
}

export type Suggestion = {
	id: number
	status: ESuggestionStatus
	type: ESuggestionType
	subType: ESuggestionType
	title: string
	potentialValue: number | null
	duration: number | null
	imageFileNameWhenCreated: string | null
	imageFileNameWhenInProgress: string | null
	imageFileNameWhenDone: string | null
	measuredFrom: string
	measuredTo: string
	priority: ETaskPriority
	dueDate: string
	createdAt: string
	updatedAt: string
	startedAt?: string
	completedAt?: string
	project: IProject
	source: SuggestionSource
	assignees: number[]
	comments: number
	imageList?: { type: string; url: string; createdDate: string }[]
}

export type GoogleAnalyticsGoalConversionRateAll = {
	date: Date
	conversionRate: number
}

export type GoogleAnalyticsPageViews = {
	device: 'mobile' | 'tablet' | 'desktop'
	users: number
}

export type GoogleAnalyticsSessions = {
	date: Date
	sessions: number
}
export type ChatItem = {
	role: 'user' | 'assistant' | 'system'
	content: string
}

type DashboardState = {
	loading: {
		googleAnalyticsSessions?: boolean
		googleAnalyticsGoalConversionRateAll?: boolean
		googleAnalyticsPageViews?: boolean
		googleAnalyticTable?: boolean
		facebookPageFans?: boolean
		facebookPageFansOnline?: boolean
		facebookPageFansGenderAge?: boolean
		facebookPageReactions?: boolean
		facebookPageActions?: boolean
		googleSearchDataDate?: boolean
		googleSearchDataTable?: boolean
		googleAdsChartData?: boolean
		googleAdsCampaignTable?: boolean
		googleAdsAdGroupTable?: boolean
		overviewSuggestionData?: boolean
	}
	count: {
		[key: number]: SuggestionCount
	}
	suggestions: {
		[key: number]: Suggestion[]
	}
	facebookPageActions: Record<string, unknown>
	facebookPageActions2Weeks: Record<string, unknown>
	facebookPageFans: Record<string, unknown>
	facebookPageFans2Weeks: Record<string, unknown>
	facebookPageFansOnline: Record<string, unknown>
	facebookPageReactions: any[]
	facebookPageReactions2Weeks: any[]
	facebookPageReactionsLike: any[]
	facebookPageReactionsLike2Weeks: any[]
	googleAnalyticsGoalConversionRateAll: GoogleAnalyticsGoalConversionRateAll[]
	googleAnalyticsPageViews: GoogleAnalyticsPageViews[]
	googleAnalyticsSessions: GoogleAnalyticsSessions[]
	//TODO create type
	googleAnalyticsComparisonData: any
	googleSearchDataTable: any
	googleAnalyticTable: any
	googleSearchDataDate: any
	facebookPageFansGenderAge: any
	googleAdsChartData: any
	googleAdsAdGroupTable: any
	googleAdsCampaignTable: any
	overviewSuggestionData: any
	chatStream: ChatItem[]
}

const initialState: DashboardState = {
	loading: {},
	count: {
		[SuggestionStatus.pending]: { seo: 0, ads: 0, some: 0, cro: 0 },
		[SuggestionStatus.active]: { seo: 0, ads: 0, some: 0, cro: 0 },
		[SuggestionStatus.archived]: { seo: 0, ads: 0, some: 0, cro: 0 },
		[SuggestionStatus.done]: { seo: 0, ads: 0, some: 0, cro: 0 },
		[SuggestionStatus.not_useful]: { seo: 0, ads: 0, some: 0, cro: 0 },
	},
	suggestions: {
		[SuggestionStatus.pending]: [],
		[SuggestionStatus.active]: [],
		[SuggestionStatus.archived]: [],
		[SuggestionStatus.done]: [],
		[SuggestionStatus.not_useful]: [],
	},
	facebookPageActions: {},
	facebookPageActions2Weeks: {},
	facebookPageFans: {},
	facebookPageFans2Weeks: {},
	facebookPageFansOnline: {},
	facebookPageReactions: [],
	facebookPageReactions2Weeks: [],
	facebookPageReactionsLike: [],
	facebookPageReactionsLike2Weeks: [],
	googleAnalyticsGoalConversionRateAll: [],
	googleAnalyticsPageViews: [],
	googleAnalyticsSessions: [],
	googleSearchDataTable: null,
	googleAnalyticTable: null,
	googleAnalyticsComparisonData: {
		googleAnalyticsForLastMonth: [],
		googleAnalyticsForThisMonth: [],
		conversionData: {
			conversionThisMonth: {
				conversion: 0,
				rate: 0,
			},
			conversionLastMonth: {
				conversion: 0,
				rate: 0,
			},

			conversionComparisonWithLastMonth: {
				conversion: 0,
				rate: 0,
			},
		},
	},
	googleSearchDataDate: null,
	facebookPageFansGenderAge: null,
	googleAdsChartData: null,
	googleAdsAdGroupTable: null,
	googleAdsCampaignTable: null,
	overviewSuggestionData: null,
	chatStream: [],
}

export const dashboardReducer = (
	state = initialState,
	action: ProjectsActions | DashboardActions,
): typeof initialState => {
	// const action.payload.data = action.payload && action.payload.data
	// const action.payload.data.values = action.payload.data && action.payload.data.values
	switch (action.type) {
		case RESET_LOADERS:
			return {
				...state,
				loading: {
					...state.loading,
					googleAnalyticsSessions: false,
					googleAnalyticsGoalConversionRateAll: false,
					googleAnalyticsPageViews: false,
					googleAnalyticTable: false,
					facebookPageFans: false,
					facebookPageFansOnline: false,
					facebookPageFansGenderAge: false,
					facebookPageReactions: false,
					facebookPageActions: false,
					googleSearchDataDate: false,
					googleSearchDataTable: false,
					googleAdsChartData: false,
					googleAdsCampaignTable: false,
					googleAdsAdGroupTable: false,
					overviewSuggestionData: false,
				},
			}
		case CREATE_PROJECT:
		case RESET_PROJECT_STATE:
		case GET_PROJECT_BY_ID:
		case SIGN_OUT:
		case SIGN_IN_SUCCESS:
		case SIGN_UP_SUCCESS:
			return { ...initialState }
		case GET_GOOGLE_ANALYTIC_PAGE:
			return {
				...state,
				loading: { ...state.loading, googleAnalyticTable: true },
			}
		case GET_GOOGLE_ANALYTIC_PAGE_FAIL:
			return {
				...state,
				loading: { ...state.loading, googleAnalyticTable: false },
			}
		case GET_GOOGLE_ANALYTIC_PAGE_SUCCESS:
			return {
				...state,
				googleAnalyticTable: action.payload.data,
				loading: { ...state.loading, googleAnalyticTable: false },
			}
		case GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE:
			return {
				...state,
				loading: { ...state.loading, googleSearchDataTable: true },
			}
		case GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE_FAIL:
			return {
				...state,
				loading: { ...state.loading, googleSearchDataTable: false },
			}
		case GET_GOOGLE_SEARCH_CONSOLE_DATA_PAGE_SUCCESS:
			return {
				...state,
				googleSearchDataTable: action.payload.data
					? formatGoogleSearchDataTable(action.payload.data)
					: null,
				loading: { ...state.loading, googleSearchDataTable: false },
			}
		case GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE:
			return {
				...state,
				loading: { ...state.loading, googleSearchDataDate: true },
			}
		case GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE_FAIL:
			return {
				...state,
				loading: { ...state.loading, googleSearchDataDate: false },
			}
		case GET_GOOGLE_SEARCH_CONSOLE_DATA_DATE_SUCCESS:
			return {
				...state,
				googleSearchDataDate: action.payload.data
					? formatGoogleSearchDataDate(action.payload.data)
					: null,
				loading: { ...state.loading, googleSearchDataDate: false },
			}
		case GET_GOOGLE_ANALYTIC_DATA_SESSIONS:
			return {
				...state,
				loading: { ...state.loading, googleAnalyticsSessions: true },
			}
		case GET_GOOGLE_ANALYTIC_DATA_SESSIONS_FAIL:
			return {
				...state,
				googleAnalyticsSessions: [],
				loading: { ...state.loading, googleAnalyticsSessions: false },
			}
		case GET_GOOGLE_ANALYTIC_DATA_SESSIONS_SUCCESS:
			return {
				...state,
				googleAnalyticsSessions: formatGoogleAnalyticsSessions(
					action.payload.data,
				),
				loading: { ...state.loading, googleAnalyticsSessions: false },
			}
		case GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL:
			return {
				...state,
				loading: {
					...state.loading,
					googleAnalyticsGoalConversionRateAll: true,
				},
			}
		case GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL_FAIL:
			return {
				...state,
				googleAnalyticsGoalConversionRateAll: [],
				loading: {
					...state.loading,
					googleAnalyticsGoalConversionRateAll: false,
				},
			}
		case GET_GOOGLE_ANALYTIC_DATA_GOAL_CONVERSION_RATE_ALL_SUCCESS:
			return {
				...state,
				googleAnalyticsGoalConversionRateAll:
					formatGoogleAnalyticsGoalConversionRateAll(action.payload.data),
				loading: {
					...state.loading,
					googleAnalyticsGoalConversionRateAll: false,
				},
			}
		case GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS:
			return {
				...state,
				loading: { ...state.loading, googleAnalyticsPageViews: true },
			}
		case GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS_FAIL:
			return {
				...state,
				googleAnalyticsPageViews: [],
				loading: { ...state.loading, googleAnalyticsPageViews: false },
			}
		case GET_GOOGLE_ANALYTIC_DATA_PAGE_VIEWS_SUCCESS:
			return {
				...state,
				googleAnalyticsPageViews: formatGoogleAnalyticsDeviceUsers(
					action.payload.data,
				),
				loading: { ...state.loading, googleAnalyticsPageViews: false },
			}
		case GET_GOOGLE_ANALYTICS_COMPARISON_DATA_SUCCESS:
			return {
				...state,
				googleAnalyticsComparisonData:
					action.payload.data !== ''
						? action.payload.data
						: state.googleAnalyticsComparisonData,
			}

		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS:
			return { ...state, loading: { ...state.loading, facebookPageFans: true } }
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_FAIL:
			return {
				...state,
				loading: { ...state.loading, facebookPageFans: false },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_SUCCESS:
			return {
				...state,
				facebookPageFans: formatFacebookPageData(action.payload.data.values),
				facebookPageFans2Weeks: formatFacebookPageData(
					action.payload.data.values,
					'2weeks',
				),
				loading: { ...state.loading, facebookPageFans: false },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE:
			return {
				...state,
				loading: { ...state.loading, facebookPageFansOnline: true },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE_FAIL:
			return {
				...state,
				loading: { ...state.loading, facebookPageFansOnline: false },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_ONLINE_SUCCESS:
			return {
				...state,
				facebookPageFansOnline: formatFacebookOnlineTime(
					action.payload.data.values,
				),
				loading: { ...state.loading, facebookPageFansOnline: false },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE:
			return {
				...state,
				loading: { ...state.loading, facebookPageFansGenderAge: true },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE_FAIL:
			return {
				...state,
				loading: { ...state.loading, facebookPageFansGenderAge: false },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_FANS_GENDER_AGE_SUCCESS:
			return {
				...state,
				facebookPageFansGenderAge: formatFacebookAgeGender(
					action.payload.data.values,
				),
				loading: { ...state.loading, facebookPageFansGenderAge: false },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS:
			return {
				...state,
				loading: { ...state.loading, facebookPageActions: true },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS_FAIL:
			return {
				...state,
				loading: { ...state.loading, facebookPageActions: false },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_ACTIONS_SUCCESS:
			return {
				...state,
				facebookPageActions: formatFacebookPageData(action.payload.data.values),
				facebookPageActions2Weeks: formatFacebookPageData(
					action.payload.data.values,
					'2weeks',
				),
				loading: { ...state.loading, facebookPageActions: false },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS:
			return {
				...state,
				loading: { ...state.loading, facebookPageReactions: true },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS_FAIL:
			return {
				...state,
				loading: { ...state.loading, facebookPageReactions: false },
			}
		case GET_FACEBOOK_PAGE_INSIGHT_PAGE_REACTIONS_SUCCESS:
			return {
				...state,
				facebookPageReactions: formatFacebookPageReactions(
					action.payload.data.values,
				),
				facebookPageReactionsLike: formatFacebookPageReactions(
					action.payload.data.values,
					'like',
				),
				facebookPageReactions2Weeks: formatFacebookPageReactions(
					action.payload.data.values,
					'',
					'2weeks',
				),
				facebookPageReactionsLike2Weeks: formatFacebookPageReactions(
					action.payload.data.values,
					'like',
					'2weeks',
				),
				loading: { ...state.loading, facebookPageReactions: false },
			}
		case GET_GOOGLE_ADS_CHART_DATA:
			return {
				...state,
				loading: { ...state.loading, googleAdsChartData: true },
			}
		case GET_GOOGLE_ADS_CHART_DATA_FAIL:
			return {
				...state,
				loading: { ...state.loading, googleAdsChartData: false },
			}
		case GET_GOOGLE_ADS_CHART_DATA_SUCCESS:
			return {
				...state,
				googleAdsChartData: action.payload.data
					? formatGoogleAdsChartData(action.payload.data)
					: null,
				loading: { ...state.loading, googleAdsChartData: false },
			}
		case GET_GOOGLE_ADS_AD_GROUP_DATA:
			return {
				...state,
				loading: { ...state.loading, googleAdsAdGroupTable: true },
			}
		case GET_GOOGLE_ADS_AD_GROUP_DATA_FAIL:
			return {
				...state,
				loading: { ...state.loading, googleAdsAdGroupTable: false },
			}
		case GET_GOOGLE_ADS_AD_GROUP_DATA_SUCCESS:
			return {
				...state,
				googleAdsAdGroupTable: action.payload.data
					? formatgoogleAdsAdGroupTable(action.payload.data)
					: null,
				loading: { ...state.loading, googleAdsAdGroupTable: false },
			}
		case GET_GOOGLE_ADS_CAMPAIGN_DATA:
			return {
				...state,
				loading: { ...state.loading, googleAdsCampaignTable: true },
			}
		case GET_GOOGLE_ADS_CAMPAIGN_DATA_FAIL:
			return {
				...state,
				loading: { ...state.loading, googleAdsCampaignTable: false },
			}
		case GET_GOOGLE_ADS_CAMPAIGN_DATA_SUCCESS:
			return {
				...state,
				googleAdsCampaignTable: action.payload.data
					? formatgoogleAdsCampaignTable(action.payload.data)
					: null,
				loading: { ...state.loading, googleAdsCampaignTable: false },
			}
		case GENERATE_SUGGESTION_BY_TYPE:
		case GET_SUGGESTION_BY_TYPE:
			return {
				...state,
				loading: { ...state.loading, overviewSuggestionData: true },
			}
		case GENERATE_SUGGESTION_BY_TYPE_FAIL:
		case GET_SUGGESTION_BY_TYPE_FAIL:
			return {
				...state,
				overviewSuggestionData: [],
				loading: { ...state.loading, overviewSuggestionData: false },
			}
		case GENERATE_SUGGESTION_BY_TYPE_SUCCESS:
			return {
				...state,
				loading: { ...state.loading, overviewSuggestionData: false },
			}
		case UPDATE_SUGGESTION_STATUS:
			return {
				...state,
				loading: { ...state.loading, overviewSuggestionData: true },
			}
		case UPDATE_SUGGESTION_STATUS_SUCCESS:
			return {
				...state,
				loading: { ...state.loading, overviewSuggestionData: false },
				suggestions: patchSuggestion(state.suggestions, action.payload.data),
			}
		case UPDATE_SUGGESTION_STATUS_FAIL:
			return {
				...state,
				loading: { ...state.loading, overviewSuggestionData: false },
			}
		case GET_SUGGESTION_COUNT_SUCCESS:
			return {
				...state,
				count: action.payload.data,
				loading: { ...state.loading },
			}
		case GET_SUGGESTION_BY_TYPE_SUCCESS: {
			const suggestionStatus =
				Array.isArray(action.payload.data) && action.payload.data.length
					? action.payload.data.every(
							suggestion =>
								suggestion.status ===
								action.payload.data[action.payload.data.length - 1].status,
					  )
					: null
			if (suggestionStatus) {
				return {
					...state,
					suggestions: {
						...state.suggestions,
						[action.payload.data[0].status]: action.payload.data,
					},
					loading: { ...state.loading, overviewSuggestionData: false },
				}
			} else {
				return {
					...state,
					suggestions: mapSuggestions(action.payload.data),
					loading: { ...state.loading, overviewSuggestionData: false },
				}
			}
		}
		case UPDATE_SUGGESTION_ASSIGNEES_SUCCESS: {
			return {
				...state,
				loading: { ...state.loading, overviewSuggestionData: false },
				suggestions: patchAssignees(state.suggestions, action.payload.data),
			}
		}
		case UPDATE_CHAT_STREAM: {
			return {
				...state,
				chatStream: [...action.payload.data],
			}
		}
		case RESET_CHAT_STREAM: {
			return {
				...state,
				chatStream: [],
			}
		}
		case RESET_DASHBOARD_REDUCER: {
			return { ...initialState }
		}
		default:
			return state
	}
}

export const dashboardSelectors = {
	getLoading: (state = initialState): boolean => {
		const values = Object.values(state.loading)
		return values.length > 0 && values.includes(true)
	},
	getChart: (state = initialState, chartName: keyof DashboardState): any =>
		state[chartName],
	getAllDashboardCharts: (state = initialState): DashboardState => state,
	getSuggestions: (
		state = initialState,
	): {
		[key: number]: Suggestion[]
	} => state.suggestions,
	getAllSuggestions: (state = initialState): any => [
		...state.suggestions[SuggestionStatus.active],
		...state.suggestions[SuggestionStatus.archived],
		...state.suggestions[SuggestionStatus.done],
		...state.suggestions[SuggestionStatus.not_useful],
		...state.suggestions[SuggestionStatus.pending],
	],
	getActiveSuggestions: (state = initialState): Suggestion[] =>
		state.suggestions[SuggestionStatus.active],
	getArchivedSuggestions: (state = initialState): Suggestion[] =>
		state.suggestions[SuggestionStatus.archived],
	getDoneSuggestions: (state = initialState): Suggestion[] =>
		state.suggestions[SuggestionStatus.done],
	getNotUsefulSuggestions: (state = initialState): Suggestion[] =>
		state.suggestions[SuggestionStatus.not_useful],
	getPendingSuggestions: (state = initialState): Suggestion[] =>
		state.suggestions[SuggestionStatus.pending],
	getSuggestionCount: (
		state = initialState,
	): {
		[key: number]: SuggestionCount
	} => state.count,
	getSuggestionsForTaskView: (
		state = initialState,
	): {
		todoSuggestions: Suggestion[]
		resolvedSuggestions: Suggestion[]
		ignoredSuggestions: Suggestion[]
		pendingSuggestions: Suggestion[]
	} => {
		return {
			todoSuggestions: state.suggestions[SuggestionStatus.active],
			resolvedSuggestions: state.suggestions[SuggestionStatus.done],
			ignoredSuggestions: state.suggestions[SuggestionStatus.not_useful],
			pendingSuggestions: state.suggestions[SuggestionStatus.pending],
		}
	},
	googleAnalyticsComparisonData: (state = initialState): any =>
		state.googleAnalyticsComparisonData,
	chatStream: (state = initialState): any => state.chatStream,
}
