/**
 * Protected route
 * Only let user access the route if user has been authenticated
 * Otherwise redirect user to login page
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { ROUTER_PATH } from '../../../constants'
import { useSelector } from 'react-redux'
import { userSelectors } from '../../../reducers'

// Wrap title and meta data to Component
const withHelmet = (component, title) => (
	<>
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={`Dashboa | ${title}`} />
		</Helmet>
		{component}
	</>
)

const ProtectedRoute = ({
	// eslint-disable-next-line @typescript-eslint/naming-convention
	component: Component,
	title,
	unauthenticatedRedirect = ROUTER_PATH.LOGIN,
	...rest
}) => {
	const { user } = useSelector(state => state)
	const isAuthenticated = userSelectors.isAuthenticated(user)
	if (isAuthenticated === false) {
		return <Redirect to={unauthenticatedRedirect} />
	}

	return (
		<Route
			{...rest}
			render={props => withHelmet(<Component {...props} />, title)}
		/>
	)
}

export default ProtectedRoute
