import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import {
	Trans,
	useTranslation,
	WithTranslation,
	withTranslation,
} from 'react-i18next'
import { connect } from 'react-redux'
import {
	EGenerateSuggestionStatuses,
	ESubscriptionLevel,
	IDataSource,
	IProject,
	isAccessibleToBulkContentCreation,
	ROUTER_PATH,
} from '../../constants'
import { createBrowserHistory } from 'history'
import { history, projectSelectors, userSelectors } from '../../reducers'
import moment from 'moment'
import { checkDataSource, getActiveDataSource } from '../../helpers/fnHelpers'
import { projectActions } from '../../actions'
import { isArray } from 'lodash'
import { ProjectState } from '../../reducers/project'
import { Company, User } from '../../reducers/user'

type InformationBarProps = {
	user: User
	currentProject: IProject
	company: Company
	checkGenerationStatus: (projectId: number) => Promise<ProjectState>
} & PropsWithChildren<WithTranslation>

const InformationBar: FC<InformationBarProps> = ({
	user,
	currentProject,
	company,
	checkGenerationStatus,
}: InformationBarProps) => {
	useEffect(() => {
		const interval = setInterval(async () => {
			await checkGenerationStatus(currentProject.id).then((res: any) => {
				if (
					res &&
					isArray(res.payload.data) &&
					res.payload.data.length > 0 &&
					res.payload.data.every(
						(generate: any) =>
							generate.status === EGenerateSuggestionStatuses.GENERATED ||
							generate.status === EGenerateSuggestionStatuses.FAIL,
					)
				) {
					clearInterval(interval)
				}
			})
		}, 120000)
		return () => {
			clearInterval(interval)
		}
	}, [currentProject.id])

	const dataSources = currentProject && currentProject.dataSources
	const dataSource = getActiveDataSource(dataSources)
	const currentRoute = createBrowserHistory().location.pathname
	const usedRoutes = [
		ROUTER_PATH.ADS_VISUALISATION,
		ROUTER_PATH.KEYWORD_PLANNER,
		ROUTER_PATH.KEYWORD_RANKING,
		ROUTER_PATH.TASKS,
		ROUTER_PATH.AUDIT,
		ROUTER_PATH.OVERVIEW,
		ROUTER_PATH.POST_SCHEDULER_NEW_POST,
		ROUTER_PATH.POST_SCHEDULER,
		ROUTER_PATH.AI_CONTENT_IN_BULK,
		ROUTER_PATH.SUBSCRIPTION,
		ROUTER_PATH.AI_CONTENT_CREATOR,
	]
	const { t } = useTranslation()
	if (!usedRoutes.includes(currentRoute)) {
		return null
	}
	const arr = [ESubscriptionLevel.NOT_SUBSCRIBED, ESubscriptionLevel.TRIAL]
	const facebookAccountExists = Boolean(
		currentProject.dataSources.filter(
			(datasource: IDataSource) => checkDataSource(datasource).FACEBOOK_PAGE,
		).length,
	)
	const linkedinAccountExists = Boolean(
		currentProject.dataSources.filter(
			(datasource: IDataSource) => checkDataSource(datasource).LINKEDIN_PAGE,
		).length,
	)
	const googleAdsConnected = !!dataSource && !!dataSource.googleAdsCustomerId

	const renderSuggestionStatus = () => {
		if (
			currentProject.generateSuggestionStatus.length > 0 &&
			currentProject.generateSuggestionStatus.some(
				(generate: any) =>
					generate.status === EGenerateSuggestionStatuses.GENERATING,
			)
		) {
			let render = ''
			currentProject.generateSuggestionStatus.map(
				(suggestionStatus: any, index: number) => {
					const textToShow = `${suggestionStatus.type.toUpperCase()} ${t(
						'common.tasks',
					)}: ${t(
						`common.${EGenerateSuggestionStatuses[suggestionStatus.status]}`,
					)}${
						index !== currentProject.generateSuggestionStatus.length - 1
							? ' | '
							: ''
					}`
					render = render.concat(textToShow)
				},
			)
			return render
		} else {
			return null
		}
	}
	const suggestionStatusText = renderSuggestionStatus()
	const renderInfo = (route: string) => {
		switch (route) {
			case ROUTER_PATH.ADS_VISUALISATION:
				return t(
					'infobar.ads visualization is disabled until you upgrade your plan',
				)
			case ROUTER_PATH.KEYWORD_PLANNER:
				return t(
					'infobar.keyword planner is disabled until you upgrade your plan',
				)
			case ROUTER_PATH.KEYWORD_RANKING:
				return t(
					'infobar.keyword ranking is disabled until you upgrade your plan',
				)
			case ROUTER_PATH.AUDIT:
				return t(
					'infobar.technical audit is disabled until you upgrade your plan',
				)
			case ROUTER_PATH.TASKS:
				return t('infobar.tasks is disabled until you upgrade your plan')
			case ROUTER_PATH.OVERVIEW:
				return <Trans t={t} i18nKey={'infobar.subscribe'} />
			case ROUTER_PATH.POST_SCHEDULER_NEW_POST:
				return t(
					'infobar.post scheduler is disabled until you upgrade your plan',
				)
			case ROUTER_PATH.AI_CONTENT_CREATOR:
				return t(
					'infobar.content creator is disabled until you upgrade your plan',
				)
			default:
				return null
		}
	}
	const renderWarning = (route: string) => {
		switch (route) {
			case ROUTER_PATH.ADS_VISUALISATION:
				if (googleAdsConnected) {
					return null
				} else {
					return t('infobar.please connect your google Ads to use this feature')
				}
			case ROUTER_PATH.KEYWORD_PLANNER:
				return null
			case ROUTER_PATH.KEYWORD_RANKING:
				return null
			case ROUTER_PATH.AUDIT:
				return null
			case ROUTER_PATH.TASKS:
				return null
			case ROUTER_PATH.OVERVIEW:
				return null
			case ROUTER_PATH.POST_SCHEDULER:
			case ROUTER_PATH.POST_SCHEDULER_NEW_POST:
				if (facebookAccountExists || linkedinAccountExists) {
					return null
				} else {
					return t(
						'infobar.post scheduler is disabled because no facebook or linkedin',
					)
				}
			case ROUTER_PATH.AI_CONTENT_IN_BULK:
				if (isAccessibleToBulkContentCreation(company.subscriptionLevel)) {
					return null
				} else {
					return t(
						'infobar.content creator is disabled until you upgrade your plan',
					)
				}
			default:
				return null
		}
	}

	if (arr.includes(company.subscriptionLevel)) {
		if (company.subscriptionLevel === ESubscriptionLevel.TRIAL) {
			return (
				<>
					{suggestionStatusText ? (
						<div className='information-bar-warning-version'>
							<p>{suggestionStatusText}</p>
						</div>
					) : null}
					<div
						className='information-bar-trial-version'
						onClick={() => history.push(ROUTER_PATH.SUBSCRIPTION)}
					>
						{moment().isBefore(company.trialEndDate) ? (
							<>
								<p>
									{currentRoute === ROUTER_PATH.OVERVIEW
										? t('infobar.dashboard trial text')
										: t('infobar.this feature will be disabled')}
								</p>
								<p>{`${t('infobar.your trial ends', {
									days: moment(company.trialEndDate).diff(moment(), 'day'),
								})}`}</p>
							</>
						) : (
							<>
								<p>
									{t(
										'infobar.create a project in order to be able to use this feature',
									)}
								</p>
								<p>
									{t(
										'infobar.your trial will start once you create your first project',
									)}
								</p>
							</>
						)}
					</div>
					{renderWarning(currentRoute) ? (
						<div
							className='information-bar-warning-version'
							onClick={() => history.push(ROUTER_PATH.PROJECT_SETTINGS)}
						>
							<p>{renderWarning(currentRoute)}</p>
						</div>
					) : null}
				</>
			)
		} else {
			return (
				<>
					<div
						className='information-bar-basic-version'
						onClick={() => history.push(ROUTER_PATH.SUBSCRIPTION)}
					>
						<p>{renderInfo(currentRoute)}</p>
					</div>
					{renderWarning(currentRoute) ? (
						<div
							className='information-bar-warning-version'
							onClick={() => history.push(ROUTER_PATH.PROJECT_SETTINGS)}
						>
							<p>{renderWarning(currentRoute)}</p>
						</div>
					) : null}
				</>
			)
		}
	} else {
		return (
			<>
				{suggestionStatusText ? (
					<div className='information-bar-warning-version'>
						<p>{suggestionStatusText}</p>
					</div>
				) : null}
				{renderWarning(currentRoute) ? (
					<div
						className='information-bar-warning-version'
						onClick={() => history.push(ROUTER_PATH.PROJECT_SETTINGS)}
					>
						<p>{renderWarning(currentRoute)}</p>
					</div>
				) : null}
			</>
		)
	}
}

export default connect(
	(state: any) => ({
		user: userSelectors.getUser(state.user),
		company: userSelectors.getUserCompany(state.user),
		currentProject: projectSelectors.getProject(state.project),
	}),
	dispatch => ({
		checkGenerationStatus: (projectId: number) =>
			dispatch(projectActions.checkGenerationStatus(projectId)),
	}),
)(withTranslation()(InformationBar))
