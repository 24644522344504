import { API } from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import {
	CHANGE_FIELD,
	CREATE_LINKEDIN_IMAGE_POST,
	CREATE_LINKEDIN_IMAGE_SCHEDULED_POST,
	CREATE_LINKEDIN_POST,
	CREATE_LINKEDIN_SCHEDULED_POST,
	DELETE_FACEBOOK_POST,
	DELETE_INSTAGRAM_SCHEDULED_POST,
	DELETE_LINKEDIN_POST_BY_ID,
	EDIT_SCHEDULED_INSTAGRAM_POST,
	EDIT_SOME_FACEBOOK_POST,
	GET_FACEBOOK_POST_ATTACHMENTS,
	GET_FACEBOOK_POST_ATTACHMENTS_SUCCESS,
	GET_INSTAGRAM_ACCOUNT,
	GET_INSTAGRAM_ACCOUNT_SUCCESS,
	GET_LINKEDIN_POSTS,
	GET_LINKEDIN_POST_BY_ID,
	GET_LINKEDIN_POST_BY_ID_SUCCESS,
	GET_LINKEDIN_SCHEDULED_POSTS_ALL,
	GET_LINKEDIN_SCHEDULED_POST_BY_ID,
	GET_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS,
	PUBLISH_INSTAGRAM_POST,
	PUBLISH_INSTAGRAM_POST_SUCCESS,
	RESET_DRAFT_SOME_POST,
	SCHEDULE_FACEBOOK_POST,
	SCHEDULE_FACEBOOK_POST_SUCCESS,
	SCHEDULE_INSTAGRAM_POST,
	SCHEDULE_INSTAGRAM_POST_SUCCESS,
	UPDATE_LINKEDIN_POST_BY_ID,
	UPDATE_LINKEDIN_POST_BY_ID_SUCCESS,
	UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID,
	UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS,
	UPLOAD_FACEBOOK_PHOTO,
	UPLOAD_SCHEDULED_INSTAGRAM_PHOTO,
} from './types'
import { ActionChangeField } from './ads'
import { GetProjectById } from './project'
import { ActionWithoutPayload } from './audit'
import { PostImage, DraftSomePost } from '../reducers/some-post'
import { AiImageGenerator } from './ai-content'

interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P }
}

export type LinkedInPost = {
	id: string
	createdAt: string
	message: string
	published: boolean
	type: 'linkedIn'
}

export type ChangeSomePostField = ActionChangeField<
	typeof CHANGE_FIELD,
	{
		field: string
		value: string | boolean | PostImage[] | number | DraftSomePost
	}
>
export type GetLinkedInPostById = Action<
	typeof GET_LINKEDIN_POST_BY_ID_SUCCESS,
	any
>
export type ScheduleFacebookPost = Action<
	typeof SCHEDULE_FACEBOOK_POST_SUCCESS,
	ScheduleFacebookPostRequestBody
>
export type ScheduleInstagramPost = Action<
	typeof SCHEDULE_INSTAGRAM_POST_SUCCESS,
	PublishInstagramPostRequestBody
>
export type PostInstagramPost = Action<
	typeof PUBLISH_INSTAGRAM_POST_SUCCESS,
	PublishInstagramPostRequestBody
>
type GetInstagramAccount = Action<
	typeof GET_INSTAGRAM_ACCOUNT_SUCCESS,
	{
		id: string
	}
>
type ResetDraftSomePost = ActionWithoutPayload<typeof RESET_DRAFT_SOME_POST>
type GetFacebookPostAttachments = ActionWithoutPayload<
	typeof GET_FACEBOOK_POST_ATTACHMENTS
>
type GetFacebookPostAttachmentsSuccess = Action<
	typeof GET_FACEBOOK_POST_ATTACHMENTS_SUCCESS,
	any
>
export type GetLinkedInScheduledPostById = Action<
	typeof GET_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS,
	any
>
export type GetLinkedInScheduledPostAll = Action<
	typeof GET_LINKEDIN_SCHEDULED_POSTS_ALL,
	any
>
export type UpdateLinkedInScheduledPostById = Action<
	typeof UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID_SUCCESS,
	any
>

export type SomePostActions =
	| ChangeSomePostField
	| ScheduleFacebookPost
	| GetInstagramAccount
	| GetProjectById
	| PostInstagramPost
	| ScheduleInstagramPost
	| ResetDraftSomePost
	| GetFacebookPostAttachments
	| GetFacebookPostAttachmentsSuccess
	| GetLinkedInPostById
	| GetLinkedInScheduledPostById
	| GetLinkedInScheduledPostAll
	| UpdateLinkedInScheduledPostById
	| AiImageGenerator

export const changeSomePostField = (
	field: string,
	value: string | boolean | PostImage[] | number | DraftSomePost,
): ChangeSomePostField => {
	return {
		type: CHANGE_FIELD,
		payload: {
			field,
			value,
		},
	}
}
export type ScheduleFacebookPostPayload = {
	projectId: number
	postContent: string
	toBePublished: string
	scheduledTime: string
	link?: string
	file?: any
}
export type ScheduleFacebookPostRequestBody = {
	postContent: string
	toBePublished: string
	scheduledTime: string
	link?: string
	file?: any
}
export const scheduleFacebookPost = (
	projectId: number,
	type: string,
	requestBody: ScheduleFacebookPostRequestBody,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'type'],
		[projectId, type],
		API.SCHEDULE_FACEBOOK_POST,
	)
	return {
		type: SCHEDULE_FACEBOOK_POST,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}

export const getInstagramAccount = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_INSTAGRAM_ACCOUNT,
	)
	return {
		type: GET_INSTAGRAM_ACCOUNT,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export type GetFacebookPostAttachmentsRequestBody = {
	postId: string
	postType: string
}

export const getFacebookPostAttachments = (
	projectId: number,
	requestBody: GetFacebookPostAttachmentsRequestBody,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_FACEBOOK_POST_ATTACHMENTS,
	)
	return {
		type: GET_FACEBOOK_POST_ATTACHMENTS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}

export type PublishInstagramPostRequestBody = {
	instagramId: string
	caption: string
	files: Blob[]
}

export const publishInstagramPost = (
	projectId: number,
	requestBody: PublishInstagramPostRequestBody,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.PUBLISH_INSTAGRAM_POST,
	)
	return {
		type: PUBLISH_INSTAGRAM_POST,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}

export const createLinkedInPost = (
	projectId: number,
	requestBody: { content: string; scheduledTime: string },
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.POST_LINKEDIN_POST,
	)
	return {
		type: CREATE_LINKEDIN_POST,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}
export const createLinkedInScheduledPost = (
	projectId: number,
	requestBody: { content: string; scheduledTime: string },
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.CREATE_LINKEDIN_SCHEDULED_POST,
	)
	return {
		type: CREATE_LINKEDIN_SCHEDULED_POST,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}
export const createLinkedInImagePost = (
	projectId: number,
	requestBody: { content: string; files: Blob[] },
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.POST_LINKEDIN_IMAGE_POST,
	)
	return {
		type: CREATE_LINKEDIN_IMAGE_POST,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}
export const createLinkedInImageScheduledPost = (
	projectId: number,
	requestBody: { content: string; scheduledTime: string; files: Blob[] },
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.CREATE_LINKEDIN_IMAGE_SCHEDULED_POST,
	)
	return {
		type: CREATE_LINKEDIN_IMAGE_SCHEDULED_POST,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}
export const updateLinkedInPost = (
	projectId: number,
	postId: string,
	postType: string,
	requestBody: { content: string },
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'postId', 'postType'],
		[projectId, postId, postType],
		API.UPDATE_LINKEDIN_POST,
	)
	return {
		type: UPDATE_LINKEDIN_POST_BY_ID,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}
export const updateLinkedInScheduledPostById = (
	projectId: number,
	postId: string,
	requestBody: { content: string; files?: Blob[] },
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'postId'],
		[projectId, postId],
		API.UPDATE_LINKEDIN_SCHEDULED_POST,
	)
	return {
		type: UPDATE_LINKEDIN_SCHEDULED_POST_BY_ID,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}
export const getLinkedInPosts = (
	projectId: number,
	from: string,
	to: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_LINKEDIN_POSTS,
	)
	return {
		type: GET_LINKEDIN_POSTS,
		payload: {
			request: {
				method: 'GET',
				url: `${apiUri}?from=${from}&to=${to}`,
			},
		},
	}
}
export const getLinkedInScheduledPostsAll = (
	projectId: number,
	// from: string,
	// to: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_LINKEDIN_SCHEDULED_POST_ALL,
	)
	return {
		type: GET_LINKEDIN_SCHEDULED_POSTS_ALL,
		payload: {
			request: {
				method: 'GET',
				url: `${apiUri}`,
				// ?from=${from}&to=${to}`,
			},
		},
	}
}
export const getLinkedInPostById = (
	projectId: number,
	postId: string,
	postType: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'postId', 'postType'],
		[projectId, postId, postType],
		API.GET_LINKEDIN_POST_BY_ID,
	)
	return {
		type: GET_LINKEDIN_POST_BY_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const getLinkedInScheduledPostById = (
	projectId: number,
	postId: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'postId'],
		[projectId, postId],
		API.GET_LINKEDIN_SCHEDULED_POST_BY_ID,
	)
	return {
		type: GET_LINKEDIN_SCHEDULED_POST_BY_ID,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const deleteLinkedInPostById = (
	projectId: number,
	postId: string,
	postType: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'postId', 'postType'],
		[projectId, postId, postType],
		API.GET_LINKEDIN_POST_BY_ID,
	)

	return {
		type: DELETE_LINKEDIN_POST_BY_ID,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUri,
			},
		},
	}
}

export const deleteLinkedInScheduledPostById = (
	projectId: number,
	postId: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'postId'],
		[projectId, postId],
		API.GET_LINKEDIN_SCHEDULED_POST_BY_ID,
	)

	return {
		type: DELETE_LINKEDIN_POST_BY_ID,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUri,
			},
		},
	}
}

export const scheduleInstagramPost = (
	projectId: number,
	requestBody: PublishInstagramPostRequestBody,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.SCHEDULE_INSTAGRAM_POST,
	)
	return {
		type: SCHEDULE_INSTAGRAM_POST,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}
export const resetDraftSomePost = (): any => {
	return {
		type: RESET_DRAFT_SOME_POST,
	}
}

export type FacebookMediaAttachment = {
	media_fbid: string
	message: string
}

export type EditSomeFacebookPostBody = {
	postId: string
	message: string
	attachedMedia?: FacebookMediaAttachment[]
}

export const editSomeFacebookPost = (
	projectId: number,
	requestBody: EditSomeFacebookPostBody,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.EDIT_SOME_FACEBOOK_POST,
	)
	return {
		type: EDIT_SOME_FACEBOOK_POST,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}

export type UploadFacebookPhotoBody = {
	scheduledTime?: string
	file?: any
}
export const uploadFacebookPhoto = (
	projectId: number,
	requestBody: UploadFacebookPhotoBody,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.UPLOAD_FACEBOOK_PHOTO,
	)
	return {
		type: UPLOAD_FACEBOOK_PHOTO,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}
export type InstagramItemsDto = {
	imageIds: string[]
	text: string
}

export const editScheduledInstagramPost = (
	accountId: number,
	projectId: number,
	requestBody: {
		postId: string
		instagramItem: InstagramItemsDto
		toBePublishedTime: string
	},
): any => {
	const apiUri = replaceParamsUri(
		['accountId', 'projectId'],
		[accountId, projectId],
		API.EDIT_SCHEDULED_INSTAGRAM_POST,
	)
	return {
		type: EDIT_SCHEDULED_INSTAGRAM_POST,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}

export type UploadScheduledInstagramPhotoBody = {
	file?: any
}
export const uploadScheduledInstagramPhoto = (
	projectId: number,
	requestBody: UploadScheduledInstagramPhotoBody,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.UPLOAD_SCHEDULED_INSTAGRAM_PHOTO,
	)
	return {
		type: UPLOAD_SCHEDULED_INSTAGRAM_PHOTO,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}

export const deleteFacebookPost = (
	projectId: number,
	requestBody: {
		postId: string
	},
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.DELETE_FACEBOOK_POST,
	)
	return {
		type: DELETE_FACEBOOK_POST,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}

export const deleteInstagramScheduledPost = (
	projectId: number,
	requestBody: {
		postId: string
	},
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.DELETE_INSTAGRAM_SCHEDULED_POST,
	)
	return {
		type: DELETE_INSTAGRAM_SCHEDULED_POST,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUri,
				data: requestBody,
			},
		},
	}
}
