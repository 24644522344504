import { SignOut } from '../actions/audit'
import { QuotaLimitActions } from '../actions/quota-limit'
import { GET_QUOTA_LIMIT_SUCCESS, SIGN_OUT } from '../actions/types'
import { EFeatureNameWithQuota } from '../constants'

export type QuotaLimitState = {
	quotas: {
		[key in EFeatureNameWithQuota]: {
			quotaLimit: number | 0
			requestUsed: number | 0
		}
	}
}

const initialState: QuotaLimitState = {
	quotas: {
		[EFeatureNameWithQuota.aiContent]: {
			quotaLimit: 0,
			requestUsed: 0,
		},
		[EFeatureNameWithQuota.keywordPlanner]: {
			quotaLimit: 0,
			requestUsed: 0,
		},
		[EFeatureNameWithQuota.priceScalping]: {
			quotaLimit: 0,
			requestUsed: 0,
		},
	},
}

export const quotaLimitReducer = (
	state = initialState,
	action: QuotaLimitActions | SignOut,
): typeof initialState => {
	switch (action.type) {
		case GET_QUOTA_LIMIT_SUCCESS:
			return {
				...state,
				quotas: {
					...state.quotas,
					[action.payload.data.feature]: {
						quotaLimit: action.payload.data.quotaLimit,
						requestUsed: action.payload.data.requestUsed,
					},
				},
			}
		case SIGN_OUT:
			return initialState
		default:
			return state
	}
}

export const quotaLimitSelector = {
	getQuotaLimit: (state = initialState): QuotaLimitState['quotas'] =>
		state.quotas,
}
