import React, { ReactElement } from 'react'
import { GoogleAdItem } from '../../../constants'
// import { GoogleAdKeywordItem } from '../../../constants'
import { fromMicrons } from '../../../helpers/dataTransformer'
import { GoogleAdsFilterOptions } from '../../../reducers/ads'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'

interface Props {
	ad: GoogleAdItem
	size: GoogleAdsFilterOptions['size']
	index?: number
}

export default function GoogleAdsItem({
	ad,
	size,
	index,
}: Props): ReactElement {
	const { t } = useTranslation()
	return (
		<div
			key={index || ad.adGroupName}
			className={`ads-visualisation-page__content--container__adGroups--item small blue-border ${ad.color} ${size}`}
		>
			{ad.headlines &&
			Array.isArray(ad.headlines) &&
			ad.headlines.length > 0 ? (
				ad.headlines.map((headline: any, i: number) => {
					if (i === 0) {
						if (typeof headline !== 'string' && headline.text) {
							return (
								<div
									key={i}
									className={
										'ads-visualisation-page__content--container__campaigns--item-header'
									}
								>
									<span />
									<h6 onClick={() => ad.onClick(ad)}>{headline.text}</h6>
									<>
										{ad.approving ? (
											<>{'APPROVING'}</>
										) : (
											<span
												className={
													'ads-visualisation-page__content--container__campaigns--item-header edit'
												}
												onClick={() => ad.onEditClick(ad)}
											>
												<EditIcon />
											</span>
										)}
									</>
								</div>
							)
						} else {
							return (
								<div
									className={
										'ads-visualisation-page__content--container__campaigns--item-header'
									}
									key={i}
								>
									<span />
									<h6 onClick={() => ad.onClick(ad)}>{headline}</h6>
									<>
										{ad.approving ? (
											<>{'APPROVING'}</>
										) : (
											<span
												className={
													'ads-visualisation-page__content--container__campaigns--item-header edit'
												}
												onClick={() => ad.onEditClick(ad)}
											>
												<EditIcon />
											</span>
										)}
									</>
								</div>
							)
						}
					} else {
						return null
					}
				})
			) : (
				<h6 onClick={() => ad.onClick(ad)}>{'Dynamic ad'}</h6>
			)}
			<div className='ads-visualisation-page__content--container__adGroups--item--keyword'>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item border'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.clicks')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{ad.clicks}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item border'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.roas')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{ad.ROAS}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item border'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.conversions')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{ad.conversions}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item border'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.price per conversion')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{`${ad.pricePerConversion} €`}
					</p>
				</div>
				<div
					className={`${'ads-visualisation-page__content--container__adGroups--item--keyword-item border'} ${size}`}
				>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item key'>
						{t('ads visualization.average cpc')}
					</p>
					<p className='ads-visualisation-page__content--container__adGroups--item--keyword-item value'>
						{`${ad.averageCPC}€`}
					</p>
				</div>
			</div>
		</div>
	)
}
