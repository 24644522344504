/**
 * Root reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

// Login
// User
import { adsReducer, adsSelectors } from './ads'
import { userReducer, userSelectors } from './user'
import { projectReducer, projectSelectors } from './project'
import { paymentReducer } from './payment'
import { keywordsReducer } from './keywords'
import { loadingReducer, loadingSelectors } from './loading'
import { dashboardReducer, dashboardSelectors } from './dashboard'
import { messageReducer, messageSelectors } from './message'
import { auditReducer, auditSelectors } from './audit'
import { reportReducer, reportSelectors } from './report'
import { tutorialReducer } from './tutorial'
import { aiContentReducer } from './ai-content'
import { quotaLimitReducer } from './quota-limit'
import { somePostReducer } from './some-post'
import { errorReducer } from './error'
import { wpReducer, wpSelectors } from './wp'
import { taskReducer, taskSelectors } from './task'
import { commentReducer, commentSelectors } from './comment'
import { rankPredictionReducer } from './rank-prediction'
import { priceScalpingReducer } from './price-scalper'
import { categoryMapReducer } from './category-creator'
const history = createBrowserHistory()

export const historyReducer = connectRouter(history)

// export root reducer
export const rootReducer = combineReducers({
	somePost: somePostReducer,
	ai: aiContentReducer,
	ads: adsReducer,
	error: errorReducer,
	message: messageReducer,
	router: historyReducer,
	user: userReducer,
	project: projectReducer,
	loading: loadingReducer,
	dashboard: dashboardReducer,
	payment: paymentReducer,
	report: reportReducer,
	keywords: keywordsReducer,
	audit: auditReducer,
	tutorial: tutorialReducer,
	quotaLimit: quotaLimitReducer,
	wp: wpReducer,
	task: taskReducer,
	comment: commentReducer,
	rankPredictor: rankPredictionReducer,
	priceScalping: priceScalpingReducer,
	categoryMap: categoryMapReducer,
})

export type DashboaReducerState = ReturnType<typeof rootReducer>

// export history
export { history }

// export getters/ selectors
export {
	userSelectors,
	loadingSelectors,
	projectSelectors,
	dashboardSelectors,
	messageSelectors,
	auditSelectors,
	adsSelectors,
	reportSelectors,
	wpSelectors,
	taskSelectors,
	commentSelectors,
}
